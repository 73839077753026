import { CloseCircleOutlined, DoubleLeftOutlined } from "@ant-design/icons";
import { Button, Popover, Result, Skeleton } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import logAction from "utils/logActions";
import openNotification from "utils/notification";
import * as API from "../../../../../api/API";
import { selectAccountingFirm } from "../../../../../slices/accountingFirmSlice";
import { selectClient } from "../../../../../slices/clientSlice";
import "../../../MissingDocuments.css";
import Type from "./Type";

const howManyDocuments = (data) => {
  let total = 0;
  data.forEach((element) => element.operations.forEach(() => total++));
  return total;
};

const views = {
  1: [
    { id: "enc", label: "Encaissements à justifier", data: [] },
    { id: "dec", label: "Décaissements à justifier", data: [] },
  ],
  2: [
    { id: "ach", label: "Achats", data: [] },
    { id: "ven", label: "Ventes", data: [] },
    { id: "att", label: "Compte d'attente et autres comptes", data: [] },
  ],
  3: [],
};

function Index(props) {
  const clientId = props.match.params.clientId;
  const clientRemoteId = props.match.params.clientRemoteId;

  const client = useSelector(selectClient);
  const accountingFirm = useSelector(selectAccountingFirm);

  const [missingDocData, setMissingDocData] = useState();
  const [structuredOp, setStructuredOp] = useState();
  const [error, setError] = useState(false);
  const [ghostDocuments, setGhostDocuments] = useState([]);
  const [mode, setMode] = useState(1);
  const [downloading, setDownloading] = useState(false);
  const [allExpanded, setAllExpanded] = useState(false);

  useEffect(() => {
    if (!client.missingDocView) return;
    setMode(client.missingDocView);
  }, [client.missingDocView]);

  useEffect(() => {
    if (!props.wait)
      (async () => {
        setMissingDocData();
        let res = await API.getMissingDocuments(clientId);
        if (res.status === 200) {
          res = await res.json();
          if (accountingFirm.fec === true) {
            const missingDocDataWithoutGhost = {
              ...res,
              operationCategories: res.operationCategories.map((category) => ({
                ...category,
                operations: category.operations.filter((operation) => operation.ghost !== 1),
              })),
            };
            setMissingDocData(missingDocDataWithoutGhost);
          } else {
            setMissingDocData(res);
          }
        } else {
          setError(true);
          props.setBadgeReceived(0);
        }
      })();
  }, [props.wait, clientRemoteId]);

  useEffect(() => {
    if (!missingDocData || !mode) return;
    let structuredOpTmp = JSON.parse(JSON.stringify(views[mode]));

    let totalAmount = 0;

    const filtered = missingDocData.operationCategories.reduce((acc, elt) => {
      const op = elt.operations.filter(
        (op) => op.fileId || op.files?.length > 0 || op.comment || op.isLost || op.isLostNoAccount
      );

      if (op.length > 0) {
        op.sort((a, b) => b.date - a.date);

        totalAmount += op.reduce((sum, operation) => sum + Math.abs(operation.amount), 0);

        acc.push({
          ...elt,
          operations: op,
          accountNumber:
            missingDocData.operationCategories.find((acc) => acc.title === elt.title)
              ?.accountNumber || "",
        });
      }

      return acc;
    }, []);
    filtered.sort(function (a, b) {
      var titleA = a.accountNumber
        ? a.accountNumber.trim().toUpperCase()
        : a.title.trim().toUpperCase();
      var titleB = b.accountNumber
        ? b.accountNumber.trim().toUpperCase()
        : b.title.trim().toUpperCase();
      return titleA.localeCompare(titleB, undefined, {
        numeric: true,
        sensitivity: "base",
      });
    });

    const processOperationCategories = (conditionFn, indexFn) => {
      filtered.forEach((account) => {
        if (conditionFn(account)) {
          const index = indexFn(account);
          structuredOpTmp[index].data.push(account);
        }
      });
    };

    switch (mode) {
      case 1:
        processOperationCategories(
          (account) => account?.operations?.length > 0,
          (account) => (account.operations[0].amount > 0 ? 0 : 1)
        );
        break;
      case 2:
        processOperationCategories(
          () => true,
          (account) => (account.accountNumber === "40" ? 0 : account.accountNumber === "41" ? 1 : 2)
        );
        break;
      case 3:
        const catByJournal = {};
        const opByJournal = {};

        filtered.forEach((item) => {
          item.operations.forEach((operation) => {
            if (!catByJournal[operation.journal]) {
              catByJournal[operation.journal] = {};
            }
            if (!catByJournal[operation.journal][item.accountNumberAux]) {
              catByJournal[operation.journal][item.accountNumberAux] = {
                title: item.title,
                operations: [],
              };
            }
            catByJournal[operation.journal][item.accountNumberAux].operations.push(operation);

            if (!opByJournal[operation.journal]) {
              opByJournal[operation.journal] = [];
            }
            opByJournal[operation.journal].push(operation);
          });
        });

        structuredOpTmp = Object.keys(catByJournal).map((journal) => ({
          id: journal,
          label: Object.values(catByJournal[journal])[0].operations[0].journalLabel,
          data: Object.keys(catByJournal[journal]).map((accountNumberAux) => ({
            id: accountNumberAux + journal,
            accountNumberAux,
            title: catByJournal[journal][accountNumberAux].title,
            operations: catByJournal[journal][accountNumberAux].operations,
          })),
        }));

        break;
      default:
        break;
    }
    setStructuredOp(structuredOpTmp);

    let currentGhostDocs = [];
    filtered.forEach((element) => {
      element.operations.length > 0 &&
        element.operations.forEach((op) => {
          if (op.ghost === 1) {
            op["categoryId"] = element.categoryId;
            currentGhostDocs.push(op);
          }
        });
    });
    setGhostDocuments(currentGhostDocs);

    let tmpNbrReceived = howManyDocuments(filtered);
    setAllExpanded(tmpNbrReceived < process.env.REACT_APP_EXPAND_LIMIT);
    props.setBadgeReceived(tmpNbrReceived + props.nbrOthers);
    props.setReceived(tmpNbrReceived);
    props.setNumberReceivedDoc(tmpNbrReceived);
    props.setAmountReceivedDoc(totalAmount);
  }, [missingDocData, mode]);

  const downloadS3 = (backup) => {
    (async () => {
      let res = await API.getExportS3(client._id, backup);
      if (res.status === 500) {
        openNotification("error", "Erreur lors de la récupération du backup");
        setDownloading(false);
      } else {
        res = await res.blob();
        const url = URL.createObjectURL(res);
        const link = document.createElement("a");

        link.setAttribute("href", url);
        link.setAttribute("download", "Fichiers - " + client.name + ".zip");
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setDownloading(false);
      }
    })();
  };

  const deleteOperationInUI = (categoryId, operationId) => {
    setStructuredOp((prev) =>
      prev.map((view) => ({
        ...view,
        data: view.data
          .map((cat) => ({
            ...cat,
            operations: cat.operations.filter((op) => op._id !== operationId),
          }))
          .filter((cat) => cat.operations.length > 0),
      }))
    );
    props.setReceived((prev) => prev - 1);
    props.setBadgeReceived((prev) => prev - 1);
    props.setNumberReceivedDoc((prev) => prev - 1);
  };

  return (
    <>
      {error ? (
        <>
          <Result
            icon={<CloseCircleOutlined style={{ color: "#4569f8" }} />}
            title="Erreur de chargement des pièces reçues"
            subTitle={
              <>
                <p>Nous ne parvenons pas à récupérer les pièces déposées par votre client. </p>
                <p>Actualisez la page ou téléchargez le back-up si besoin. </p>
              </>
            }
            extra={
              <Button
                className="other-action-btn"
                onClick={(e) => {
                  e.preventDefault();
                  logAction(240, 1, client._id);
                  setDownloading(true);
                  downloadS3("2");
                }}
                loading={downloading}
                style={{ top: "-10px" }}
              >
                Télécharger le back-up depuis 2 mois
              </Button>
            }
          />
        </>
      ) : !missingDocData ? (
        <Skeleton
          className="skeleton-analysis"
          active={true}
          paragraph={{
            rows: 4,
          }}
        />
      ) : (
        <div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Popover
              placement="top"
              content={allExpanded ? "Tout replier" : "Tout déplier"}
              overlayClassName="navbar-popover-overlay"
            >
              <Button
                className="expand-all-icon"
                icon={
                  <DoubleLeftOutlined
                    className={allExpanded ? "expand-icon-down" : "expand-icon-up"}
                  />
                }
                shape="circle"
                onClick={() => setAllExpanded(!allExpanded)}
              />
            </Popover>
          </div>
          {structuredOp &&
            structuredOp.map((view) => (
              <Type
                {...props}
                key={view.id}
                id={view.id}
                mode={mode}
                data={view.data}
                allData={structuredOp}
                setAllData={setStructuredOp}
                label={view.label}
                allExpanded={allExpanded}
                onDeleteOperation={(obj) => deleteOperationInUI(obj.categoryId, obj.operationId)}
              />
            ))}
          <p>
            Je ne retrouve plus mes pièces.{" "}
            <Button
              type="link"
              onClick={(e) => {
                e.preventDefault();
                logAction(240, 1, client._id);
                setDownloading(true);
                downloadS3("2");
              }}
              style={{ paddingLeft: "0px" }}
              loading={downloading}
            >
              Télécharger le backup depuis 2 mois.
            </Button>
          </p>
        </div>
      )}
    </>
  );
}

export default Index;

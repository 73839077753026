import {
  Alert,
  Button,
  Card,
  Divider,
  InputNumber,
  Menu,
  Radio,
  Space,
  Tabs,
  Upload,
  message,
} from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { ChromePicker } from "react-color";
import { useDispatch, useSelector } from "react-redux";
import * as API from "../../api/API";
import { ReactComponent as ImgPalette } from "../../assets/images/palette-de-peinture.svg";
import { selectAccountingFirm, updateAccountingFirm } from "../../slices/accountingFirmSlice";
import logAction from "../../utils/logActions";
import openNotification from "../../utils/notification";
import FileSettings from "./FileSettings";
import TemplateMail from "./TemplateMail";
import TemplateMailForms from "./TemplateMailForms";

import Icon, {
  EllipsisOutlined,
  ExclamationCircleOutlined,
  MailOutlined,
  PaperClipOutlined,
  SolutionOutlined,
} from "@ant-design/icons";
import TipTapEditorSimple from "utils/tiptap/TipTapEditorSimple";

const { TabPane } = Tabs;

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

const allMailTemplatesInit = [
  {
    type: "analysis",
    subject: "[<nom_dossier_client>] Votre analyse comptable - <mois_analyse>",
    body: [
      {
        para: "begining",
        text: "Bonjour, <br/><br/>Veuillez trouver ci-dessous l’analyse comptable de la société &lt;nom_dossier_client&gt; pour le mois &lt;mois_analyse&gt;.<br/><br/>&lt;signature_collab&gt;",
      },
    ],
  },
  {
    type: "missingdoc",
    subject: "[<nom_dossier_client>] Vos justificatifs manquants",
    linkText: "Accéder à la liste des justificatifs manquants",
    body: [
      {
        para: "begining",
        text: "Bonjour,<br/><br/>Il manque actuellement &lt;nb_justif&gt; justificatifs à votre dossier pour un montant total de &lt;montant_justif&gt; € TTC.<br/><br/>Je vous invite à me les transmettre dès que possible. Je reste à votre disposition pour toute question.",
      },
      {
        para: "ending",
        text: "(Ce lien est valable pour une durée de 60 jours) <br/><br/> &lt;signature_collab&gt;",
      },
    ],
  },
  {
    type: "missingdocreminder",
    subject: "[<nom_dossier_client>] Vos justificatifs manquants",
    linkText: "Accéder à la liste des justificatifs manquants",
    body: [
      {
        para: "begining",
        text: "Bonjour,<br/><br/>Nous nous permettons de vous relancer car &lt;nb_justif&gt; points en suspens sont encore en attente d’un justificatif.  <br/><br/>Je vous invite à me les transmettre dès que possible. ",
      },
      {
        para: "ending",
        text: "(Ce lien est valable pour une durée de 60 jours) <br/><br/> &lt;signature_collab&gt;",
      },
    ],
  },
  {
    type: "outstandingsclients",
    subject: "[<nom_dossier_client>] Vos encours clients",
    linkText: "Accéder à la liste des factures non réglées",
    body: [
      {
        para: "begining",
        text: "Bonjour,<br/><br/>&lt;nb_factures&gt; factures clients n'ont pas été réglées pour un montant total de &lt;montant_factures&gt; € TTC.<br/><br/>Je vous invite à réviser ces éléments et à indiquer, le cas échéant, le mode de paiement utilisé et la date de celui-ci.",
      },
      {
        para: "ending",
        text: "(Ce lien est valable pour une durée de 60 jours) <br/><br/> &lt;signature_collab&gt;",
      },
    ],
  },
  {
    type: "outstandingsproviders",
    subject: "[<nom_dossier_client>] Vos encours fournisseurs",
    linkText: "Accéder à la liste des factures non réglées",
    body: [
      {
        para: "begining",
        text: "Bonjour,<br/><br/>&lt;nb_factures&gt; factures fournisseurs n'ont pas été réglées pour un montant total de &lt;montant_factures&gt; € TTC.<br/><br/>Je vous invite à réviser ces éléments et à indiquer, le cas échéant, le mode de paiement utilisé et la date de celui-ci.",
      },
      {
        para: "ending",
        text: "(Ce lien est valable pour une durée de 60 jours) <br/><br/> &lt;signature_collab&gt;",
      },
    ],
  },
  {
    type: "global",
    subject: "[<nom_dossier_client>] Vos points en suspens",
    linkText: "Accéder au détail",
    body: [
      {
        para: "begining",
        text: "Bonjour,<br/><br/>Voici les points actuellement en suspens sur votre dossier :",
      },
      {
        para: "missingdoc",
        text: "<ul><li>&lt;nb_justif&gt; justificatifs manquants pour un montant total de &lt;montant_justif&gt; € TTC</li></ul>",
      },
      {
        para: "outstandingsclients",
        text: "<ul><li>&lt;nb_factures&gt; factures clients non réglées pour un montant total de &lt;montant_factures&gt; € TTC</li></ul>",
      },
      {
        para: "outstandingsproviders",
        text: "<ul><li>&lt;nb_factures&gt; factures fournisseurs non réglées pour un montant total de &lt;montant_factures&gt; € TTC</li></ul>",
      },
      {
        para: "ending",
        text: "(Ce lien est valable pour une durée de 60 jours) <br/><br/> &lt;signature_collab&gt;",
      },
    ],
  },
  {
    type: "form",
    subject: "[<nom_cabinet>] - <nom_form>",
    linkText: "Accéder au formulaire",
    body: [
      {
        para: "begining",
        text: "Bonjour,<br/><br/>Je vous invite à remplir le formulaire &lt;nom_form&gt; avant le &lt;date_fin&gt;.<br/>Je reste à votre disposition pour tout complément d'information.",
      },
      {
        para: "ending",
        text: "&lt;signature_collab&gt;",
      },
    ],
  },
  {
    type: "formreminder",
    subject: "[<nom_cabinet>] - <nom_form>",
    linkText: "Accéder au formulaire",
    body: [
      {
        para: "begining",
        text: "Bonjour,<br/><br/>Je me permets de vous relancer au sujet du formulaire &lt;nom_form&gt;.<br/>A ce jour, vous avez rempli &lt;taux_completion&gt; de votre formulaire.<br/>Afin d'assurer le traitement de votre dossier, je vous remercie de compléter les informations manquantes avant le &lt;date_fin&gt;.<br/>Je reste à votre disposition pour tout complément d'information.",
      },
      {
        para: "ending",
        text: "&lt;signature_collab&gt;",
      },
    ],
  },
];

function Custom(props) {
  const dispatch = useDispatch();

  const accountingFirm = useSelector(selectAccountingFirm);
  const tokenClient = localStorage.getItem("token");

  const [color, setColor] = useState(accountingFirm.color || "#eaf4fe");
  const [logo, setLogo] = useState();
  const [logoFileId, setLogoFileId] = useState("");
  const [, setFileList] = useState();
  const [allMailTemplates, setAllMailTemplates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentTab, setCurrentTab] = useState("menu1");
  const [missingDocEndDate, setMissingDocEndDate] = useState();
  const [inputNumberValue, setInputNumberValue] = useState();
  const [messageLost, setMessageLost] = useState("");
  const [clientPageText, setClientPageText] = useState("");

  //const [reset, setReset] = useState(false);

  const PaletteIcon = (props) => <Icon component={ImgPalette} {...props} />;

  const menuItems = [
    {
      key: "sub1",
      label: "Modèles de mail",
      icon: <MailOutlined />,
      children: [
        {
          key: "menu1",
          label: "Mail analyse",
        },
        {
          key: "menu2",
          label: "Mail pièces manquantes",
        },
        {
          key: "menu3",
          label: "Mail relance automatique pièces manquantes",
        },
        {
          key: "menu4",
          label: "Mail encours clients",
        },
        {
          key: "menu5",
          label: "Mail encours fournisseurs",
        },
        {
          key: "menu6",
          label: "Mail relance globale",
        },
        {
          key: "menu7",
          label: "Mail d'envoi Forms",
        },
        {
          key: "menu8",
          label: "Mail relance Forms",
        },
      ],
    },

    {
      key: "sub2",
      label: "Design du cabinet",
      icon: <PaletteIcon />,
      children: [
        {
          key: "menu9",
          label: "Logo",
        },
        {
          key: "menu10",
          label: "Couleur de fond",
        },
      ],
    },
    {
      key: "sub3",
      label: "Formulaire client",
      icon: <SolutionOutlined />,
      children: [
        {
          key: "menu11",
          label: "Paragraphe formulaire",
        },
        {
          key: "menu12",
          label: "Message Pièce perdue",
        },
      ],
    },
    {
      key: "sub4",
      label: "Fichiers",
      icon: <PaperClipOutlined />,
      children: [
        {
          key: "menu13",
          label: "Taille et formats des fichiers",
        },
      ],
    },
    {
      key: "menu14",
      label: "Autres",
      icon: <EllipsisOutlined />,
    },
  ];

  const initMessageLost =
    "<p><span>Nous attirons votre attention sur le fait que la non présentation de la pièce justificative entraînera immédiatement la non récupération de la TVA et en cas de contrôle : </span><ul><li> la non déductibilité de la charge</li></ul><span>et, en fonction de votre régime d’imposition : </span><ul><li> un rappel d’impôt sur le revenu et un complément de charges sociales</li></ul><span>ou : </span><ul><li> un rappel d'impôt sur les sociétés</li></ul></p>";
  const initClientPageText =
    "<p> Bonjour,</p><p>Voici la liste des mouvements pour lesquels un justificatif est manquant. Nous vous invitons à transmettre la pièce correspondante et à indiquer la nature du mouvement le cas échéant.</p>";

  useEffect(() => {
    if (accountingFirm.missingDocEndDate) {
      if (accountingFirm.missingDocEndDate === 101 || accountingFirm.missingDocEndDate === 102) {
        setMissingDocEndDate(accountingFirm.missingDocEndDate);
      } else {
        setMissingDocEndDate(103);
        setInputNumberValue(accountingFirm.missingDocEndDate);
      }
    } else {
      setMissingDocEndDate(101);
      setInputNumberValue(15);
    }

    if (accountingFirm.lostDocCustomText) {
      setMessageLost(accountingFirm.lostDocCustomText);
    } else {
      setMessageLost(initMessageLost);
    }

    if (accountingFirm.clientPageCustomText) {
      setClientPageText(accountingFirm.clientPageCustomText);
    } else {
      setClientPageText(initClientPageText);
    }
  }, []);

  useEffect(() => {
    (async () => {
      var templates = [];
      setLogoFileId(accountingFirm.logoFile?._id);

      allMailTemplatesInit.forEach((elt) => {
        const currentTemplate = Object.keys(accountingFirm).find(
          (key) => key.toLowerCase() === "templatemail" + elt.type.toLowerCase()
        );
        templates.push({
          type: elt.type,
          subject: accountingFirm[currentTemplate]?.subject || elt.subject,
          linkText: accountingFirm[currentTemplate]?.linkText || elt.linkText,
          body: accountingFirm[currentTemplate]?.body || elt.body,
        });
      });
      setAllMailTemplates(templates);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      let res = await API.getAccountingFirmLogo(accountingFirm._id);
      if (res.status === 200) {
        const data = await res.blob();
        setLogo(URL.createObjectURL(data));
        setLoading(false);
      } else {
        console.log("error");
        setLoading(false);
      }
    })();
  }, []);

  const handleChangeColor = (color) => {
    setColor(color.hex);
    logAction(121, 1);
    (async () => {
      const res = await API.putAccountingFirm(accountingFirm._id, { color: color.hex });
      if (res.status === 201) {
        openNotification("success", "Modification effectuée");
        dispatch(updateAccountingFirm({ color: color.hex }));
      } else openNotification("error", "Erreur lors de la modification de la couleur");
    })();
  };

  const handleChange = (info) => {
    if (info.file.status === "done") {
      (async () => {
        if (logoFileId !== "") {
          await API.removeAccountingFirmLogo(accountingFirm._id, {
            fileId: logoFileId,
          });
        }
        let formData = new FormData();
        formData.append("files", info.file.originFileObj, info.file.name);
        formData.set("accountingFirmId", accountingFirm._id);
        const url = process.env.REACT_APP_INTERFACE_BACKEND + "/accountingFirm/logo";
        axios({
          url,
          method: "post",
          headers: {
            Authorization: `Bearer ${tokenClient}`,
          },
          processData: false,
          data: formData,
        })
          .then((response) => {
            dispatch(updateAccountingFirm({ logoFile: response.data.logoFile }));
            getBase64(info.file.originFileObj, (img) => setLogo(img));
            setFileList(info.file);
            setLogoFileId(response.data.logoFile._id);
          })
          .catch(function (error) {
            console.log(error);
          });
      })();
    }
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("Uniquement les fichiers JPG/PNG sont acceptés.");
    }
    /*const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;*/
    return isJpgOrPng;
  };

  const resetLogo = () => {
    setLogo();
    if (logoFileId !== "") {
      (async () => {
        await API.removeAccountingFirmLogo(accountingFirm._id, {
          fileId: logoFileId,
        });
        setLogoFileId("");
      })();
    }
  };

  const handleMenuClick = (e) => {
    setCurrentTab(e.key);
  };

  const onMissingDocEndDateChange = (e) => {
    setMissingDocEndDate(e.target.value);
  };

  const onNumberInputChange = (value) => {
    setInputNumberValue(value);
  };

  const saveMissingDocEndDate = async () => {
    let valueToSave;
    if (missingDocEndDate === 103) {
      valueToSave = inputNumberValue;
    } else {
      valueToSave = missingDocEndDate;
    }

    const res = await API.putAccountingFirm(accountingFirm._id, { missingDocEndDate: valueToSave });
    if (res.status === 201) {
      openNotification("success", "Modification effectuée");
      dispatch(updateAccountingFirm({ missingDocEndDate: valueToSave }));
    } else openNotification("error", "Erreur lors de la modification de la date");
  };

  const handleClientPageTextChange = (reset) => {
    (async () => {
      const res = await API.putAccountingFirm(accountingFirm._id, {
        clientPageCustomText: reset ? initClientPageText : clientPageText,
      });
      if (res.status === 201) {
        openNotification("success", "Modification effectuée");
        dispatch(updateAccountingFirm({ clientPageCustomText: clientPageText }));
      } else openNotification("error", "Erreur lors de la modification ");
    })();
  };

  const handleMessageLostChange = (reset) => {
    (async () => {
      const res = await API.putAccountingFirm(accountingFirm._id, {
        lostDocCustomText: reset ? initMessageLost : messageLost,
      });
      if (res.status === 201) {
        openNotification("success", "Modification effectuée");
        dispatch(updateAccountingFirm({ lostDocCustomText: messageLost }));
      } else openNotification("error", "Erreur lors de la modification ");
    })();
  };

  const renderContent = () => {
    switch (currentTab) {
      case "menu1":
        return (
          <div key="menu1">
            {" "}
            <TemplateMail
              {...props}
              type="analysis"
              mailTemplate={allMailTemplates.find((elt) => elt.type === "analysis")}
              mailTemplateInit={allMailTemplatesInit.find((elt) => elt.type === "analysis")}
            />
          </div>
        );
      case "menu2":
        return (
          <div key="menu2">
            {" "}
            <TemplateMail
              {...props}
              type="missingdoc"
              mailTemplate={allMailTemplates.find((elt) => elt.type === "missingdoc")}
              mailTemplateInit={allMailTemplatesInit.find((elt) => elt.type === "missingdoc")}
            />
          </div>
        );
      case "menu3":
        return (
          <div key="menu3">
            {" "}
            <TemplateMail
              {...props}
              type="missingdocreminder"
              mailTemplate={allMailTemplates.find((elt) => elt.type === "missingdocreminder")}
              mailTemplateInit={allMailTemplatesInit.find(
                (elt) => elt.type === "missingdocreminder"
              )}
            />
          </div>
        );
      case "menu4":
        return (
          <div key="menu4">
            {" "}
            <TemplateMail
              {...props}
              type="outstandingsclients"
              mailTemplate={allMailTemplates.find((elt) => elt.type === "outstandingsclients")}
              mailTemplateInit={allMailTemplatesInit.find(
                (elt) => elt.type === "outstandingsclients"
              )}
            />
          </div>
        );
      case "menu5":
        return (
          <div key="menu5">
            <TemplateMail
              {...props}
              type="outstandingsproviders"
              mailTemplate={allMailTemplates.find((elt) => elt.type === "outstandingsproviders")}
              mailTemplateInit={allMailTemplatesInit.find(
                (elt) => elt.type === "outstandingsproviders"
              )}
            />
          </div>
        );
      case "menu6":
        return (
          <div key="menu6">
            <TemplateMail
              {...props}
              type="global"
              mailTemplate={allMailTemplates.find((elt) => elt.type === "global")}
              mailTemplateInit={allMailTemplatesInit.find((elt) => elt.type === "global")}
            />
          </div>
        );
      case "menu7":
        return (
          <div key="menu7">
            <TemplateMailForms
              {...props}
              type="form"
              mailTemplate={allMailTemplates.find((elt) => elt.type === "form")}
              mailTemplateInit={allMailTemplatesInit.find((elt) => elt.type === "form")}
            />
          </div>
        );
      case "menu8":
        return (
          <div key="menu8">
            <TemplateMailForms
              {...props}
              type="formreminder"
              mailTemplate={allMailTemplates.find((elt) => elt.type === "formreminder")}
              mailTemplateInit={allMailTemplatesInit.find((elt) => elt.type === "formreminder")}
            />
          </div>
        );
      case "menu9":
        return (
          <div key="menu9">
            {" "}
            <p>
              Le logo apparaîtra dans le formulaire de chargement des justificatifs côté client
              ainsi que dans les signatures des emails sortants.
            </p>
            <Upload
              {...props}
              method="get"
              className="upload-logo-img"
              showUploadList={false}
              maxCount={1}
              listType="picture"
              onChange={handleChange}
              beforeUpload={beforeUpload}
            >
              {logo ? (
                <img src={logo} alt="logo" className="logo-display" style={{ maxHeight: "80px" }} />
              ) : null}
              <Button
                type="text"
                className="add-logo-img call-action-btn"
                onClick={(e) => {
                  e.preventDefault();
                  logAction(120, 1);
                }}
              >
                Charger un fichier
              </Button>
            </Upload>
            {logo ? (
              <Button
                className="other-action-btn reset-logo-img"
                onClick={(e) => {
                  e.preventDefault();
                  resetLogo();
                }}
              >
                Supprimer
              </Button>
            ) : null}
          </div>
        );
      case "menu10":
        return (
          <div key="menu10">
            <p>
              Définissez ici la couleur de fond du formulaire de chargement des justificatifs côté
              client et des emails sortants.
            </p>
            <ChromePicker
              className="color-picker"
              disableAlpha={true}
              color={color}
              onChangeComplete={handleChangeColor}
            />
            <Button
              className="call-action-btn reset-color-btn"
              onClick={(e) => {
                e.preventDefault();
                setColor("#eaf4fe");
                handleChangeColor({ hex: "#eaf4fe" });
              }}
            >
              Réinitialiser
            </Button>
          </div>
        );
      case "menu11":
        return (
          <div key="menu11">
            {" "}
            <p style={{ fontWeight: "bold" }}>Paragraphe formulaire client :</p>
            <TipTapEditorSimple content={clientPageText} setContent={setClientPageText} />
            <div style={{ textAlign: "right" }}>
              <Button
                className="other-action-btn "
                style={{ marginTop: 20, marginRight: 10 }}
                onClick={(e) => {
                  e.preventDefault();
                  setClientPageText(initClientPageText);
                  handleClientPageTextChange(true);
                }}
              >
                Réinitialiser
              </Button>
              <Button
                className="call-action-btn"
                style={{ marginTop: 20 }}
                onClick={(e) => {
                  e.preventDefault();
                  handleClientPageTextChange(false);
                }}
              >
                Valider
              </Button>
            </div>
          </div>
        );
      case "menu12":
        return (
          <div key="menu12">
            <p style={{ fontWeight: "bold" }}>Message d'alerte pièces perdues :</p>
            <TipTapEditorSimple content={messageLost} setContent={setMessageLost} />

            <div style={{ marginTop: 20 }}>
              <p style={{ fontWeight: "bold" }}>Aperçu :</p>
              <Card
                className="preview-lost"
                style={{ maxWidth: "500px" }}
                title={
                  <div>
                    <ExclamationCircleOutlined
                      style={{ color: "#FF9700", fontSize: "20px", marginRight: 10 }}
                    />{" "}
                    <span>Pensez-vous pouvoir retrouver ce justificatif ?</span>{" "}
                  </div>
                }
              >
                <Alert
                  className="modal-lost-alert"
                  description={
                    <div
                      dangerouslySetInnerHTML={{ __html: messageLost }}
                      style={{ display: "inline-block", width: "90%" }}
                    />
                  }
                  type="warning"
                />
              </Card>
            </div>
            <div style={{ textAlign: "right" }}>
              <Button
                className="other-action-btn "
                style={{ marginTop: 20, marginRight: 10 }}
                onClick={(e) => {
                  e.preventDefault();
                  setMessageLost(initMessageLost);
                  handleMessageLostChange(true);
                }}
              >
                Réinitialiser
              </Button>
              <Button
                className="call-action-btn"
                style={{ marginTop: 20 }}
                onClick={(e) => {
                  e.preventDefault();
                  handleMessageLostChange(false);
                }}
              >
                Valider
              </Button>
            </div>
          </div>
        );
      case "menu13":
        return (
          <div key="menu13">
            {" "}
            <p style={{ fontStyle: "italic", marginBottom: "25px" }}>
              Personnalisez ici la taille maximale et les formats de fichiers acceptés pour la
              fonctionnalité Pièces manquantes.
            </p>
            <FileSettings />
          </div>
        );
      case "menu14":
        return (
          <div key="menu14">
            {" "}
            <p style={{ fontWeight: "bold" }}>
              Pièces manquantes : date de fin de la période prise en compte (par défaut)
            </p>
            <Radio.Group onChange={onMissingDocEndDateChange} value={missingDocEndDate}>
              <Space direction="vertical">
                <Radio value={101}>Date du jour</Radio>
                <Radio value={102}>Dernier jour du mois précédent</Radio>
                <Radio value={103}>
                  Le dernier{" "}
                  <InputNumber
                    min={1}
                    defaultValue={accountingFirm.missingDocEndDate || 15}
                    max={31}
                    onChange={onNumberInputChange}
                    style={{
                      width: 70,
                      marginLeft: 10,
                    }}
                  />
                </Radio>
              </Space>
            </Radio.Group>
            <p style={{ fontStyle: "italic", fontSize: "12px", margin: "8px 0 8px 5px" }}>
              Si le mois concerné n’atteint pas la valeur renseignée, le dernier jour du mois
              précédent sera sélectionné.{" "}
            </p>
            <Button
              className="add-logo-img call-action-btn"
              onClick={saveMissingDocEndDate}
              style={{ marginTop: 20 }}
            >
              Sauvegarder
            </Button>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <>
      {loading ? (
        ""
      ) : (
        <div className="custom-block">
          <div style={{ display: "flex" }}>
            <Menu
              mode="inline"
              onClick={handleMenuClick}
              defaultSelectedKeys={["menu1"]}
              defaultOpenKeys={["sub1"]}
              style={{ width: 256 }}
            >
              {menuItems.map((item) => {
                if (item.children) {
                  return (
                    <Menu.SubMenu key={item.key} icon={item.icon} title={item.label}>
                      {item.children.map((child) => (
                        <Menu.Item key={child.key}>{child.label}</Menu.Item>
                      ))}
                    </Menu.SubMenu>
                  );
                }
                return (
                  <Menu.Item key={item.key} icon={item.icon}>
                    {item.label}
                  </Menu.Item>
                );
              })}
            </Menu>
            <Divider type="vertical" style={{ height: "auto", marginLeft: 24 }} />

            <div
              style={{ marginLeft: 24, padding: 24, background: "#fff", flex: 1, minHeight: 280 }}
            >
              <div style={{ display: "inline-block" }}>{renderContent()}</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default Custom;

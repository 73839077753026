import React from "react";

import DuplicatesClients from "./DuplicatesClients";
import OrphansClients from "./OrphansClients";
import Hubspot from "./Hubspot";
import Proabono from "./Proabono";
// import Orphans from "./Orphans";

import "../Dashboard.css";

function Supervisor() {
  return (
    <div className="dashboard">
      <DuplicatesClients />
      <OrphansClients />
      <Hubspot />
      <Proabono />
      {/* <Orphans /> */}
    </div>
  );
}

export default Supervisor;

import { useState } from "react";
import CheckboxFeat from "./CheckboxFeat";

function Features(props) {
  const [roleFeatures, setRoleFeatures] = useState(props.role.features);

  return (
    <>
      {props.role &&
        props.features.map((feat) => (
          <>
            <CheckboxFeat
              {...props}
              feature={feat}
              defaultChecked={props.role.features?.includes(feat._id)}
              roleFeatures={roleFeatures}
              setRoleFeatures={setRoleFeatures}
            />
            <br />
          </>
        ))}
    </>
  );
}

export default Features;

import { CommentOutlined } from "@ant-design/icons";
import { Popover, Table, Tag } from "antd";
import * as number from "../../utils/number";

const views = {
  0: { id: "other", label: "Autres", data: [] },
  1: [
    { id: "enc", label: "Encaissements à justifier", data: [] },
    { id: "dec", label: "Décaissements à justifier", data: [] },
  ],
  2: [
    { id: "ach", label: "Achats", data: [] },
    { id: "ven", label: "Ventes", data: [] },
    { id: "att", label: "Compte d'attente et autres comptes", data: [] },
  ],
  3: [
    // {id: "BQ Societe Generale", label: "BQ Societe Generale", data: operations[]},
  ],
};

const options = [
  { label: ".png", value: "image/png" },
  { label: ".jpeg", value: "image/jpeg" },
  { label: ".jpg", value: "image/jpg" },
  { label: ".pdf", value: "application/pdf" },
  { label: ".txt", value: "text/plain" },
  { label: ".csv", value: "text/csv" },
  {
    label: ".docx",
    value: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  },
  { label: ".doc", value: "application/msword" },
  { label: ".xls", value: "application/vnd.ms-excel" },
  { label: ".xlsx", value: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" },
  { label: ".ofx", value: "application/x-ofx" },
  { label: ".json", value: "application/json" },
];

const tabViews = {
  missingdoc: "1",
  outstandingsclients: "2",
  outstandingsproviders: "3",
};

const accountColumnsDesktop = [
  {
    title: "N° de compte aux.",
    dataIndex: "accountNumber",
    key: "accountNumber",
    width: "170px",
    sorter: (a, b) => a.accountNumber.localeCompare(b.accountNumber),
    render: (text, record) => {
      const nbrCommentCollab = record.operations.reduce(
        (count, op) => (op.commentCollab ? count + 1 : count),
        0
      );

      return (
        <>
          <span>{text}</span>
          {record.operations.some((op) => op.commentCollab) && (
            <Popover
              content={
                nbrCommentCollab > 1
                  ? `${nbrCommentCollab} commentaires du cabinet`
                  : `${nbrCommentCollab} commentaire du cabinet`
              }
            >
              <Tag color="var(--blue)" style={{ marginLeft: "10px" }} borderless>
                <span style={{ color: "white" }}>
                  {record.operations.reduce(
                    (count, op) => (op.commentCollab ? count + 1 : count),
                    0
                  )}
                  <CommentOutlined style={{ marginLeft: "5px" }} />
                </span>
              </Tag>
            </Popover>
          )}
        </>
      );
    },
  },
  {
    title: "Lib. compte auxiliaire",
    dataIndex: "accountLabel",
    key: "accountLabel",
    width: "170px",
    sorter: (a, b) => a.accountLabel.localeCompare(b.accountLabel),
  },
  {
    title: "Nb de factures",
    dataIndex: "invoicesNb",
    key: "invoicesNb",
    align: "center",
    width: "100px",
    sorter: (a, b) => a.invoicesNb - b.invoicesNb,
    render: (text) => (
      <span
        style={{
          float: "right",
        }}
      >
        {text}
      </span>
    ),
  },
  {
    title: "Encours",
    dataIndex: "outstandingsTotal",
    key: "outstandingsTotal",
    align: "center",
    width: "110px",
    defaultSortOrder: "descend",
    sorter: (a, b) => Math.abs(a.outstandingsTotal) - Math.abs(b.outstandingsTotal),
    render: (text) => (
      <span
        style={{
          float: "right",
        }}
      >
        {number.parseToAmount(Math.abs(text))}
      </span>
    ),
  },
];

const operationColumnsDesktop = [
  {
    title: "N° pièce",
    dataIndex: "pieceRef",
    key: "pieceRef",
    width: "150px",
    sorter: (a, b) => a.pieceRef.localeCompare(b.pieceRef),
  },
  {
    title: "Lib. opération",
    dataIndex: "label",
    key: "label",
    width: "150px",
    sorter: (a, b) => a.label.localeCompare(b.label),
  },
  {
    title: "Date de facturation",
    dataIndex: "date",
    key: "date",
    align: "center",
    width: "150px",
    sorter: (a, b) => new Date(a.date) - new Date(b.date),
    render: (text) => (
      <span
        style={{
          float: "right",
        }}
      >
        {new Date(text)?.toLocaleDateString()}
      </span>
    ),
  },
  {
    title: "Echéance",
    dataIndex: "dueDays",
    key: "dueDays",
    align: "center",
    width: "120px",
    defaultSortOrder: "descend",
    sorter: (a, b) => a.dueDays - b.dueDays,
    render: (text) => (
      <span
        style={{
          float: "right",
        }}
      >
        {text} jours
      </span>
    ),
  },
  {
    title: "Encours",
    dataIndex: "amount",
    key: "amount",
    align: "center",
    width: "110px",
    sorter: (a, b) => a.amount - b.amount,

    render: (text) => (
      <span
        style={{
          float: "right",
        }}
      >
        {number.parseToAmount(Math.abs(text))}
      </span>
    ),
  },
];

const accountColumnsResponsive = [
  {
    title: "Compte Aux",
    dataIndex: "accountNumber",
    key: "accountNumber",
    width: "100px",
    render: (text, record) => {
      const nbrCommentCollab = record.operations.reduce(
        (count, op) => (op.commentCollab ? count + 1 : count),
        0
      );

      return (
        <>
          <p>{text}</p>
          {record.operations.some((op) => op.commentCollab) && (
            <Popover
              content={
                nbrCommentCollab > 1
                  ? `${nbrCommentCollab} commentaires du cabinet`
                  : `${nbrCommentCollab} commentaire du cabinet`
              }
            >
              <Tag color="var(--blue)" style={{ marginLeft: "10px" }} borderless>
                <span style={{ color: "white" }}>
                  {record.operations.reduce(
                    (count, op) => (op.commentCollab ? count + 1 : count),
                    0
                  )}
                  <CommentOutlined style={{ marginLeft: "5px" }} />
                </span>
              </Tag>
            </Popover>
          )}
        </>
      );
    },
  },
  {
    title: <p style={{ position: "relative", top: "1px" }}>Nb facture</p>,
    dataIndex: "invoicesNb",
    key: "invoicesNb",
    align: "center",
    width: "80px",
    render: (text) => (
      <p
        style={{
          float: "center",
        }}
      >
        {text}
      </p>
    ),
  },
  {
    title: "Encours",
    dataIndex: "outstandingsTotal",
    key: "outstandingsTotal",
    align: "center",
    width: "100px",
    defaultSortOrder: "descend",
    render: (text) => (
      <p
        style={{
          float: "right",
        }}
      >
        {number.parseToAmount(Math.abs(text))}
      </p>
    ),
  },
  Table.EXPAND_COLUMN,
];

export {
  accountColumnsDesktop,
  accountColumnsResponsive,
  operationColumnsDesktop,
  options,
  tabViews,
  views,
};

import { UploadOutlined } from "@ant-design/icons";
import { Button, Form, Switch, Upload } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import TipTapEditorSignature from "utils/tiptap/TipTapEditorSignature";
import * as API from "../../api/API";
import { selectAccountingFirm } from "../../slices/accountingFirmSlice";
import { selectUser, updateUser } from "../../slices/userSlice";
import openNotification from "../../utils/notification";

function UserSignature(props) {
  const dispatch = useDispatch();

  const accountingFirm = useSelector(selectAccountingFirm);
  const user = useSelector(selectUser);

  const tokenClient = localStorage.getItem("token");

  const [logo, setLogo] = useState();
  const [loadingImage, setLoadingImage] = useState(true);

  const [userSign, setUserSign] = useState();
  const [displaySignatureLogo, setDisplaySignatureLogo] = useState(false);
  const [displaySignatureImg, setDisplaySignatureImg] = useState(false);
  const [imageInfo, setImageInfo] = useState(null);
  const [imageB64, setImageB64] = useState(null);
  const [existingImage, setExistingImage] = useState(null);
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    (async () => {
      let res = await API.getAccountingFirmLogo(accountingFirm._id);
      if (res.status === 200) {
        const data = await res.blob();
        setLogo(URL.createObjectURL(data));
        setLoadingImage(false);
      } else {
        console.log("Error getAccountingFirmLogo");
        setLoadingImage(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (user.signatureImage) {
      (async () => {
        let res = await API.getUserSignatureImage(user._id, 2);
        if (res.status === 200) {
          const data = await res.json();
          if (data.fileId) {
            setDisplaySignatureImg(true);
            const imageBuffer = data.imageBuffer;
            setExistingImage(`data:image/png;base64,${imageBuffer}`);
            setImageB64(`data:image/png;base64,${imageBuffer}`);
            setFileList([
              {
                uid: "-1",
                name: data.filename,
                status: "done",
              },
            ]);
          } else {
            console.log("No fileId in response");
          }
        } else {
          console.log("Error getUserSignatureImage");
        }
      })();
    }
  }, [user._id]);

  const onSubmit = async () => {
    try {
      const res = await API.putUser(user._id, {
        signature: userSign,
        signatureDisplayLogo: displaySignatureLogo,
      });

      if (res.status === 201) {
        dispatch(
          updateUser({
            signature: userSign,
            signatureDisplayLogo: displaySignatureLogo,
          })
        );
        openNotification("success", "Modification effectuée");
      } else {
        openNotification("error", "Erreur lors de la modification de la signature");
        return;
      }

      if (displaySignatureImg && fileList.length > 0) {
        if (existingImage !== imageB64 && imageB64 !== null) {
          let formData = new FormData();
          formData.append("files", imageInfo.file.originFileObj, imageInfo.file.name);
          formData.set("userId", user._id);
          const url = process.env.REACT_APP_INTERFACE_BACKEND + "/user/signature";

          const response = await axios({
            url,
            method: "post",
            headers: {
              Authorization: `Bearer ${tokenClient}`,
            },
            data: formData,
          });

          dispatch(updateUser({ signatureImage: response.data.signatureImage }));
        }
      } else {
        if (user.signatureImage) {
          console.log("Removing signature image");
          const removeRes = await API.removeUserSignatureImage(user._id);
          if (removeRes.status === 200) {
            dispatch(updateUser({ signatureImage: "" }));
            setExistingImage(null);
          } else {
            console.log("Error removeUserSignatureImage");
          }
        }
      }
    } catch (error) {
      console.error("Error in onSubmit:", error);
      openNotification("error", "Une erreur est survenue lors de la mise à jour");
    }
  };

  useEffect(() => {
    setUserSign(user.signature || "");
  }, [user.signature]);

  useEffect(() => {
    setDisplaySignatureLogo(user.signatureDisplayLogo);
  }, [user.signatureDisplayLogo]);

  const onChangeSignatureLogo = () => {
    setDisplaySignatureLogo(!displaySignatureLogo);
  };

  const onChangeSignatureImg = () => {
    setDisplaySignatureImg(!displaySignatureImg);
  };

  const uploadBehavior = {
    onRemove() {
      setImageB64(null);
      setImageInfo(null);
      setFileList([]);
    },
    beforeUpload(file) {
      getBase64(file, (imageUrl) => {
        setImageB64(imageUrl);
      });
      const fileBlob = new Blob([file]);
      setImageInfo({ file: { originFileObj: fileBlob, name: file.name } });
      setFileList([
        {
          uid: file.uid,
          name: file.name,
          status: "done",
        },
      ]);
    },
    fileList,
  };

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  return (
    <>
      {user.status === "ready" && userSign !== undefined ? (
        <>
          <Form
            layout="vertical"
            onFinish={onSubmit}
            className="signature-form"
            initialValues={{
              displaySignatureLogo: user.signatureDisplayLogo,
              displaySignatureImg: user.signatureDisplayImage,
            }}
          >
            <div className="form-btn-block">
              <Button
                className="other-action-btn cancel-btn"
                onClick={(e) => {
                  e.preventDefault();
                  props.history.push("/homepage");
                }}
              >
                Annuler
              </Button>
              <Form.Item className="submit-changes-btn">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="call-action-btn modal-submit-btn save-signature"
                >
                  Sauvegarder
                </Button>
              </Form.Item>
            </div>
            <p style={{ marginTop: "17px" }}>
              Votre signature est utilisée pour les envois d’emails à vos clients via Sesha.
            </p>
            <Form.Item name="signature">
              <TipTapEditorSignature
                content={
                  userSign ||
                  `<div>Cordialement</div><div>${user.firstName} ${user.lastName}</div><div><span style="color: rgb(69, 105, 248)">${accountingFirm.name}</span></div>`
                }
                setContent={setUserSign}
              />
            </Form.Item>
            <div>
              <p>
                <Form.Item name="displaySignatureLogo">
                  <Switch
                    style={{ marginRight: "10px" }}
                    onChange={() => onChangeSignatureLogo()}
                    checked={displaySignatureLogo}
                  />
                  Afficher le logo du cabinet
                </Form.Item>
              </p>
              <p>
                <Form.Item name="displaySignatureImg">
                  <Switch
                    style={{ marginRight: "10px" }}
                    onChange={() => onChangeSignatureImg()}
                    checked={displaySignatureImg}
                  />
                  Utiliser une image en signature
                </Form.Item>
              </p>
            </div>
            <Form.Item name="signatureImage" className="signature-list">
              <Upload {...uploadBehavior} maxCount={1} listType="text">
                {fileList.length === 0 ? (
                  <Button
                    style={{ borderRadius: "50px" }}
                    disabled={!displaySignatureImg}
                    icon={<UploadOutlined />}
                  >
                    Charger une image de signature
                  </Button>
                ) : null}
              </Upload>
            </Form.Item>
          </Form>
          <p style={{ fontWeight: "bold" }}>Aperçu :</p>
          <div style={{ marginLeft: "15px" }}>
            {/* texte de la signature */}
            <div dangerouslySetInnerHTML={{ __html: userSign }} />

            {/* Logo */}
            {displaySignatureLogo && !loadingImage ? (
              <img src={logo ? logo : null} alt="logo cabinet" style={{ maxHeight: "80px" }} />
            ) : null}

            {/* Image */}
            {displaySignatureImg && imageB64 ? (
              <>
                <br />
                <img
                  src={imageB64}
                  alt="signature collaborateur"
                  style={{ maxWidth: "380px", maxHeight: "300px" }}
                />
              </>
            ) : null}
          </div>
        </>
      ) : null}
    </>
  );
}

export default UserSignature;

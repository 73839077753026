import * as API from "../api/API";

function logAction(id, value, clientId, accountingFirmId) {
  (async () => {
    let reskpi = await API.postLogAction({
      action_id: id,
      value: value,
      ...(clientId && { client_id: clientId }),
      ...(accountingFirmId && { accounting_firm_id: accountingFirmId }),
    });
    reskpi = await reskpi.json();

    return reskpi;
  })();
}

export { logAction as default };

import { DownCircleOutlined } from "@ant-design/icons";
import { Table } from "antd";
import { accountColumnsResponsive } from "components/client/misc";
import OutstandingsTableCardResponsive from "./TableCard";

const OutstandingsTableResponsive = (props) => {
  return (
    <div className="client-outstandings-table">
      <div>
        <span>
          <h3>Détail</h3>
          <div></div>
        </span>
        <p>
          <b>
            Encours supérieurs à {props.dueDays ?? 0} jour{props.dueDays > 1 && "s"} au{" "}
            {new Date(props.endDate).toLocaleDateString()}
          </b>
        </p>
      </div>

      <Table
        className="outstandings-table-responsive"
        columns={accountColumnsResponsive}
        dataSource={props.accountData.filter((cat) => cat.operations.some((op) => !op.hidden))}
        pagination={{ defaultPageSize: 10, showSizeChanger: true }}
        expandable={{
          expandedRowRender: (record) => {
            return (
              <div>
                {record.operations.map((op) => (
                  <OutstandingsTableCardResponsive
                    record={{ ...op }}
                    type={props.type}
                    accountData={props.accountData}
                    data={props.data}
                    setData={props.setData}
                  />
                ))}
              </div>
            );
          },
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <DownCircleOutlined onClick={(e) => onExpand(record, e)} className="expand-icon-up" />
            ) : (
              <DownCircleOutlined
                className="expand-icon-down"
                onClick={(e) => onExpand(record, e)}
              />
            ),
        }}
      />
    </div>
  );
};

export default OutstandingsTableResponsive;

import {
  ArrowRightOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
  ExclamationCircleOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from "@ant-design/icons";
import { Button, Checkbox, Col, Form, Input, Modal, Row, Switch } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import logAction from "utils/logActions";
import TooltipConnect from "utils/tooltipConnect";
import * as API from "../../../api/API";
import Slash from "../../../assets/images/slash.png";
import { selectAccountingFirm, updateAccountingFirm } from "../../../slices/accountingFirmSlice";
import openNotification from "../../../utils/notification";

const { confirm } = Modal;

function AdminMCF() {
  const dispatch = useDispatch();

  const accountingFirm = useSelector(selectAccountingFirm);
  const [isMCFConnected, setIsMCFConnected] = useState();
  const [checkMCFConnexion, setCheckMCFConnexion] = useState();
  const [loading, setLoading] = useState(false);
  const [hideMultiplePaths, setHideMultiplePaths] = useState(
    accountingFirm.mcfSubFolderSales || accountingFirm.mcfSubFolderOther ? false : true
  );
  const [folderName, setFolderName] = useState(accountingFirm.mcfFolder || "ZONE DE DEPOT");

  const updateAccountingFirmData = async (data) => {
    try {
      await API.putAccountingFirm(accountingFirm._id, data);
      openNotification("success", "Modifications enregistrées");
      dispatch(updateAccountingFirm(data));
    } catch (error) {
      console.log(error);
      openNotification("error", "Erreur lors de l'enregistrement");
    }
  };
  const onFinish = (values) => {
    (async () => {
      const res = await API.testMCFConnections({
        token: values.mcftoken,
      });
      if (res[0].statusCode === 0) {
        setCheckMCFConnexion(true);
        setIsMCFConnected(true);
        const data = {
          mcfToken: values.mcftoken,
          mcfFolder: values.folderName,
          mcfSubFolder: values.subFolderName,
          mcfSubFolderSales: values.subFolderNameSales,
          mcfSubFolderOther: values.subFolderNameOther,
          mcfSiren: values.siren,
        };
        updateAccountingFirmData(data);
        isMCFConnected && logAction(207, 1);
      } else {
        setCheckMCFConnexion(false);
      }
    })();
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    openNotification("error", "Erreur lors de l'enregistrement");
  };

  useEffect(() => {
    const testMCFConnection = async () => {
      const res = await API.testMCFConnections({
        token: accountingFirm.mcfToken,
      });
      setCheckMCFConnexion(res[0].statusCode === 0);
      setLoading(false);
    };

    setLoading(true);

    if (accountingFirm.status === "ready") {
      if (accountingFirm.mcfToken) {
        setIsMCFConnected(true);
        testMCFConnection();
      } else {
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountingFirm.status]);

  useEffect(() => {
    if (isMCFConnected === false) {
      setCheckMCFConnexion();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMCFConnected]);

  const showMCFAlert = () => {
    confirm({
      title: "",
      icon: <ExclamationCircleOutlined className="prompt-icon" style={{ top: "-30px" }} />,
      className: "modal-invalid modal-invalid-mail",
      maskClosable: false,
      closable: false,
      content: (
        <div>
          <div className="prompt-text-style">
            <p>
              Voulez-vous vraiment désactiver la connexion à MCF ? Si vous continuez, le token sera
              supprimé.
            </p>
          </div>
          <div className="prompt-btns">
            <Button
              className="other-action-btn invalid-cancel"
              onClick={(e) => {
                e.preventDefault();
                Modal.destroyAll();
              }}
            >
              Annuler
            </Button>
            <Button
              className="call-action-btn"
              onClick={(e) => {
                e.preventDefault();
                setIsMCFConnected(false);
                logAction(208, 1);
                updateAccountingFirm({ mcfToken: null });
                Modal.destroyAll();
              }}
            >
              Continuer
            </Button>
          </div>
        </div>
      ),
    });
  };

  return (
    <>
      {loading ? (
        ""
      ) : (
        <Col>
          <p className="settings-category-p mcf-co">Connexion à MyCompanyFiles : </p>
          <div className="how-to-connection-float">
            <TooltipConnect
              url={"https://knowledge.sesha.expert/knowledge/connexion-%C3%A0-mycompanyfiles"}
              text={"Comment connecter Sesha à MyCompanyFiles ?"}
            />
          </div>
          <br />
          <div className="mcf-switch">
            {" "}
            <Switch
              checked={isMCFConnected}
              onChange={() => {
                if (isMCFConnected) showMCFAlert();
                else setIsMCFConnected(!isMCFConnected);
              }}
            />{" "}
            <span>
              {isMCFConnected ? (
                checkMCFConnexion ? (
                  "Activée"
                ) : (
                  <b>Veuillez renseigner votre token MyCompanyFiles et cliquer sur "Valider"</b>
                )
              ) : (
                "Désactivée"
              )}
            </span>
          </div>

          {isMCFConnected ? (
            <>
              {" "}
              {checkMCFConnexion ? (
                <div className="mcf-valid-block">
                  <CheckCircleFilled className="mcf-valid-icon" />
                  <p className="mcf-valid-text">
                    <b>Félicitations, Sesha est connecté à MyCompanyFiles.</b>
                  </p>
                  <p>
                    Les pièces reçues de vos clients sont automatiquement chargées dans la zone de
                    dépôt correspondante.
                  </p>
                </div>
              ) : checkMCFConnexion === false ? (
                <div className="mcf-invalid-block">
                  <CloseCircleFilled className="mcf-invalid-icon" />
                  <p className="mcf-invalid-text">
                    <b>Token non reconnu.</b>
                  </p>
                  <p>Veuillez essayer à nouveau.</p>
                </div>
              ) : null}
              {isMCFConnected && (
                <Form
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  layout="vertical"
                  initialValues={{
                    mcftoken: accountingFirm.mcfToken,
                    folderName: accountingFirm.mcfFolder || "ZONE DE DEPOT",
                    subFolderName: accountingFirm.mcfSubFolder,
                    subFolderNameSales: accountingFirm.mcfSubFolderSales,
                    subFolderNameOther: accountingFirm.mcfSubFolderOther,
                    siren: accountingFirm.mcfSiren,
                  }}
                  className="user-profile-form"
                  onValuesChange={(changedValues, allValues) => {
                    if (changedValues.folderName) setFolderName(changedValues.folderName);
                    if (changedValues.folderName === "") setFolderName("");
                  }}
                >
                  <Form.Item name="mcftoken" label="Token MyCompanyFiles">
                    <Input.Password
                      bordered={true}
                      className={"form-input"}
                      iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                      }
                    />
                  </Form.Item>

                  <p>Personnalisez ici le chemin de dépôt des pièces</p>
                  <Row>
                    <Col xs={23} sm={3} md={4}>
                      <p className="path">[Dossier client]</p>
                    </Col>
                    <Col className="col-separator" xs={1} sm={1} md={2}>
                      <img src={Slash} alt="Url separator" className="url-separator" />
                    </Col>

                    <Col xs={23} sm={8} md={8}>
                      <Form.Item
                        name="folderName"
                        extra="Nom exact du dossier de dépôt de vos dossiers client"
                        rules={[
                          { required: true, message: "Merci de renseigner un nom de dossier." },
                        ]}
                      >
                        <Input bordered={true} className={"form-input"} />
                      </Form.Item>
                    </Col>
                    <Col className="col-separator" xs={1} sm={1} md={2}>
                      <img src={Slash} alt="Url separator" className="url-separator" />
                    </Col>
                    <Col xs={23} sm={8} md={8}>
                      <Form.Item
                        name="subFolderName"
                        extra={
                          hideMultiplePaths
                            ? "Sous-dossier dans lequel seront déversées les pièces transmises"
                            : "Sous-dossier dans lequel seront déversées les pièces transmises : Achats"
                        }
                      >
                        <Input bordered={true} className={"form-input"} />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Checkbox
                    onClick={(e) => e.stopPropagation()}
                    className={"checkbox-mcf-paths"}
                    checked={hideMultiplePaths}
                    onChange={(e) => {
                      setHideMultiplePaths(e.target.checked);
                      if (
                        e.target.checked === true &&
                        (accountingFirm.mcfSubFolderSales || accountingFirm.mcfSubFolderOther)
                      )
                        updateAccountingFirmData({ mcfSubFolderSales: "", mcfSubFolderOther: "" });
                    }}
                  >
                    Chemin unique
                  </Checkbox>

                  {!hideMultiplePaths && (
                    <>
                      <Row>
                        <Col xs={23} sm={3} md={4}>
                          <p className="path">[Dossier client]</p>
                        </Col>
                        <Col className="col-separator" xs={1} sm={1} md={2}>
                          <img src={Slash} alt="Url separator" className="url-separator" />
                        </Col>
                        <Col xs={23} sm={8} md={8}>
                          <p className="path">{folderName}</p>
                        </Col>
                        <Col className="col-separator" xs={1} sm={1} md={2}>
                          <img src={Slash} alt="Url separator" className="url-separator" />
                        </Col>
                        <Col xs={23} sm={8} md={8}>
                          <Form.Item
                            name="subFolderNameSales"
                            extra="Sous-dossier dans lequel seront déversées les pièces transmises : Ventes"
                          >
                            <Input bordered={true} className={"form-input"} />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={23} sm={3} md={4}>
                          <p className="path">[Dossier client]</p>
                        </Col>
                        <Col className="col-separator" xs={1} sm={1} md={2}>
                          <img src={Slash} alt="Url separator" className="url-separator" />
                        </Col>
                        <Col xs={23} sm={8} md={8}>
                          <p className="path">{folderName}</p>
                        </Col>
                        <Col className="col-separator" xs={1} sm={1} md={2}>
                          <img src={Slash} alt="Url separator" className="url-separator" />
                        </Col>
                        <Col xs={23} sm={8} md={8}>
                          <Form.Item
                            name="subFolderNameOther"
                            extra="Sous-dossier dans lequel seront déversées les pièces transmises : Autres"
                          >
                            <Input bordered={true} className={"form-input"} />
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                  )}
                  <Form.Item
                    name="siren"
                    label="Nom de l’attribut SIREN dans MyCompanyFiles"
                    extra="Saisissez ici le libellé exact de l’attribut correspondant au numéro de SIREN dans MyCompanyFiles"
                  >
                    <Input bordered={true} className={"form-input"} />
                  </Form.Item>
                  <Form.Item className="submit-changes-btn" style={{ marginLeft: "0" }}>
                    <Button className="call-action-btn modal-submit-btn" htmlType="submit">
                      Valider <ArrowRightOutlined />
                    </Button>
                  </Form.Item>
                </Form>
              )}
            </>
          ) : (
            <div>
              <p>La connexion à MyCompanyFiles est disponible pour votre cabinet.</p>
              <p>
                Une fois activée, les pièces reçues de vos clients seront automatiquement chargées
                dans la zone de dépôt correspondante.
              </p>
            </div>
          )}
        </Col>
      )}
    </>
  );
}
export default AdminMCF;

import { CopyOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, notification, Table, Tag } from "antd";
import { useEffect, useState } from "react";

function UsersTable(props) {
  const [usersData, setUsersData] = useState();

  function testDossierImport(currentAccFirm, dossierId) {
    return currentAccFirm.allImportedClients.some(({ remoteId }) => remoteId === dossierId);
  }
  
  function rightDisplay(row, inherited) {
    return (r) => (<Tag color={inherited ? "#85c1e9" : "MediumSeaGreen"} key={row._id+r}
      style={tagStyle}
    >
      {r}
    </Tag>);
  }

  useEffect(() => {
    (async () => {
      setUsersData(
        props.displayedUsers.map((user) => {
          
          let directRights; 
          let inheritedRights;

          let dossiersDirectImported;
          let dossiersInheritedImported;
          let dossiersNotImported;

          if(props.currentAccFirm.connectedRights) {
            directRights = props.currentAccFirm.allRights?.filter((right) => right.user_mail === user.email); 

            inheritedRights = props.currentAccFirm.allRights
              .filter(({ user_id }) => user.rights.includes(user_id))
            
            // avoid duplicates
            dossiersDirectImported = new Set();
            dossiersInheritedImported = new Set();
            dossiersNotImported = new Set();

            // process dossier from direct rights
            for(let dossier of directRights.map(({ dossiers }) => dossiers).flat(1)) {
              if(testDossierImport(props.currentAccFirm, dossier)) dossiersDirectImported.add(dossier);
              else dossiersNotImported.add(dossier);
            }
            
            // process dossier from inherited rights
            for(let dossier of inheritedRights.map(({ dossiers }) => dossiers).flat(1)) {
              if(testDossierImport(props.currentAccFirm, dossier)) dossiersInheritedImported.add(dossier);
              else dossiersNotImported.add(dossier);
            }

            dossiersDirectImported = [...dossiersDirectImported];
            dossiersInheritedImported = [...dossiersInheritedImported];
            dossiersNotImported = [...dossiersNotImported];
          }
          else {
            directRights = [];
            inheritedRights = [];

            dossiersDirectImported = props.allFirmClients.filter((client) => 
                client.collaboratorsId.length === 0 
                || client.collaboratorsId?.includes(user._id)
              )
            dossiersInheritedImported = [];
          }

          /*
          // only useful if dossiers are given to the admin pop up
          // or if we want to check codes from provider instead of our DB
          if(props.currentAccFirm.connectedRights) {
            const allCodesMap = new Map(props.currentAccFirm.allDataProviderCodes.map((elt) => [elt.code?.toString(), elt]));
            const allIdMap = new Map(props.currentAccFirm.allDataProviderCodes.map((elt) => [elt.id?.toString(), elt]));

            dossiersDirect.concat(dossiersInherited||[]).forEach((dossier) => {
              dossier.key = dossier.docId;

              const foundCode =
                allCodesMap.get(dossier.docId?.toString()) ||
                allIdMap.get(dossier.docId?.toString());

              if (foundCode) {
                dossier.name = foundCode.name;
              }
            });
          }
            */

          return {
            ...user,
            key: user._id,
            newRoleLabel: props.currentAccFirm.roles.find(r => r._id === user.newRole)?.label
              || "Pour débloquer l'affichage de ce rôle, veuillez activer votre abonnement à Sesha+ Support MAX"
                + " (à partir de seulement 5.99€/mois pour les 6 premiers mois, puis 9.99€/mois).",
            dossiers: {
              direct: dossiersDirectImported,
              inherited: dossiersInheritedImported,
              notImported: dossiersNotImported
            },
            rights: {
              direct: directRights.map( r => r.user_id),
              inherited: inheritedRights.map( r => r.user_id),
            },
          };
        })
      );
    })();
  }, [props.displayedUsers, props.allFirmClients]);

  const tagStyle = {
    minimumWidth: "40px",
    textAlign: "center",
  }

  const columns = [
    {
      title: "ID",
      dataIndex: "_id",
      key: "_id",
      align: "center",
      width: "40px",
      render: (_id) => {
        const openNotification = () => {
          notification.info({
            message: "UserID copié",
            description: `{_id: ObjectId("${_id}")}`,
          });
        };
        return (
          <>
            <Button
              type="text"
              onClick={() => {
                openNotification();
                navigator.clipboard.writeText(`{_id: ObjectId("${_id}")}`);
              }}
            >
              <CopyOutlined style={{ fontSize: "20px", color: "#4569F8" }} />
            </Button>
          </>
        );
      },
    },
    {
      title: "Nom",
      dataIndex: "lastName",
      key: "lastName",
      width: "200px",
      align: "center",
      render: (lastName) => <>{lastName}</>,
    },
    {
      title: "Prénom",
      dataIndex: "firstName",
      key: "firstName",
      width: "200px",
      align: "center",
      render: (firstName) => <>{firstName}</>,
    },
    {
      title: "Mail",
      dataIndex: "email",
      key: "email",
      width: "auto",
      align: "center",
      render: (email) => <>{email}</>,
    },
    {
      title: "Rôle",
      dataIndex: "newRoleLabel",
      key: "newRoleLabel",
      width: "200px",
      align: "center",
      render: (newRoleLabel, row) => <> {newRoleLabel} </>,
    },
    {
      title: props.currentAccFirm.connectedRights ? "Dossiers accessibles" : "Dossiers attitrés",
      dataIndex: "dossiers",
      key: "dossiers",
      align: "center",
      width: "300px",
      render: (dossiers, row) => {
        // /!\ be careful, it's a component used for clients' admin app pages
        return <div>
          <Tag color="#85c1e9" key={dossiers}
            style={tagStyle}
          >
          { dossiers.direct.length+dossiers.inherited.length } 
          { dossiers.notImported ? 
              "/" + (dossiers.direct.length+dossiers.inherited.length+dossiers.notImported.length)
            : ""
          }
        </Tag>
        </div>
      },
    },
    {
      title: "Droits attribués",
      dataIndex: "rights",
      key: "rights",
      align: "center",
      width: "300px",
      hidden: !props.currentAccFirm.connectedRights,
      render: (rights, row) => {
        return (props.loadingDisplayedUsers ? 
            <LoadingOutlined /> 
          : <div style={{ 
              display: "flex",
              flexWrap: "wrap",
              gap: "5px",
            }}>
              { 
                rights.direct.map(rightDisplay(row, false))
                  .concat(rights.inherited.map(rightDisplay(row, true))) 
              }
          </div>
        );
      },
    },
    // filter out hidden column
  ].filter( c => c.hidden !== true);

  return (
    <div>
    <Table
      columns={columns}
      loading={usersData === undefined}
      dataSource={usersData}
      size="small"
      bordered
      pagination={true}
      pageSize={5}
      style={{ width: "100%" }}
      rowKey={(record) => record.key}
      scroll={{
        x: "max-content",
      }}
    />
    </div>
  );
}

export default UsersTable;

import { NotificationOutlined } from "@ant-design/icons";
import { Collapse, Select, Switch } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import logAction from "utils/logActions";
import * as API from "../../api/API";
import { selectUser, updateUser } from "../../slices/userSlice";
import openNotification from "../../utils/notification";

const { Option } = Select;
const { Panel } = Collapse;

function UserNotifications(props) {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  const [notify, setNotify] = useState("");

  useEffect(() => {
    if (user.notify) {
      setNotify("Activé");
    } else {
      setNotify("Désactivé");
    }
  }, []);

  const getHeader = () => {
    if (notify === "Activé") {
      return (
        <>
          <span>Notifications - </span>
          <span style={{ color: "#0b8235" }}>{notify}</span>
        </>
      );
    } else {
      return (
        <>
          <span>Notifications - </span>
          <span style={{ color: "#99999a" }}>{notify}</span>
        </>
      );
    }
  };

  const onNotifyChange = (checked) => {
    if (checked) {
      setNotify("Activé");
      logAction(216, 1);
    } else {
      setNotify("Désactivé");
      logAction(217, 1);
      (async () => {
        const res = await API.putUser(user._id, { notify: null, clientsDidAction: [] });
        if (res.status === 201) {
          dispatch(updateUser({ notify: null, clientsDidAction: [] }));
          openNotification("success", "Modification effectuée");
        } else openNotification("error", "Erreur lors de la modification de l'utilisateur");
      })();
    }
  };

  const onPeriodChange = (value) => {
    (async () => {
      const res = await API.putUser(user._id, { notify: value });
      if (res.status === 201) {
        dispatch(updateUser({ notify: value }));
        openNotification("success", "Modification effectuée");
      } else openNotification("error", "Erreur lors de la modification de l'utilisateur");
    })();
  };

  return (
    <>
      <div className="user-settings">
        <Collapse
          bordered={false}
          defaultActiveKey={["1"]}
          className="site-collapse-custom-collapse"
        >
          <Panel
            header={getHeader()}
            key="1"
            className="site-collapse-custom-panel"
            extra={<NotificationOutlined style={{ fontSize: "22px" }} />}
            showArrow={false}
          >
            <Switch
              className="notify-switch"
              checked={notify === "Activé" ? true : false}
              onChange={onNotifyChange}
            />
            <p className="notify-text-p">
              Je souhaite être alerté par email lorsque mon client me transmet des justificatifs
            </p>
            {notify === "Activé" ? (
              <>
                {" "}
                <p>Fréquence des notifications par mail :</p>{" "}
                <Select
                  placeholder="Sélection de la période"
                  disabled={notify === "Activé" ? false : true}
                  defaultValue={notify === "Activé" ? user.notify : null}
                  onChange={onPeriodChange}
                  defaultOpen={true}
                >
                  <Option value="DAILY">Quotidienne</Option>
                  <Option value="WEEKLY">Hebdomadaire</Option>
                  <Option value="MONTHLY">Mensuelle</Option>
                </Select>
              </>
            ) : null}

            <div className="info-notifications-text">
              <p>
                Une fois les notifications activées, vous recevrez un email pour chaque client ayant
                répondu à vos demandes. Le cas échéant, celui-ci vous sera envoyé :
              </p>
              <ul>
                <li>- Tous les jours en début de matinée (journalière)</li>
                <li>- Tous les lundis en début de matinée (hebdomadaire)</li>
                <li>
                  - Le premier jour du mois en début de matinée (mensuelle) en fonction de votre
                  choix.
                </li>
              </ul>
            </div>
          </Panel>
        </Collapse>
      </div>
    </>
  );
}

export default UserNotifications;

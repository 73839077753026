import { DeleteOutlined, EditOutlined, PaperClipOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Card, Divider, Input, Select, Space, Tag, Typography } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as API from "../../../api/API";
import { selectAccountingFirm, updateAccountingFirm } from "../../../slices/accountingFirmSlice";
import openNotification from "../../../utils/notification";
import TipTapEditor from "../../../utils/tiptap/TipTapEditorOthers";

const { Option } = Select;

function OtherModels() {
  const dispatch = useDispatch();
  const tokenClient = localStorage.getItem("token");

  const [addItemWarningEmpty, setAddItemWarningEmpty] = useState(false);
  const [addItemWarningDuplicate, setAddItemWarningDuplicate] = useState(false);
  const [listOthers, setListOthers] = useState([]);
  const [newListName, setNewListName] = useState("");
  const [selectedList, setSelectedList] = useState();
  const [isEditing, setIsEditing] = useState(null);
  const [editedContent, setEditedContent] = useState("");
  const [selectedFileList, setSelectedFileList] = useState([]);
  const [deleteFileList, setDeleteFileList] = useState([]);

  const accountingFirm = useSelector(selectAccountingFirm);

  useEffect(() => {
    setListOthers(accountingFirm.othersTemplate || []);
    setSelectedList((accountingFirm.othersTemplate && accountingFirm.othersTemplate[0]) || {});
  }, []);

  useEffect(() => {
    console.log("listOthers has changed:", listOthers);
  }, [listOthers]);

  //NOTE: create new list of others
  const addList = (e) => {
    e.preventDefault();
    (async () => {
      if (listOthers.some((el) => el.name === newListName)) {
        setAddItemWarningDuplicate(true);
      } else {
        setAddItemWarningDuplicate(false);

        const newList = {
          name: newListName,
          list: [],
        };
        if (newListName !== "") {
          var res = await API.putAccountingFirm(accountingFirm._id, {
            othersTemplate: [...listOthers, newList],
          });

          if (res.status === 201) {
            res = await res.json();

            openNotification("success", "Modification prise en compte");
            setAddItemWarningEmpty(false);
            setListOthers(res.othersTemplate);
            setSelectedList(res.othersTemplate.slice(-1)[0]);
            setNewListName("");
          } else {
            openNotification("error", "Erreur lors de la création de la liste");
          }
        } else {
          setAddItemWarningEmpty(true);
        }
      }
    })();
  };

  const onListNameChange = (event) => {
    setNewListName(event.target.value);
  };

  //NOTE: add new item "other" to current list
  const addItemToList = (other) => {
    const updatedListOthers = listOthers.map((item) => {
      if (item._id === selectedList._id) {
        return {
          ...item,
          list: [...item.list, { content: other.content, fileList: other.fileList || [] }],
        };
      }
      return item;
    });
    setListOthers(updatedListOthers);
    return updatedListOthers;
  };

  //NOTE: remove item "other" from current list
  const removeItemFromList = (index) => {
    const updatedListOthers = listOthers.map((item) => {
      if (item._id === selectedList._id) {
        return { ...item, list: item.list.filter((_, i) => i !== index) };
      }
      return item;
    });
    setListOthers(updatedListOthers);
    return updatedListOthers;
  };

  const startEditing = (index, content, fileList) => {
    setIsEditing(index);
    setEditedContent(content);
    setSelectedFileList(fileList);
  };

  const cancelEditing = () => {
    setIsEditing(null);
    setEditedContent("");
    setSelectedFileList([]);
  };

  const saveEditedContent = (data, index) => {
    const updatedListOthers = listOthers.map((item) => {
      if (item._id === selectedList._id) {
        const updatedList = item.list.map((other, i) => {
          if (i === index) {
            return { ...other, content: data.content, fileList: data.fileList || [] };
          }
          return other;
        });
        return { ...item, list: updatedList };
      }
      return item;
    });
    setListOthers(updatedListOthers);
    setIsEditing(null);
    setEditedContent("");
    setSelectedFileList([]);
    return updatedListOthers;
  };

  // delete other from list
  const deleteOtherFromList = async (other, index) => {
    if (other.fileList.length > 0) {
      for (const file of other.fileList) {
        await API.deleteFileFromOther({ fileId: file.fileId });
      }
    }
    const updatedList = removeItemFromList(index);

    const res = await API.putAccountingFirm(accountingFirm._id, {
      othersTemplate: updatedList,
    });
    if (res.status === 201) {
      dispatch(
        updateAccountingFirm({
          ...accountingFirm,
          othersTemplate: updatedList,
        })
      );
      openNotification("success", "Votre liste a été sauvegardée");
    } else {
      openNotification("error", "Erreur lors de l'enregistrement de la liste");
    }
  };

  //add or update other in list
  const saveList = async (other, index, action) => {
    const url = process.env.REACT_APP_INTERFACE_BACKEND + "/other/file";
    var updatedOther = { ...other };

    const uploadPromises = updatedOther.fileList.map(async (file) => {
      if (file.fileId.startsWith("rc-upload")) {
        const formData = new FormData();
        formData.append("files", file.fileInfo, file.fileName);

        try {
          const response = await axios({
            url,
            method: "post",
            headers: {
              Authorization: `Bearer ${tokenClient}`,
            },
            processData: false,
            data: formData,
          });
          const fileIndex = updatedOther.fileList.findIndex((elt) => elt.fileId === file.fileId);
          if (fileIndex !== -1) {
            updatedOther.fileList[fileIndex].fileId = response.data.fileId;
          }
        } catch (error) {
          console.error(error);
        }
      }
    });
    const deletePromises = deleteFileList.map(async (fileId) => {
      try {
        await API.deleteFileFromOther({ fileId });
        const updatedDeleteFileList = deleteFileList.filter((item) => item.fileId !== fileId);
        //TODO : check if deleteFileList is empty
        setDeleteFileList(updatedDeleteFileList);

        const fileIndex = updatedOther.fileList.findIndex((elt) => elt.fileId === fileId);
        if (fileIndex !== -1) {
          updatedOther.fileList.splice(fileIndex, 1);
        }
      } catch (error) {
        console.error(error);
      }
    });

    await Promise.all([...uploadPromises, ...deletePromises]);

    var updatedList;
    switch (action) {
      case "create":
        updatedList = addItemToList(updatedOther);
        break;
      case "edit":
        updatedList = saveEditedContent(updatedOther, index);
        break;
      default:
        break;
    }

    const res = await API.putAccountingFirm(accountingFirm._id, {
      othersTemplate: updatedList,
    });
    if (res.status === 201) {
      dispatch(
        updateAccountingFirm({
          ...accountingFirm,
          othersTemplate: updatedList,
        })
      );
      openNotification("success", "Votre liste a été sauvegardée");
    } else {
      openNotification("error", "Erreur lors de l'enregistrement de la liste");
    }
  };

  //TODO: delete the list and all the files
  const deleteList = async () => {
    for (const other of selectedList.list) {
      if (other.fileList.length > 0) {
        for (const file of other.fileList) {
          await API.deleteFileFromOther({ fileId: file.fileId });
        }
      }
    }

    const updatedLists = listOthers.filter((item) => item._id !== selectedList._id);

    const res = await API.putAccountingFirm(accountingFirm._id, {
      othersTemplate: updatedLists,
    });
    if (res.status === 201) {
      setListOthers(updatedLists);
      setSelectedList((accountingFirm.othersTemplate && accountingFirm.othersTemplate[0]) || {});
      dispatch(
        updateAccountingFirm({
          ...accountingFirm,
          othersTemplate: updatedLists,
        })
      );
      openNotification("success", "Votre liste a été supprimée");
    } else {
      openNotification("error", "Erreur lors de la suppression de la liste");
    }
  };

  return selectedList && listOthers ? (
    <>
      <div className="type-settings" style={{ marginBottom: "20px" }}>
        <b>Listes prédéfinies :</b>
        <Select
          value={selectedList.name}
          className="model-select"
          onSelect={(value) => {
            setSelectedList(listOthers.find((elt) => elt._id === value));
          }}
          notFoundContent={"Aucune donnée disponible"}
          popupClassName="ant-select-dropdown-zindex"
          dropdownRender={(menu) => (
            <>
              {menu || null}
              <Divider
                style={{
                  margin: "8px 0",
                }}
              />
              <Space
                align="center"
                style={{
                  padding: "0 8px 4px",
                }}
              >
                <Input value={newListName} onChange={onListNameChange} />
                <Typography.Link
                  onClick={addList}
                  style={{
                    whiteSpace: "nowrap",
                  }}
                >
                  <PlusOutlined /> Ajouter une liste
                </Typography.Link>
              </Space>
              {addItemWarningEmpty ? (
                <span className="add-item-warning">
                  Merci de renseigner un nom pour votre nouvelle liste.
                </span>
              ) : addItemWarningDuplicate ? (
                <span className="add-item-warning">Ce nom de liste existe déjà</span>
              ) : null}
            </>
          )}
          placement="bottomLeft"
          dropdownAlign={{ overflow: { adjustY: false } }}
        >
          {listOthers.map((item, i) => {
            return (
              <Option value={item._id} key={i}>
                {item.name}
              </Option>
            );
          })}
        </Select>
      </div>

      {listOthers.find((item) => item.name === selectedList.name) &&
        listOthers
          .find((item) => item.name === selectedList.name)
          .list.map((other, index) => {
            return (
              <div style={{ marginBottom: "20px" }} key={index}>
                {isEditing === index ? (
                  <div
                    style={{
                      borderRadius: "20px",
                      boxShadow: "0px 0px 10px 2px rgba(0, 0, 0, 0.1)",
                      padding: "10px",
                    }}
                  >
                    <TipTapEditor
                      content={editedContent}
                      setOtherInfo={(data) => saveList(data, index, "edit")}
                      cancelEditing={cancelEditing}
                      fileList={selectedFileList}
                      setFileList={setSelectedFileList}
                      deleteFile={(data) => setDeleteFileList([...deleteFileList, data])}
                    />
                  </div>
                ) : (
                  <Card
                    key={index}
                    style={{
                      border: "2px solid #C7E0FF",
                      background: "#EAF4FE",
                    }}
                    className="other-card"
                  >
                    <div
                      dangerouslySetInnerHTML={{ __html: other.content }}
                      style={{ display: "inline-block", width: "90%" }}
                    />

                    {other.fileList && other.fileList.length > 0 && (
                      <>
                        <Divider style={{ margin: "15px 0 10px 0" }} />
                        <div style={{ display: "block", width: "90%" }}>
                          {other.fileList.map((file, fileIndex) => (
                            <Tag key={fileIndex} color="grey" icon={<PaperClipOutlined />}>
                              {file.fileName}
                            </Tag>
                          ))}
                        </div>
                      </>
                    )}
                    <div style={{ position: "absolute", right: "10px", top: "10px" }}>
                      <Button
                        shape="circle"
                        icon={<EditOutlined />}
                        style={{ marginRight: "10px" }}
                        onClick={() => startEditing(index, other.content, other.fileList)}
                      />
                      <Button
                        shape="circle"
                        icon={<DeleteOutlined />}
                        onClick={() => {
                          deleteOtherFromList(other, index);
                        }}
                      />
                    </div>
                  </Card>
                )}
              </div>
            );
          })}

      <TipTapEditor
        setOtherInfo={(data) => saveList(data, null, "create")}
        fileList={selectedFileList}
        setFileList={setSelectedFileList}
        deleteFile={(data) => setDeleteFileList([...deleteFileList, data])}
      />
      <div style={{ float: "right", marginTop: "50px" }}>
        <Button
          className="call-action-btn other-template-delete-btn"
          onClick={deleteList}
          style={{ marginRight: "10px" }}
        >
          Supprimer la liste
        </Button>
        {/* <Button className="call-action-btn" onClick={saveList}>
          Sauvegarder
        </Button> */}
      </div>
    </>
  ) : null;
}

export default OtherModels;

import { Col, Row, Typography } from "antd";
import { useEffect, useState } from "react";
import FooterCloud from "../../assets/images/footer-cloud.png";
import "./Footer.css";

const { Text, Title } = Typography;

function DisconnectedFooter() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Row justify="center">
      <Col xs={19} sm={19} md={19} lg={19} xl={19}>
        <div className="footer-disconnected">
          <Text className="disconnected-1">
            <Title level={4}>Merci</Title>
            <Title level={4}>Vos justificatifs sont en cours de traitement…</Title>
          </Text>
          <Text className="disconnected-2">
            <Title level={4}>Vous pouvez fermer la fenêtre.</Title>
          </Text>
        </div>
        <Row className="bottom-box">
          <div
            className="footer-img-disconnected"
            style={screenWidth > 900 ? { left: "41%" } : { left: "27%" }}
          >
            <img src={FooterCloud} alt="cloud" />
          </div>
        </Row>
      </Col>
    </Row>
  );
}

export default DisconnectedFooter;

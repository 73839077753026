import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Space, Table } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import * as API from "../../../../api/API";

function extractEmails(text) {
  return text.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi);
}

function Email() {
  const [data, setData] = useState();

  useEffect(() => {
    (async () => {
      let res = await API.getEmailDashboard();
      res = await res.json();
      setData(
        res.map((el) => ({
          date: el.mail.headers.find((el) => el.name === "Date")?.value || "01/01/1970",
          bounceType: el.bounce.bounceType,
          bounceSubType: el.bounce.bounceSubType,
          destination: el.mail.destination.toString(),
          from: el.mail.headers.find((el) => el.name === "Reply-To")?.value || "Sesha",
          diagnostic: el.bounce.bouncedRecipients
            .map((bounce) => {
              if (el.mail.destination?.length > 1)
                return (
                  (bounce.diagnosticCode || "") + " (" + extractEmails(bounce.emailAddress) + ")"
                );
              return bounce.diagnosticCode;
            })
            ?.toString(),
        }))
      );
    })();
  }, []);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      align: "center",
      width: "140px",
      defaultSortOrder: "descend",
      sorter: (a, b) => dayjs(b.date).isBefore(dayjs(a.date)),
      render: (lastSeen) => dayjs(lastSeen).format("DD/MM/YYYY HH:mm:ss"),
    },
    {
      title: "Catégorie",
      dataIndex: "bounceType",
      key: "bounceType",
      align: "center",
      width: "120px",
      sorter: (a, b) => a.bounceType.localeCompare(b.bounceType),
    },
    {
      title: "Sous-catégorie",
      dataIndex: "bounceSubType",
      key: "bounceSubType",
      align: "center",
      width: "180px",
      sorter: (a, b) => a.bounceSubType.localeCompare(b.bounceSubType),
    },
    {
      title: "Raison",
      dataIndex: "diagnostic",
      key: "diagnostic",
      align: "center",
    },
    {
      title: "Expéditeur",
      dataIndex: "from",
      width: "220px",
      key: "from",
      align: "center",
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Rechercher un expéditeur"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, "from")}
            style={{ width: 205, marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, "from")}
              icon={<SearchOutlined />}
              size="small"
            >
              Rechercher
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small">
              Réinitialiser
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) =>
        record["from"].toString().toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: "Destinataire(s)",
      dataIndex: "destination",
      key: "destination",
      align: "center",
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Rechercher un destinataire"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, "destination")}
            style={{ width: 205, marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, "destination")}
              icon={<SearchOutlined />}
              size="small"
            >
              Rechercher
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small">
              Réinitialiser
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) =>
        record["destination"].toString().toLowerCase().includes(value.toLowerCase()),
    },
  ];

  return (
    <div style={{ marginTop: "30px" }}>
      <Table
        columns={columns}
        dataSource={data}
        size="small"
        bordered
        pagination={{ defaultPageSize: 20, showSizeChanger: true }}
        loading={!data}
      />
    </div>
  );
}

export default Email;

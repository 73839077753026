import {
  CheckCircleFilled,
  CloseCircleFilled,
  ExclamationCircleOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { Button, Col, Form, Input, Popover } from "antd";
import FormItem from "antd/lib/form/FormItem";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { HashLink as Link } from "react-router-hash-link";
import hasFeature from "utils/dataProviderFeatures";
import * as API from "../../api/API";
import { selectAccountingFirm } from "../../slices/accountingFirmSlice";
import Loader from "../../utils/Loader.js";
import openNotification from "../../utils/notification";

import TooltipConnect from "utils/tooltipConnect";
import loopHelper from "../../assets/images/loopHelper.png";

function AdminDataProvider(props) {
  const accountingFirm = useSelector(selectAccountingFirm);
  const [validDPCredentials, setValidDPCredentials] = useState();
  const [editDPCredentials, setEditDPCredentials] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (accountingFirm.status === "ready") {
      const testDataProviderConnexion = async () => {
        let res = await API.testDataProviderConnexion();
        if (res.status === 200) {
          setValidDPCredentials(true);
          setLoading(false);
        } else {
          setValidDPCredentials(false);
          setLoading(false);
        }
      };
      testDataProviderConnexion();

      if (hasFeature(accountingFirm.fec, accountingFirm.dataProvider, "ADMIN_CREDENTIALS")) {
        testDataProviderConnexion();
      }
    }
  }, [accountingFirm.dataProvider]);

  const onFinish = (values) => {
    // user busy or in state test ready
    (async () => {
      let response = await API.testDataProviderConnexion({
        connexion: true,
        dataProviderId: values.dataProviderId,
        dataProviderPassword: values.dataProviderPassword,
        dataProviderToken: values.dataProviderToken,
      });
      if (response.status === 200) {
        (async () => {
          const res = await API.putDataProviderAccountingFirm(values);
          if (res.status === 201) {
            openNotification("success", "Modification effectuée");
            props.history.push("/homepage");
          } else if (res.status === 401) {
            openNotification(
              "error",
              `Identifiant ou mot de passe ${accountingFirm.dataProvider} incorrect`
            );
          } else
            openNotification("error", "Erreur lors de la modification des paramètres de connexion");
        })();
      } else {
        openNotification(
          "error",
          `Identifiant ou mot de passe ${accountingFirm.dataProvider} incorrect`
        );
      }
    })();
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    openNotification("error", "Erreur lors de l'enregistrement");
  };

  const loopPopOverContent = (
    <div>
      Votre nom de domaine LOOP se situe en haut de l’onglet "Applications" du LoopHub.
      <br />
      <img src={loopHelper} alt="Loop id helper" className="loop-helper" />
    </div>
  );

  return (
    <>
      {" "}
      {loading ? (
        <div className="user-profile-loader">
          <Loader />
        </div>
      ) : (
        <>
          {(!validDPCredentials || editDPCredentials) &&
          accountingFirm.dataProvider.includes("CEGID") ? (
            <TooltipConnect
              url={
                accountingFirm.dataProvider.includes("LOOP")
                  ? "https://knowledge.sesha.expert/knowledge/connexion-cegid-loop"
                  : "https://knowledge.sesha.expert/knowledge/connexion-automatique-%C3%A0-cegid"
              }
              text="Comment trouver mes identifiants CEGID ?"
            />
          ) : null}

          {hasFeature(accountingFirm.fec, accountingFirm.dataProvider, "ADMIN_CREDENTIALS") ? (
            <Col
              xs={22}
              sm={22}
              md={22}
              lg={{ span: 9 }}
              xl={{ span: 9 }}
              className="dataprovider-block"
            >
              {validDPCredentials && editDPCredentials === false ? (
                <div className="acd-valid-block">
                  <CheckCircleFilled className="acd-valid-icon" />
                  <p className="acd-valid-text">Vos identifiants sont à jour.</p>
                  <Button
                    type="link"
                    onClick={(e) => {
                      e.preventDefault();
                      setEditDPCredentials(true);
                    }}
                  >
                    Editer
                  </Button>
                </div>
              ) : null}

              {!validDPCredentials ? (
                <div className="dataprovider-error-block">
                  <CloseCircleFilled className="acd-error-icon" />
                  <div className="acd-error-text">
                    <p>Vos identifiants ne sont pas reconnus.</p>
                    <p>Veuillez essayer à nouveau.</p>
                  </div>
                </div>
              ) : null}

              {editDPCredentials || !validDPCredentials ? (
                <>
                  <Form
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    layout="vertical"
                    initialValues={{
                      dataProviderId: accountingFirm.dataProviderId,
                      cabinetDomain: accountingFirm.cabinetDomain,
                      token: accountingFirm.dataProviderToken,
                    }}
                    className="admin-dataprovider-form"
                  >
                    {accountingFirm.dataProvider === "MYUNISOFT" ? (
                      <Form.Item name="dataProviderToken" label="Clé d’API">
                        <Input
                          bordered={true}
                          className={"form-input"}
                          onChange={(e) => {
                            e.preventDefault();
                          }}
                        />
                      </Form.Item>
                    ) : (
                      <>
                        {" "}
                        <Form.Item
                          name="dataProviderId"
                          label={
                            accountingFirm.dataProvider.includes("CEGID")
                              ? "Identifiant de la clé"
                              : "Identifiant"
                          }
                        >
                          <Input
                            bordered={true}
                            className={"form-input"}
                            onChange={(e) => {
                              e.preventDefault();
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          name="dataProviderPassword"
                          label={
                            accountingFirm.dataProvider.includes("CEGID")
                              ? "Secret de la clé"
                              : "Mot de passe"
                          }
                          className="user-inline dataprovider-pwd"
                          style={{ marginBottom: "15px" }}
                        >
                          <Input.Password
                            bordered={true}
                            className={"form-input"}
                            onChange={(e) => {
                              e.preventDefault();
                            }}
                          />
                        </Form.Item>
                      </>
                    )}

                    {accountingFirm.dataProvider === "CEGID LOOP" ? (
                      <>
                        <Popover
                          align={{ offset: [40, 0] }}
                          placement="right"
                          trigger="hover"
                          className="admin-loop-popover"
                          overlayClassName="admin-loop-popover-inner"
                          title="Trouver mon nom de domaine Loop"
                          content={loopPopOverContent}
                        >
                          Trouver mon nom de domaine <InfoCircleOutlined />
                        </Popover>
                        <Form.Item
                          name="cabinetDomain"
                          label="Nom de domaine"
                          className="form-cabinet-domain"
                        >
                          <Input
                            bordered={true}
                            className={"form-input"}
                            onChange={(e) => {
                              e.preventDefault();
                            }}
                          />
                        </Form.Item>
                      </>
                    ) : null}

                    {accountingFirm.dataProvider.includes("EXPERT") ? (
                      <div className="warning-cegid-expert-div">
                        <Col span={1}>
                          <ExclamationCircleOutlined style={{}} />
                        </Col>
                        <Col
                          span={22}
                          style={{ fontSize: "12px", marginTop: "2px", marginLeft: "5px" }}
                        >
                          Pour completer la connexion à Cegid Expert, vous devez activer le suivi de
                          la modification comptable des dossiers dans Cegid Expert. <br />
                          <Link
                            to={{
                              pathname:
                                "https://knowledge.sesha.expert/knowledge/comment-se-connecter-%C3%A0-cegid-expert",
                            }}
                            target="_blank"
                            className="cegid-expert-help-link"
                          >
                            Voir la procédure ici.
                          </Link>
                        </Col>
                      </div>
                    ) : null}

                    <FormItem className="submit-admin-changes-btn">
                      <Button className="call-action-btn modal-submit-btn" htmlType="submit">
                        Sauvegarder
                      </Button>
                    </FormItem>
                  </Form>
                </>
              ) : null}
            </Col>
          ) : null}
        </>
      )}
    </>
  );
}
export default AdminDataProvider;

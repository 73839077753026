import dayjs from "dayjs";
import * as API from "../../api/API";
import openNotification from "../../utils/notification";

const addCommentHelper = async (comment, idClient, type, record, accountData, data) => {
  const res = await API.putOutstandings(idClient, type, record.categoryId, record.id, {
    commentClient: comment,
  });
  if (res.status === 200) {
    const updatedData = accountData.map((category) => ({
      ...category,
      operations: category.operations.map((op) => ({
        ...op,
        commentClient: op.id === record.id ? comment : op.commentClient,
      })),
    }));

    API.postClientTracker(idClient, {
      action: "OUTSTANDING_COMMENT_" + type.toUpperCase(),
      category: data.find((el) => el._id === record.categoryId)?.accountNumber,
      operationId: record.id,
      dateOp: record.date,
      label: record.label,
      reference: record.piece_ref,
      amount: record.amount,
      comment: comment,
      type: type === "clients" ? "ENCOURS CLIENTS" : "ENCOURS FOURNISSEURS",
      dueDays: record.dueDays,
    });
    API.notify({
      clientId: idClient,
      action: "outstandings" + type[0].toUpperCase() + type.slice(1),
    });

    return updatedData;
  } else {
    openNotification("error", "Erreur lors de l'envoi du commentaire");
    return false;
  }
};

const deleteCommentHelper = async (idClient, type, record, accountData, data) => {
  const res = await API.putOutstandings(idClient, type, record.categoryId, record.id, {
    commentClient: "",
  });
  if (res.status === 200) {
    const updatedData = accountData.map((category) => ({
      ...category,
      operations: category.operations.map((op) =>
        op.id === record.id ? { ...op, commentClient: "" } : op
      ),
    }));
    return updatedData;
  } else {
    openNotification("error", "Erreur lors de la suppression du commantaire");
    return;
  }
};

const getNumberFilteredHelper = (data) => {
  return data.reduce((acc, cur) => acc + cur.operations.filter((op) => !op.hidden).length, 0);
};

const getAmountFilteredHelper = (data) => {
  return data.reduce(
    (acc, current) =>
      acc +
      current.operations
        .filter((op) => !op.hidden)
        .reduce((acc, current) => acc + current.amount, 0),
    0
  );
};

const formatDataHelper = (propsData, endDate) => {
  return propsData.map((cat) => ({
    ...cat,
    key: cat._id,
    invoicesNb: cat.operations.length,
    outstandingsTotal: cat.operations.reduce((acc, current) => acc + current.amount, 0),
    operations: cat.operations.map((op) => ({
      ...op,
      dueDays: dayjs(endDate).startOf("day").diff(dayjs(op.date).startOf("day"), "days"),
    })),
  }));
};

export {
  addCommentHelper,
  deleteCommentHelper,
  formatDataHelper,
  getAmountFilteredHelper,
  getNumberFilteredHelper,
};

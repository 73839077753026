import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Input } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as API from "../../../api/API";
import { selectClient } from "../../../slices/clientSlice";
import logAction from "../../../utils/logActions";

function CommentCollabOutstandings(props) {
  const client = useSelector(selectClient);
  const [operation, setOperation] = useState({});
  const [commentCollabOutstandings, setCommentCollabOutstandings] = useState("");
  const [currentCommentCollabOutstandings, setCurrentCommentCollabOutstandings] = useState("");
  const [commentClient, setCommentClient] = useState("");

  const [editing, setEditing] = useState(false);

  useEffect(() => {
    setOperation(props.record);
    setCommentCollabOutstandings(props.record.commentCollab);
    setCurrentCommentCollabOutstandings(props.record.commentCollab);
    setCommentClient(props.record.commentClient);
  }, [props.record]);

  const updateCommentCollabOutstandings = (comment) => {
    (async () => {
      const res = await API.putOutstandings(
        client._id,
        props.type,
        props.record.idCategory,
        props.record.id,
        {
          commentCollab: comment,
        }
      );

      if (res.status === 200) {
        console.log("Comment collab updated");

        const updatedData = props.accountData.map((category) => ({
          ...category,
          operations: category.operations.map((op) => ({
            ...op,
            commentCollab: op.id === operation?.id ? comment : op.commentCollab,
          })),
        }));

        setOperation({ ...operation, commentCollab: comment });
        props.setAccountData(updatedData);

        setCommentCollabOutstandings(comment);
      } else console.log("Error");
    })();
  };

  const deleteCommentCollabOutstandings = () => {
    (async () => {
      const res = await API.putOutstandings(
        client._id,
        props.type,
        props.record.idCategory,
        props.record.id,
        {
          commentCollab: null,
        }
      );
      if (res.status === 200) {
        console.log("Comment collab deleted");
        const updatedData = props.data.map((category) => ({
          ...category,
          operations: category.operations.map((op) =>
            op._id === operation._id ? { ...op, commentCollab: "" } : op
          ),
        }));

        setOperation({ ...operation, commentCollab: undefined });
        props.setAccountData(updatedData);

        setCommentCollabOutstandings("");
      } else console.log("Error");
    })();
  };

  return (
    <div className="comment-div">
      {commentClient ? (
        <p>
          <b style={{ marginLeft: "-4px" }}>Commentaire client :</b>
          <span
            className="commentaire-cabinet-span"
            style={{
              paddingRight: "10px",
              paddingLeft: "3px",
            }}
          >
            {commentClient}
          </span>
        </p>
      ) : null}
      {commentCollabOutstandings || editing ? (
        <>
          <div
            style={{
              display: "flex",
              alignItems: !editing ? "center" : "",
              paddingTop: "0px",
              paddingBottom: "20px",
            }}
          >
            {!editing ? (
              <>
                <b>Commentaire cabinet :</b>
                <span
                  className="commentaire-cabinet-span"
                  style={{
                    paddingRight: "10px",
                    paddingLeft: "3px",
                  }}
                >
                  {commentCollabOutstandings}
                </span>
              </>
            ) : (
              <span className="commentaire-cabinet-edit-span">
                <Input
                  className="other-edit"
                  type="text"
                  defaultValue={commentCollabOutstandings}
                  placeholder="Ajouter un commentaire"
                  onChange={(e) => {
                    setCurrentCommentCollabOutstandings(e.target.value);
                  }}
                  onPressEnter={(e) => {
                    setEditing(false);
                    logAction(props.type === "providers" ? 265 : 244, 1, client._id);
                    updateCommentCollabOutstandings(e.target.value);
                  }}
                />
                <span className="help-txt">Appuyez sur « Entrée » pour valider</span>
              </span>
            )}
            <Button
              className="other-action-btn"
              shape="circle"
              icon={<EditOutlined />}
              onClick={(e) => {
                if (editing) {
                  updateCommentCollabOutstandings(currentCommentCollabOutstandings);
                  setEditing(false);
                } else {
                  setEditing(true);
                }
              }}
            />
            <Button
              className="other-action-btn other-delete-btn"
              shape="circle"
              icon={<DeleteOutlined />}
              onClick={(e) => {
                deleteCommentCollabOutstandings();
                setEditing(false);
              }}
            />
          </div>
        </>
      ) : (
        <Button
          type="text"
          className="add-comment my-collapsible__toggle"
          onClick={(e) => {
            setEditing(true);
          }}
        >
          Ajouter un commentaire
        </Button>
      )}
    </div>
  );
}

export default CommentCollabOutstandings;

import { EditOutlined } from "@ant-design/icons";
import { Button, Input } from "antd";
import { useEffect, useRef, useState } from "react";

function numberWithThousandSeparator(x) {
  return x.toString().replace(/[\u202F\u00A0]/g, " ") + " €";
}

const invertPercent = (value, percent) => {
  if (!value) return 0;
  if (!percent) return value;
  return value / (1 + percent / 100);
};
const parseNum = (num) => parseFloat(Math.round(num)).toLocaleString("fr-FR", {});

function RenderCell(props) {
  const [newCellValue, setNewCellValue] = useState(props.text);
  const [editMode, setEditMode] = useState(false);

  const cellRef = useRef(null);
  const inputRef = useRef(null);
  const selectRef = useRef(null);

  useEffect(() => {
    if (editMode) {
      inputRef.current?.focus();
      selectRef.current?.focus();
    }
  }, [editMode]);

  useEffect(() => {
    setNewCellValue(Math.round(props.text));
  }, [props.text]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (cellRef.current && !cellRef.current?.contains(event.target) && props.editedCell) {
        props.setEditedCell({});
        onFinish(newCellValue);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cellRef, props.editedCell, newCellValue]);

  useEffect(() => {
    setEditMode(props.editedCell?.type === props.type && props.editedCell?.date === props.date);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.editedCell, props.type]);

  const onFinish = (newCellValue) => {
    const localIndicators = JSON.parse(JSON.stringify(props.indicators));

    const targetIndicator = localIndicators[props.type].find(
      (el) => el.date === `${props.year}-${props.month}-01T00:00:00.000Z`
    );
    if (targetIndicator) {
      targetIndicator.value =
        invertPercent(newCellValue, props.settings?.percent?.[props.type]) || 0;
    } else {
      localIndicators[props.type].push({
        date: `${props.year}-${props.month}-01T00:00:00.000Z`,
        value: invertPercent(newCellValue, props.settings?.percent?.[props.type]) || 0,
      });
    }

    props.setIndicators(localIndicators);
  };

  return (
    <>
      {editMode ? (
        <div ref={cellRef}>
          <Input
            className="cell-number-input"
            type="number"
            ref={inputRef}
            value={newCellValue}
            onChange={(e) => setNewCellValue(parseInt(e.target.value))}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                props.setEditedCell({});
                onFinish(newCellValue);
              }
            }}
          />
        </div>
      ) : (
        <>
          <div>{numberWithThousandSeparator(parseNum(newCellValue || 0))}</div>
          {props.showEditCell?.type === props.type && props.showEditCell?.date === props.date ? (
            <Button
              type="primary"
              shape="circle"
              icon={
                <EditOutlined
                  fontSize="9px"
                  style={{
                    clipPath: "inset(0% 10% 16% 10%)",
                    position: "relative",
                    bottom: "2px",
                  }}
                />
              }
              size="small"
              style={{
                position: "absolute",
                top: "4px",
                right: "0px",
                minWidth: "18px",
                minHeight: "18px",
                width: "18px",
                height: "18px",
                padding: "0px",
              }}
            />
          ) : null}
        </>
      )}
    </>
  );
}
export default RenderCell;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getClient } from "../api/API";

const initialState = {
  status: "empty",
};

export const fetchClientAsync = createAsyncThunk("client/fetchClient", async (id) => {
  if (id) {
    const res = await getClient(id);
    return await res.json();
  }
});

export const clientSlice = createSlice({
  name: "client",
  initialState,
  reducers: {
    updateClient: (state, action) => {
      state = Object.assign(state, action.payload);
      state.status = "ready";
    },
    resetClient: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchClientAsync.pending, (state) => {
        state.status = "busy";
      })
      .addCase(fetchClientAsync.fulfilled, (state, action) => {
        state = Object.assign(state, action.payload);
        state.status = "ready";
      });
  },
});

export const { updateClient, resetClient } = clientSlice.actions;

export const selectClient = (state) => state.client;

export default clientSlice.reducer;

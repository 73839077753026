import { Alert, Button, Carousel, Col, Modal, Row, Spin } from "antd";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import hasFeature from "utils/dataProviderFeatures";
import openNotification from "utils/notification";
import { renewToken } from "utils/security";
import * as API from "../../api/API";
import { selectAccountingFirm } from "../../slices/accountingFirmSlice";
import { resetClient } from "../../slices/clientSlice";
import { selectUser, updateUser } from "../../slices/userSlice";
import LoaderRaw from "../../utils/LoaderRaw";
import * as sentry from "../../utils/sentry";
import Footer from "../footer/Footer.js";
import Nav from "../nav/Nav.js";
import ClientList from "./ClientList.js";
import ClientSearch from "./ClientSearch.js";
import "./Homepage.css";

function Homepage(props) {
  const dispatch = useDispatch();

  const accountingFirm = useSelector(selectAccountingFirm);
  const user = useSelector(selectUser);

  const [clientList, setClientList] = useState([]);
  const [busy, setBusy] = useState(true);
  const [synchro, setSynchro] = useState(false);
  const [masked, setMasked] = useState();
  const [hasSeenNewVersion, setHasSeenNewVersion] = useState(true);
  const [newsImages, setNewsImages] = useState([]);
  const [imageLoading, setImageLoading] = useState(false);
  const [index, setIndex] = useState(0);

  const carouselRef = useRef();

  useEffect(() => {
    renewToken();
  }, []);

  useEffect(() => {
    if (accountingFirm.status === "ready") {
      if (hasFeature(accountingFirm.fec, accountingFirm.dataProvider, "CLIENT_IMPORT")) {
        (async () => {
          let response = await API.testDataProviderConnexion();
          const res = await response.clone().json();
          if (response.status === 500) {
            openNotification(
              "error",
              "Une erreur interne est survenue, merci de réessayer dans quelques minutes."
            );
          } else if (response.status === 400) {
            Modal.info({
              className: "alert-data-provider",
              content: `Pour que SESHA puisse analyser vos dossiers clients, il est nécessaire de renseigner vos identifiants ${accountingFirm.dataProvider}.`,
              okText: `${
                accountingFirm.dataProvider.includes("CEGID") ||
                accountingFirm.dataProvider === "MYUNISOFT"
                  ? "Accéder à ma page d'administration"
                  : "Accéder à mon profil utilisateur"
              }`,
              onOk() {
                if (
                  accountingFirm.dataProvider.includes("CEGID") ||
                  accountingFirm.dataProvider === "MYUNISOFT"
                )
                  props.history.push("/admin");
                else if (accountingFirm.dataProvider === "FULLL")
                  window.location.href = process.env.REACT_APP_URL_FULLL;
                else props.history.push({ pathname: "/settings", hash: "4" });
              },
            });
          } else if (response.status === 401) {
            Modal.info({
              className: "alert-data-provider",
              closable: true,
              content:
                accountingFirm.dataProvider === "FULLL"
                  ? `Nous vous invitons à mettre à jour votre connexion à  ${accountingFirm.dataProvider}.`
                  : `Vos identifiants de connexion à ${accountingFirm.dataProvider} semblent ne pas être reconnus. Nous vous invitons à les mettre à jour.`,
              okText: `${
                accountingFirm.dataProvider.includes("CEGID") ||
                accountingFirm.dataProvider === "MYUNISOFT"
                  ? "Accéder à ma page d'administration"
                  : accountingFirm.dataProvider === "FULLL"
                  ? "Se connecter via FULLL"
                  : (accountingFirm.dataProvider === "HORUS" || accountingFirm.dataProvider === "INQOM")
                  ? "Se connecter via Chift"
                  : "Accéder à mon profil utilisateur"
              }`,
              onOk() {
                if (
                  accountingFirm.dataProvider.includes("CEGID") ||
                  accountingFirm.dataProvider === "MYUNISOFT"
                )
                  props.history.push("/admin");
                else if (accountingFirm.dataProvider === "FULLL")
                  window.location.href = process.env.REACT_APP_URL_FULLL;
                else if (accountingFirm.dataProvider === "HORUS" || accountingFirm.dataProvider === "INQOM") window.location.href = res.url;
                else props.history.push({ pathname: "/settings", hash: "4" });
              },
            });
          }
        })();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountingFirm.status]);

  useEffect(() => {
    if (accountingFirm.status === "ready") {
      (async () => {
        let res = await API.getClientsByAccountingFirmId(accountingFirm._id);
        res = await res.json();
        setClientList(res);

        setBusy(false);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountingFirm.status, masked]);

  useEffect(() => {
    if (accountingFirm.status === "ready" && synchro) {
      (async () => {
        let res = await API.getClientsByAccountingFirmId(accountingFirm._id);
        res = await res.json();
        setClientList(res);
        setSynchro(false);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountingFirm.status, synchro]);

  useEffect(() => {
    if (user.status === "ready" && user.hasSeenNewVersion !== undefined) {
      setImageLoading(true);
      (async () => {
        let res = await API.getNewsText("news");
        res = await res.json();
        if (res.images) setNewsImages(res.images);
        setImageLoading(false);
      })();
      setHasSeenNewVersion(user.hasSeenNewVersion);
    }
  }, [user._id]);

  useEffect(() => {
    sentry.addTag("client", undefined);

    (async () => {
      await API.getSynchroClients();
      setSynchro(true);
    })();

    dispatch(resetClient());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (accountingFirm.status === "ready")
      API.postSynchroProAbono({ accountingFirmId: accountingFirm._id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountingFirm.status]);

  const onFinish = () => {
    setHasSeenNewVersion(true);
    (async () => {
      const res = await API.putUser(user._id, {
        hasSeenNewVersion: true,
      });
      if (res.status === 201) {
        dispatch(
          updateUser({
            hasSeenNewVersion: true,
          })
        );
      }
    })();
  };

  return (
    <>
      {user.status === "ready" ? (
        <div className="homepage">
          {props.location.state !== undefined && props.location.state.error ? (
            <Alert
              className="error-alert"
              message="Génération de l'analyse"
              description="Les données disponibles ne permettent malheureusement pas de générer une analyse."
              type="error"
              closable
            />
          ) : null}
          <Nav {...props} />
          <Row justify="center" className="homepage-content">
            <Col xs={22} sm={22} md={22} lg={22} xl={22}>
              <div className="homepage-welcome-message">Bienvenue {user.firstName},</div>
            </Col>
            <Col xs={22} sm={22} md={22} lg={22} xl={22} className="search-box">
              <div className="search-div">
                <Row justify="center">
                  <Col xs={20} sm={12} md={12} lg={12} xl={12}>
                    <ClientSearch
                      {...props}
                      width={"23.32px"}
                      clientList={clientList}
                      homepage={true}
                      reload={(data) => console.log(data)}
                    />
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs={22} sm={22} md={22} lg={22} xl={22} className="list-box">
              {busy ? (
                <LoaderRaw />
              ) : (
                <ClientList {...props} clientList={clientList} setMasked={setMasked} />
              )}
            </Col>
          </Row>
          <Footer {...props} homepage={true} />
          <Modal
            title="Nouveau sur Sesha"
            width={830}
            style={{ top: 20, paddingBottom: "0" }}
            open={!hasSeenNewVersion}
            className="vpf-modal hp-modal"
            closable={false}
            footer={
              <>
                <Button
                  style={
                    index !== 0
                      ? {
                          borderColor: "var(--blue) !important",
                          color: "var(--blue) !important",
                          marginLeft: "30px",
                        }
                      : {
                          display: "none",
                        }
                  }
                  onClick={() => {
                    carouselRef.current.prev();
                    setIndex(index - 1);
                  }}
                  className="other-action-btn"
                >
                  Précédent
                </Button>
                {imageLoading === false && index === newsImages.length - 1 ? (
                  <>
                    <Button
                      style={{ float: "right", marginRight: "30px" }}
                      onClick={(e) => {
                        e.preventDefault();
                        onFinish();
                      }}
                      className="call-action-btn"
                    >
                      J'ai compris
                    </Button>
                    <Button
                      style={{
                        borderColor: "var(--blue) !important",
                        color: "var(--blue) !important",
                        float: "right",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        window
                          .open("https://meetings.hubspot.com/bastien-daubin", "_blank")
                          .focus();
                      }}
                      className="other-action-btn"
                    >
                      Prendre RDV
                    </Button>
                  </>
                ) : imageLoading === false && index !== newsImages.length - 1 ? (
                  <Button
                    style={{ float: "right", marginRight: "30px" }}
                    onClick={() => {
                      carouselRef.current.next();
                      setIndex(index + 1);
                    }}
                    className="call-action-btn"
                  >
                    Suivant
                  </Button>
                ) : null}
                <span
                  style={{
                    padding: " 0px 20px 20px 0px",
                    backgroundColor: "transparent",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <p
                    style={
                      index !== newsImages.length - 1
                        ? {
                            textAlign: "center",
                            position: "absolute",
                            bottom: "35px",
                            fontSize: "0.8em",
                            width: "auto",
                            marginLeft: "20%",
                          }
                        : {
                            textAlign: "center",
                            position: "absolute",
                            bottom: "35px",
                            fontSize: "0.8em",
                            width: "auto",
                            marginLeft: "37%",
                          }
                    }

                    // className="new-version-modal-text"
                  >
                    Pour en savoir plus, rendez-vous sur{" "}
                    <Link
                      className="new-version-modal-text-link"
                      to={
                        index === 0
                          ? {
                              pathname:
                                "https://knowledge.sesha.expert/knowledge/comment-activer-la-gestion-des-droits",
                            }
                          : index === 1
                          ? {
                              pathname:
                                "https://knowledge.sesha.expert/knowledge/comment-cr%C3%A9er-un-indicateur-personnalis%C3%A9-dans-un-mod%C3%A8le-de-donn%C3%A9es",
                            }
                          : {
                              pathname:
                                "https://knowledge.sesha.expert/knowledge/comment-générer-une-analyse-par-rapport-à-un-prévisionnel",
                            }
                      }
                      target="_blank"
                    >
                      la FAQ
                    </Link>{" "}
                    {index === newsImages.length - 1 ? (
                      "."
                    ) : (
                      <>
                        ou{" "}
                        <Link
                          className="new-version-modal-text-link"
                          to={{ pathname: "https://meetings.hubspot.com/bastien-daubin" }}
                          target="_blank"
                        >
                          prenez rendez-vous{" "}
                        </Link>
                        avec votre interlocuteur commercial.
                      </>
                    )}
                  </p>
                </span>
              </>
            }
          >
            {imageLoading ? (
              <div className="image-loader">
                <Spin />
              </div>
            ) : (
              <>
                <Carousel autoplaySpeed={4500} ref={carouselRef} dots={false}>
                  {newsImages.map((image, index) => (
                    <div key={index}>
                      <img
                        src={newsImages[index].dataUrl}
                        alt="news"
                        style={{
                          width: "100%",
                          height: "auto",
                          objectPosition: "center",
                          margin: "0px auto",
                        }}
                      ></img>
                    </div>
                  ))}
                </Carousel>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <button
                    onClick={() => {
                      carouselRef.current.goTo(0, false);
                      setIndex(0);
                    }}
                    className={
                      index === 0
                        ? "news-slider-btn active-slider-btn"
                        : "news-slider-btn disabled-slider-btn"
                    }
                  />

                  <button
                    onClick={() => {
                      carouselRef.current.goTo(1, false);
                      setIndex(1);
                    }}
                    className={
                      index === 1
                        ? "news-slider-btn active-slider-btn"
                        : "news-slider-btn disabled-slider-btn"
                    }
                  />
                  <button
                    onClick={() => {
                      carouselRef.current.goTo(2, false);
                      setIndex(2);
                    }}
                    className={
                      index === 2
                        ? "news-slider-btn active-slider-btn"
                        : "news-slider-btn disabled-slider-btn"
                    }
                  />
                </div>
              </>
            )}
          </Modal>
        </div>
      ) : null}
    </>
  );
}

export default Homepage;

import { DeleteOutlined } from "@ant-design/icons";
import { Button, Table, Typography } from "antd";
import { useEffect, useState } from "react";

import * as API from "../../../api/API";
import LoaderRaw from "../../../utils/LoaderRaw";
import openNotification from "../../../utils/notification";

const { Text } = Typography;

function OrphansClients() {
  const [selectedRows, setSelectedRows] = useState([]);
  const [clientListUpdate, setClientListUpdate] = useState(false);
  const [displayHeader, setDisplayHeader] = useState(false);
  const [displayDelete, setDisplayDelete] = useState(false);
  const [dataSource, setDataSource] = useState();
  const [selectionType] = useState("checkbox");

  useEffect(() => {
    (async () => {
      let clients = await API.getOrphansClients();
      clients = await clients.json();
      setClientListUpdate(false);
      setDataSource(clients.map((client) => ({ ...client, key: client._id })));
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientListUpdate === true]);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      if (selectedRows.length === 1) {
        setSelectedRows(selectedRows);
        setDisplayHeader(true);
        setDisplayDelete(true);
      } else if (selectedRows.length >= 1) {
        setSelectedRows(selectedRows);
        setDisplayHeader(true);
        setDisplayDelete(true);
      } else {
        setSelectedRows(null);
        setDisplayHeader(false);
        setDisplayDelete(false);
      }
    },
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "_id",
      sorter: (a, b) => a._id.localeCompare(b._id),
      render: (text, record) => text,
    },
    {
      title: "Client remote ID",
      dataIndex: "remoteId",
      sorter: (a, b) => a.remoteId.localeCompare(b.remoteId),
      render: (text, record) => text,
    },
    {
      title: "Client name",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record) => text,
    },
    {
      title: "accountingFirmId",
      dataIndex: "accountingFirmId",
      sorter: (a, b) => a.accountingFirmId.localeCompare(b.accountingFirmId),
      render: (text, record) => text,
    },
    {
      title: "Dernière maj",
      dataIndex: "updateDate",
      sorter: (a, b) => new Date(b.updateDate) - new Date(a.updateDate),
      render: (text) => new Date(text)?.toLocaleDateString(),
    },
  ];

  const handleDelete = () => {
    if (
      window.confirm(
        `Voulez-vous supprimer les clients suivants :\r ${selectedRows.map((r) => r.name)}?`
      )
    ) {
      selectedRows.forEach((elt) => {
        (async () => {
          const res = await API.postToDeleteRemoteClient({
            firm_id: elt.accountingFirmId,
            company_id: elt._id,
          });
          if (res.status === 200) {
            const resp = await API.deleteClient(elt._id);
            if (resp.status === 200) openNotification("success", "Client supprimé back + mongo");
            else openNotification("error", "Client supprimé back uniquement");
          } else {
            openNotification("error", "Client non trouvé dans le back");
          }
          setClientListUpdate(true);
          setDisplayHeader(false);
        })();
      });
    }
  };

  return (
    <div className="userDashboard">
      <br />
      <Text className="section-title">Liste des clients orphelins ({dataSource?.length || 0})</Text>
      <div className="blue-bar"></div>
      <br />
      {displayHeader ? (
        <div className="header-table">
          {displayDelete ? (
            <div
              style={{
                display: "inline-block",
                float: "right",
                padding: "9px",
              }}
            >
              <Button
                className="call-action-btn"
                key="delete"
                onClick={handleDelete}
                icon={<DeleteOutlined />}
              >
                Supprimer
              </Button>
            </div>
          ) : null}
        </div>
      ) : null}

      <Table
        rowSelection={{
          type: selectionType,
          ...rowSelection,
        }}
        columns={columns}
        dataSource={dataSource}
        size="small"
        bordered
        pagination={{ defaultPageSize: 10, showSizeChanger: true }}
        loading={{ indicator: <LoaderRaw />, spinning: !dataSource }}
      />
    </div>
  );
}

export default OrphansClients;

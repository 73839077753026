import { DeleteOutlined, EditOutlined, MessageOutlined } from "@ant-design/icons";
import { Button, Input } from "antd";
import { useState } from "react";
const { TextArea } = Input;

function IndicDescription(props) {
  const [showDescriptionInput, setShowDescriptionInput] = useState({});
  const [newDescription, setNewDescription] = useState("");

  // Entrée en mode édition
  const handleDescriptionClick = (indicId) => {
    setShowDescriptionInput((prev) => ({ ...prev, [indicId]: true }));
  };

  // Confirmation de l'édition de la description
  const handleDescriptionEnter = (indicId, event) => {
    const newValue = event.target.value;
    const isNewIndicator = props.newIndic;

    if (isNewIndicator) {
      if (newValue) {
        setNewDescription(newValue);
        props.setNewIndicDescription(newValue);
        props.setIsChanging(true);
      }
    } else {
      const currentIndic = props.currentTemplate.templateAnalysis.find(
        (indic) => indic.id === indicId
      );

      if (currentIndic?.description !== newValue) {
        const updatedTemplate = {
          ...props.currentTemplate,
          templateAnalysis: props.currentTemplate.templateAnalysis.map((indic) =>
            indic.id === indicId ? { ...indic, description: newValue } : indic
          ),
        };

        props.setIsChanging(true);
        props.setCurrentTemplate(updatedTemplate);
      }
    }

    setShowDescriptionInput((prev) => ({ ...prev, [indicId]: false }));
  };

  // Suppression de la description
  const handleDeleteDescription = (indicId) => {
    if (props.newIndic === true) {
      setNewDescription("");
      props.setNewIndicDescription("");
    } else {
      const updatedTemplate = {
        ...props.currentTemplate,
        templateAnalysis: props.currentTemplate.templateAnalysis.map((indic) => {
          if (indic.id === indicId) {
            return { ...indic, description: "" };
          }
          return indic;
        }),
      };
      props.setIsChanging(true);
      props.setCurrentTemplate(updatedTemplate);
    }
  };

  return (
    <>
      {props.currentTemplate.name === "Modèle Sesha" ? (
        // Cas spécial pour le Modèle Sesha
        (props.indic.id === "marge" || props.indic.id === "masse_sal") && (
          <div style={{ marginTop: "-10px", marginBottom: "0px !important" }}>
            <div className="template-description-icons">
              <span className="template-description">
                {props.indic?.description || newDescription}
              </span>
            </div>
          </div>
        )
      ) : (
        // Autres modèles
        <div style={{ marginTop: "-10px", marginBottom: "0px !important" }}>
          {showDescriptionInput[props.indic?.id] ? (
            // Mode édition
            <>
              <TextArea
                name={props.name}
                style={{ borderRadius: "20px", maxWidth: "96.55%" }}
                placeholder="Ajouter une note"
                defaultValue={props.indic?.description || newDescription || ""}
                onPressEnter={(e) => handleDescriptionEnter(props.indic?.id, e)}
                autoFocus
                autoSize={true}
              />
              <span className="out-placeholder">Appuyez sur « Entrée » pour valider</span>
            </>
          ) : props.indic?.description || newDescription ? (
            // Affichage de la description existante
            <div className="template-description-icons">
              <span className="template-description">
                {props.indic?.description || newDescription}
              </span>
              <>
                <Button
                  className="template-description-icon"
                  type="link"
                  icon={<EditOutlined />}
                  onClick={() => handleDescriptionClick(props.indic?.id)}
                />
                <Button
                  className="template-description-icon delete"
                  type="link"
                  icon={<DeleteOutlined />}
                  onClick={() => handleDeleteDescription(props.indic?.id)}
                />
              </>
            </div>
          ) : (
            // Bouton pour ajouter une note
            <Button
              type="link"
              icon={<MessageOutlined />}
              onClick={() => handleDescriptionClick(props.indic?.id)}
              style={{ marginLeft: "-16px" }}
            >
              Ajouter une note
            </Button>
          )}
        </div>
      )}
    </>
  );
}

export default IndicDescription;

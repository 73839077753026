import { Col, Row, Typography } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as API from "../../api/API";
import { selectClient } from "../../slices/clientSlice";
import Variation from "../analysis/analysis/Variation.js";

const { Text } = Typography;

const numberWithThousandSeparator = (x) => {
  if (x !== "") {
    return (
      Math.round(x)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " €"
    );
  }
};

const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const blobToDataURL = (blob) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
};

const fetchTableImg = async (id) => {
  const styleSheets = Array.from(document.styleSheets).filter(
    (styleSheet) => !styleSheet.href || styleSheet.href.startsWith(window.location.origin)
  );
  let css = "";
  for (const styleSheet of styleSheets) {
    for (const cssRule of styleSheet.cssRules) {
      css += ` ${cssRule.cssText} `;
    }
  }

  let dataUrl;
  try {
    const res = await API.postImage({ html: document.getElementById(id).innerHTML, css });
    const blob = await res.blob();
    dataUrl = await blobToDataURL(blob);
  } catch (error) {
    console.error(error);
  }

  return dataUrl;
};

const AnalyseTable = (props) => {
  const client = useSelector(selectClient);

  const [graphs, setGraphs] = useState({});

  const [display_marge_value] = useState(props.sendData[0].display_marge_value);
  const [display_masse_sal_value] = useState(props.sendData[0].display_masse_sal_value);

  const [tableOrder, setTableOrder] = useState([]);

  useEffect(() => {
    //verif why la condition qui est commentée existe
    if (
      (graphs.table_yoy && graphs.table_cumul) ||
      (props.sendData.length === 2 && props.sendData[1].para === "edit-treso" && graphs.table_cumul)
    )
      (async () => {
        const yoyImg = await fetchTableImg("yoyEmail");
        const cumulImg = await fetchTableImg("cumulEmail");
        props.html([
          {
            id: "yoy",
            html: yoyImg,
          },
          { id: "cumul", html: cumulImg },
        ]);
      })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graphs]);

  useEffect(() => {
    let localGraphs = { ...props.analysisData.graphs };
    localGraphs.table_yoy = localGraphs.table_yoy.map((elt) => ({
      ...elt,
      marge_percent: elt.ca !== 0 ? ((elt.marge / elt.ca) * 100).toFixed(2) : "NA",
      masse_sal_percent: elt.ca !== 0 ? ((elt.masse_sal / elt.ca) * 100).toFixed(2) : "NA",
    }));

    localGraphs.table_cumul = localGraphs.table_cumul.map((elt) => ({
      ...elt,
      marge_percent: elt.ca !== 0 ? ((elt.marge / elt.ca) * 100).toFixed(2) : "NA",
      masse_sal_percent: elt.ca !== 0 ? ((elt.masse_sal / elt.ca) * 100).toFixed(2) : "NA",
    }));
    setGraphs(localGraphs);
  }, [props.analysisData.graphs]);

  useEffect(() => {
    var tableGeneration = [];
    var indicsTableOrder = [];
    if (client.tableOrder && client.tableOrder.length > 0) {
      indicsTableOrder = client.tableOrder;
    } else {
      indicsTableOrder = ["ca", "marge", "masse_sal", "treso"];
    }

    indicsTableOrder?.forEach((elt) => {
      if (props.sendData.filter((elt) => elt.para !== "table").find((obj) => obj.id === elt))
        tableGeneration.push(
          props.sendData.filter((elt) => elt.para !== "table").find((obj) => obj.id === elt)
        );
    });

    setTableOrder(tableGeneration);
  }, []);

  const CellVar = ({ row, index, column, table_type }) => {
    if (index === 0) {
      if (column.selector === "marge") {
        return (
          <div className="cell-div">
            {(row.ca === 0 && display_marge_value === false) || client.pastYearComp ? null : (
              <Variation
                {...props}
                data={row}
                column={column}
                type={table_type}
                percent={display_marge_value}
                graphs={graphs}
              />
            )}
            <Text className="first-row">
              {column.selector === "marge" && display_marge_value
                ? numberWithThousandSeparator(row[column.selector])
                : row[column.selector + "_percent"] === "NA"
                ? row[column.selector + "_percent"]
                : row[column.selector + "_percent"] + "%"}
            </Text>
          </div>
        );
      } else {
        if (column.selector === "masse_sal") {
          return (
            <div className="cell-div">
              {(row.ca === 0 && display_masse_sal_value === false) || client.pastYearComp ? null : (
                <Variation
                  {...props}
                  data={row}
                  column={column}
                  type={table_type}
                  percent={display_masse_sal_value}
                  graphs={graphs}
                />
              )}
              <Text className="first-row">
                {column.selector === "masse_sal" && display_masse_sal_value
                  ? numberWithThousandSeparator(row[column.selector])
                  : row[column.selector + "_percent"] === "NA"
                  ? row[column.selector + "_percent"]
                  : row[column.selector + "_percent"] + "%"}
              </Text>
            </div>
          );
        } else {
          return (
            <div className="cell-div">
              {client.pastYearComp ? null : (
                <Variation
                  {...props}
                  data={row}
                  column={column}
                  type={table_type}
                  graphs={graphs}
                />
              )}
              <Text className="first-row">{numberWithThousandSeparator(row[column.selector])}</Text>
            </div>
          );
        }
      }
    } else {
      if (column.selector === "marge" || column.selector === "masse_sal") {
        return (
          <Text
            className="second-row"
            style={client.pastYearComp ? { visibility: "hidden" } : null}
          >
            {(column.selector === "marge" && display_marge_value) ||
            (column.selector === "masse_sal" && display_masse_sal_value)
              ? numberWithThousandSeparator(row[column.selector])
              : row[column.selector + "_percent"] === "NA"
              ? row[column.selector + "_percent"]
              : row[column.selector + "_percent"] + "%"}
          </Text>
        );
      } else {
        return (
          <Text
            style={client.pastYearComp ? { visibility: "hidden" } : null}
            className="second-row"
          >
            {numberWithThousandSeparator(row[column.selector])}
          </Text>
        );
      }
    }
  };

  return (
    <>
      <>
        {tableOrder.filter((elt) => elt.para !== "edit-treso").length > 0 ? (
          <>
            <Text className="section-title">
              {capitalize(props.date)}{" "}
              {client.pastYearComp
                ? null
                : client.forecast?.settings?.active?.global
                ? "par rapport au prévisionnel"
                : "par rapport à " +
                  props.date.split(" ")[0] +
                  " " +
                  (props.date.split(" ")[1] - 1).toString()}
            </Text>
            <div className="blue-bar space" />
          </>
        ) : null}
        <div id="yoyEmail">
          <Row className="row-table-email">
            {tableOrder
              .filter((elt) => elt.para !== "edit-treso")
              .map((obj) => {
                return (
                  <Col
                    span={12}
                    className="col-table-email"
                    style={client.pastYearComp ? { marginBottom: 0 } : null}
                  >
                    <div id={"column-" + obj.id} style={{ color: "#6e6e73", fontSize: "12px" }}>
                      {obj.title}
                    </div>
                    {graphs.table_yoy?.map((elt, index) => (
                      <CellVar
                        row={elt}
                        index={index}
                        column={{ selector: obj.id }}
                        table_type="table_yoy"
                        key={index}
                      />
                    ))}
                  </Col>
                );
              })}
          </Row>
        </div>
      </>
      <>
        {tableOrder.length > 0 ? (
          <>
            <Text className="section-title">
              Cumul {props.date.split(" ")[1]}{" "}
              {client.pastYearComp
                ? null
                : "par rapport au cumul " +
                  (client.forecast?.settings?.active?.global
                    ? "prévisionnel"
                    : (props.date.split(" ")[1] - 1).toString())}
            </Text>
            <div className="blue-bar space" />
          </>
        ) : null}
        <div id="cumulEmail">
          <Row className="row-table-email">
            {tableOrder.map((obj) => {
              return (
                <Col
                  span={12}
                  className="col-table-email"
                  style={client.pastYearComp ? { marginBottom: 0 } : null}
                >
                  <div id={"column-" + obj.id} style={{ color: "#6e6e73", fontSize: "12px" }}>
                    {obj.title}
                  </div>
                  {graphs.table_cumul?.map((elt, index) => (
                    <CellVar
                      row={elt}
                      index={index}
                      column={{ selector: obj.id }}
                      table_type="table_cumul"
                      key={index}
                    />
                  ))}
                </Col>
              );
            })}
          </Row>
        </div>
      </>
    </>
  );
};

export default AnalyseTable;

import { Checkbox, Col, Row, Table, Typography } from "antd";
import * as dayjs from "dayjs";
import "dayjs/locale/fr";
import { EFeatures } from "enums/EFeatures";
import jwt_decode from "jwt-decode";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import logAction from "utils/logActions";
import { hasFeatures } from "utils/security";
import * as API from "../../api/API";
import { selectAccountingFirm } from "../../slices/accountingFirmSlice";
import { selectUser, updateUser } from "../../slices/userSlice";
import openNotification from "../../utils/notification";

const { Text } = Typography;

function ClientList(props) {
  const user = useSelector(selectUser);
  const accountingFirm = useSelector(selectAccountingFirm);
  const dispatch = useDispatch();
  const decodedToken = jwt_decode(localStorage.getItem("token"));

  const [clients, setClients] = useState([]);
  const [dataSource, setdataSource] = useState([]);
  const [showOnlyMine, setShowOnlyMine] = useState();

  useEffect(() => {
    if (user.status === "ready") {
      if (user.hpShowUserClients) {
        setShowOnlyMine(user.hpShowUserClients);
      } else {
        setShowOnlyMine(false);
      }
    }
  }, []);

  useEffect(() => {
    props.setMasked();
    setClients(props.clientList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.clientList]);

  useEffect(() => {
    clients.forEach(function (obj) {
      obj.key = obj._id;
      if (obj.city === null) {
        obj.city = "";
      }
    });
    const sorted = clients.sort((a, b) => new Date(b.lastUpdate) - new Date(a.lastUpdate));
    setdataSource(sorted);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clients, showOnlyMine]);

  const columns = [
    {
      title: "Nom",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      width: "250px",
      render: (text, record) => {
        const client = clients.find((el) => el._id === record._id);
        return (
          <Link
            onClick={() => logAction(213, 1, client._id)}
            className="client-link"
            to={{
              pathname: `/analysis/${client._id}/${client.remoteId}/`,
            }}
          >
            {record.name}
          </Link>
        );
      },
      defaultSortOrder: user?.hpSorter?.field === "name" ? user?.hpSorter?.order : null,
    },
    {
      title: "Code dossier",
      dataIndex: "code",
      width: "135px",
      sorter: (a, b) => a.code?.localeCompare(b.code),
      defaultSortOrder: user?.hpSorter?.field === "code" ? user?.hpSorter?.order : null,
      hide: accountingFirm.fec || accountingFirm.dataProvider === "SAGE" ? true : false,
    },
    {
      title: "Adresse",
      dataIndex: "address",
      width: "140px",
      sorter: false,
    },
    {
      title: "Ville",
      dataIndex: "city",
      width: "110px",
      sorter: (a, b) => a.city.localeCompare(b.city),
      defaultSortOrder: user?.hpSorter?.field === "city" ? user?.hpSorter?.order : null,
    },
    {
      title: "Code Postal ",
      width: "70px",
      align: "center",
      dataIndex: "postalCode",
      sorter: (a, b) => a.postalCode - b.postalCode,
      defaultSortOrder: user?.hpSorter?.field === "postalCode" ? user?.hpSorter?.order : null,
    },
    {
      title: "Code APE",
      width: "81px",
      align: "center",
      dataIndex: "apeCode",
      sorter: (a, b) => a.apeCode - b.apeCode,
      defaultSortOrder: user?.hpSorter?.field === "apeCode" ? user?.hpSorter?.order : null,
    },
    {
      title: "Pièces reçues ",
      dataIndex: "receivedDocCount",
      sorter: (a, b) => a.receivedDocCount - b.receivedDocCount,
      width: "70px",
      align: "center",
      render: (text, record) => {
        const client = clients.find((el) => el._id === record._id);
        return (
          <Link
            className="client-link"
            style={{ margin: "0 auto" }}
            to={{
              pathname: `/analysis/${client._id}/${client.remoteId}/`,
              state: {
                key: "4",
              },
            }}
          >
            {record.receivedDocCount}
          </Link>
        );
      },
      defaultSortOrder: user?.hpSorter?.field === "receivedDocCount" ? user?.hpSorter?.order : null,
    },
    {
      title: (
        <span>
          Dernière
          <br />
          pièce chargée
        </span>
      ),
      dataIndex: "lastUpdate",
      align: "center",
      width: "130px",
      render: (text, record) => {
        if (record["lastUpdate"] !== null) {
          return (
            <span style={{ margin: "0 auto" }}>
              {dayjs(record["lastUpdate"]).format("DD/MM/YYYY")}
            </span>
          );
        }
      },
      sorter: (rowA, rowB) => new Date(rowB.lastUpdate) - new Date(rowA.lastUpdate),
      defaultSortOrder: user?.hpSorter?.field !== "lastUpdate" ? null : user?.hpSorter?.order,
    },
    {
      title: "Masquer",
      center: true,
      width: "70px",
      align: "center",
      hide: !hasFeatures([EFeatures.MANAGE_USERS_AND_RIGHTS]) || accountingFirm.connectedRights,
      render: (text, record) => {
        return (
          <Checkbox
            defaultChecked={record.active === false ? true : false}
            onChange={(e) => setInactif(e, record._id)}
          />
        );
      },
    },
  ];

  const setInactif = (e, clientId) => {
    (async () => {
      const res = await API.putClient(clientId, {
        active: !e.target.checked,
      });
      if (res.status === 201) {
        props.setMasked(clientId);
        openNotification("success", "Modification prise en compte");
      } else openNotification("error", "Erreur lors de la modification du dossier client");
    })();
  };

  const setFilterList = (e, userId) => {
    setShowOnlyMine(e.target.checked);
    (async () => {
      const res = await API.putUser(userId, {
        hpShowUserClients: e.target.checked,
      });
      if (res.status === 201) {
        dispatch(
          updateUser({
            hpShowUserClients: e.target.checked,
          })
        );
      }
    })();
  };

  const setPageSize = (pageSize, userId) => {
    (async () => {
      const res = await API.putUser(userId, {
        hpPageSize: pageSize,
      });
      if (res.status === 201 || res.status === 200) {
        dispatch(
          updateUser({
            hpPageSize: pageSize,
          })
        );
      }
    })();
  };

  const noData = showOnlyMine ? (
    <div className="no-data">Vous n'avez pas de dossiers assignés</div>
  ) : (
    <div className="no-data">Vos dossiers clients apparaîtront ici</div>
  );

  const onChangeSorter = (pagination, filters, sorter, extra) => {
    (async () => {
      const res = await API.putUser(user._id, {
        hpSorter: {
          field: sorter.field,
          order: sorter.order,
        },
      });
      if (res.status === 201 || res.status === 200) {
        dispatch(
          updateUser({
            hpSorter: {
              field: sorter.field,
              order: sorter.order,
            },
          })
        );
      }
    })();
  };

  return (
    <div className="client-list">
      <Row justify="center">
        <Col xs={24} sm={22} md={22} lg={22}>
          <Text className="section-title">Vos clients</Text>
          <div className="blue-bar"></div>
          {accountingFirm.connectedRights ? null : (
            <Checkbox
              className="checkbox-hp-select"
              defaultChecked={user.hpShowUserClients}
              onChange={(e) => setFilterList(e, user._id)}
            >
              Afficher uniquement mes dossiers client
            </Checkbox>
          )}

          <Table
            className="hp-client-list"
            rowClassName={(record, index) =>
              index % 2 === 0 ? "table-row-light" : "table-row-dark"
            }
            columns={columns.filter(({ hide }) => !hide)}
            dataSource={
              showOnlyMine
                ? dataSource.filter((cl) => cl.collaboratorsId?.includes(user._id))
                : dataSource
            }
            sortDirections={["ascend", "descend", "ascend"]}
            showSorterTooltip={false}
            size="small"
            bordered={!decodedToken.theme}
            locale={{ emptyText: noData }}
            pagination={{
              defaultPageSize: user.hpPageSize || 10,
              showSizeChanger: true,
              onShowSizeChange: (current, pageSize) => {
                setPageSize(pageSize, user._id);
              },
            }}
            onChange={onChangeSorter}
          />
        </Col>
      </Row>
    </div>
  );
}

export default ClientList;

// eslint-disable-next-line import/prefer-default-export
export const EFeatures = {
  // Sesha Classic
  ACCESS_TO_SESHA: "ACCESS_TO_SESHA",
  MANAGE_USERS_AND_RIGHTS: "MANAGE_USERS_AND_RIGHTS",
  ADMINISTRATION: "ADMINISTRATION",
  ADD_CLIENT: "ADD_CLIENT",

  // Sesha Forms
  ACCESS_TO_FORMS: "ACCESS_TO_FORMS",
  MANAGE_FORMS_RIGHTS: "MANAGE_FORMS_RIGHTS",
  CREATE_AND_SEND_FORM: "CREATE_AND_SEND_FORM",

  // Dashboard
  SUPERADMIN_READ: "SUPERADMIN_READ",
  SUPERADMIN_WRITE: "SUPERADMIN_WRITE",
  SUPERADMIN_TECH: "SUPERADMIN_TECH",
};

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUser } from "../api/API";

const initialState = {
  status: "empty",
};

export const fetchUserAsync = createAsyncThunk("user/fetchUser", async (id) => {
  if (id) {
    const res = await getUser(id);
    return await res.json();
  }
});

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUser: (state, action) => {
      state = Object.assign(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserAsync.pending, (state) => {
        state.status = "busy";
      })
      .addCase(fetchUserAsync.fulfilled, (state, action) => {
        state = Object.assign(state, action.payload);
        state.status = "ready";
      });
  },
});

export const { updateUser } = userSlice.actions;

export const selectUser = (state) => state.user;

export default userSlice.reducer;

import { useEffect, useState } from "react";

import { Button, Modal, Select, Switch, Table } from "antd";
import * as dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import * as API from "../../../api/API";
import { selectClient, updateClient } from "../../../slices/clientSlice";
import RenderCell from "./RenderCell";

function DataTuning(props) {
  const clientId = props.match.params.clientId;
  const clientRemoteId = props.match.params.clientRemoteId;
  const dispatch = useDispatch();
  const client = useSelector(selectClient);

  const [openModal, setOpenModal] = useState(false);
  const [columns, setColumns] = useState([]);
  const [dataSource, setDataSource] = useState([
    { key: "current_year", year_type: "Exercice en cours" },
    { key: "past_year", year_type: "Exercice précédent" },
  ]);
  const [originalData, setOriginalData] = useState(null);

  const [showEditCell, setShowEditCell] = useState({});
  const [editedCell, setEditedCell] = useState({});
  const [extrapolate, setExtrapolate] = useState([]);
  const [useTheoreticalStock, setUseTheoreticalStock] = useState(false);

  const updateCellValue = (type, month, value) => {
    setDataSource((prevDataSource) =>
      prevDataSource.map((row) =>
        row.key === type ? { ...row, [month]: parseFloat(value) || 0 } : row
      )
    );
  };

  const generateColumns = (startDate) => {
    const start = dayjs(startDate);
    const col = [];

    col.push({
      title: "Stock théorique à fin",
      dataIndex: "year_type",
      key: "year_type",
      className: "year-type",
      width: "150px",
    });

    for (let i = 0; i < 12; i++) {
      const currentMonth = start.add(i, "month");

      col.push({
        title: currentMonth.format("MMM"),
        dataIndex: currentMonth.format("MMM").toLowerCase(),
        key: currentMonth.format("MMM").toLowerCase(),
        align: "center",
        width: "90px",
        onCell: (record) => {
          return {
            onMouseEnter: () => {
              setShowEditCell({
                year_type: record.key,
                month: currentMonth.format("MMM").toLowerCase(),
              });
            },
            onMouseLeave: () => {
              setShowEditCell({});
            },
            onClick: () => {
              setEditedCell({
                year_type: record.key,
                month: currentMonth.format("MMM").toLowerCase(),
              });
            },
          };
        },
        render: (text, record) => {
          const cellValue = record[currentMonth.format("MMM").toLowerCase()] || 0;

          return (
            <RenderCell
              {...props}
              key={record.key + currentMonth}
              text={cellValue}
              type={record.key}
              month={currentMonth.format("MMM").toLowerCase()}
              showEditCell={showEditCell}
              editedCell={editedCell}
              setEditedCell={setEditedCell}
              updateCellValue={updateCellValue}
            />
          );
        },
      });
    }

    return col;
  };

  const transformDataForTable = (data) => {
    const currentYearData = { key: "current_year", year_type: "Exercice en cours" };
    const pastYearData = { key: "past_year", year_type: "Exercice précédent" };

    data.stock.forEach(({ month, current_year, past_year }) => {
      const monthName = dayjs()
        .month(month - 1)
        .format("MMM")
        .toLowerCase();
      currentYearData[monthName] = current_year;
      pastYearData[monthName] = past_year;
    });

    return [currentYearData, pastYearData];
  };

  const getStock = () => {
    (async () => {
      let res = await API.getStock(
        clientId,
        clientRemoteId,
        props.date.fiscal_year,
        props.date.month
      );
      res = await res.json();
      const data = {};
      data.stock = res.stocks;
      setDataSource(transformDataForTable(data));
    })();
  };

  useEffect(() => {
    var monthColumnns = [];
    monthColumnns = generateColumns(props.analysisData.client.date_debut_exercice);
    setColumns(monthColumnns);
  }, [showEditCell, editedCell, dataSource]);

  useEffect(() => {
    if (props.dataTuning) setDataSource(transformDataForTable(props.dataTuning));
    else getStock();
    setExtrapolate(props.dataTuning?.extrapolate);
    setUseTheoreticalStock(props.dataTuning?.active);
  }, []);

  const handleChange = (value) => {
    setExtrapolate(value);
  };

  const submitDataTuning = () => {
    (async () => {
      const stockData = [];

      const currentYearData = dataSource.find((row) => row.key === "current_year");
      const pastYearData = dataSource.find((row) => row.key === "past_year");

      // Mois présents dans les données
      const allMonths = new Set([
        ...Object.keys(currentYearData || {}),
        ...Object.keys(pastYearData || {}),
      ]);

      // Map noms de mois à leurs numéros
      const monthMap = {
        "janv.": 1,
        "févr.": 2,
        mars: 3,
        "avr.": 4,
        mai: 5,
        juin: 6,
        "juil.": 7,
        août: 8,
        "sept.": 9,
        "oct.": 10,
        "nov.": 11,
        "déc.": 12,
      };

      // Créez les objets de données dans l'ordre où les mois apparaissent
      allMonths.forEach((month) => {
        if (month in monthMap) {
          stockData.push({
            month: monthMap[month],
            current_year: currentYearData[month] || 0,
            past_year: pastYearData[month] || 0,
          });
        }
      });

      const dataTuningObject = {
        active: useTheoreticalStock,
        stock: stockData,
        extrapolate: extrapolate,
      };

      const res = await API.putClient(clientId, {
        dataTuning: dataTuningObject,
      });
      if (res.status === 201) {
        setOpenModal(false);
        dispatch(
          updateClient({
            dataTuning: dataTuningObject,
          })
        );
        window.location.reload();
      }
    })();
  };

  return (
    <div>
      <Button
        className="other-action-btn"
        style={{ zIndex: 3 }}
        onClick={(e) => {
          e.preventDefault();
          setOriginalData(dataSource);
          setOpenModal(true);
        }}
      >
        Ajuster les données
      </Button>
      <Modal
        title="Ajuster les données"
        className="edit-table-order-modal"
        open={openModal}
        onCancel={(e) => {
          e.preventDefault();
          setDataSource(originalData);
          setUseTheoreticalStock(client.dataTuning?.active || false);
          setExtrapolate(client.dataTuning?.extrapolate || []);
          setOpenModal(false);
        }}
        footer={null}
        forceRender
        width="1020px"
      >
        <div style={{ marginTop: "20px", marginBottom: "10px" }} className="stock">
          <Switch
            checked={useTheoreticalStock}
            onChange={(checked) => {
              setUseTheoreticalStock(checked);
            }}
          />
          <span style={{ marginLeft: "10px" }}>Utiliser un stock théorique</span>
          <Button
            className="other-action-btn"
            style={{ position: "absolute", right: "20px" }}
            onClick={(e) => {
              getStock();
            }}
          >
            Réinitialiser les données
          </Button>
        </div>

        <div className="stock-table-div">
          <Table
            className="stock-table"
            columns={columns}
            dataSource={dataSource}
            pagination={false}
          />
        </div>
        <div>
          <p style={{ marginBottom: "5px" }}>Comptes à extrapoler depuis N-1 : </p>
          <p
            style={{
              fontStyle: "italic",
              color: "#6E6E73",
              marginTop: "5px",
              marginBottom: "20px",
            }}
          >
            Pour les comptes renseignés ci-dessous, la somme de l'exercice précédent sera
            mensualisée sur l'exercice en cours.
          </p>
          <Select
            mode="tags"
            className={"add-extrapolate"}
            popupClassName={"config-extrapolate-dropdown"}
            notFoundContent={null}
            open={false}
            onChange={handleChange}
            value={extrapolate}
          />
          <p
            style={{
              fontStyle: "italic",
              color: "#6E6E73",
              marginTop: "5px",
              marginBottom: "20px",
            }}
          >
            <span style={{ fontWeight: "bold", fontStyle: "normal" }}>Exemple : </span>Je renseigne
            le compte 6061 comme compte à extrapoler. Sur N-1, je comptabilise 1200€ pour ce compte.
            Sur l'exercice N, 100€ seront comptabilisés tous les mois.
          </p>
        </div>

        <div className="prompt-btns" style={{ marginTop: "20px" }}>
          <Button
            className="other-action-btn invalid-cancel"
            onClick={(e) => {
              e.preventDefault();
              setDataSource(originalData);
              setUseTheoreticalStock(client.dataTuning?.active || false);
              setExtrapolate(client.dataTuning?.extrapolate || []);
              setOpenModal(false);
            }}
          >
            Annuler
          </Button>
          <Button
            className="call-action-btn"
            onClick={(e) => {
              e.preventDefault();
              submitDataTuning();
            }}
          >
            Valider
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default DataTuning;

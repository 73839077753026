import { ArrowRightOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import { addCommentHelper } from "helpers/client/clientOutstandingsHelper";
import { useContext, useState } from "react";
import ClientContext from "../../../../../context/ClientContext";
import * as number from "../../../../../utils/number";

const OutstandingsTableCardResponsive = (props) => {
  const [isInEditMode, setIsInEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const context = useContext(ClientContext);

  const onSubmit = async (value) => {
    if (!value.comment) {
      setIsInEditMode(false);
      return;
    }

    setIsLoading(true);
    const res = await addCommentHelper(
      value.comment,
      context.idClient,
      props.type,
      props.record,
      props.accountData,
      props.data
    );
    if (res) {
      props.setData(res);
    }
    setIsLoading(false);
    setIsInEditMode(false);
  };

  return (
    <div className="client-outstandings-responsive-card">
      <div onClick={() => setIsInEditMode((previous) => !previous)}>
        <p>
          <b>Pièces n°{props.record.pieceRef}</b>
        </p>
        <span>
          {number.parseToAmount(Math.abs(props.record.amount))}
          <ArrowRightOutlined style={{ position: "relative", top: "-1px" }} />
        </span>
      </div>
      <p>{props.record.label}</p>
      <div>
        <p>
          <b>Facturation : </b> {new Date(props.record.date).toLocaleDateString()}
        </p>
        <p>
          <b>Echéance : </b>
          {props.record.dueDays} jours
        </p>
      </div>

      {isInEditMode ? (
        <Form onFinish={async (data) => await onSubmit(data)}>
          <Form.Item name="comment">
            <Input placeholder="Ajouter un commentaire" />
          </Form.Item>
          <div>
            <Button
              type="default"
              icon={
                <CloseOutlined
                  style={{
                    position: "relative",
                    top: "2px",
                  }}
                />
              }
              shape="circle"
              onClick={() => setIsInEditMode(false)}
              loading={isLoading}
            />

            <Button
              type="default"
              htmlType="submit"
              icon={
                <CheckOutlined
                  style={{
                    color: "hsla(137, 89%, 44%, 1)",
                    position: "relative",
                    top: "2px",
                  }}
                />
              }
              shape="circle"
              loading={isLoading}
            />
          </div>
        </Form>
      ) : (
        props.record.commentClient && (
          <>
            {props.record.commentCollab && (
              <p>
                <b>Commentaire cabinet :</b> {props.record.commentCollab}
              </p>
            )}
            <p>
              <b>Votre commentaire :</b> {props.record.commentClient}
            </p>
          </>
        )
      )}
    </div>
  );
};

export default OutstandingsTableCardResponsive;

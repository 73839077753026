import { CheckCircleFilled, RollbackOutlined } from "@ant-design/icons";
import { Button, Card, Col, Row, Select, Switch, Table, Tooltip, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as API from "../../../api/API";
import ForwardOne from "../../../assets/images/Forward1.png";
import ForwardTwo from "../../../assets/images/Forward2.png";
import ForwardThree from "../../../assets/images/Forward3.png";
import { selectAccountingFirm } from "../../../slices/accountingFirmSlice";
import Footer from "../../footer/Footer.js";
import Nav from "../../nav/Nav.js";

import "../subscription.css";
let offers = require(process.env.REACT_APP_OFFERS);
const { Option } = Select;
const { Text } = Typography;

const currentAnnualOfferRank = (idOffer) => {
  let rank = 0;
  let periode = "";
  offers.forEach((offer) => {
    offer["annual"].forEach((i) => {
      if (i.proabonoId === idOffer) {
        rank = i.rank;
        periode = "annual";
      } else {
        offer["monthly"].forEach((j) => {
          if (j.proabonoId === idOffer) {
            rank = j.rank;
            periode = "monthly";
          }
        });
      }
    });
  });
  return { rank, periode };
};

const data = [
  {
    key: "1",
    feature: "Analyses rédigées et partageables",
    essentiel: true,
    pro: true,
    proplus: true,
    description:
      "Sans délai, éclairez votre client sur la situation comptable de son entreprise à l'aide des commentaires et des graphiques préparés par Sesha. N'oubliez pas d'y ajouter votre touche personnelle !",
  },
  {
    key: "2",
    feature: "Pièces manquantes",
    essentiel: true,
    pro: true,
    proplus: true,
    description:
      "Sesha fait la liste des pièces manquantes au dossier client. Vous relancez le client en 1 clic... et il vous répond sans plus d'effort !",
  },
  {
    key: "3",
    feature: "Points d'attention",
    essentiel: true,
    pro: true,
    proplus: true,
    description:
      "Sesha vous alerte sur les anomalies comptables et les priorité juridiques ou fiscales à traiter avec le client - autant d'opportunités de démontrer votre expertise.",
  },
  {
    key: "4",
    feature: "Connexion aux logiciels comptables",
    essentiel: true,
    pro: true,
    proplus: true,
    description:
      "Votre logiciel comptable n'a pas de secret pour Sesha. Oubliez les import/export de fichier FEC, la synchronisation des données est automatique et en temps réel !",
  },
  {
    key: "5",
    feature: "Connexion aux outils de GED (API ou Mail to Box)",
    essentiel: true,
    pro: true,
    proplus: true,
    description:
      "Avis aux adeptes de la fluidité digitale : les pièces transmises par vos clients dans Sesha sont directement déposées dans votre outil de GED partagée.",
  },
  {
    key: "6",
    feature: "Personnalisation",
    essentiel: true,
    pro: true,
    proplus: true,
    description:
      "Mettez Sesha aux couleurs de votre cabinet et donnez votre style aux modèles de communication proposés. En bref, passez en marque blanche.",
  },
  {
    key: "7",
    feature: "Historisation",
    essentiel: true,
    pro: true,
    proplus: true,
    description:
      "Retrouvez en 1 clic l'historique des échanges sur chaque dossier client (relances, analyses, chargement de justificatif, déclaration de perte...). Avec Sesha, vous ne ratez aucune information et pourrez faire le point à la fin de l'année.",
  },
  {
    key: "8",
    feature: "Support par email",
    essentiel: true,
    pro: true,
    proplus: true,
    description:
      "Notre équipe support est à votre service et s'efforce d'être la plus réactive possible.",
  },
  {
    key: "9",
    feature: "Vue Portefeuille",
    essentiel: false,
    pro: true,
    proplus: true,
    description:
      "A découvrir d'urgence dans Sesha : les rapports sur la situation de vos dossiers client pour prioriser vos actions et optimiser ainsi votre portefeuille.",
  },
  {
    key: "10",
    feature: "Encours",
    essentiel: false,
    pro: true,
    proplus: true,
    description:
      "Sesha fait aussi la liste des encours clients et fournisseurs - à transmettre au client qui appréciera votre aide dans le suivi de ses encours !",
  },

  {
    key: "11",
    feature: "Paramétrage des indicateurs",
    essentiel: false,
    pro: true,
    proplus: true,
    description:
      "Parce que chacune entreprise a sa particularité, personnalisez les comptes et les journaux utilisés dans le calcul des indicateurs de Sesha.",
  },

  {
    key: "12",
    feature: "Support téléphonique / CSM dédié",
    essentiel: false,
    pro: false,
    proplus: true,
    description:
      "Un expert Sesha vous aide à tirer le meilleur profit de votre nouvel outil. Un problème ? Contactez-le par téléphone pour obtenir rapidement la solution.",
  },
];

const columns = [
  {
    title: "Fonctionnalités",
    dataIndex: "feature",
    key: "feature",
    render: (text, record) => (
      <Tooltip placement="right" title={record.description} overlayClassName="feature-tooltip">
        <span className="feature-render">{text}</span>
      </Tooltip>
    ),
  },
  {
    title: "Offre Essentielle",
    dataIndex: "essentiel",
    key: "essentiel",
    align: "center",
    render: (text, record) =>
      text ? <CheckCircleFilled style={{ color: "#4569F8", fontSize: "18px" }} /> : null,
  },
  {
    title: "Offre Pro",
    dataIndex: "pro",
    key: "pro",
    align: "center",
    render: (text, record) =>
      text && record.essentiel === true ? (
        <CheckCircleFilled style={{ color: "#4569F8", fontSize: "18px" }} />
      ) : record.pro === true ? (
        <CheckCircleFilled style={{ color: "#FF9B21", fontSize: "18px" }} />
      ) : null,
  },
  {
    title: "Offre Pro +",
    dataIndex: "proplus",
    key: "proplus",
    align: "center",
    render: (text, record) =>
      text && record.pro === true ? (
        <CheckCircleFilled style={{ color: "#4569F8", fontSize: "18px" }} />
      ) : (
        <CheckCircleFilled style={{ color: "#FF9B21", fontSize: "18px" }} />
      ),
  },
];

function Offers(props) {
  const accountingFirm = useSelector(selectAccountingFirm);
  const [periode, setPeriode] = useState("annual");
  const [clientCountNbr, setClientCountNbr] = useState(50);
  const [width, setWidth] = React.useState(window.innerWidth);
  const [loading, isLoading] = useState(true);
  const [priorOffers, setPriorOffers] = useState(false);
  const breakpoint = 700;

  const queryParams = new URLSearchParams(window.location.search);
  const idOffer = queryParams.get("refo");

  useEffect(() => {
    offers.forEach((offer) => {
      offer.annual.forEach((elt) => {
        if (elt.proabonoId === idOffer) {
          setPeriode("annual");
          setClientCountNbr(elt.clientCount);
          isLoading(false);
        } else {
          isLoading(false);
        }
      });
      offer.monthly.forEach((elt) => {
        if (elt.proabonoId === idOffer) {
          setPeriode("monthly");
          setClientCountNbr(elt.clientCount);
          isLoading(false);
        } else {
          isLoading(false);
        }
      });
    });
  }, []);

  useEffect(() => {
    console.log("offers");
  }, [periode, clientCountNbr]);

  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  useEffect(() => {
    (async () => {
      if (accountingFirm.status === "ready" && accountingFirm.proAbono) {
        let res = await API.getSubscriptions(accountingFirm.proAbono?.ReferenceCustomer);
        res = await res.json();
        /* const priorOff = res.data?.filter(
          (item) =>
            (item.StateSubscription === "Terminated" ||
              item.StateSubscription === "TerminatedAtRenewal") &&
            item.AmountRecurrence > 0
        );*/
        if (res.data !== undefined) {
          setPriorOffers(true);
        }
      }
    })();
  }, [accountingFirm]);

  const onSwitchChange = (checked) => {
    if (checked) setPeriode("annual");
    else setPeriode("monthly");
  };

  const onChange = (value) => {
    setClientCountNbr(parseInt(value));
  };

  const upgradeOffer = (idTargetOffer) => {
    (async () => {
      let res = await API.upgradeSubscription(accountingFirm._id);
      res = await res.json();
      const newOffer = res.data?.Items.find((item) => item.ReferenceOffer === idTargetOffer);
      const link =
        newOffer.Links.find((link) => link.rel === "hosted-upgrade")?.href ||
        newOffer.Links.find((link) => link.rel === "hosted-subscribe")?.href;
      props.history.push({
        pathname: "/subscribe",
        state: { link },
      });
    })();
  };

  const mobileDescription = (type) => {
    let filteredData = [];

    switch (type) {
      case "ESSENTIAL":
        filteredData = data.filter((elt) => elt.essentiel === true);
        break;
      case "PRO":
        filteredData = data.filter((elt) => elt.pro === true && elt.essentiel === false);
        break;
      case "PRO_PLUS":
        filteredData = data.filter(
          (elt) => elt.proplus === true && elt.pro === false && elt.essentiel === false
        );
        break;
      default:
        break;
    }

    return (
      <div className="mobile-offers-detail">
        {filteredData.map((data) => {
          return (
            <>
              <div style={{ display: "block" }}>
                <CheckCircleFilled
                  style={{ color: "#4569F8", fontSize: "18px", display: "inline-block" }}
                />

                <Tooltip
                  placement="bottom"
                  title={data.description}
                  overlayClassName="feature-tooltip"
                >
                  <p className="feature-render-mobile">{data.feature}</p>
                </Tooltip>
              </div>
            </>
          );
        })}
      </div>
    );
  };

  return (
    <>
      {loading ? (
        ""
      ) : (
        <div className="offers">
          <Nav offers={true} />
          <Row justify="center" className="offers-content">
            <Col span={22} className="offers-box">
              <div className="section-title-offer">
                <Text className="section-title">Nos offres</Text>
                <div className="blue-bar"></div>
              </div>
              {priorOffers ? (
                <Button
                  icon={<RollbackOutlined />}
                  onClick={(e) => {
                    e.preventDefault();
                    props.history.push({
                      pathname: "/subscribe",
                    });
                  }}
                  style={{
                    background: "#ffffff",
                    borderRadius: "16px",
                    right: "55px",
                    top: "30px",
                    position: "absolute",
                  }}
                >
                  <span className="back-txt">Retour</span>
                </Button>
              ) : null}

              <div className="period-switch">
                <span>Paiement mensuel</span>
                <Switch checked={periode === "annual" ? true : false} onChange={onSwitchChange} />
                <span>annuel</span>
              </div>
              <div className="number-select">
                <span className="number-select-span">Nombre de dossiers</span>
                <Select defaultValue={clientCountNbr.toString()} onChange={onChange}>
                  <Option value="50">50</Option>
                  <Option value="100">100</Option>
                  <Option value="300">300</Option>
                </Select>
              </div>
              <Row gutter={18} justify="center" className="offers-row">
                {offers.map((offer, index) => {
                  const offerType = offer[periode].find(
                    (elt) => elt.clientCount === clientCountNbr
                  );
                  const currentRank = currentAnnualOfferRank(idOffer).rank;
                  const currentPeriode = currentAnnualOfferRank(idOffer).periode;

                  return (
                    <>
                      <Col xs={18} sm={18} md={7} lg={7} xl={7}>
                        {/* {offer.type === "ESSENTIAL" ? (
                          <img src={HumanEssential} alt="humaaan" className="img-essential" />
                        ) : offer.type === "PRO" ? (
                          <img src={HumanPro} alt="humaaan" className="img-pro" />
                        ) : (
                          <img src={HumanProPlus} alt="humaaan" className="img-pro-plus" />
                        )}*/}
                        <Card
                          key={index}
                          title={
                            offer.type === "ESSENTIAL" ? (
                              <>
                                <img
                                  src={ForwardOne}
                                  alt="forward"
                                  style={{ marginRight: "10px" }}
                                />
                                <span>{offer.title}</span>
                                <p className="description-one">
                                  Idéal pour un <b>expert indépendant</b>
                                </p>
                                <p className="description-two">
                                  Connectez Sesha à vos outils comptables et profitez des fonctions
                                  essentielles à tarif réduit.
                                </p>
                              </>
                            ) : offer.type === "PRO" ? (
                              <>
                                <img
                                  src={ForwardTwo}
                                  alt="forward"
                                  style={{ marginRight: "10px" }}
                                />
                                <span>{offer.title}</span>
                                <p className="description-one">
                                  Idéal pour un cabinet de <b>taille intermédiaire</b>
                                </p>
                                <p className="description-two">
                                  Toutes les fonctionnalités incluses dont la vue globale des
                                  dossiers, le suivi des encours et le paramétrage avancé.
                                </p>
                              </>
                            ) : (
                              <>
                                <img
                                  src={ForwardThree}
                                  alt="forward"
                                  style={{ marginRight: "10px" }}
                                />
                                <span>{offer.title}</span>
                                <p className="description-one">
                                  Idéal pour les entités de <b>grande taille</b>
                                </p>
                                <p className="description-two">
                                  Pour la gestion des grands volumes de dossiers client et l’accès à
                                  un support personnalisé et dédié à votre entreprise.
                                </p>
                              </>
                            )
                          }
                          bordered={offer.type === "PRO" ? true : false}
                          className="offer-card"
                        >
                          <div className="card-price">
                            {offer.type === "PRO_PLUS" &&
                            (periode === "monthly" || clientCountNbr < 300) ? (
                              <p className="pro-plus-p ">
                                L'offre Pro Plus est accessible à partir de 300 dossiers avec un
                                engagement annuel
                              </p>
                            ) : (
                              <>
                                <span className="offer-price">{offerType.monthlyCost}</span>
                                <div className="offer-price-div">
                                  <span className="offer-price-period">€ HT</span>
                                  <span className="offer-price-period">/mois</span>
                                </div>
                                {periode === "annual" ? (
                                  <div className="price-description-div">
                                    <span>facturé </span>
                                    <span> {offerType.annualCost} €/an</span>
                                  </div>
                                ) : (
                                  <div className="price-description-div">facturé mensuellement</div>
                                )}
                                <div className="add-client-div">
                                  {/*<PlusCircleFilled
                                style={{
                                  color: "#4569F8",
                                  fontSize: "18px",
                                  marginTop: "10px",
                                  verticalAlign: "top",
                                }}
                              />*/}
                                  <p className="add-client-txt">
                                    {offerType.additionalCost.toFixed(2)} € / dossier supplémentaire
                                  </p>
                                </div>
                              </>
                            )}
                          </div>
                          {offer.type === "PRO_PLUS" &&
                          (periode === "monthly" ||
                            clientCountNbr < 300) ? null : currentPeriode === "annual" &&
                            offerType?.rank < currentRank ? (
                            ""
                          ) : offer.type === "PRO_PLUS" ? (
                            <Button
                              className="call-action-btn offer-select-btn"
                              type="link"
                              target="_blank"
                              href="https://meetings.hubspot.com/bastien-daubin/clone"
                            >
                              Contactez-nous
                            </Button>
                          ) : (
                            <Button
                              className="call-action-btn offer-select-btn"
                              onClick={(e) => {
                                e.preventDefault();

                                upgradeOffer(offerType.proabonoId);
                              }}
                              disabled={idOffer && idOffer === offerType?.proabonoId ? true : false}
                            >
                              {idOffer && idOffer === offerType?.proabonoId
                                ? "Offre Actuelle"
                                : "Choisir"}
                            </Button>
                          )}
                        </Card>
                      </Col>
                      {width < breakpoint ? mobileDescription(offer.type) : null}
                    </>
                  );
                })}
              </Row>
              <br />
              <br />
              <p>
                Tous nos prix sont affichés <b>hors taxes</b>.
              </p>
              <p>
                Membre d'un réseau partenaire ?{" "}
                <a
                  href="https://meetings.hubspot.com/bastien-daubin/clone"
                  target="_blank"
                  rel="noreferrer"
                >
                  Contactez-nous{" "}
                </a>
              </p>
              <br />
              {width > breakpoint ? (
                <Row justify="center">
                  <Col xs={18} sm={18} md={18} lg={20} xl={20}>
                    <Table
                      dataSource={data}
                      columns={columns}
                      pagination={false}
                      className="feature-table"
                      rowClassName={(record, index) =>
                        index % 2 === 0 ? "table-row-blue" : "table-row-light"
                      }
                    />
                  </Col>
                </Row>
              ) : null}
            </Col>
          </Row>
          <Footer />
        </div>
      )}
    </>
  );
}

export default Offers;

import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Alert, Button, Modal, Switch } from "antd";
import { updateLostDocumentHelper } from "helpers/client/clientUploadHelper";
import { useContext, useState } from "react";
import ClientContext from "../../../../../context/ClientContext";

function LostDocument(props) {
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [isSwitched, setIsSwitched] = useState(
    props.operation.isLost || props.operation.isLostNoAccount
  );

  const context = useContext(ClientContext);

  const onSubmit = async (value) => {
    await updateLostDocumentHelper(
      context.idClient,
      props.operation,
      value.isLost,
      value.isLostNoAccount,
      props.setData,
      props.category
    );
  };

  if (props.title === "Autres") return null;

  return (
    <span className="toggle-button-loss">
      <p>Perdu ?</p>
      <Switch
        size="small"
        checked={isSwitched}
        onChange={(checked, e) => {
          setIsSwitched(!isSwitched);
          if (checked) {
            setModalIsVisible(true);
          } else {
            setModalIsVisible(false);
            setIsSwitched(false);
            onSubmit({ isLost: false, isLostNoAccount: false });
          }
        }}
      />
      <Modal
        title={
          <span>
            <ExclamationCircleOutlined />
            <p>Pensez-vous pouvoir retrouver ce justificatif ?</p>
          </span>
        }
        className="modal-lost-client"
        open={modalIsVisible}
        maskClosable={false}
        closable={false}
        keyboard={false}
        footer={
          <>
            <Button
              key="back"
              onClick={() => {
                setIsSwitched(false);
                onSubmit({ isLost: false, isLostNoAccount: false });
                setModalIsVisible(false);
              }}
            >
              Annuler
            </Button>
            <div>
              <Button
                key="link"
                onClick={() => {
                  setIsSwitched(true);
                  onSubmit({ isLost: false, isLostNoAccount: true });
                  setModalIsVisible(false);
                }}
              >
                Non
              </Button>
              <Button
                key="submit"
                type="primary"
                onClick={() => {
                  setIsSwitched(true);
                  onSubmit({ isLost: true, isLostNoAccount: false });
                  setModalIsVisible(false);
                }}
              >
                Oui
              </Button>
            </div>
          </>
        }
      >
        <Alert
          description={
            props.missingDocLostMessage ? (
              <span dangerouslySetInnerHTML={{ __html: props.missingDocLostMessage }} />
            ) : (
              <>
                <p style={{ margin: "0px" }}>
                  <span>
                    Nous attirons votre attention sur le fait que la non présentation de la pièce
                    justificative entraînera immédiatement la non récupération de la TVA et en cas
                    de contrôle :{" "}
                  </span>
                  <ul>
                    <li> la non déductibilité de la charge</li>
                  </ul>
                  <span>et, en fonction de votre régime d’imposition : </span>
                  <ul>
                    <li> un rappel d’impôt sur le revenu et un complément de charges sociales</li>
                  </ul>
                  <span>ou : </span>
                  <ul>
                    <li> un rappel d'impôt sur les sociétés</li>
                  </ul>
                </p>
              </>
            )
          }
          type="warning"
        />
      </Modal>
    </span>
  );
}

export default LostDocument;

import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
const modules = {
  toolbar: [
    ["bold"],
    [
      {
        background: ["#FFEED6", "#D9FFED", "#fff"],
      },
    ],
    [
      {
        color: [],
      },
    ],
  ],
};
const formats = ["bold", "background", "color", "list", "bullet", "indent"];
const TextEditor = (props) => {
  return (
    <>
      {props.text ? (
        <ReactQuill
          theme={"bubble"}
          value={props.text || ""}
          modules={modules}
          formats={formats}
          onChange={(value) => {
            props.setText(value);
          }}
        />
      ) : null}
    </>
  );
};

export default TextEditor;

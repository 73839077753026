import { CaretRightOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Collapse, Input, Typography } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import openNotification from "utils/notification";
import * as API from "../../../api/API";
import { selectAccountingFirm } from "../../../slices/accountingFirmSlice";
import Features from "./Features";

const { Text } = Typography;

function Roles(props) {
  const accountingFirm = useSelector(selectAccountingFirm);

  const [roleList, setRoleList] = useState([]);
  const [users, setUsers] = useState([]);
  const [features, setFeatures] = useState([]);
  const [editId, setEditId] = useState(null);
  const [editValue, setEditValue] = useState(null);
  const [update, setUpdate] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const res = await API.getRoles();
      const roles = await res.json();
      setRoleList(roles);

      const resFeat = await API.getFeatures();
      const feat = await resFeat.json();
      setFeatures(feat);

      const resUsers = await API.getUsersByAccountingFirmId(accountingFirm._id);
      const users = await resUsers.json();
      setUsers(users);
    }
    if (accountingFirm.status === "ready") fetchData();
  }, [update, accountingFirm.status, props.updateRoles]);

  const saveLabel = async (role) => {
    if (editValue) {
      await API.putRole(role._id, { label: editValue });
      setUpdate(!update);
    }
    setEditValue(role._id && role._id === editId ? null : role.label);
    setEditId(role._id && role._id === editId ? null : role._id);
    props.setUpdateAdmin(!props.updateAdmin);
  };

  const addRole = async () => {
    await API.postRole({ label: "Nouveau rôle", features: [] });
    setUpdate(!update);
    props.setUpdateAdmin(!props.updateAdmin);
  };

  const deleteRole = async (id) => {
    if (roleList.length === 1) {
      openNotification("error", "Le dernier rôle ne peut pas être supprimé");
      return;
    }
    if (users.map((el) => el.newRole).includes(id)) {
      openNotification("error", "Ce rôle est utilisé par au moins un utilisateur");
      return;
    }
    await API.deleteRole(id);
    setUpdate(!update);
    props.setUpdateAdmin(!props.updateAdmin);
  };

  return (
    <>
      <Text className="section-title">Gestion des rôles</Text>
      <div className="blue-bar"></div>
      <Button
        className="call-action-btn"
        type="primary"
        style={{ marginTop: "25px", marginBottom: "10px", width: "20%" }}
        onClick={() => addRole()}
      >
        Ajouter un rôle
      </Button>
      <Collapse
        bordered={false}
        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
        items={roleList.map((role, i) => ({
          key: i,
          label: (
            <>
              <EditOutlined
                onClick={(event) => {
                  event.stopPropagation();
                  saveLabel(role);
                }}
                style={{ paddingRight: "10px" }}
              />
              {role._id === editId ? (
                <>
                  <Input
                    defaultValue={role.label}
                    onClick={(e) => e.stopPropagation()}
                    onChange={(e) => setEditValue(e.target.value)}
                    onPressEnter={(e) => {
                      e.stopPropagation();
                      saveLabel(role);
                    }}
                    style={{ fontWeight: "bold", width: "30%" }}
                  />
                  <DeleteOutlined
                    onClick={(event) => {
                      event.stopPropagation();
                      deleteRole(role._id);
                    }}
                    style={{ paddingLeft: "10px" }}
                  />
                </>
              ) : (
                <span style={{ fontWeight: "bold" }}>{role.label}</span>
              )}
            </>
          ),
          showArrow: false,
          children: <Features role={role} features={features} />,
        }))}
      />
    </>
  );
}

export default Roles;

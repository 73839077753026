import { ShopOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Button, Col, Dropdown, Menu, Row, Typography } from "antd";
import { EFeatures } from "enums/EFeatures";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import * as API from "../../api/API";
import circleLinkTo from "../../assets/images/circleLinkTo.svg";
import seshaFormBeta from "../../assets/images/logo-forms-beta.svg";
import { ReactComponent as LogoSeshaWhite } from "../../assets/images/logo-sesha-dark-theme.svg";
import logoSesha from "../../assets/images/logo-sesha.svg";
import seshaVPF from "../../assets/images/logo-vpf.svg";
import { selectAccountingFirm } from "../../slices/accountingFirmSlice";
import { selectClient } from "../../slices/clientSlice";
import { selectUser } from "../../slices/userSlice";
import logAction from "../../utils/logActions";
import { hasFeatures } from "../../utils/security";
import ClientSearch from "../homepage/ClientSearch.js";
import "./Nav.css";
import { seshaLogoModifier } from "./logoModifier";

const { Text } = Typography;

function Nav(props) {
  const accountingFirm = useSelector(selectAccountingFirm);
  const user = useSelector(selectUser);
  const client = useSelector(selectClient);

  const [clientList, setClientList] = useState([]);
  const [trial, setTrial] = useState(false);

  const [modifiedSeshaLogo, setModifiedSeshaLogo] = useState("");

  const CHOSEN_THEME = localStorage.getItem("theme") || "LIGHT";

  const clientName = window.location.pathname.includes("analysis") ? client.name || "" : "";

  useEffect(() => {
    (async () => {
      setModifiedSeshaLogo(btoa(await seshaLogoModifier(logoSesha, process.env.REACT_APP_ENV)));
    })();
  }, []);

  const menuItems = [
    {
      label: <Link to="/admin">Administration</Link>,
      key: "1",
      features: [EFeatures.ADMINISTRATION, EFeatures.MANAGE_USERS_AND_RIGHTS],
    },
    {
      label: <Link to="/settings">Mon compte</Link>,
      key: "2",
      features: [EFeatures.ACCESS_TO_SESHA, EFeatures.ACCESS_TO_FORMS],
    },
    {
      label: <Link to="/subscribe">Mon offre</Link>,
      key: "3",
      features: [EFeatures.ADMINISTRATION],
    },
    {
      label: <Link to="/statistics">Statistiques</Link>,
      key: "4",
      features: [EFeatures.ADMINISTRATION],
    },
    {
      label: <Link to="/dashboard">Dashboard</Link>,
      key: "5",
      features: [EFeatures.SUPERADMIN_READ, EFeatures.SUPERADMIN_WRITE, EFeatures.SUPERADMIN_TECH],
    },
    {
      label: (
        <Link to={{ pathname: "https://knowledge.sesha.expert/knowledge" }} target="_blank">
          Aide & FAQ
        </Link>
      ),
      key: "6",
      features: [EFeatures.ACCESS_TO_SESHA, EFeatures.ACCESS_TO_FORMS],
    },
    {
      label: <Link to="/logout">Déconnexion</Link>,
      key: "7",
      features: [EFeatures.ACCESS_TO_SESHA, EFeatures.ACCESS_TO_FORMS],
    },
  ];
  const items2 = [
    {
      key: "1",
      label: (
        <a
          href={process.env.REACT_APP_URL_FORMS + "/landing/" + localStorage.getItem("token")}
          style={{
            width: "132px",
            height: "40px",
            display: "block",
          }}
        >
          <div
            style={{
              backgroundImage: `url(${seshaFormBeta})`,
              width: "132px",
              height: "40px",
              position: "absolute",
              backgroundRepeat: "no-repeat",
              top: "20%",
            }}
          ></div>
        </a>
      ),
      show: hasFeatures([EFeatures.ACCESS_TO_FORMS]),
    },
    {
      key: "2",
      label: (
        <Link
          to={window.location.href.includes("portfolio") ? "/homepage" : "/portfolio"}
          style={{
            width: "127px",
            height: "40px",
            display: "block",
          }}
        >
          <div
            style={{
              backgroundImage: window.location.href.includes("portfolio")
                ? `url(data:image/svg+xml;base64,${modifiedSeshaLogo})`
                : `url(${seshaVPF})`,
              width: "127px",
              height: "40px",
              position: "absolute",
              backgroundRepeat: "no-repeat",
              top: "20%",
            }}
          ></div>
        </Link>
      ),
      show: true,
    },
  ];

  useEffect(() => {
    if (accountingFirm.status === "ready") {
      (async () => {
        let res = await API.getClientsByAccountingFirmId(accountingFirm._id);
        res = await res.json();
        setClientList(res);
      })();
      if (accountingFirm.proAbono?.trial) setTrial(accountingFirm.proAbono.trial);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountingFirm.status]);

  return (
    <Row justify="center">
      <Col xs={22} sm={22} md={22} lg={22} xl={22}>
        <Row className="header-row" align="middle">
          <Col xs={1} sm={0} md={0} lg={0} xl={0}></Col>
          <Col xs={0} sm={11} md={5} lg={4} xl={4}>
            <div id="svg-container">
              <Link to="/homepage">
                {window.location.href.includes("portfolio") ? (
                  <img src={seshaVPF} alt="Sesha Logo VPF" width={"132px"} height={"40px"} />
                ) : CHOSEN_THEME === "LIGHT" ? (
                  <img
                    hidden={modifiedSeshaLogo === ""}
                    src={"data:image/svg+xml;base64," + modifiedSeshaLogo}
                    alt="Sesha Logo"
                    width={"132px"}
                    height={"40px"}
                  />
                ) : (
                  <LogoSeshaWhite />
                )}
              </Link>
            </div>
          </Col>

          <Col xs={2} sm={2} md={2} lg={2} xl={2}>
            <Dropdown
              menu={{ items: items2.filter((el) => el.show) }}
              placement="bottom"
              arrow={{ pointAtCenter: true }}
            >
              <div
                style={{
                  backgroundImage: `url(${circleLinkTo})`,
                  width: "32px",
                  height: "32px",
                }}
              ></div>
            </Dropdown>
          </Col>

          <Col xs={11} sm={11} md={5} lg={4} xl={4} className="nav-search">
            {props.offers ? null : (
              <ClientSearch
                {...props}
                width={"14px"}
                clientList={clientList}
                homepage={false}
                reload={(data) => {
                  if (props.reload) {
                    props.reload(data);
                    props.setKey("1");
                  }
                }}
              />
            )}
          </Col>

          <Col
            xs={{ span: 0, offset: clientName ? 1 : 1 + 0 }}
            sm={{ span: 0, offset: clientName ? 0 : 0 + 6 }}
            md={{ span: 0, offset: clientName ? 0 : 0 + 3 }}
            lg={{ span: 1, offset: clientName ? 0 : 1 + 3 }}
            xl={{ span: 1, offset: clientName ? 2 : 4 + 2 }}
          ></Col>
          <Col xs={0} sm={0} md={2} lg={2} xl={2} className="trial-left">
            {hasFeatures([
              EFeatures.ADMINISTRATION,
              EFeatures.SUPERADMIN_READ,
              EFeatures.SUPERADMIN_WRITE,
              EFeatures.SUPERADMIN_TECH,
            ])
              ? trial
                ? "jours d'essai restants"
                : null
              : null}
          </Col>
          <Col xs={0} sm={0} md={1} lg={1} xl={1} className="trial-right">
            {hasFeatures([
              EFeatures.ADMINISTRATION,
              EFeatures.SUPERADMIN_READ,
              EFeatures.SUPERADMIN_WRITE,
              EFeatures.SUPERADMIN_TECH,
            ])
              ? trial
                ? trial
                : null
              : null}
          </Col>
          {clientName ? (
            <Col xs={0} sm={8} md={5} lg={5} xl={4}>
              <Text>
                <div className="client-name-header">
                  <div className="button-client-header">
                    <Button
                      style={{
                        pointerEvents: "none",
                        display: "table-cell",
                        verticalAlign: "middle",
                      }}
                      shape="circle"
                      default
                      icon={<ShopOutlined />}
                    />{" "}
                  </div>
                  <div className="client-name-header-table">{clientName}</div>
                </div>
              </Text>
            </Col>
          ) : null}

          <Col xs={11} sm={11} md={6} lg={6} xl={4} className="menu-options">
            <Dropdown
              overlayClassName="nav-dropdown"
              overlay={
                <Menu
                  items={menuItems.filter((el) => hasFeatures(el.features))}
                  className="nav-menu"
                  onClick={(tab) => {
                    if (tab.key === "2") logAction(30, 1);
                    if (tab.key === "5") logAction(140, 1);
                  }}
                />
              }
              disabled={props.offers ? true : false}
            >
              <div className="ant-dropdown-link" href="#">
                {/*<Avatar src='//s.gravatar.com/avatar/b7fb138d53ba0f573212ccce38a7c43b?s=80' />*/}
                <Avatar icon={<UserOutlined />} />
                <span className="menu-client-name">
                  <span className="span-fistname">{user.firstName}</span>{" "}
                  {props.offers ? null : <div className="down-icon"></div>}
                </span>
              </div>
            </Dropdown>
            {process.env.REACT_APP_ENV === "dev" && (
              <a href={"http://localhost:3001/landing/" + localStorage.getItem("token")}>
                Forms local
              </a>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Nav;

import { CheckCircleFilled, CloseCircleFilled, ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as API from "../../api/API";
import { selectAccountingFirm } from "../../slices/accountingFirmSlice";
import { selectUser, updateUser } from "../../slices/userSlice";
import LoaderRaw from "../../utils/LoaderRaw";
import openNotification from "../../utils/notification";

function ConnexionACD(props) {
  const dispatch = useDispatch();

  const accountingFirm = useSelector(selectAccountingFirm);
  const user = useSelector(selectUser);

  const [changedDPCredentials, setChangedDPCredentials] = useState(false);
  const [validDPCredentials, setValidDPCredentials] = useState();
  const [editDPCredentials, setEditDPCredentials] = useState(false);
  const [acdPassword, setAcdPassword] = useState("");
  const [seshaPassword, setSeshaPassword] = useState("");

  const [busy, setBusy] = useState(true);

  useEffect(() => {
    if (accountingFirm.status === "ready") {
      const testDataProviderConnexion = async () => {
        let res = await API.testDataProviderConnexion();
        if (res.status === 200) {
          setValidDPCredentials(true);
          setBusy(false);
        } else {
          setValidDPCredentials(false);
          setBusy(false);
        }
      };
      testDataProviderConnexion();
    }
  }, [accountingFirm.dataProvider]);

  const onFinish = (values) => {
    // user busy or in state test ready
    if (changedDPCredentials === true) {
      (async () => {
        let response = await API.testDataProviderConnexion({
          connexion: true,
          dataProviderId: values.dataProviderId,
          dataProviderPassword: values.dataProviderPassword,
        });

        if (response.status === 200) {
          (async () => {
            values.email = user.email;
            const res = await API.putDataProviderUser(values);
            if (res.status === 201) {
              openNotification("success", "Modification effectuée");
              props.history.push("/homepage");
            } else if (res.status === 401) {
              openNotification(
                "error",
                `Identifiant ou mot de passe ${accountingFirm.dataProvider} incorrect`
              );
            } else if (res.status === 418) {
              openNotification("error", "Mot de passe Sesha incorrect");
            } else openNotification("error", "Erreur lors de la modification de l'utilisateur");
          })();
        } else {
          openNotification(
            "error",
            `Identifiant ou mot de passe ${accountingFirm.dataProvider} incorrect`
          );
        }
      })();
    } else {
      (async () => {
        const res = await API.putUser(user._id, values);
        if (res.status === 201) {
          dispatch(updateUser(values));
          openNotification("success", "Modification effectuée");
          props.history.push("/homepage");
        } else openNotification("error", "Erreur lors de la modification de l'utilisateur");
      })();
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      {busy ? (
        <div className="user-profile-loader">
          <LoaderRaw />
        </div>
      ) : (
        <>
          <Form
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
            initialValues={{
              dataProviderId: user.dataProviderId,
            }}
            className="user-profile-form"
          >
            <Row>
              <Col xs={22} sm={22} md={22} lg={{ offset: 2, span: 9 }} xl={{ offset: 2, span: 9 }}>
                <div className="identifiant-info" style={{ position: "relative", top: "0px" }}>
                  {validDPCredentials && editDPCredentials === false ? (
                    <div className="acd-valid-block">
                      <CheckCircleFilled className="acd-valid-icon" />
                      <p className="acd-valid-text">Vos identifiants sont à jour.</p>
                      <Button
                        type="link"
                        onClick={(e) => {
                          e.preventDefault();
                          setEditDPCredentials(true);
                        }}
                      >
                        Editer
                      </Button>
                    </div>
                  ) : null}
                </div>
                {!validDPCredentials && editDPCredentials === false ? (
                  <p
                    style={{
                      fontWeight: "bold",
                      marginBottom: "0px",
                    }}
                  >
                    Connexion {accountingFirm.dataProvider} :{" "}
                  </p>
                ) : null}

                {!validDPCredentials &&
                user.dataProviderId !== undefined &&
                user.dataProviderPassword !== undefined ? (
                  <div>
                    <CloseCircleFilled className="acd-error-icon" style={{ color: "red" }} />
                    <div className="acd-error-text">
                      <p>Vos identifiants ne sont pas reconnus.</p>
                      <p>Veuillez essayer à nouveau.</p>
                    </div>
                  </div>
                ) : null}
                {!validDPCredentials && editDPCredentials === false ? (
                  <p style={{ fontStyle: "italic", fontSize: "12px", color: "grey" }}>
                    Renseignez vos identifiants <b>i-Suite</b>, et votre mot de passe <b>Sesha</b>
                  </p>
                ) : null}

                {editDPCredentials || !validDPCredentials ? (
                  <>
                    <Form.Item
                      name="dataProviderId"
                      label={
                        <>
                          Identifiant&nbsp;
                          <b>
                            <i>
                              {accountingFirm.dataProvider === "ACD"
                                ? "i-Suite"
                                : accountingFirm.dataProvider}
                            </i>
                          </b>
                        </>
                      }
                      rules={[
                        {
                          required: true,
                          message: "Veuillez entrer votre identifiant",
                        },
                      ]}
                    >
                      <Input
                        bordered={true}
                        className={"form-input"}
                        onChange={(e) => {
                          e.preventDefault();
                          setChangedDPCredentials(true);
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      name="dataProviderPassword"
                      label={
                        <>
                          Mot de passe&nbsp;
                          <b>
                            <i>i-Suite</i>
                          </b>
                        </>
                      }
                      rules={[
                        {
                          required: true,
                          message: "Veuillez entrer votre mot de passe",
                        },
                      ]}
                      className="user-inline user-pwd"
                    >
                      <Input.Password
                        bordered={true}
                        className={"form-input"}
                        onChange={(e) => {
                          e.preventDefault();
                          setChangedDPCredentials(true);
                          setAcdPassword(e.target.value);
                        }}
                      />
                    </Form.Item>
                  </>
                ) : null}
              </Col>

              {editDPCredentials || !validDPCredentials ? (
                <>
                  <Col
                    span={8}
                    className="sesha-password-input"
                    style={
                      !validDPCredentials &&
                      user.dataProviderId !== undefined &&
                      user.dataProviderPassword !== undefined
                        ? null
                        : { marginTop: "5.6em" }
                    }
                  >
                    <Form.Item
                      name="seshaPassword"
                      label={
                        <>
                          <span>
                            Mot de passe&nbsp;{" "}
                            <b>
                              <i>Sesha</i>
                            </b>
                          </span>
                        </>
                      }
                      className="user-inline user-pwd"
                      rules={[
                        {
                          required: true,
                          message: "Veuillez entrer votre mot de passe Sesha",
                        },
                      ]}
                    >
                      <Input.Password
                        bordered={true}
                        className={"email-input "}
                        onChange={(e) => {
                          e.preventDefault();
                          setSeshaPassword(e.target.value);
                        }}
                      />
                    </Form.Item>
                    {seshaPassword === acdPassword && (seshaPassword || acdPassword) ? (
                      <div
                        style={{
                          position: "relative",
                          bottom: "20px",
                          color: "#FF9700",
                        }}
                      >
                        <Row>
                          <Col span={1} style={{ fontSize: "15px" }}>
                            <ExclamationCircleOutlined />
                          </Col>
                          <Col
                            span={22}
                            style={{ fontSize: "11px", marginLeft: "10px", marginTop: "4px" }}
                          >
                            Les mots de passe sont identiques. <br /> Avez-vous bien renseigné votre
                            mot de passe Sesha dans ce champ ?
                          </Col>
                        </Row>
                      </div>
                    ) : null}
                    <Form.Item
                      className="submit-changes-btn "
                      style={{
                        position: "absolute",
                        right: "30%",
                        top: "66%",
                      }}
                    >
                      <Button className="call-action-btn modal-submit-btn" htmlType="submit">
                        Valider
                      </Button>
                    </Form.Item>
                  </Col>
                </>
              ) : null}
            </Row>
          </Form>
        </>
      )}
    </>
  );
}

export default ConnexionACD;

import React from "react";
import { Column } from "@ant-design/plots";

const OverviewGraphComponent = (props) => {
  var config = {
    data: props.data,
    xField: "month",
    yField: "count",
    columnStyle: {
      fill: "#4569f8",
    },
  };
  return (
    <div className="overview-chart-component">
      <p>{props.title}</p>
      <Column {...config} />
    </div>
  );
};
export default OverviewGraphComponent;

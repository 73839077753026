import React from "react";
import FooterCloud from "../assets/images/black-cloud.png";
import { Row, Col } from "antd";


const Text = (
  <div>
    <p>L'application SESHA n'est pas disponible sur Internet Explorer.</p>
    <p>Nous vous invitons à utiliser Edge, Chrome ou Firefox.</p>
  </div>
);

function InternetExplorer() {
  return (
    <Row justify="center" className="ie-row">
      <Col xs={24} sm={24} md={20} lg={20} xl={20} className="ie-box">
        <div className="ie-logo">
          <img src={FooterCloud} alt="cloud" />
        </div>
        <p className="ie-oups">Oups ...</p>
        <div className="ie-text">{Text}</div>
      </Col>
    </Row>
  );
}

export default InternetExplorer;

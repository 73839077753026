import React from "react";
import { Typography } from "antd";

const { Text } = Typography;

const parseNum = (num) =>
  parseFloat(num).toLocaleString("fr-FR", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

function Variation(props) {
  const percentString = props.percent === false ? "_percent" : "";

  const variation =
    ((props.graphs[props.type][0][props.column.selector + percentString] -
      props.graphs[props.type][1][props.column.selector + percentString]) /
      props.graphs[props.type][1][props.column.selector + percentString]) *
    100;

  if (
    variation &&
    variation !== Infinity &&
    props.graphs[props.type][1][props.column.selector + percentString] >= 0 &&
    props.graphs[props.type][0][props.column.selector + percentString] >= 0
  ) {
    const firstClass = props.column.selector === "masse_sal" ? "var-neg" : "var-pos";
    const secondClass = props.column.selector === "masse_sal" ? "var-pos" : "var-neg";
    return (
      <div className={`variation-div ${variation > 0 ? firstClass : secondClass}`}>
        <Text className="variation">
          {variation > 0 ? "+" : ""}
          {Math.abs(variation) >= 100 ? parseNum(~~variation) : parseNum(variation)}%
        </Text>
      </div>
    );
  }
}

export default Variation;

import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Card } from "antd";

const IndicatorItem = (props) => {
  const { dragOverlay } = props;

  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: props.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    display: "inline-block",
    opacity: isDragging ? 0.5 : 1,
    cursor: dragOverlay ? "grabbing" : "grab",
  };
  return (
    <div style={style} ref={setNodeRef} {...attributes} {...listeners}>
      <Card
        style={{
          width: 121,
          height: 88,
          alignSelf: "center",
          fontSize: "12px",
          fontWeight: "bold",
          background: props.containerId === "container1" ? "#E8F4FF" : "#F5F5F5",
          boxShadow: " rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        }}
      >
        <div style={{ verticalAlign: "middle" }}>{props.name}</div>
      </Card>
    </div>
  );
};

export default IndicatorItem;

import {
  configureStore
} from "@reduxjs/toolkit";
import counterReducer from "../slices/counterSlice";
import accountingFirmReducer from "../slices/accountingFirmSlice";
import userReducer from "../slices/userSlice";
import clientReducer from "../slices/clientSlice";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    accountingFirm: accountingFirmReducer,
    user: userReducer,
    client: clientReducer,
  },
});
import React, { useState, useEffect } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Col, Row } from "antd";

import criteriaList from "../criteriaList";

function truncateString(str, num) {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + "...";
}

const convertOperator = (operator) => {
  switch (operator) {
    case "gte":
      return "minimum";
    case "lte":
      return "maximum";
    case "eq":
      return "égal à";
    default:
      return "";
  }
};

const OrderItem = (props) => {
  const { id, dragOverlay } = props;

  const [itemStyle, setItemStyle] = useState({});
  const [keyPointsList] = useState(
    criteriaList.find((elt) => elt.value === "keyPointsListInclusive").list
  );

  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id,
  });

  const checkKeyPointOrNot = (label, value) => {
    switch (label) {
      case "keyPointsListInclusive":
        return value.map((el) => keyPointsList[el]).join(", ");
      case "keyPointsListExclusive":
        return value.map((el) => keyPointsList[el]).join(", ");
      case "collaboratorsInclusive":
        return value
          .map(
            (el) =>
              props.users.find((user) => user._id === el)?.firstName +
              " " +
              props.users.find((user) => user._id === el)?.lastName
          )
          .join(", ");
      case "collaboratorsExclusive":
        return value
          .map(
            (el) =>
              props.users.find((user) => user._id === el)?.firstName +
              " " +
              props.users.find((user) => user._id === el)?.lastName
          )
          .join(", ");
      case "apeCode":
        return value
          .map((el) => props.apeCodes.find((ape) => ape.code === el)?.codeLabel)
          .join(", ");
      case "fiscalEndMonthExclusive":
        return value
          .map(
            (el) => criteriaList.find((crit) => crit.value === "fiscalEndMonthExclusive").list[el]
          )
          .join(", ");
      default:
        return value;
    }
  };

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
    cursor: dragOverlay ? "grabbing" : "grab",
  };

  useEffect(() => {}, []);

  return (
    <li style={style} ref={setNodeRef} {...attributes} {...listeners}>
      <Row
        className="criteria-line order-row"
        style={itemStyle}
        onMouseEnter={(e) => {
          setItemStyle({
            backgroundColor: "rgba(69,105,248,0.1)",
          });
        }}
        onMouseLeave={(e) => {
          setItemStyle({
            backgroundColor: "transparent",
          });
        }}
      >
        <Col span={24} className="order-report-name-criteria">
          <p className="order-report-name">{truncateString(props.report.name, 50)}</p>
          {props.report.criteria ? (
            Object.entries(
              Object.fromEntries(
                Object.entries(props.report.criteria).filter(([_, v]) => v !== null)
              ) || {}
            ).map((crit, i) => (
              <div className="criteria-recap-item" key={crit[0]}>
                <span style={{ color: "grey", marginRight: "10px" }}>
                  {criteriaList.find((el) => el.value === crit[0]).label}{" "}
                  <b>
                    {convertOperator(crit[1].operator)} {checkKeyPointOrNot(crit[0], crit[1].value)}
                  </b>
                </span>
                {i === Object.entries(props.report.criteria || {}).length - 1 ? null : (
                  <span style={{ marginRight: "10px" }}> · </span>
                )}
              </div>
            ))
          ) : (
            <div>
              <span style={{ color: "grey", marginRight: "10px" }}>
                Aucun critère n'a été appliqué
              </span>
            </div>
          )}
        </Col>
      </Row>
    </li>
  );
};

export default OrderItem;

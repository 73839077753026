import { Col, Row, Statistic } from "antd";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ data }) => (
  <>
    <Row gutter={16} className="expanded-component">
      <Col span={6}>
        <Statistic
          title="Date d'habilitation"
          value={
            data.creationDate
              ? new Date(data.creationDate).toLocaleString("fr-FR", {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                })
              : ""
          }
        />
      </Col>
      <Col span={6}>
        <Statistic
          title="Dernière connexion"
          value={
            data.lastLogin
              ? new Date(data.lastLogin).toLocaleString("fr-FR", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                })
              : "Jamais"
          }
        />
      </Col>
      <Col span={6}>
        <Statistic title="Identifiant utilisateur" value={data._id} />
      </Col>
    </Row>
  </>
);

import React from "react";
import { notification } from "antd";
import { CloseCircleFilled } from "@ant-design/icons";

const openNotification = (status, text) => {
  const param = {
    message: text,
    placement: "bottomRight",
    duration: 15,
    className: "notification",
    closeIcon: <CloseCircleFilled style={{ color: "var(--blue)" }} />,
  };
  switch (status) {
    case "success":
      notification.success(param);
      break;
    case "error":
      notification.error(param);
      break;
    default:
      break;
  }
};

export { openNotification as default };

import { SearchOutlined } from "@ant-design/icons";
import { Checkbox, Input } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as API from "../../../api/API";
import { selectAccountingFirm, updateAccountingFirm } from "../../../slices/accountingFirmSlice";
import openNotification from "../../../utils/notification";

// function to handle delay while modifying the checklist (to avoid too many API calls)
function useDebounce(callback, delay) {
  const timeoutRef = useRef(null);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const debouncedCallback = useCallback(
    (...args) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        callback(...args);
      }, delay);
    },
    [callback, delay]
  );

  return debouncedCallback;
}

function CheckListDepot() {
  const dispatch = useDispatch();
  const accountingFirm = useSelector(selectAccountingFirm);
  const [clientList, setClientList] = useState([]);
  const [filteredClientList, setFilteredClientList] = useState([]);
  const [checkedClientIds, setCheckedClientIds] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    if (accountingFirm.status === "ready") {
      (async () => {
        let res = await API.getClientsByAccountingFirmId(accountingFirm._id);
        res = await res.json();
        setClientList(res);
        setFilteredClientList(res);

        const checkedIds = res
          .filter((client) => !accountingFirm.excludedListForIDepot?.includes(client._id))
          .map((client) => client._id);
        setCheckedClientIds(checkedIds);
      })();
    }
  }, [accountingFirm.status, accountingFirm.excludedListForIDepot]);

  const updateDatabase = useCallback(
    async (checkedValues) => {
      const uncheckedClientIds = clientList
        .map((client) => client._id)
        .filter((id) => !checkedValues.includes(id));

      try {
        const res = await API.putAccountingFirm(accountingFirm._id, {
          excludedListForIDepot: uncheckedClientIds,
        });

        if (res.status === 201 || res.status === 200) {
          openNotification("success", "Modifications enregistrées");
          dispatch(
            updateAccountingFirm({ ...accountingFirm, excludedListForIDepot: uncheckedClientIds })
          );
        } else {
          throw new Error("Erreur lors de l'enregistrement");
        }
      } catch (error) {
        // Revert the local state if the API call fails
        openNotification("error", "Erreur lors de l'enregistrement");
        setCheckedClientIds((prevCheckedIds) =>
          prevCheckedIds.filter((id) => !uncheckedClientIds?.includes(id))
        );
      }
    },
    [accountingFirm, clientList, dispatch]
  );

  const debouncedUpdateDatabase = useDebounce(updateDatabase, 3000);

  const onChange = (checkedValues) => {
    setCheckedClientIds(checkedValues);
    debouncedUpdateDatabase(checkedValues);
  };

  const checkboxList = filteredClientList.map((client) => ({
    label: client.name,
    value: client._id,
  }));

  const onSearch = (value) => {
    setSearchValue(value);
    const filtered = clientList.filter((client) =>
      client.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredClientList(filtered);
  };

  const selectAll = () => {
    const allClientIds = filteredClientList.map((client) => client._id);
    setCheckedClientIds(allClientIds);
    debouncedUpdateDatabase(allClientIds);
  };

  const unselectAll = () => {
    setCheckedClientIds([]);
    debouncedUpdateDatabase([]);
  };

  return (
    <div className="checklist-container">
      <p>Activer i-dépôt pour les dossiers suivants : </p>
      <Input
        placeholder="Rechercher par le nom"
        suffix={<SearchOutlined />}
        onChange={(e) => onSearch(e.target.value)}
        value={searchValue}
        style={{
          width: "100%",
          marginBottom: "10px",
        }}
      />
      <div className="client-list">
        <Checkbox.Group options={checkboxList} value={checkedClientIds} onChange={onChange} />
      </div>
      <div className="select-all">
        <a
          href="# "
          onClick={(e) => {
            e.preventDefault();
            unselectAll();
          }}
        >
          Tout désélectionner
        </a>
        <span> / </span>
        <a
          href="# "
          onClick={(e) => {
            e.preventDefault();
            selectAll();
          }}
        >
          Tout sélectionner
        </a>
      </div>
    </div>
  );
}

export default CheckListDepot;

import { useEffect, useState } from "react";
import {
  formatDataHelper,
  getAmountFilteredHelper,
  getNumberFilteredHelper,
} from "../../../../../helpers/client/clientOutstandingsHelper";
import "./OutstandingsClientResponsive.css";
import OutstandingsStatisticsResponsive from "./Statistics";
import OutstandingsTableResponsive from "./Table";

const OutstandingsResponsive = (props) => {
  const [accountData, setAccountData] = useState([]);
  const [totalAmountFiltered, setTotalAmountFiltered] = useState();
  const [totalNumberFiltered, setTotalNumberFiltered] = useState();

  useEffect(() => {
    const formattedData = formatDataHelper(props.data, props.endDate);
    const amountFiltered = getAmountFilteredHelper(formattedData);
    const numberFiltered = getNumberFilteredHelper(formattedData);

    setTotalAmountFiltered(amountFiltered);
    setAccountData(formattedData);
    setTotalNumberFiltered(numberFiltered);
  }, [props.data]);

  return (
    <div className="client-outstandings-responsive">
      <div>
        <OutstandingsStatisticsResponsive
          totalAmountFiltered={totalAmountFiltered}
          totalNumberFiltered={totalNumberFiltered}
          endDate={props.endDate}
        />
        <p>
          Si le règlement de l’une de ces factures a déjà été réalisé, merci de nous indiquer de
          quelle façon en commentaires.
        </p>
      </div>
      <OutstandingsTableResponsive
        data={props.data}
        setData={props.setData}
        accountData={accountData}
        type={props.type}
        dueDays={props.dueDays}
        endDate={props.endDate}
      />
    </div>
  );
};

export default OutstandingsResponsive;

import React from "react";
import { Line } from "@ant-design/plots";

const OverviewGraphLine = (props) => {
  const config = {
    data: props.data,
    padding: "auto",
    xField: "day",
    yField: "count",
    xAxis: {
      tickCount: 10,
    },
  };
  return (
    <div className="overview-chart-component">
      <p>{props.title}</p>
      <Line {...config} />
    </div>
  );
};
export default OverviewGraphLine;

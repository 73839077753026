import { Button } from "antd";
import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export class TemplateMailTextEditor extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      text: this.props.text || "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.insertText = this.insertText.bind(this);
  }

  handleChange(value) {
    this.setState({ text: value });
    this.props.setText(value);
  }
  // componentDidMount() {
  //   this.quill.focus();
  // }

  componentDidUpdate(prevProps, prevState) {
    const newTxt = this.props.text;
    if (prevProps.text !== newTxt) {
      this.setState({ text: newTxt });
    }
  }

  insertText(txt) {
    this.quill.getEditor().focus();
    const editor = this.quill.getEditor();
    const cursorPosition = editor.getSelection().index;
    editor.insertText(cursorPosition, txt);
    editor.setSelection(cursorPosition + txt.length);
  }

  static formats = ["bold", "italic", "underline", "color", "list", "bullet", "indent"];

  static modules = {
    toolbar: {
      container: [
        ["bold", "italic", "underline"],
        [
          {
            color: [],
          },
        ],
      ],
    },
  };

  render() {
    return (
      <div
        onClick={(e) => {
          e.preventDefault();
          this.props.setHide(true);
        }}
      >
        {!this.props.hide ? (
          <div className="template-btns">
            <p className="template-btns-title">Balises disponibles :</p>
            <p className="template-btns-description">
              Elles seront remplacées par les données correspondantes
            </p>
            {this.props.template === "form" || this.props.template === "formreminder" ? (
              <>
                <Button
                  className="ql-insertClientName call-action-btn template-btn"
                  onClick={() => this.insertText("<nom_cabinet>")}
                >
                  <p>{"<nom_cabinet>"}</p>
                </Button>
                <Button
                  className="ql-insertClientName call-action-btn template-btn"
                  onClick={() => this.insertText("<nom_form>")}
                >
                  <p>{"<nom_form>"}</p>
                </Button>
                <Button
                  className="ql-insertClientName call-action-btn template-btn"
                  onClick={() => this.insertText("<date_fin>")}
                >
                  <p>{"<date_fin>"}</p>
                </Button>
                {this.props.template === "formreminder" ? (
                  <Button
                    className="ql-insertClientName call-action-btn template-btn"
                    onClick={() => this.insertText("<taux_completion>")}
                  >
                    <p>{"<taux_completion>"}</p>
                  </Button>
                ) : null}
                <Button
                  className="ql-collabSignature call-action-btn template-btn"
                  onClick={() => this.insertText("<signature_collab>")}
                >
                  <p>{"<signature_collab>"}</p>
                </Button>
              </>
            ) : (
              <>
                <Button
                  className="ql-insertClientName call-action-btn template-btn"
                  onClick={() => this.insertText("<nom_dossier_client>")}
                >
                  <p>{"<nom_dossier_client>"}</p>
                </Button>

                {this.props.template === "begining" ||
                this.props.template === "ending" ? null : this.props.template === "analysis" ? (
                  <Button
                    className="ql-analysisMonth call-action-btn template-btn"
                    onClick={() => this.insertText("<mois_analyse>")}
                  >
                    <p>{"<mois_analyse>"}</p>
                  </Button>
                ) : (
                  <>
                    <Button
                      className="ql-NumberMissingDoc call-action-btn template-btn"
                      onClick={() =>
                        this.props.template === "missingdoc"
                          ? this.insertText("<nb_justif>")
                          : this.insertText("<nb_factures>")
                      }
                    >
                      <p>
                        {this.props.template === "missingdoc" ? "<nb_justif>" : "<nb_factures>"}
                      </p>
                    </Button>

                    <Button
                      className="ql-AmountMissingDoc call-action-btn template-btn"
                      onClick={() =>
                        this.props.template === "missingdoc"
                          ? this.insertText("<montant_justif>")
                          : this.insertText("<montant_factures>")
                      }
                    >
                      <p>
                        {this.props.template === "missingdoc"
                          ? "<montant_justif>"
                          : "<montant_factures>"}
                      </p>
                    </Button>
                  </>
                )}

                <Button
                  className="ql-collabFirstName call-action-btn template-btn"
                  onClick={() => this.insertText("<prenom_collab>")}
                >
                  <p>{"<prenom_collab>"}</p>
                </Button>
                <Button
                  className="ql-collabLastName call-action-btn template-btn"
                  onClick={() => this.insertText("<nom_collab>")}
                >
                  <p>{"<nom_collab>"}</p>
                </Button>
                <Button
                  className="ql-collabSignature call-action-btn template-btn"
                  onClick={() => this.insertText("<signature_collab>")}
                >
                  <p>{"<signature_collab>"}</p>
                </Button>
              </>
            )}
          </div>
        ) : null}

        <ReactQuill
          ref={(el) => {
            this.quill = el;
          }}
          theme={"bubble"}
          defaultValue={this.state.text}
          modules={TemplateMailTextEditor.modules}
          formats={TemplateMailTextEditor.formats}
          onChange={this.handleChange}
        />
      </div>
    );
  }
}

export default TemplateMailTextEditor;

import { FrownOutlined, MailOutlined } from "@ant-design/icons";
import { Affix, Button, Checkbox, Col, Drawer, Form, Result, Row, Skeleton, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TimeMeasurement } from "utils/timeMeasurement";
import * as API from "../../../api/API";
import { selectAccountingFirm } from "../../../slices/accountingFirmSlice";
import { selectClient, updateClient } from "../../../slices/clientSlice";
import Loader from "../../../utils/LoaderRaw.js";
import { getMailDate } from "../../../utils/getDate";
import logAction from "../../../utils/logActions";
import AnalyseTable from "./AnalyseTable";
import Report from "./Report";

const defaultTemplate = {
  name: "sesha",
  templateAnalysis: [
    {
      id: "ca",
      add_accounts: ["70"],
      remove_accounts: [],
      remove_journals: [],
      chart: "C",
    },
    {
      id: "marge",
      add_accounts: [],
      remove_accounts: ["601", "602", "603", "604", "605", "607", "608", "609"],
      remove_journals: [],
      rule_1: true,
      chart: "L",
      description:
        "Dans le modèle par défaut: en cas d'absence de stock, l'analyse est établie à stock constant.",
    },
    {
      id: "masse_sal",
      add_accounts: ["64"],
      remove_accounts: [],
      remove_journals: [],
      chart: "L",
      description:
        "Dans le modèle par défaut: salaires, traitements et charges sociales (dirigeants inclus).",
    },
    {
      id: "treso",
      add_accounts: ["5"],
      remove_accounts: [],
      remove_journals: [],
      chart: "L",
    },
    {
      id: "treso_dispo",
      add_accounts: ["512"],
      remove_accounts: [],
      remove_journals: [],
      chart: "",
    },
    {
      id: "charges_ext",
      add_accounts: ["61", "62", "606"],
      remove_accounts: [],
      remove_journals: [],
      chart: "L",
    },
    {
      id: "result",
      add_accounts: ["7"],
      remove_accounts: ["6"],
      remove_journals: [],
      chart: "L",
    },
  ],
};

const indicName = {
  ca: "Chiffre d'affaires",
  marge: "Marge",
  masse_sal: "Masse salariale",
  treso: "Trésorerie",
  charges_ext: "Charges externes",
  result: "Résultat",
};

let timeMeasurement;

function Analysis(props) {
  const clientId = props.match.params.clientId;
  const clientRemoteId = props.match.params.clientRemoteId;

  const dispatch = useDispatch();
  const client = useSelector(selectClient);
  const accountingFirm = useSelector(selectAccountingFirm);

  const [analysisData, setAnalysisData] = useState();

  const [, setDatas] = React.useState([]);
  const [mail_all] = React.useState(false);
  const [showAnalysisMail, setShowAnalysisMail] = useState(false);
  const [allEmailImagesReady, setAllEmailImagesReady] = useState(false);
  const [showIndic, setShowIndic] = useState(false);
  const [update, setUpdate] = useState(false);
  const [tableDatas, setTableDatas] = useState([]);
  const [reportDatas, setReportDatas] = useState([]);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [analysisTemplate, setAnalysisTemplate] = useState();
  const [indicsTableOrder, setIndicsTableOrder] = useState([]);

  useEffect(() => {
    timeMeasurement = new TimeMeasurement();
    setShowIndic(false);
  }, []);

  useEffect(() => {
    var template = {};
    if (!props.wait)
      (async () => {
        let resClient = await API.getClient(clientId);
        resClient = await resClient.json();
        if (!accountingFirm.proAbonoFeatures?.includes("analysis_config")) {
          template = defaultTemplate;
        } else {
          let res = await API.getAnalysisConfigTemplates();
          res = await res.json();
          if (!resClient.templates?.analysis) {
            template = res.find((elt) => elt.type === "ANALYSIS" && elt.default) || defaultTemplate;
          } else {
            template =
              res.find((modele) => modele._id === resClient.templates?.analysis) || defaultTemplate;
          }
        }

        if (resClient.tableOrder && resClient.tableOrder.length > 0) {
          setIndicsTableOrder(resClient.tableOrder);
        } else {
          let indicsarray = template.templateAnalysis
            .filter((elt) => elt.visible || elt.visible === undefined)
            .map(({ id }) => id);
          indicsarray = indicsarray.slice(0, 4);
          setIndicsTableOrder(indicsarray);
        }

        setAnalysisTemplate(template);
      })();
  }, [props.wait]);

  useEffect(() => {
    setShowAnalysisMail(props.selectedMailType === "analysis" ? true : false);
  }, [props.selectedMailType]);

  useEffect(() => {
    setShowIndic(false);
    (async () => {
      setAnalysisData();
      setShowAnalysisMail(false);
      let res = await API.getAnalysis(
        clientId,
        clientRemoteId,
        props.date.fiscal_year,
        props.date.month
      );
      if (res.status === 200) {
        res = await res.json();
        setError(false);
        dispatch(updateClient({ client: res.client }));
        setAnalysisData(res);
      } else setError(true);
      logAction(10, timeMeasurement.getElapsedTime(), clientId);
    })();
  }, [clientRemoteId, props.date]);

  useEffect(() => {
    setSuccess(false);
    setTimeout(() => {
      setSuccess(true);
    }, 20000);
  }, [props.date, clientRemoteId]);

  useEffect(() => {
    if (props.selectedMailType !== "analysis") setShowAnalysisMail(false);
  }, [props.selectedMailType]);

  const showDrawer = () => {
    setShowIndic(true);
    setUpdate(true);
  };

  const formatIndicName = (indic) => {
    if (indic) {
      const name = indic.substring(0, 15);
      return indic.length > 15 ? <Tooltip title={indic}>{name + "..."}</Tooltip> : name;
    }
    return undefined;
  };

  const onDrawerClose = () => {
    setShowIndic(false);
    setUpdate(false);
  };

  const onFinish = (values) => {
    (async () => {
      let para = [];

      const valuesId = values.indics.map((elt) => elt.split("edit-")[1]);

      reportDatas.forEach((indic) => {
        if (valuesId.includes(indic.id)) para.push(indic);
      });

      para = [tableDatas, ...para];
      setDatas(para);
      props.sendData(para);
      props.analysisData(analysisData);

      const res = await API.putClient(clientId, {
        analysisIndics: values.indics,
      });
      if (res.status === 201) {
        dispatch(
          updateClient({
            analysisIndics: values.indics,
          })
        );
      }
    })();
  };

  return (
    <>
      {" "}
      {error ? (
        <Result
          icon={<FrownOutlined style={{ color: "#4569f8" }} />}
          title="L'analyse n'a pas pu être générée, réessayez dans quelques instants"
        />
      ) : !analysisData || !analysisTemplate?.templateAnalysis ? (
        <div style={{ marginTop: "20px" }}>
          <Skeleton
            active={true}
            paragraph={{
              rows: 4,
            }}
          />
          <Skeleton
            active={true}
            paragraph={{
              rows: 4,
            }}
          />
          {success ? (
            <div className="loading-message">
              <div className="loading-message-icon">{<Loader />}</div>
              <div style={{ padding: "3%", marginLeft: "2%" }}>
                Analyse en cours de chargement...
              </div>
            </div>
          ) : null}
        </div>
      ) : (
        <>
          {!showAnalysisMail && (
            <>
              <div onMouseLeave={onDrawerClose}>
                <Affix offsetTop={120} className="btn-fix">
                  <div
                    className="site-drawer-render-indics"
                    style={{ height: showIndic ? 450 : 100 }}
                  >
                    <Button
                      type="primary"
                      disabled={analysisData.text.status_code === 400}
                      className="select-indic-btn"
                      onMouseEnter={showDrawer}
                    >
                      <MailOutlined style={{ fontSize: "22px" }} />
                    </Button>

                    <Drawer
                      title="Personnaliser et envoyer au client"
                      rootClassName="indic-drawer"
                      placement="right"
                      closable={false}
                      onClose={onDrawerClose}
                      open={showIndic}
                      getContainer={false}
                      rootStyle={{ position: "absolute" }}
                      width={300}
                      mask={false}
                    >
                      <Form
                        rootClassName="indics-form"
                        onFinish={onFinish}
                        initialValues={{
                          indics:
                            client.analysisIndics &&
                            client.analysisIndics.length > 0 &&
                            client.analysisIndics[0].split("-")[0] === "edit"
                              ? client.analysisIndics
                              : [
                                  "edit-ca",
                                  "edit-marge",
                                  "edit-masse_sal",
                                  "edit-treso",
                                  "edit-charges_ext",
                                  "edit-result",
                                ],
                        }}
                      >
                        <Form.Item
                          name="indics"
                          rootClassName="indics-checkbox-group"
                          style={{ width: "100%" }}
                        >
                          <Checkbox.Group>
                            <Row>
                              {analysisTemplate.templateAnalysis
                                .filter((elt) => elt.id !== "treso_dispo")
                                .filter((elt) => elt.visible || elt.visible === undefined)
                                .map((indic) => (
                                  <Col
                                    span={14}
                                    offset={6}
                                    style={{ paddingLeft: "4px" }}
                                    key={indic.id}
                                  >
                                    <Checkbox
                                      value={"edit-" + indic.id}
                                      rootStyle={{ lineHeight: "22px" }}
                                    >
                                      {formatIndicName(indic.name) || indicName[indic.id]}
                                    </Checkbox>
                                  </Col>
                                ))}
                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                        <Form.Item rootClassName="submit-indics-btn">
                          <Button
                            rootClassName="call-action-btn modal-submit-btn"
                            htmlType="submit"
                            loading={!allEmailImagesReady}
                            onClick={() => {
                              setTimeout(function () {
                                onDrawerClose();
                                setShowAnalysisMail(true);
                                props.setSelectedMailType("analysis");
                              }, 100);
                            }}
                          >
                            Suivant
                          </Button>
                        </Form.Item>
                      </Form>
                    </Drawer>
                  </div>
                </Affix>
              </div>
              {client.email?.analysis?.lastEmailSent ? (
                <span className="mail-date">
                  Dernier envoi {getMailDate(client.email.analysis.lastEmailSent)}
                </span>
              ) : null}
            </>
          )}
          <Row className="row-tables">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <AnalyseTable
                {...props}
                analysisData={analysisData}
                all={mail_all}
                update={update}
                selectAnalyseDate={props.setDate}
                selectTable={(data) => {
                  setTableDatas(data);
                }}
                analysisTemplate={analysisTemplate}
                indicsTableOrder={indicsTableOrder}
                setIndicsTableOrder={setIndicsTableOrder}
              />

              <>
                <Report
                  {...props}
                  analysisData={analysisData}
                  all={mail_all}
                  update={update}
                  show={showAnalysisMail}
                  selectDivReport={(data) => {
                    setReportDatas(data);
                  }}
                  setAllEmailImagesReady={setAllEmailImagesReady}
                  analysisTemplate={analysisTemplate}
                />
              </>
            </Col>
          </Row>
        </>
      )}
    </>
  );
}

export default Analysis;

// {input.client ? (

//   ) : (
//     <div>
//       Oups... Il semble que le dossier client n'a pas pu être chargé...
//       N'hésitez pas à nous signaler cet incident via le bouton "Quelque
//       chose à nous signaler" situé à droite de l'écran.
//     </div>
//   )}

const criteriaList = [
  {
    label: "Pièces manquantes (nb)",
    value: "missingDocNumber",
    type: "number",
    filter: "Nombre de pièces manquantes",
    family: "Indicateur Sesha",
    placeholder: "Saisir un nombre",
  },
  {
    label: "Pièces manquantes (€)",
    value: "missingDocAmount",
    type: "number",
    filter: "Montant total (€)",
    family: "Indicateur Sesha",
    placeholder: "Saisir un montant",
  },
  {
    label: "Pièces reçues / pièces demandées",
    value: "receivedVsRequested",
    type: "number",
    filter: "Minimum (%)",
    family: "Indicateur Sesha",
    placeholder: "Saisir un pourcentage",
  },
  {
    label: "Pièces perdues / pièces demandées",
    value: "lostVsRequested",
    type: "number",
    filter: "Minimum (%)",
    family: "Indicateur Sesha",
    placeholder: "Saisir un pourcentage",
  },
  {
    label: "Dernière analyse envoyée",
    value: "lastAnalysisEmailSent",
    type: "number",
    filter: "Nombre en jours",
    family: "Indicateur Sesha",
    placeholder: "Saisir un nombre de jours",
  },
  {
    label: "Dernière relance pièces manquantes",
    value: "lastMissingDocEmailSent",
    type: "number",
    filter: "Nombre en jours",
    family: "Indicateur Sesha",
    placeholder: "Saisir un nombre de jours",
  },
  {
    label: "Encours fournisseurs (€)",
    value: "outstandingsProviderAmount",
    type: "number",
    filter: "Total encours fournisseurs (€)",
    family: "Indicateur Sesha",
    placeholder: "Saisir un montant",
  },
  {
    label: "Encours clients (€)",
    value: "outstandingsClientAmount",
    type: "number",
    filter: "Total encours client (€)",
    family: "Indicateur Sesha",
    placeholder: "Saisir un montant",
  },
  {
    label: "Factures fournisseur impayées",
    value: "outstandingsProvider",
    type: "number",
    filter: "Nombre de factures fournisseur impayées",
    family: "Indicateur Sesha",
    placeholder: "Saisir un nombre",
  },
  {
    label: "Factures client impayées",
    value: "outstandingsClient",
    type: "number",
    filter: "Nombre de factures fournisseur impayées",
    family: "Indicateur Sesha",
    placeholder: "Saisir un nombre",
  },
  {
    label: "Secteur(s) d’activité(s)",
    value: "apeCode",
    type: "list",
    filter: "Codes APE",
    family: "Indicateur Sesha",
    placeholder: "Saisir un nombre",
  },
  {
    label: "Points d'attention (et)",
    value: "keyPointsListInclusive",
    type: "list",
    filter: "Liste des points pour chaque client",
    list: {
      1: "Compte courant d'associé débiteur",
      2: "Compte d'encaissements en attente créditeur",
      3: "Caisse créditrice",
      4: "Caisse supérieure à au moins 8 jours de CA mensuel",
      6: "Retard de traitement du dossier comptable",
      7: "Compte 44551 : mouvement non lettré de plus de 30 jours",
      19: "Compte 431 : mouvement non lettré de plus de 90 jours",
      20: "Compte 437 : mouvement non lettré de plus de 90 jours",
      8: "Capitaux propre inférieurs à la moitié du capital social (n-1)",
      9: "Risque de solvabilité",
      10: "Risque de cessation de paiements",
      12: "Compte de virement interne non soldé",
      13: "Apport d'espèces supérieur à 3000€",
      14: "Compte 421 débiteur",
      1402: "Mouvements non soldés au sein du compte 421",
      15: "Présence de comptes soldés mais non lettrés au sein des comptes 401 et 411",
      16: "1er client",
      17: "1er salarié",
      18: "Stop de la masse salariale",
      2101: "Evolution des cotisations TNS",
      2102: "Evolution des cotisations TNS - Mouvement créditeur",
    },
    family: "Indicateur Sesha",
  },
  {
    label: "Points d'attention (ou)",
    value: "keyPointsListExclusive",
    type: "list",
    filter: "Au moins un des points dans la liste",
    list: {
      1: "Compte courant d'associé débiteur",
      2: "Compte d'encaissements en attente créditeur",
      3: "Caisse créditrice",
      4: "Caisse supérieure à au moins 8 jours de CA mensuel",
      6: "Retard de traitement du dossier comptable",
      7: "Compte 44551 : mouvement non lettré de plus de 30 jours",
      19: "Compte 431 : mouvement non lettré de plus de 90 jours",
      20: "Compte 437 : mouvement non lettré de plus de 90 jours",
      8: "Capitaux propre inférieurs à la moitié du capital social (n-1)",
      9: "Risque de solvabilité",
      10: "Risque de cessation de paiements",
      12: "Compte de virement interne non soldé",
      13: "Apport d'espèces supérieur à 3000€",
      14: "Compte 421 débiteur",
      1402: "Mouvements non soldés au sein du compte 421",
      15: "Présence de comptes soldés mais non lettrés au sein des comptes 401 et 411",
      16: "1er client",
      17: "1er salarié",
      18: "Stop de la masse salariale",
      2101: "Evolution des cotisations TNS",
      2102: "Evolution des cotisations TNS - Mouvement créditeur",
    },
    family: "Indicateur Sesha",
  },
  {
    label: "Points d'attention (nb)",
    value: "keyPointsNumber",
    type: "number",
    filter: "Nombre de points d'attention",
    family: "Indicateur Sesha",
    placeholder: "Saisir un nombre",
  },
  {
    label: "Modèles Analyse",
    value: "analysisTemplate",
    type: "list",
    filter: "Modèles Analyse",
    family: "Indicateur Sesha",
  },
  {
    label: "Modèles Pièces Manquantes",
    value: "missingDocTemplate",
    type: "list",
    filter: "Modèles Pièces Manquantes",
    family: "Indicateur Sesha",
  },
  {
    label: "Modèles Encours Clients",
    value: "outstandingsClientsTemplate",
    type: "list",
    filter: "Modèles Encours Clients",
    family: "Indicateur Sesha",
  },
  {
    label: "Modèles Encours Fournisseurs",
    value: "outstandingsProvidersTemplate",
    type: "list",
    filter: "Modèles Encours Fournisseurs",
    family: "Indicateur Sesha",
  },
  {
    label: "Collaborateurs (et)",
    value: "collaboratorsInclusive",
    type: "list",
    filter: "Liste des collaborateurs",
    family: "Indicateur Sesha",
  },
  {
    label: "Collaborateurs (ou)",
    value: "collaboratorsExclusive",
    type: "list",
    filter: "Liste des collaborateurs",
    family: "Indicateur Sesha",
  },
  {
    label: "Mois de clôture",
    value: "fiscalEndMonthExclusive",
    type: "list",
    filter: "Mois de clôture",
    list: {
      1: "Janvier",
      2: "Février",
      3: "Mars",
      4: "Avril",
      5: "Mai",
      6: "Juin",
      7: "Juillet",
      8: "Août",
      9: "Septembre",
      10: "Octobre",
      11: "Novembre",
      12: "Décembre",
    },
    family: "Indicateur Sesha",
  },
  {
    label: "Dernière mise à jour",
    value: "updateDate",
    type: "number",
    filter: "Nombre en jours",
    family: "Indicateur Sesha",
    placeholder: "Saisir un nombre de jours",
  },
  {
    label: "Chiffre d'affaires mensuel",
    value: "monthlyCA",
    type: "number",
    filter: "Montant du chiffre d'affaires mensuel (€)",
    family: "Indicateur chiffre d'affaires",
    placeholder: "Saisir un montant",
  },
  {
    label: "Chiffre d'affaires mensuel N-1",
    value: "monthlyCAPastYear",
    type: "number",
    filter: "Montant du chiffre d'affaires mensuel N-1 (€)",
    family: "Indicateur chiffre d'affaires",
    placeholder: "Saisir un montant",
  },
  {
    label: "Chiffre d'affaires cumulé",
    value: "cumulCA",
    type: "number",
    filter: "Montant du chiffre d'affaires cumulé (€)",
    family: "Indicateur chiffre d'affaires",
    placeholder: "Saisir un montant",
  },
  {
    label: "Chiffre d'affaires cumulé n-1",
    value: "cumulCAPastYear",
    type: "number",
    filter: "Montant du chiffre d'affaires cumulé n-1 (€)",
    family: "Indicateur chiffre d'affaires",
    placeholder: "Saisir un montant",
  },
  {
    label: "Chiffre d'affaires total n-1",
    value: "totalCAPastYear",
    type: "number",
    filter: "Montant du chiffre d'affaires total n-1 (€)",
    family: "Indicateur chiffre d'affaires",
    placeholder: "Saisir un montant",
  },
  {
    label: "Chiffre d'affaire mensuel (var. en %)",
    value: "varCAPercent",
    type: "number",
    filter: "Pourcentage de la variation",
    family: "Indicateur chiffre d'affaires",
    placeholder: "Saisir un pourcentage",
  },
  {
    label: "Chiffre d'affaire mensuel (var. vs m-1 en %)",
    value: "varCAPercentPastMonth",
    type: "number",
    filter: "Pourcentage de la variation",
    family: "Indicateur chiffre d'affaires",
    placeholder: "Saisir un pourcentage",
  },
  {
    label: "Chiffre d'affaire cumulé (var. en %)",
    value: "varCAPercentCumul",
    type: "number",
    filter: "Pourcentage de la variation",
    family: "Indicateur chiffre d'affaires",
    placeholder: "Saisir un pourcentage",
  },
  {
    label: "Masse salariale mensuelle",
    value: "monthlyMasseSal",
    type: "number",
    filter: "Montant de la masse salariale mensuelle (€)",
    family: "Indicateur masse salariale",
    placeholder: "Saisir un montant",
  },
  {
    label: "Masse salariale mensuelle N-1",
    value: "monthlyMasseSalPastYear",
    type: "number",
    filter: "Montant de la masse salariale mensuelle N-1 (€)",
    family: "Indicateur masse salariale",
    placeholder: "Saisir un montant",
  },
  {
    label: "Masse salariale cumulée",
    value: "cumulMasseSal",
    type: "number",
    filter: "Montant de la masse salariale cumulée (€)",
    family: "Indicateur masse salariale",
    placeholder: "Saisir un montant",
  },
  {
    label: "Masse salariale cumulée N-1",
    value: "cumulMasseSalPastYear",
    type: "number",
    filter: "Montant de la masse salariale cumulée N-1 (€)",
    family: "Indicateur masse salariale",
    placeholder: "Saisir un montant",
  },
  {
    label: "Masse salariale en % du CA mensuel (var. en %)",
    value: "varMasseSalCAPercent",
    type: "number",
    filter: "Pourcentage de la variation",
    family: "Indicateur masse salariale",
    placeholder: "Saisir un pourcentage",
  },
  {
    label: "Masse salariale en % du CA mensuel (var. vs m-1 en %)",
    value: "varMasseSalCAPercentPastMonth",
    type: "number",
    filter: "Pourcentage de la variation",
    family: "Indicateur masse salariale",
    placeholder: "Saisir un pourcentage",
  },
  {
    label: "Masse salariale en % du CA cumulé (var.)",
    value: "varMasseSalCAPercentCumul",
    type: "number",
    filter: "Pourcentage de la variation",
    family: "Indicateur masse salariale",
    placeholder: "Saisir un pourcentage",
  },
  {
    label: "Trésorerie cumulée",
    value: "cumulTreso",
    type: "number",
    filter: "Montant de la trésorerie cumulée (€)",
    family: "Indicateur trésorerie",
    placeholder: "Saisir un montant",
  },
  {
    label: "Trésorerie cumulée n-1",
    value: "cumulTresoPastYear",
    type: "number",
    filter: "Montant de la trésorerie cumulée n-1 (€)",
    family: "Indicateur trésorerie",
    placeholder: "Saisir un montant",
  },
  {
    label: "Trésorerie cumulée (var. vs m-1) (€)",
    value: "varTresoAmountPastMonth",
    type: "number",
    filter: "Variation en €",
    family: "Indicateur trésorerie",
    placeholder: "Saisir un montant",
  },
  {
    label: "Trésorerie cumulée (var. vs m-1 en %)",
    value: "varTresoPercentPastMonth",
    type: "number",
    filter: "Variation en %",
    family: "Indicateur trésorerie",
    placeholder: "Saisir un pourcentage",
  },
  {
    label: "Nombre d'opérations non-lettrées en compte d'attente (47)",
    value: "nbAwaitingAccount",
    type: "number",
    filter: "Nombre d'opérations",
    family: "Surveillance de production",
    placeholder: "Saisir un nombre",
  },
  {
    label: "Montant total des opérations non-lettrées en compte d'attente (47)",
    value: "amountAwaitingAccount",
    type: "number",
    filter: "Montant total",
    family: "Surveillance de production",
    placeholder: "Saisir un nombre",
  },
  {
    label: "Marge mensuelle",
    value: "monthlyMarge",
    type: "number",
    filter: "Montant de la marge mensuelle (€)",
    family: "Indicateur marge",
    placeholder: "Saisir un montant",
  },
  {
    label: "Marge mensuelle N-1",
    value: "monthlyMargePastYear",
    type: "number",
    filter: "Montant de la marge mensuelle N-1 (€)",
    family: "Indicateur marge",
    placeholder: "Saisir un montant",
  },
  {
    label: "Marge cumulée",
    value: "cumulMarge",
    type: "number",
    filter: "Montant de la marge cumulée (€)",
    family: "Indicateur marge",
    placeholder: "Saisir un montant",
  },
  {
    label: "Marge cumulée N-1",
    value: "varMargePercentCumul",
    type: "number",
    filter: "Montant de la marge cumulée N-1 (€)",
    family: "Indicateur marge",
    placeholder: "Saisir un montant",
  },
  {
    label: "Taux de marge cumulée N",
    value: "cumulMargePastYear",
    type: "number",
    filter: "Taux de marge cumulée N (%)",
    family: "Indicateur marge",
  },
  {
    label: "Taux de marge cumulée N-1",
    value: "varMargePercentCumulPastYear",
    type: "number",
    filter: "Taux de marge cumulée N-1 (%)",
    family: "Indicateur marge",
  },
  {
    label: "Variation du taux de marge",
    value: "varTauxMarge",
    type: "number",
    filter: "Variation du taux de marge (%)",
    family: "Indicateur marge",
  },
  {
    label: "Charges externes mensuelles",
    value: "monthlyChargesExt",
    type: "number",
    filter: "Montant des charges externes mensuelles (€)",
    family: "Indicateur charges externes",
    placeholder: "Saisir un montant",
  },
  {
    label: "Charges externes mensuelles N-1",
    value: "monthlyChargesExtPastYear",
    type: "number",
    filter: "Montant des charges externes mensuelles N-1 (€)",
    family: "Indicateur charges externes",
    placeholder: "Saisir un montant",
  },
  {
    label: "Charges externes cumulées",
    value: "cumulChargesExt",
    type: "number",
    filter: "Montant des charges externes cumulées (€)",
    family: "Indicateur charges externes",
    placeholder: "Saisir un montant",
  },
  {
    label: "Charges externes cumulées N-1",
    value: "cumulChargesExtPastYear",
    type: "number",
    filter: "Montant des charges externes cumulées N-1 (€)",
    family: "Indicateur charges externes",
    placeholder: "Saisir un montant",
  },
  {
    label: "Charges externes cumulées (var. en %)",
    value: "varChargesExtPercentCumul",
    type: "number",
    filter: "Pourcentage de la variation",
    family: "Indicateur charges externes",
    placeholder: "Saisir un pourcentage",
  },
  {
    label: "Charges externes cumulées en % du CA cumulé",
    value: "varChargesExtCAPercentCumul",
    type: "number",
    filter: "Pourcentage de la variation",
    family: "Indicateur charges externes",
    placeholder: "Saisir un pourcentage",
  },
  {
    label: "Charges externes cumulées en % du CA cumulé (var. en % vs n-1)",
    value: "varChargesExtCAPercentCumulPastYear",
    type: "number",
    filter: "Pourcentage de la variation",
    family: "Indicateur charges externes",
    placeholder: "Saisir un pourcentage",
  },
  {
    label: "Résultat mensuel",
    value: "monthlyResult",
    type: "number",
    filter: "Montant du résultat mensuel (€)",
    family: "Indicateur résultat",
    placeholder: "Saisir un montant",
  },
  {
    label: "Résultat mensuel N-1",
    value: "monthlyResultPastYear",
    type: "number",
    filter: "Montant du résultat mensuel N-1 (€)",
    family: "Indicateur résultat",
    placeholder: "Saisir un montant",
  },
  {
    label: "Résultat cumulé",
    value: "cumulResult",
    type: "number",
    filter: "Montant du résultat cumulé (€)",
    family: "Indicateur résultat",
    placeholder: "Saisir un montant",
  },
  {
    label: "Résultat cumulé N-1",
    value: "cumulResultPastYear",
    type: "number",
    filter: "Montant du résultat cumulé N-1 (€)",
    family: "Indicateur résultat",
    placeholder: "Saisir un montant",
  },
  {
    label: "Résultat cumulé (var. en %)",
    value: "varResultPercentCumul",
    type: "number",
    filter: "Pourcentage de la variation",
    family: "Indicateur résultat",
    placeholder: "Saisir un pourcentage",
  },
  {
    label: "Résultat total n-1",
    value: "totalResultPastYear",
    type: "number",
    filter: "Montant du résultat total n-1 (€)",
    family: "Indicateur résultat",
    placeholder: "Saisir un montant",
  },
];

export default criteriaList;

import React from "react";

function Version() {
  return (
    <div>
      Build: <b>{process.env.REACT_APP_TRAVIS_BUILD_TIMESTAMP}</b>
      <br />
      Commit hash: <b>{process.env.REACT_APP_TRAVIS_COMMIT_HASH}</b>
      <br />
      Commit message: <b>{process.env.REACT_APP_TRAVIS_COMMIT_MESSAGE}</b>
    </div>
  );
}

export default Version;

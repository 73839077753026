import { CopyOutlined, InfoCircleOutlined, LinkOutlined } from "@ant-design/icons";
import { Button, notification, Tag, Tooltip } from "antd";

import dayjs from "dayjs";

import ColoredCapsule from "utils/ColoredCapsule";
import DownloadButtons from "./ClientTableDownloadButtons";
import IDepot from "./IDepot";
import Mcf from "./Mcf";

function idLabel(name) {
  const openNotification = (id) => {
    notification.info({
      message: `${name} au clipboard`,
      description: `{${name}: "${id}"}`,
    });
  };

  return (id) => (
    <Button
      type="text"
      onClick={() => {
        openNotification(id);
        navigator.clipboard.writeText(`{${name}: "${id}"}`);
      }}
    >
      <CopyOutlined style={{ fontSize: "20px", color: "#4569F8" }} />
    </Button>
  );
}

function formatDateAndHour(date) {
  let [dateStr, hourStr] = dayjs(date).format("DD/MM/YYYY HH:mm:ss").split(" ");
  return (
    <>
      {dateStr}
      <br />
      {hourStr}
    </>
  );
}

function typeAnalysisLabel(client) {
  let startDate = "...";
  let endDate = undefined;

  // compute month / year displayed taking into account invalid dates
  if (client?.forecast?.settings?.active?.global) {
    // only display month or year if it's available
    if (client?.forecast?.settings?.start_month) {
      startDate = dayjs().set("month", client?.forecast?.settings?.start_month).format("MMM") + " ";
    } else startDate += " ";

    // look for right year depending on end date definition
    if (client?.forecast?.settings?.start_year || client?.forecast?.settings?.fiscal_year) {
      startDate += client?.forecast?.settings?.end_month
        ? client?.forecast?.settings?.start_year
        : client?.forecast?.settings?.fiscal_year;
    }
    // only add the place holder for year if there is a month
    else if (startDate !== "... ") startDate += "...";

    if (client?.forecast?.settings?.end_month) {
      endDate = dayjs().set("month", client?.forecast?.settings?.end_month).format("MMM") + " ";

      endDate += client?.forecast?.settings?.fiscal_year
        ? client?.forecast?.settings?.fiscal_year
        : "...";
    }
  }

  return (
    <>
      {client?.forecast?.settings?.active?.global
        ? "Prévisionnel"
        : client?.pastYearComp
        ? "Sans n-1"
        : "Classique"}
      {client?.forecast?.settings?.active?.global && (
        <>
          <br />
          <span
            style={{
              textTransform: "capitalize",
            }}
          >
            {startDate}
            {endDate && (
              <>
                <br />
                {endDate}
              </>
            )}
          </span>
        </>
      )}
    </>
  );
}

function lastAnalysisLabel(client) {
  return client.email?.analysis?.lastEmailSent
    ? formatDateAndHour(client.email?.analysis?.lastEmailSent)
    : "Jamais";
}

function fiscalStartMonthLabel(fiscalStartMonth) {
  return (
    <span
      style={{
        textTransform: "capitalize",
      }}
    >
      {fiscalStartMonth
        ? // -2 : 1 to 0 starting month index for dayjs lib, then month before to get closing month
          dayjs()
            .month((fiscalStartMonth - 2) % 12)
            .format("MMMM")
        : "Non renseigné"}
    </span>
  );
}

function stockLabel(stockStatus) {
  return (
    <>
      <ColoredCapsule
        status={stockStatus ? "ok" : "off"}
        content={stockStatus ? "Activé" : "Désactivé"}
      />
    </>
  );
}

function extrapolationLabel(extrapolate) {
  return extrapolate?.length ? extrapolate.join(", ") : "N/A";
}

function analysisTemplateLabel(templateData, info) {
  // if there are an info object to use
  if(info) {
    let tagStyle = {
      minimumWidth: "40px",
      textAlign: "center",
      backgroundColor: "grey",
      color: "white",
    }

    function journalTag(value, key) {
      return <Tag key={key} style={tagStyle}> {value} </Tag>
    }

    let toolTipContent = <div>
      <p>Journaux détectés: {info.detected.map(journalTag)} </p>
      <p>Journaux supprimés: {info.hidden.map(journalTag)} </p>
      <p>Journaux ajoutés: {info.added.map(journalTag)} </p>
      { info.excluded?
          <p>Journaux exclus: {info.excluded?.map(journalTag)} </p> 
        : undefined
      }
      { info.included?
          <p>Journaux inclus: {info.included?.map(journalTag)} </p> 
        : undefined
      }
      { info.dueDays ? <p> Nombre de jours: {info.dueDays} </p> : undefined }
      { <p> Date de début: {info.startDate} </p> }
      { <p> Date de fin: {info.endDate} </p> }
    </div>

    return <div>
      {templateData?.name || "Sesha"}
      {" "}
      <Tooltip placement="top" title={toolTipContent}>
        <InfoCircleOutlined />
      </Tooltip>
  </div>
  }

  return templateData?.name || "Sesha";
}

function lastLabel(date) {
  return date ? formatDateAndHour(date) : "Jamais";
}

function accesClientLabel(clientToken) {
  return clientToken ? (
    <Button type="text" href={window.location.origin + "/client/" + clientToken} target="_blank">
      <LinkOutlined style={{ color: "blue" }} />
    </Button>
  ) : (
    ""
  );
}

function lastUpdate(lastUpdate) {
  return lastUpdate ? dayjs(lastUpdate).format("DD/MM/YYYY HH:mm:ss") : "Jamais";
}

function mailToBoxLabel(mailToBox) {
  return (
    <>
      {mailToBox ? (Array.isArray(mailToBox) ? mailToBox.join(", ") : mailToBox) : "Non renseigné"}
    </>
  );
}

function gedLabel(idepotState) {
  return (mcfState) => (<>
    <Mcf mcfState={mcfState} />
    <IDepot idepotState={idepotState} /> 
    </>
  );
}


function downloadButtons(currentAccFirmId, client) {
  return <DownloadButtons
    currentAccFirmId={currentAccFirmId}
    client={client}
  />;
}

const labels = {
  idLabel,
  analysisTemplateLabel,
  fiscalStartMonthLabel,
  extrapolationLabel,
  lastAnalysisLabel,
  lastLabel,
  stockLabel,
  typeAnalysisLabel,
  accesClientLabel,
  lastUpdate,
  mailToBoxLabel,
  gedLabel,
  downloadButtons,
};

export default labels;

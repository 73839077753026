import { FormOutlined, MailOutlined, PhoneOutlined } from "@ant-design/icons";
import { Button, Form, Input, Popover } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import logAction from "utils/logActions";
import * as API from "../../api/API";
import { ReactComponent as ImgSubmitEntry } from "../../assets/images/SubmitEntry.svg";
import { selectUser } from "../../slices/userSlice";

function Tools(props) {
  const user = useSelector(selectUser);
  const [newEntry, setNewEntry] = useState("");
  const [visibility, setVisibility] = useState(false);

  function handleChange(value, type) {
    (async () => {
      if (type === "email") logAction(247, 1, props.clientId);
      else if (type === "phone") logAction(248, 1, props.clientId);
      else if (type === "note") logAction(249, 1, props.clientId);

      const res = await API.postCollabTracker(props.clientId, {
        author: user.firstName + " " + user.lastName,
        action: Object.keys(value)[0].toUpperCase(),
        comment: value[Object.keys(value)[0]],
      });
      if (res.status === 201) {
        props.update(true);
        setVisibility(false);
        setNewEntry("");
      }
    })();
  }

  const newEntryForm = (type) => {
    return (
      <Form
        name="basic"
        layout="inline"
        onFinish={(e) => handleChange(e, type)}
        initialValues={{
          email: "Mail envoyé au client",
          phone: "Echange téléphonique avec le client",
        }}
      >
        <Form.Item
          name={type}
          label=""
          className="entry-input"
          rules={[{ required: true, message: "Veuillez renseigner un commentaire." }]}
          requiredMark={false}
        >
          <Input
            placeholder="Ajouter un élément (appuyez sur « entrée » pour valider)"
            maxLength={400}
          />
        </Form.Item>{" "}
        <Form.Item className="submit-entry-btn-item">
          <Button
            className="submit-entry-btn"
            shape="circle"
            htmlType="submit"
            style={{
              color: "white",
              background: "#81B65D",
            }}
            icon={<ImgSubmitEntry />}
          />{" "}
        </Form.Item>{" "}
      </Form>
    );
  };

  const newEntryFormType = (type) => {
    switch (type) {
      case "email":
        return newEntryForm("email");
      case "phone":
        return newEntryForm("phone");
      case "note":
        return newEntryForm("note");
      default:
        return null;
    }
  };

  return (
    <>
      <div className="tracker-modal-tools">
        <div className="entry-btns">
          <Popover
            placement="top"
            content="Consigner un email"
            className={
              newEntry !== "" && newEntry !== "email" ? "entry-form-hidden" : "email-entry-popover"
            }
            overlayClassName="navbar-popover-overlay"
          >
            <Button
              className="email-entry-btn"
              shape="circle"
              style={{
                color: "white",
                background: "#4368FA",
              }}
              onClick={() => {
                if (newEntry === "email") {
                  setNewEntry("");
                } else {
                  setNewEntry("email");
                }
                setVisibility(!visibility);
              }}
              icon={<MailOutlined />}
            />{" "}
          </Popover>{" "}
          <Popover
            placement="top"
            content="Consigner un échange téléphonique"
            className={
              newEntry !== "" && newEntry !== "phone" ? "entry-form-hidden" : "phone-entry-popover"
            }
            overlayClassName="navbar-popover-overlay"
          >
            <Button
              className="phone-entry-btn"
              shape="circle"
              style={{
                color: "white",
                background: "#4368FA",
              }}
              onClick={() => {
                if (newEntry === "phone") {
                  setNewEntry("");
                } else {
                  setNewEntry("phone");
                }
                setVisibility(!visibility);
              }}
              icon={<PhoneOutlined />}
            />{" "}
          </Popover>{" "}
          <Popover
            placement="top"
            content="Consigner une note"
            className={
              newEntry !== "" && newEntry !== "note" ? "entry-form-hidden" : "note-entry-popover"
            }
            overlayClassName="navbar-popover-overlay"
          >
            <Button
              className="note-entry-btn"
              shape="circle"
              style={{
                color: "white",
                background: "#4368FA",
              }}
              onClick={() => {
                if (newEntry === "note") {
                  setNewEntry("");
                } else {
                  setNewEntry("note");
                }
                setVisibility(!visibility);
              }}
              icon={<FormOutlined />}
            />{" "}
          </Popover>{" "}
        </div>{" "}
        <div className={visibility ? "entry-form" : "entry-form-hidden"}>
          {" "}
          {newEntryFormType(newEntry)}{" "}
        </div>{" "}
      </div>{" "}
    </>
  );
}

export default Tools;

import { Buffer } from "buffer";
import "dayjs/locale/fr";
import * as API from "../../api/API";

function _getDownloadDate() {
  const d = new Date();
  const month = d.getMonth() + 1;
  const day = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
  const year = d.getFullYear();
  return day + "" + month + "" + year;
}

const _createAndDownloadAllBlobFiles = async (client, res) => {
  res = await res.blob();
  const link = document.createElement("a");
  const zipName = client.client?.name || "client";
  const dateDownload = _getDownloadDate();
  if (link.download !== undefined) {
    const url = URL.createObjectURL(res);
    link.setAttribute("href", url);
    link.setAttribute("download", zipName.replace(/\s/g, "") + "_" + dateDownload + ".zip");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

const _createAndDownloadBlobFile = (body, filename) => {
  const blob = new Blob([body]);
  const fileName = `${filename}`;
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, fileName);
  } else {
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

export class AttachmentDocHelper {

  static downloadFiles = async (files) => {
    for (let file of files) {
      const res = await API.getFileFromOtherById(file.fileId);

      if (res.status === 200) {
        const data = await res.json();
        _createAndDownloadBlobFile(new Blob([Buffer.from(data.file.buffer.data)]), file.name ?? file.fileName);
      } 
      else console.log("error");
    }
  };
}

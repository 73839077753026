import { Form, Select } from "antd";
import { useState } from "react";

function CustomSelect(props) {
  const [onFocus, setOnFocus] = useState(false);

  return (
    <>
      <Form.Item name={props.name} noStyle>
        <Select
          mode="tags"
          className={props.cssClassName}
          popupClassName={"config-select-dropdown"}
          disabled={props.template?.sesha}
          onFocus={() => setOnFocus(true)}
          onBlur={() => setOnFocus(false)}
          notFoundContent={null}
          open={false}
        />
      </Form.Item>
      {onFocus ? (
        <span className="out-placeholder">Appuyez sur « Entrée » pour valider</span>
      ) : null}
    </>
  );
}

export default CustomSelect;

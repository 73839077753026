import footerImage from "../../../assets/images/black-cloud.png";

const ClientFooter = () => {
  return (
    <footer>
      <img src={footerImage} alt="footer" />
    </footer>
  );
};

export default ClientFooter;

import { Progress } from "antd";
import * as number from "../../../../../utils/number";

const OutstandingsStatisticsResponsive = (props) => {
  return (
    <div className="client-outstandings-statistics">
      <div>
        <Progress
          type="circle"
          percent={100}
          size={50}
          strokeColor="#4569f8"
          format={() => <p>{props.totalNumberFiltered}</p>}
        />
        <p>
          <b>Factures non payées </b>
          <br />
          Au {new Date(props.endDate).toLocaleDateString()}
        </p>
      </div>
      <div>
        <p>
          <b>{number.parseToAmount(Math.abs(props.totalAmountFiltered))}</b>
          <br />
          <b>Non payés</b>
        </p>
      </div>
    </div>
  );
};

export default OutstandingsStatisticsResponsive;

import { Button, Col } from "antd";
import { useSelector } from "react-redux";
import * as API from "../../../api/API";
import { selectAccountingFirm } from "../../../slices/accountingFirmSlice";
import openNotification from "../../../utils/notification";

function AdminInqom() {
  const accountingFirm = useSelector(selectAccountingFirm);

  const handleInqomUpdate = async () => {
    try {
      const res = await API.synchroClients(accountingFirm._id);
      const resJson = await res.json();

      // for tab opening instead of redirection, opener is set to null to avoid security risks
      const newWindow = window.open(resJson.url, '_blank', 'noopener,noreferrer')
      if (newWindow) newWindow.opener = null

    } catch (error) {
      console.log(error);
      openNotification("error", "Erreur lors de l'accès à Chift");
    }
  };
  

  return (
    <>
      <Col>
        <div>
          <p>
            Pour gérer les dossiers accessibles dans Sesha, cliquez sur le bouton ci-dessous : <br/>
            <span className="dataprovider-connexion-sub-txt">
              Vous serez redirigé vers Chift, où vous pourrez ajouter/supprimer les dossiers souhaités.
            </span>
          </p>
          <Button
            className="call-action-btn"
            type="primary"
            style={{ width: "20%" }}
            onClick={handleInqomUpdate}
          >
            Gérer les dossier accessibles
          </Button>
        </div>
      </Col>
    </>
  );
}
export default AdminInqom;

/* eslint-disable jsx-a11y/anchor-is-valid */
import { DatePicker } from "antd";
import locale from "antd/es/date-picker/locale/fr_FR";
import dayjs from "dayjs";
import { useState } from "react";
import "../manager.css";

const { RangePicker } = DatePicker;

function DatePickerManager(props) {
  const [selectedDate, setSelectedDate] = useState(4);
  const [dateRange, setDateRange] = useState();

  const handleDateChange = (months, selected) => {
    props.setStartDate(months === 0 ? null : dayjs().subtract(months, "months"));
    props.setEndDate(null);
    setSelectedDate(selected);
    props.selectedDateType(selected);
    setDateRange([]);
  };

  const handleRangePickerChange = (date, dateString) => {
    props.setStartDate(dateString[0]);
    props.setEndDate(dateString[1]);
    setSelectedDate(null);
    props.selectedDateType(0);
    setDateRange(date);
  };

  return (
    <>
      <span className="date">
        <RangePicker
          onChange={handleRangePickerChange}
          locale={locale}
          size="small"
          value={dateRange}
          className="range-picker"
        />
        <a onClick={() => handleDateChange(1, 1)} className={selectedDate === 1 ? "selected" : ""}>
          1 mois
        </a>{" "}
        -{" "}
        <a onClick={() => handleDateChange(3, 2)} className={selectedDate === 2 ? "selected" : ""}>
          3 mois
        </a>{" "}
        -{" "}
        <a onClick={() => handleDateChange(12, 3)} className={selectedDate === 3 ? "selected" : ""}>
          1 an
        </a>{" "}
        -{" "}
        <a onClick={() => handleDateChange(0, 4)} className={selectedDate === 4 ? "selected" : ""}>
          Depuis le début
        </a>
      </span>
    </>
  );
}

export default DatePickerManager;

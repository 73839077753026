import { useState } from "react";
import { Document, Page } from "react-pdf";

function PdfViewer(props) {
  const [numPages, setNumPages] = useState();

  return (
    <>
      <Document file={props.file} onLoadSuccess={({ numPages }) => setNumPages(numPages)}>
        <span style={{ alignItems: "center", justifyItems: "center", display: "inline-block" }}>
          {Array.apply(null, Array(numPages))
            .map((x, i) => i + 1)
            .map((page) => (
              <div style={{ marginBottom: "30px", overflow: "hidden", borderRadius: "20px" }}>
                <Page pageNumber={page} renderAnnotationLayer={false} renderTextLayer={false} />
              </div>
            ))}
        </span>
      </Document>
    </>
  );
}
export default PdfViewer;

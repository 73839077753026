import { CloseOutlined } from "@ant-design/icons";
import { Button, Form, InputNumber, Select, Tag } from "antd";
import { useDispatch, useSelector } from "react-redux";
import * as API from "../../api/API";
import openNotification from "../../utils/notification";

import logAction from "utils/logActions";
import { selectAccountingFirm, updateAccountingFirm } from "../../slices/accountingFirmSlice";
const { Option } = Select;

const option = [
  { label: ".png", value: "image/png" },
  { label: ".jpeg", value: "image/jpeg" },
  { label: ".jpg", value: "image/jpg" },
  { label: ".tiff", value: "image/tiff" },
  { label: ".pdf", value: "application/pdf" },
  { label: ".txt", value: "text/plain" },
  { label: ".csv", value: "text/csv" },
  {
    label: ".docx",
    value: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  },
  { label: ".doc", value: "application/msword" },
  { label: ".xls", value: "application/vnd.ms-excel" },
  { label: ".xlsx", value: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" },
  { label: ".ofx", value: "application/x-ofx" },
  { label: ".json", value: "application/json" },
];

function FileSettings() {
  const dispatch = useDispatch();
  const accountingFirm = useSelector(selectAccountingFirm);

  const onFinish = (values) => {
    (async () => {
      if (values.fileTypes.length === 0) values.fileTypes = option.map(({ value }) => value);
      if (accountingFirm.fileSize !== values.fileSize) logAction(203, 1);
      if (accountingFirm.fileTypes.toString() !== values.fileTypes.toString()) logAction(204, 1);
      let res = await API.putAccountingFirm(accountingFirm._id, values);
      dispatch(
        updateAccountingFirm({
          ...accountingFirm,
          fileSize: values.fileSize,
          fileTypes: values.fileTypes,
        })
      );

      if (res.status === 201) openNotification("success", "Modification effectuée");
      else openNotification("error", "Erreur lors de la modification des paramètres");
    })();
  };

  const tagRender = (props) => {
    const { label, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={"#DCFFEA"}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        closeIcon={<CloseOutlined style={{ color: "#96E8B6" }} />}
        style={{
          border: "none",
          color: "black",
          fontWeight: "lighter",
          borderRadius: "9px",
        }}
      >
        {label}
      </Tag>
    );
  };

  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      initialValues={{
        fileTypes:
          accountingFirm.fileTypes?.length > 0
            ? accountingFirm.fileTypes
            : option.map(({ value }) => value),
        fileSize: accountingFirm.fileSize > 0 ? accountingFirm.fileSize : null,
      }}
    >
      <Form.Item label="Taille maximale des fichiers" style={{ fontWeight: "bold" }}>
        <Form.Item name="fileSize" style={{ fontWeight: "bold", display: "inline-block" }}>
          <InputNumber
            min={1}
            controls={false}
            style={{
              marginTop: "8px",
              borderRadius: "76px",
              width: "75px",
            }}
          />
        </Form.Item>
        <span
          style={{
            marginLeft: "10px",
            marginTop: "13px",
            display: "inline-block",
            fontWeight: "lighter",
          }}
        >
          Mo
        </span>
      </Form.Item>
      <Form.Item
        name="fileTypes"
        label="Formats acceptés"
        style={{ fontWeight: "bold", marginTop: "-10px" }}
      >
        <Select
          mode="multiple"
          allowClear
          options={option}
          tagRender={tagRender}
          style={{
            width: "550px",
            marginTop: "8px",
          }}
          placement="bottomLeft"
          dropdownAlign={{ overflow: { adjustY: false } }}
        >
          {option?.map((elt, i) => (
            <Option key={i} value={elt.value}>
              {elt}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit" className="call-action-btn">
          Valider
        </Button>
      </Form.Item>
    </Form>
  );
}

export default FileSettings;

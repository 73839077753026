import { UpCircleOutlined } from "@ant-design/icons";
import { Checkbox, Collapse, Table } from "antd";
import { useEffect, useState } from "react";
import * as number from "../../../../utils/number";
import Category from "./Category";

function TableJournals(props) {
  const [data, setData] = useState([]);
  const [activeOperations, setActiveOperations] = useState([]);
  const [styleColumns, setStyleColumns] = useState([]);

  useEffect(() => {
    var columns = props.accountColumns;
    columns.forEach((col, i) => {
      switch (i) {
        case 0:
          col.width = "70px";
          break;
        case 1:
          col.width = "250px";
          break;
        case 2:
          col.width = "230px";
          break;
        case 3:
          col.width = "190px";
          break;
        case 4:
          col.width = "190px";
          break;
        /* case 5:
          col.width = "190px";
          break; */
        default:
          break;
      }
    });
    setStyleColumns(columns);
  }, []);

  useEffect(() => {
    if (props.mode !== 3 || !data) return;
    props.setExpandedRows(
      props.allExpanded ? data.map(({ data }) => data.map(({ key }) => key)).flat() : []
    );
  }, [props.allExpanded]);

  useEffect(() => {
    if (props.categories.length === 0) return;

    const operationsByJournal = {};

    const getType = (account) => {
      if (account.accountNumber === "40") return "achats";
      else if (account.accountNumber === "41") {
        return "ventes";
      } else {
        return "aux";
      }
    };

    props.categories.forEach((account) => {
      account.operations.forEach((operation) => {
        const type = getType(account);

        if (!operationsByJournal[operation.journal]) {
          operationsByJournal[operation.journal] = { ventes: {}, achats: {}, aux: {} };
        }

        if (!operationsByJournal[operation.journal][type][account._id]) {
          operationsByJournal[operation.journal][type][account._id] = {
            title: account.title,
            accountNumber: account.accountNumber,
            accountNumberAux: account.accountNumberAux,
            operations: [],
          };
        }

        operationsByJournal[operation.journal][type][account._id].operations.push(operation);
      });
    });

    if (!operationsByJournal[props.id]?.ventes || !operationsByJournal[props.id]?.achats) return;

    setData(
      [
        {
          id: "ach",
          label: "Achats",
          key: "achats",
          data: Object.keys(operationsByJournal[props.id].achats).map((j) => ({
            _id: j + props.id + "achats",
            key: j + props.id + "achats",
            categoryId: j,
            accountNumber: operationsByJournal[props.id].achats[j].accountNumber,
            accountNumberAux: operationsByJournal[props.id].achats[j].accountNumberAux,
            title: operationsByJournal[props.id].achats[j].title,
            operations: operationsByJournal[props.id].achats[j].operations,
            numberMissingDoc: operationsByJournal[props.id].achats[j].operations.length,
            amount: operationsByJournal[props.id].achats[j].operations.reduce(
              (acc, curr) => acc + Math.abs(curr.amount),
              0
            ),
          })),
        },
        {
          id: "ven",
          label: "Ventes",
          key: "ventes",
          data: Object.keys(operationsByJournal[props.id].ventes).map((j) => ({
            _id: j + props.id + "ventes",
            key: j + props.id + "ventes",
            categoryId: j,
            accountNumber: operationsByJournal[props.id].ventes[j].accountNumber,
            accountNumberAux: operationsByJournal[props.id].ventes[j].accountNumberAux,
            title: operationsByJournal[props.id].ventes[j].title,
            operations: operationsByJournal[props.id].ventes[j].operations,
            numberMissingDoc: operationsByJournal[props.id].ventes[j].operations.length,
            amount: operationsByJournal[props.id].ventes[j].operations.reduce(
              (acc, curr) => acc + Math.abs(curr.amount),
              0
            ),
          })),
        },
        {
          id: "au",
          label: "Compte d’attente et autres comptes",
          key: "aux",
          data: Object.keys(operationsByJournal[props.id].aux).map((j) => ({
            _id: j + props.id + "aux",
            key: j + props.id + "aux",
            categoryId: j,
            accountNumber: operationsByJournal[props.id].aux[j].accountNumber,
            accountNumberAux: operationsByJournal[props.id].aux[j].accountNumberAux,
            title: operationsByJournal[props.id].aux[j].title,
            operations: operationsByJournal[props.id].aux[j].operations,
            numberMissingDoc: operationsByJournal[props.id].aux[j].operations.length,
            amount: operationsByJournal[props.id].aux[j].operations.reduce(
              (acc, curr) => acc + Math.abs(curr.amount),
              0
            ),
          })),
        },
      ]
        .map((el) => ({ ...el, totalAmount: el.data.reduce((acc, curr) => acc + curr.amount, 0) }))
        .filter((el) => el.totalAmount > 0)
    );

    setActiveOperations(
      props.categories
        .map(({ operations }) => operations.filter((op) => op.active !== false))
        .flat(1)
    );
  }, [props.categories, props.id]);

  const uncheckOperations = (categoryId, accountNumber, type, uncheck) => {
    console.debug("uncheckOperations()");

    const shouldUpdateOperation = (op) => {
      if (type === "ac") {
        return accountNumber === "40";
      } else if (type === "vt") {
        return accountNumber === "41";
      }
      return accountNumber !== "40" && accountNumber !== "41";
    };
    props.setAllData([
      ...props.allData.map((el) =>
        el.id !== props.id
          ? el
          : {
              ...el,
              data: props.categories.map((cat) =>
                cat._id !== categoryId
                  ? cat
                  : {
                      ...cat,
                      operations: cat.operations.map((op) =>
                        shouldUpdateOperation(op) ? { ...op, active: !uncheck } : op
                      ),
                    }
              ),
            }
      ),
    ]);
  };

  return (
    <>
      <Collapse
        className="type-journal-collapse"
        defaultActiveKey={["ven", "ach", "au"]}
        items={data
          .filter((element) => element.totalAmount !== 0)
          .map((el) => ({
            key: el.id,
            label: (
              <div className="journal-type-title">
                {el.label} - {number.withThousandSeparator(number.parseNum(el.totalAmount))}
              </div>
            ),
            children: (
              <Table
                className="missing-table"
                showHeader={false}
                dataSource={el.data}
                columns={styleColumns}
                rowSelection={
                  props.editMode && {
                    renderCell: (checked, record) => {
                      const activeOperationsMap = new Map(
                        record.operations.map((op) => [op._id, op])
                      );

                      const totalOperations = record.operations.length;
                      const activeOperationsCount = activeOperations.reduce(
                        (count, op) => (activeOperationsMap.has(op._id) ? count + 1 : count),
                        0
                      );

                      return (
                        <Checkbox
                          indeterminate={
                            activeOperationsCount > 0 && activeOperationsCount < totalOperations
                          }
                          checked={activeOperationsCount === totalOperations}
                          onChange={(e) => {
                            const uncheck = !e.target.checked;
                            const type = record.key.includes("achats")
                              ? "ac"
                              : record.key.includes("ventes")
                              ? "vt"
                              : "au";
                            uncheckOperations(
                              record.categoryId,
                              record.accountNumber,
                              type,
                              uncheck
                            );
                          }}
                        />
                      );
                    },
                    hideSelectAll: true,
                  }
                }
                expandable={{
                  expandedRowKeys: props.expandedRowKeys,
                  expandedRowRender: (record) => (
                    <Category {...props} category={record} key={record._id} />
                  ),
                  expandRowByClick: true,
                  onExpandedRowsChange: (rows) => {
                    props.setExpandedRows(rows);
                  },
                  expandIcon: ({ expanded, onExpand, record }) => (
                    <UpCircleOutlined
                      onClick={(e) => onExpand(record, e)}
                      className={expanded ? "expand-icon-down" : "expand-icon-up"}
                    />
                  ),
                }}
              />
            ),
          }))}
        expandIconPosition="end"
        bordered={false}
        expandIcon={({ isActive }) => (
          <>
            <UpCircleOutlined
              style={{ fontSize: "20px" }}
              className={isActive ? "expand-icon-down" : "expand-icon-up"}
            />{" "}
          </>
        )}
      />
    </>
  );
}

export default TableJournals;

import { Button, Col, Form, Input, Modal, Row } from "antd";
import FormItem from "antd/lib/form/FormItem";
import passwordValidator from "password-validator";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import hasFeature from "utils/dataProviderFeatures";
import * as API from "../../api/API";
import { selectAccountingFirm } from "../../slices/accountingFirmSlice";
import { selectUser, updateUser } from "../../slices/userSlice";
import LoaderRaw from "../../utils/LoaderRaw";
import openNotification from "../../utils/notification";

var schema = new passwordValidator();
schema
  .is()
  .min(8)
  .is()
  .max(100)
  .has()
  .uppercase()
  .has()
  .lowercase()
  .has()
  .digits(1)
  .has()
  .not()
  .spaces()
  .is()
  .not()
  .oneOf(["Passw0rd", "Password123"]);

function UserProfile(props) {
  const dispatch = useDispatch();

  const accountingFirm = useSelector(selectAccountingFirm);
  const user = useSelector(selectUser);

  const [changedDPCredentials] = useState(false);
  const [, setValidDPCredentials] = useState();
  const [changePwdModal, setChangePwdModal] = useState(false);
  const [busy, setBusy] = useState(true);

  useEffect(() => {
    if (accountingFirm.status === "ready") {
      const testDataProviderConnexion = async () => {
        let res = await API.testDataProviderConnexion();
        if (res.status === 200) {
          setValidDPCredentials(true);
          setBusy(false);
        } else {
          setValidDPCredentials(false);
          setBusy(false);
        }
      };
      testDataProviderConnexion();

      if (hasFeature(accountingFirm.fec, accountingFirm.dataProvider, "USER_CREDENTIALS")) {
        testDataProviderConnexion();
      }
    }
  }, [accountingFirm.dataProvider]);

  const onFinish = (values) => {
    // user busy or in state test ready
    if (changedDPCredentials === true) {
      (async () => {
        let response = await API.testDataProviderConnexion({
          connexion: true,
          dataProviderId: values.dataProviderId,
          dataProviderPassword: values.dataProviderPassword,
        });
        if (response.status === 200) {
          (async () => {
            const res = await API.putDataProviderUser(values);
            if (res.status === 201) {
              openNotification("success", "Modification effectuée");
              props.history.push("/homepage");
            } else if (res.status === 401) {
              openNotification(
                "error",
                `Identifiant ou mot de passe ${accountingFirm.dataProvider} incorrect`
              );
            } else if (res.status === 418) {
              openNotification("error", "Mot de passe Sesha incorrect");
            } else openNotification("error", "Erreur lors de la modification de l'utilisateur");
          })();
        } else {
          openNotification(
            "error",
            `Identifiant ou mot de passe ${accountingFirm.dataProvider} incorrect`
          );
        }
      })();
    } else {
      (async () => {
        const res = await API.putUser(user._id, values);
        if (res.status === 201) {
          dispatch(updateUser(values));
          openNotification("success", "Modification effectuée");
          props.history.push("/homepage");
        } else openNotification("error", "Erreur lors de la modification de l'utilisateur");
      })();
    }
  };

  const onPwdChangeFinish = (values) => {
    const data = {
      currentPwd: values.currentSeshaPassword,
      newPwd: values.newSeshaPassword,
      passwordConf: values.confirmSeshaPassword,
      dataProvider: accountingFirm.dataProvider,
    };
    (async () => {
      let res = await API.changePwd(user._id, data);
      if (res.status === 200) {
        openNotification("success", "Modification effectuée");
        setChangePwdModal(false);
      } else {
        if (res.status === 401) {
          openNotification("error", `Votre mot de passe Sesha n'est pas valide`);
        }
      }
    })();
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      {busy ? (
        <div className="user-profile-loader">
          <LoaderRaw />
        </div>
      ) : (
        <>
          <Form
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
            initialValues={{
              firstName: user.firstName,
              lastName: user.lastName,
              email: user.email,
              dataProviderId: user.dataProviderId,
            }}
            className="user-profile-form"
          >
            <div className="form-bnt-block">
              <Button
                className="other-action-btn cancel-btn"
                onClick={(e) => {
                  e.preventDefault();
                  props.history.push("/homepage");
                }}
              >
                Annuler
              </Button>
              <FormItem className="submit-changes-btn">
                <Button className="call-action-btn modal-submit-btn" htmlType="submit">
                  Sauvegarder
                </Button>
              </FormItem>
            </div>

            <Row justify="center">
              <Col xs={22} sm={22} md={22} lg={10} xl={10}>
                <p className="settings-category-p">Données personnelles : </p>
                <div className="user-basic">
                  <Form.Item
                    name="firstName"
                    label="Prénom"
                    rules={[
                      {
                        pattern: new RegExp(/^[A-Za-z\s'À-ÖØ-öø-ÿ]+$/i),
                        message: "Le prénom n'accepte que des caractères alphabétiques",
                      },
                    ]}
                  >
                    <Input bordered={true} className={"form-input"} />
                  </Form.Item>
                  <Form.Item
                    name="lastName"
                    label="Nom"
                    rules={[
                      {
                        pattern: new RegExp(/^[A-Za-z\s'À-ÖØ-öø-ÿ]+$/i),
                        message: "Le nom n'accepte que des caractères alphabétiques",
                      },
                    ]}
                  >
                    <Input bordered={true} className={"form-input"} />
                  </Form.Item>
                </div>
                <div className="user-other-info">
                  <Form.Item name="email" label="Email">
                    <Input bordered={true} className={"form-input"} />
                  </Form.Item>
                  <Button
                    className="other-action-btn user-inline change-pwd-btn"
                    onClick={(e) => {
                      e.preventDefault();
                      setChangePwdModal(true);
                    }}
                  >
                    Changer le mot de passe
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
          <Modal
            title="Modifier votre mot de passe Sesha"
            className="change-pwd-modal"
            open={changePwdModal}
            onCancel={(e) => {
              e.preventDefault();
              setChangePwdModal(false);
            }}
            footer={null}
          >
            <Form labelAlign="left" onFinish={onPwdChangeFinish} onFinishFailed={onFinishFailed}>
              <Form.Item
                name="currentSeshaPassword"
                label="Mot de passe actuel"
                className="user-inline user-pwd"
                rules={[
                  {
                    required: true,
                    message: "Merci de renseigner un mot de passe",
                  },
                ]}
              >
                <Input.Password bordered={true} className={"form-input"} />
              </Form.Item>
              <Form.Item
                name="newSeshaPassword"
                label="Nouveau mot de passe"
                className="user-inline user-pwd"
                rules={[
                  {
                    required: true,
                    message: "Merci de renseigner un mot de passe",
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (schema.validate(value) === true) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(
                          <>
                            <p>Votre mot de passe doit comporter :</p>
                            <div>
                              <ul>
                                <li>Au moins 8 caractères</li>
                                <li>Au moins 1 chiffre</li>
                                <li>Au moins 1 majuscule et 1 minuscule</li>
                                <li>Sans espace</li>
                              </ul>
                            </div>
                          </>
                        );
                      }
                    },
                  }),
                ]}
              >
                <Input.Password
                  className={"form-input"}
                  onCopy={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                />
              </Form.Item>
              <Form.Item
                name="confirmSeshaPassword"
                label="Confirmer le nouveau mot de passe"
                className="user-inline user-pwd"
                dependencies={["newSeshaPassword"]}
                rules={[
                  {
                    required: true,
                    message: "Merci de confirmer votre nouveau mot de passe",
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("newSeshaPassword") === value) {
                        return Promise.resolve();
                      }

                      return Promise.reject("Il y a une erreur dans un mot de passe");
                    },
                  }),
                ]}
              >
                <Input.Password className={"form-input"} />
              </Form.Item>
              <Form.Item className="change-pwd-actions">
                <Button
                  className="other-action-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    setChangePwdModal(false);
                  }}
                >
                  Annuler
                </Button>
                <Button className="call-action-btn" htmlType="submit">
                  Modifier le mot de passe
                </Button>
              </Form.Item>
            </Form>
          </Modal>
        </>
      )}
    </>
  );
}

export default UserProfile;

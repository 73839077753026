import { Modal, Spin } from "antd";
import { useEffect, useState } from "react";
import * as API from "../../api/API";
import Header from "./Header";
import Messenger from "./Messenger";
import Tools from "./Tools";
import "./Tracker.css";

function Tracking(props) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [dataEntry, setDataEntry] = useState([]);
  const [busy, setBusy] = useState(true);
  const [update, setUpdate] = useState(false);

  useEffect(() => {
    (async () => {
      if (props.clientId) {
        let res = await API.getTrackerMessenger(props.clientId);
        if (res.status === 200) {
          res = await res.json();
          setBusy(false);
          setUpdate(false);
          setDataEntry(res.data);
        } else {
          console.log("error");
          setBusy(false);
          setUpdate(false);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update, props.showModal, props.clientId]);

  useEffect(() => {
    setIsModalVisible(props.showModal);
  }, [props.showModal]);

  const handleCancel = () => {
    setIsModalVisible(false);
    props.showModalTracking(false);
  };

  return (
    <>
      <Modal
        title={<Header {...props} />}
        open={isModalVisible}
        onCancel={handleCancel}
        className="tracker-modal"
        footer={
          props.source === "support" ? null : (
            <Tools {...props} update={(data) => setUpdate(data)} />
          )
        }
      >
        {busy ? (
          <div className="loading-tracker">
            <Spin size="large" />
          </div>
        ) : (
          <Messenger {...props} data={dataEntry} update={(data) => setUpdate(data)} />
        )}
      </Modal>
    </>
  );
}

export default Tracking;

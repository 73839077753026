import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import hasFeature from "utils/dataProviderFeatures";
import * as API from "../../api/API";
import { selectAccountingFirm } from "../../slices/accountingFirmSlice";
import { selectClient } from "../../slices/clientSlice";

import { ArrowRightOutlined, ExclamationCircleOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Card, Form, Typography, Upload } from "antd";
import "dayjs/locale/fr";
import openNotification from "../../utils/notification";
import "./ClientSettings.css";

const { Title } = Typography;

function ClientDataUpload(props) {
  const accountingFirm = useSelector(selectAccountingFirm);
  const client = useSelector(selectClient);

  const [form] = Form.useForm();

  const [clientFiscalStartMonth] = useState("");
  const [, setBusy] = useState(true);
  const [loading, setLoading] = useState(false);
  const [, setDisabled] = useState(false);
  const [, setSecondMailToBox] = useState(true);
  const [, setCollaborators] = useState([]);

  useEffect(() => {
    if (hasFeature(accountingFirm.fec, accountingFirm.dataProvider, "BLOCK_CLIENT_EDIT"))
      setDisabled(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountingFirm.dataProvider]);

  useEffect(() => {
    (async () => {
      let res = await API.getUsersByAccountingFirmId(accountingFirm._id);
      if (res.status === 200) {
        res = await res.json();
        setCollaborators(res);
      }
    })();
  }, [accountingFirm._id]);

  async function postForm(formData) {
    let res;
    res = await API.postUpdateClient(formData);
    setLoading(false);
    if (res.status === 201) {
      res = await res.json();
      window.location.reload();
    } else if (res.status === 409) {
      openNotification(
        "error",
        "Erreur lors de l'enregistrement, un client existe déjà avec ce nom."
      );
    } else {
      openNotification(
        "error",
        "Impossible de générer l'analyse. Êtes-vous sûr que les fichiers fournis sont bien des fichiers FEC ?"
      );
    }
  }

  const onFinish = async (values) => {
    if (values.fec_n === undefined && values.fec_n_1 === undefined) {
      openNotification("error", "Veuillez charger un fichier FEC.");
    } else {
      if (
        values.fec_n === undefined &&
        values.fec_n_1 !== undefined &&
        accountingFirm.dataProvider !== "SAGE"
      ) {
        openNotification("error", "Veuillez charger un fichier FEC pour l'exercice en cours.");
      } else {
        setLoading(true);
        let formData = new FormData();
        formData.set("clientId", props.clientId);
        formData.set("remoteId", client.remoteId);
        formData.set("accountingFirmId", accountingFirm._id);
        if (!accountingFirm.connectedRights) {
          formData.set(
            "collaboratorsId",
            JSON.stringify(values.collaboratorsInCharge) || JSON.stringify([])
          );
        }

        if (values.fec_n) {
          formData.append("file", await reader(values.fec_n[0].originFileObj), "FEC-N");
        }
        if (values.fec_n_1) {
          formData.append("file", await reader(values.fec_n_1[0].originFileObj), "FEC-N-1");
        }
        postForm(formData);
      }
    }
  };

  const reader = (fileObj) =>
    new Promise((resolve) => {
      const fRd = new FileReader();
      fRd.readAsBinaryString(fileObj, "ISO-8859-1");
      fRd.onload = () => {
        resolve(
          new File([fRd.result], "file.txt", {
            type: "text/plain",
            lastModified: new Date(),
          })
        );
      };
    });

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  useEffect(() => {
    setSecondMailToBox(!client.mailToBoxSales && !client.mailToBoxOther);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setBusy(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client._id]);

  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientFiscalStartMonth]);

  return (
    <>
      <Form
        name="clientFecUpload"
        form={form}
        onFinish={(values) => {
          onFinish(values);
        }}
      >
        <div style={{ position: "relative", left: "33px" }}>
          <Button
            type="button"
            className="other-action-btn return-button-fec cancel-btn-fec"
            onClick={() => {
              props.activeKeyImport("1");
              props.importActive(false);
            }}
          >
            Annuler
          </Button>
          <Form.Item>
            <Button
              type="submit"
              htmlType="submit"
              className="call-action-btn link-button-fec validate-btn-fec"
              loading={loading}
            >
              Valider <ArrowRightOutlined />
            </Button>
          </Form.Item>
        </div>
        <div className="params-data-container">
          <Title level={4}>Chargement des fichiers FEC</Title>

          <div className="left-fec">
            {accountingFirm.fec ? (
              <Form.Item
                className="import-input-text"
                label="Charger le FEC de l'exercice en cours"
              >
                <Form.Item
                  name="fec_n"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                  // rules={[
                  //   {
                  //     required: updateMode ? false : true,
                  //     message: "Vous devez ajouter le fichier FEC de l'exercice en cours",
                  //   },
                  // ]}
                >
                  <Upload method="get" className="upload-input" maxCount={1}>
                    <Button
                      htmlType="button"
                      className="upload-fec-btn"
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <UploadOutlined /> Parcourir
                    </Button>
                  </Upload>
                </Form.Item>
              </Form.Item>
            ) : null}

            <Form.Item
              className="import-input-text-fec-n-1"
              label="Charger le FEC de l'exercice précédent"
            >
              <Form.Item
                name="fec_n_1"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                // rules={[
                //   {
                //     required: false,
                //     message: "Vous devez ajouter le fichier FEC de l'exercice précédent",
                //   },
                // ]}
              >
                <Upload method="get" className="upload-input" maxCount={1}>
                  <Button
                    htmlType="button"
                    className="upload-fec-btn"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <UploadOutlined /> Parcourir
                  </Button>
                </Upload>
              </Form.Item>
              <>
                <Card className="fec-warning-card">
                  <ExclamationCircleOutlined style={{ color: "#faad14" }} />
                  <p>
                    La mise à jour des fichiers FEC a pour effet de supprimer les justificatifs déjà
                    transmis par le client.
                  </p>
                </Card>
              </>
            </Form.Item>
          </div>
        </div>
      </Form>
    </>
  );
}

export default ClientDataUpload;

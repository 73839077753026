import { Button, Popover } from "antd";
import { useSelector } from "react-redux";
import { selectAccountingFirm } from "../../../slices/accountingFirmSlice";
import { selectClient } from "../../../slices/clientSlice";
import "../Nav.css";

import LogoIDocusRed from "../../../assets/images/idocusnotvalid.png";
import LogoIDocusOff from "../../../assets/images/idocusoff.png";
import LogoIDocusOn from "../../../assets/images/idocusvalid.png";

function IDocus() {
  const accountingFirm = useSelector(selectAccountingFirm);
  const client = useSelector(selectClient);

  const getPopoverText = (num) => {
    switch (num) {
      case 0:
        return "Dossier connecté à iDocus !";
      case 1:
        return "Dossier non connecté à iDocus. \nVeuillez renseigner un numéro de SIREN";
      case 2:
        return "Dossier non connecté à iDocus. \nLe numéro de SIREN renseigné \nn’a pas permis de trouver le dossier client.";
      case 3:
        return "Dossier non connecté à iDocus. \nErreur dans la saisie du token.";
      // 4 = Token null, cabinet non connecté à MCF volontairement
      default:
        return "Error";
    }
  };

  return (
    <>
      {accountingFirm.iDocus && client.iDocusState !== 4 ? (
        <Popover
          placement="top"
          content={getPopoverText(client.iDocusState)}
          className="info-popover"
          overlayClassName={
            client.iDocusState === 0
              ? "mcf-success-popover mcf-success-popover-subnav"
              : "mcf-fail-popover mcf-fail-popover-subnav"
          }
        >
          <Button
            className={
              client.iDocusState === 0 || client.iDocusState === 1
                ? "client-icon"
                : "client-icon client-icon-mcf-fail"
            }
            shape="circle"
            style={{ color: "black" }}
            icon={
              <img
                src={
                  client.iDocusState === 0
                    ? LogoIDocusOn
                    : client.iDocusState === 2 || client.iDocusState === 3
                    ? LogoIDocusRed
                    : LogoIDocusOff
                }
                alt="Logo iDocus"
                width="24px"
              />
            }
          />
        </Popover>
      ) : null}
    </>
  );
}

export default IDocus;

import React from "react";
import "./Footer.css";
import { Row, Col } from "antd";
import FooterCloud from "../../assets/images/footer-cloud.png";

function Footer(props) {
  return (
    <Row justify="center" className="footer">
      <Col xs={22} sm={22} md={22} lg={22} xl={22}>
        <div className="footer-img">
          <img src={FooterCloud} alt="cloud" />
        </div>
      </Col>
    </Row>
  );
}

export default Footer;

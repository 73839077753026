import { ConfigProvider } from "antd";
import React from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import * as API from "../api/API";
import ModifyPwd from "../components/login/ModifyPwd";
import Logout from "../components/logout/Logout";
import Subscribe from "../components/subscription/Subscribe";
import Unauthorized from "../components/unauthorized/Unauthorized";
import routes from "../routes";
import { firstCo, hasFeatures, isAuthenticated, shouldPay } from "../utils/security";

const useHubspotChat = () => {
  const [hasLoaded, setHasLoaded] = React.useState(false);
  const [conversationToken, setConversationToken] = React.useState();
  const [userEmail, setUserEmail] = React.useState();

  React.useEffect(() => {
    if (!window.location.href.includes("offers-iframe")) {
      let script = document.createElement("script");
      script.type = "text/javascript";
      script.id = "hs-script-loader";
      script.defer = true;
      script.async = true;
      script.src = `//js-eu1.hs-scripts.com/${process.env.REACT_APP_HUBSPOT_ID}.js`;
      document.body.appendChild(script);

      window.hsConversationsSettings = {
        loadImmediately: false,
      };
      window.hsConversationsOnReady = [
        async () => {
          let res = await API.getConversationToken();
          if (res.status === 200) {
            res = await res.json();
            setConversationToken(res.token);
            setUserEmail(res.email);
            setHasLoaded(true);
          }
        },
      ];
    }
  }, []);

  React.useEffect(() => {
    if (hasLoaded) {
      window.hsConversationsSettings = {
        identificationEmail: userEmail || "",
        identificationToken: conversationToken || "",
      };
      window.HubSpotConversations.widget.load();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasLoaded]);
};

const App = () => {
  useHubspotChat();
  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: "Roboto",
        },
      }}
    >
      <Router>
        <Switch>
          {routes.map((route, i) => (
            <RouteWithSubRoutes key={i} {...route} />
          ))}
        </Switch>
      </Router>{" "}
    </ConfigProvider>
  );
};

const RouteWithSubRoutes = (parentProps) => {
  return (
    <Route
      path={parentProps.path}
      render={(props) =>
        hasFeatures(parentProps.features) ? (
          shouldPay(parentProps.path) ? (
            <Subscribe {...props} />
          ) : firstCo() ? (
            <ModifyPwd {...props} />
          ) : (
            <parentProps.component {...props} routes={parentProps.routes} />
          )
        ) : isAuthenticated() ? (
          <Unauthorized />
        ) : (
          <Logout />
        )
      }
    />
  );
};

export default App;

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectAccountingFirm } from "../../../slices/accountingFirmSlice";
import * as API from "../../../api/API";
import { Row, Col, Typography, Statistic } from "antd";
import DatePickerManager from "./DatePickerManager";
import LoaderRaw from "../../../utils/LoaderRaw";
import { InfoCircleOutlined } from "@ant-design/icons";
import ManagerLineGraph from "./ManagerLineGraph";
import Detailed from "./Detailed";

import "../manager.css";

const { Text } = Typography;

function Global(props) {
  const accountingFirm = useSelector(selectAccountingFirm);

  const [busy, setBusy] = useState(true);
  const [globalInfo, setGlobalInfo] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [globalGraphData, setGlobalGraphData] = useState();
  const [selectedDateType, setSelectedDateType] = useState(4);

  useEffect(() => {
    if (props.forceUpdate) {
      Promise.all([
        API.postToRetrieveManagerGlobal({
          startDate: startDate,
          endDate: endDate,
        }),
        API.postToRetrieveManagerGlobalGraphData({
          startDate: startDate,
          endDate: endDate,
          selectedDateType: selectedDateType,
        })
      ]).then( response => {
        Promise.all(response.map( res => res.json())).then( results => {
          const [res, resGraph] = results;
          setGlobalInfo(res);
          setGlobalGraphData(resGraph);
          setBusy(false);
        });
      });
    }
  }, [startDate, endDate, props.forceUpdate]);

  return (
    <>
      <div className="global">
        {busy ? (
          <LoaderRaw />
        ) : (
          <>
            <div className="info-bulle">
              <InfoCircleOutlined
                style={{
                  color: "#a2b8ce",
                }}
              />
              <div className="info-bulle-select">
                <div className="info-bulle-select-text">
                  Analyse basée sur les données disponibles à date, sous réserve de mise à jour
                  régulière des dossiers client dans Sesha.
                </div>
              </div>
            </div>
            <div
              style={{
                float: "right",
                marginTop: "10px",
              }}
            >
              <DatePickerManager
                setStartDate={(data) => setStartDate(data)}
                setEndDate={(data) => setEndDate(data)}
                selectedDateType={(data) => setSelectedDateType(data)}
              />
            </div>
            <div
              style={{
                clear: "both",
              }}
            ></div>
            <Text className="section-title"> Vue globale </Text> <div className="blue-bar"> </div>
            <Row justify="center" className="row-global">
              <Col className="col-global">
                <Statistic title="Dossiers client" value={globalInfo.howManyClients} />
              </Col>
              <Col className="col-global">
                <Statistic title="Analyses envoyées" value={globalInfo.howManyAnalysisSent} />
              </Col>
              <Col className="col-global">
                <Statistic
                  title="Relances pièces manquantes"
                  value={globalInfo.howManyMissingDocEmail}
                />
              </Col>
              <Col className="col-global">
                <Statistic title="Pièces reçues" value={globalInfo.howManyReceivedDoc} />
              </Col>
              {accountingFirm.fec ? null : (
                <Col className="col-global">
                  <Statistic
                    title="Pièces reçues / pièces demandées"
                    value={globalInfo.receivedVsRequested.toString().replace(".", ",") + " %"}
                  />
                </Col>
              )}
            </Row>
            {/* <Link
                            to={{
                              pathname: `/manager/detailed`,
                            }}
                          >
                            <Button className="details-link" type="link">
                              <ZoomInOutlined /> Afficher le détail par dossier client
                            </Button>
                          </Link> */}
            <br />
            <Text className="section-title"> Graphiques </Text> <div className="blue-bar"> </div>
            <br />
            <Row>
              <Col
                xs={24}
                sm={24}
                md={10}
                lg={{
                  span: 10,
                  offset: 1,
                }}
                className="graph-container"
              >
                <ManagerLineGraph
                  {...props}
                  title="Evolution du nombre d'analyses envoyées"
                  data={globalGraphData.howManyAnalysisSent}
                  selectedDateType={selectedDateType}
                />
              </Col>
              <Col
                xs={24}
                sm={24}
                md={10}
                lg={{
                  span: 10,
                  offset: 1,
                }}
                className="graph-container"
              >
                <ManagerLineGraph
                  {...props}
                  title="Evolution du nombre de relances pièces manquantes"
                  data={globalGraphData.howManyMissingDocEmail}
                  selectedDateType={selectedDateType}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col
                xs={24}
                sm={24}
                md={10}
                lg={{
                  span: 10,
                  offset: 1,
                }}
                className="graph-container howManyReceivedDoc-graph"
              >
                <ManagerLineGraph
                  {...props}
                  title="Evolution du nombre de pièces reçues"
                  data={globalGraphData.howManyReceivedDoc}
                  selectedDateType={selectedDateType}
                />
              </Col>
            </Row>
            <Detailed {...props} startDate={startDate} endDate={endDate} />
          </>
        )}
      </div>
    </>
  );
}

export default Global;

import { ArrowRightOutlined, PaperClipOutlined } from "@ant-design/icons";
import { Divider, Tag } from "antd";
import * as uploadHelper from "helpers/client/clientUploadHelper";
import { useEffect, useState } from "react";
import { ReactComponent as ImportantIcon } from "../../../../../assets/images/important.svg";
import { toggleEditOperationHelper } from "../../../../../helpers/client/clientMissingDocHelper";
import FileUpload from "./upload";

const ClientMissingOthersResponsiveCard = (props) => {
  const [operationsEditModeList, setOperationsEditModeList] = useState({});

  useEffect(() => {
    const operationsEditModeListHasNoOperationInEditMode =
      Object.keys(operationsEditModeList).length === 0;
    if (operationsEditModeListHasNoOperationInEditMode) {
      let operationKeys = {};
      props.data.forEach((el) => {
        return el.operations.map((o) => {
          return (operationKeys[o._id] = false);
        });
      });
      setOperationsEditModeList(operationKeys);
    }
  }, [props.data]);

  const handleAttachmentDownload = async (file) => {
    await uploadHelper.downloadAttachmentHelper(file);
  };

  return (
    <div>
      {props.data[0].operations
        .filter((el) => el.showToClient)
        .map((op, index) => {
          return (
            <div className="missingDoc-operation-responsive-card" key={`operation-${index}`}>
              <div onClick={() => toggleEditOperationHelper(op, setOperationsEditModeList)}>
                <span>
                  <ImportantIcon
                    style={
                      op.invalidFileComment && op.files.length === 0 && !op.comment
                        ? {
                            filter: "saturate(0%) brightness(94%) ",
                          }
                        : {}
                    }
                    className={`operation-title-circle ${
                      op.files.length > 0 || op.comment ? "operation-title-circle-is-validated" : ""
                    }`}
                  />
                  <span>
                    <p>{new Date(op.date).toLocaleDateString("fr-FR")}</p>
                    <p dangerouslySetInnerHTML={{ __html: op.text }} />
                    {op.attachments?.length > 0 && (
                      <p>
                        <Divider style={{ margin: "15px 0 10px 0" }} />
                        <div style={{ display: "block", width: "90%" }}>
                          {op.attachments?.map((file, fileIndex) => (
                            <Tag
                              key={fileIndex}
                              style={{
                                cursor: "pointer",
                              }}
                              color="grey"
                              icon={<PaperClipOutlined />}
                              onClick={async (event) => {
                                event.stopPropagation();
                                await handleAttachmentDownload(file);
                              }}
                            >
                              {file.fileName}
                            </Tag>
                          ))}
                        </div>
                      </p>
                    )}
                  </span>
                </span>
                <span>
                  <ArrowRightOutlined
                    style={{
                      display: `${operationsEditModeList[op._id] ? "none" : "block"}`,
                    }}
                  />
                </span>
              </div>
              <div>
                {!operationsEditModeList[op._id] ? (
                  <>
                    {op.comment && (
                      <p>
                        <b>Votre commentaire : </b>
                        {op.comment}
                      </p>
                    )}
                    {op.invalidFileComment && op.files.length === 0 && !op.comment && (
                      <p style={{ color: "hsla(228, 93%, 62%, 1)" }}>
                        <b>{op.invalidFileComment.split("***")[0]} - Invalidé : </b>
                        {op.invalidFileComment.split("***")[1]}
                      </p>
                    )}
                    {op.files.map((file, index) => (
                      <span className="client-upload-file-list-not-edit-mode" key={`${index}`}>
                        <PaperClipOutlined />
                        <p>{file.fileName}</p>
                      </span>
                    ))}
                  </>
                ) : (
                  <FileUpload
                    setData={props.setMissingDocumentOther}
                    missingDoc={null}
                    operation={op}
                    maxFileSizeInMB={5}
                    tokenClient={props.tokenClient}
                    setOperationsEditModeList={setOperationsEditModeList}
                    isOtherType={true}
                    fileSettings={props.fileSettings}
                  />
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default ClientMissingOthersResponsiveCard;

import React from "react";
import "../../../nav/Nav.css";
import { Button, Popover } from "antd";

import IDepotLogo from "../../../../assets/images/logo-isuite-depot.png";

function IDepot(props) {
  const getPopoverText = (state) => {
    switch (state?.statusCode) {
      case 0:
        return "Cabinet connecté à i-Dépôt !";
      case 1:
        return "Erreur: " + state.error;
      case 2:
        return "Erreur pannières, liste distante: " + state.panieresAll;
      default:
        return "Chargement";
    }
  };

  return (
    <>
      {props.idepotState?.statusCode !== 3 ? (
        <Popover
          placement="top"
          content={getPopoverText(props.idepotState)}
          className="info-popover"
          overlayClassName={
            props.idepotState?.statusCode === 0
              ? "mcf-success-popover mcf-success-popover-subnav"
              : "mcf-fail-popover mcf-fail-popover-subnav"
          }
        >
          <Button
            className={
              props.idepotState?.statusCode === 0
                ? "client-icon"
                : "client-icon client-icon-mcf-fail"
            }
            shape="circle"
            style={{ color: "black" }}
            icon={<img src={IDepotLogo} alt="Logo MCF" width="24px" />}
          />
        </Popover>
      ) : null}
    </>
  );
}

export default IDepot;

import { Badge, Tabs } from "antd";
import "dayjs/locale/fr";
import { EFeatures } from "enums/EFeatures";
import { useState } from "react";
import { useSelector } from "react-redux";
import { hasFeatures } from "utils/security";
import { selectAccountingFirm } from "../../slices/accountingFirmSlice";
import "../import/Import.css";
import ClientDataUpload from "./ClientDataUpload";
import ClientForecast from "./ClientForecast";
import ClientInformation from "./ClientInformation";

function ClientParams(props) {
  const accountingFirm = useSelector(selectAccountingFirm);
  const [activeKey, setActiveKey] = useState("1");

  const onChange = (activeKey) => {
    setActiveKey(activeKey);
  };

  const items = [
    {
      key: "1",
      label: "Informations client",
      children: <ClientInformation {...props} />,
    },
    {
      key: "2",
      label: "Données comptables",
      children: <ClientDataUpload {...props} />,
    },
    {
      key: "3",
      label: (
        <Badge count={"BETA"} color="cyan" size={"small"} style={{ top: "-10px" }}>
          <span>Prévisionnel</span>
        </Badge>
      ),
      children: <ClientForecast {...props} />,
    },
  ];

  return (
    <div className="params-info-container">
      <div className="title-import">Paramètres client</div>
      <Tabs
        defaultActiveKey={activeKey}
        items={
          hasFeatures([EFeatures.ADD_CLIENT]) &&
          (accountingFirm.fec || accountingFirm.dataProvider === "SAGE")
            ? items
            : items.filter((elt) => elt.key !== "2")
        }
        onChange={onChange}
        style={{ marginTop: "15px" }}
      />
    </div>
  );
}

export default ClientParams;

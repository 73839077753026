import { useState } from "react";
import { useSelector } from "react-redux";
import { selectAccountingFirm } from "../../../slices/accountingFirmSlice";
import { selectClient } from "../../../slices/clientSlice";
import "../MissingDocuments.css";
import Header from "./refonte/Header";
import Other from "./refonte/Other";
import Index from "./refonte/operations/Index";

function ReceivedDocuments(props) {
  const clientId = props.match.params.clientId;
  const clientRemoteId = props.match.params.clientRemoteId;

  const client = useSelector(selectClient);
  const accountingFirm = useSelector(selectAccountingFirm);

  const [nbrReceivedDoc, setNumberReceivedDoc] = useState(0);
  const [nbrOthers, setNmbrOthers] = useState(0);

  const [amountReceivedDoc, setAmountReceivedDoc] = useState(0);

  return (
    <>
      <Header
        {...props}
        nbrReceivedTotal={nbrReceivedDoc + nbrOthers}
        amountReceivedDoc={amountReceivedDoc}
      />
      <Other {...props} other={setNmbrOthers} />
      <Index
        {...props}
        setNumberReceivedDoc={setNumberReceivedDoc}
        setAmountReceivedDoc={setAmountReceivedDoc}
        nbrOthers={nbrOthers}
      />
    </>
  );
}

export default ReceivedDocuments;

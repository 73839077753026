import { Tabs } from "antd";
import CarouselSlider from "./CarouselSlider";
import SeshaParams from "./SeshaParams";

const { TabPane } = Tabs;

function NewsParams() {
  return (
    <>
      <div className="custom-block">
        <Tabs defaultActiveKey="1" tabPosition="left" className="custom-tabs">
          <TabPane tab={<span>Encart Nouveautés</span>} key="1">
            <SeshaParams />
          </TabPane>
          <TabPane tab={<span>Carrousel des nouveautés</span>} key="2">
            <CarouselSlider />
          </TabPane>
        </Tabs>
      </div>
    </>
  );
}

export default NewsParams;

import React from "react";
import "../unauthorized.css";
import svg1 from "../../../assets/images/payment/portfolio_2.svg";
import svg2 from "../../../assets/images/payment/portfolio_3.svg";

function Portfolio(props) {
  return (
    <>
      <div className="espacement-ligne">
        <p>
          En quelques clics, vous obtenez une vue complète
          <br />
          de votre portefeuille clients et identifiez les actions
          <br />à mener ou à déléguer à vos équipes
        </p>
        <img src={svg1} alt="Tableau portfolio 1" />
        <h2 className="espacement-ligne">
          Utilisez nos rapports pré-configurés
          <br />
          ou concevez les vôtres !
        </h2>
        <img src={svg2} alt="Tableau portfolio 2" style={{ marginLeft: "-11px" }} />
      </div>
    </>
  );
}

export default Portfolio;

import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Space } from "antd";

import ReactDOMServer from 'react-dom/server';

import Highlighter from "react-highlight-words";

const newDomParser = new DOMParser();

// access a list of attributes
function accessData(data, attributes) {
    if(attributes.length === 0) return data;

    return accessData(data[attributes[0]], attributes.slice(1));
}

// get text inside any cell, being a string or a react component
function getInnerText(cell) {
    return newDomParser.parseFromString(
            ReactDOMServer.renderToString(cell), 'text/html'
        ).body.innerText;

}

// choose for the right way to eval a row
function selectDataEvalFunction(dataEval) {
    // by default, use the row
    let dataEvalFunction = (r) => r;

    // if it's a function, it was made to deal with the row
    if( dataEval instanceof Function) {
        dataEvalFunction = (r) => dataEval(r)
    }
    // if the data eval is a string , it's a simple attribute access 
    else if(typeof dataEval === 'string') {
        dataEvalFunction = (r) => r[dataEval];
    }
    // if the data eval is list of strings, it was atrributes access already handled by antd
    else  if(Array.isArray(dataEval) && dataEval.every((v) => typeof v === 'string')) {   
        dataEvalFunction = (r) => accessData(r, dataEval);
    }

    return dataEvalFunction;
}

function reactComponentSearch(dataIndex, dataEval) {
    let dataEvalFunction = selectDataEvalFunction(dataEval);

    return {
        onFilter: (value, record) => {
            let result = dataEvalFunction(record);
            return result ?
                getInnerText(result).toLowerCase().includes(value.toLowerCase())
              : ""
        },
    }
}

function reactComponentFiltered(dataIndex, dataEval) {
    let dataEvalFunction = selectDataEvalFunction(dataEval);

    return {
        onFilter: (value, record) => {
            let result = dataEvalFunction(record);
            return result ?
                (getInnerText(result).toLowerCase() === value.toLowerCase())
              : ""
        },
    }
}

// search attributes for any cell
function reactComponentSearchMenu (
    dataIndex, dataEval, placeHolder, 
    searchInputs, searchRef,
    searchTexts, setSearchTexts, searchedColumn, setSearchedColumn
) {

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        console.log(selectedKeys);
        console.log(searchTexts);

        searchTexts[dataIndex] = selectedKeys[0];
        setSearchTexts(searchTexts);

        searchedColumn.push(dataIndex);
        setSearchedColumn([...searchedColumn]);

        confirm();
    };

    const handleReset = (clearFilters) => {
        console.log(clearFilters);
        
        searchTexts[dataIndex] = "";
        setSearchTexts(searchTexts);

        searchedColumn.splice(searchedColumn.indexOf(dataIndex), 1);
        setSearchedColumn([...searchedColumn]);

        clearFilters();
    };
    
    return ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchRef}
                    placeholder={placeHolder}
                    value={selectedKeys[0]}
                    onChange={(e) => {
                        console.log(e.target.value)
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: "block" }}
                />
                <Space>
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 120 }}
                >
                    Rechercher
                </Button>
                <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 100 }}>
                    Réinitialiser
                </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined style={{ color: filtered ? "var(--blue)" : undefined }} />
        ),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchRef && searchRef.current && searchRef.current.select());
            }
        },
    });
};


// sorter for evaluated data to react/label cell as displayed in the table  
// sorter doesn't get the data folowwing dataIndex of the columns, unlike render
export function reactComponentSorter(dataEval) {
    let dataEvalFunction = selectDataEvalFunction(dataEval);
    return (a, b) => {
        return getInnerText(dataEvalFunction(a))
            .localeCompare(getInnerText(dataEvalFunction(b)))
    }
}

// render for evaluated data to react/label cell as given to the table
// potentially highlight the searched text, in strings, because it's quite hard to do with react components
export function reactComponentRenderer(dataIndex, dataEval, searchTexts, searchedColumn) {
    // by default, use the row
    let dataEvalFunction = (v, r) => r;

    // if it's a function, it was made to deal with the row
    if( dataEval instanceof Function) {
        dataEvalFunction = (v, r) => dataEval(r)
    }
    // if the data eval is a string , it's a simple attribute access 
    else if(typeof dataEval === 'string') {
        dataEvalFunction = (v, r) => v;
    }
    // if the data eval is list of strings, it was atrributes access already handled by antd
    else  if(Array.isArray(dataEval) && dataEval.every((v) => typeof v === 'string')) {   
        dataEvalFunction = (v, r) => v;
    }

    return (value, row) => {
        let finalValue = dataEvalFunction(value, row);
        if(finalValue === "222") {
            console.log(value, row);
        }
        return typeof finalValue === 'string' && searchedColumn.includes(dataIndex) ? (
            <span 
            //style={{ display: "flex" }}
            >
                <Highlighter
                    highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                    searchWords={[searchTexts[dataIndex]]}
                    autoEscape
                    textToHighlight={finalValue}
                />
            </span>)
            : finalValue;

    }
}

/* 
    dataEvalConfig can be a simple string, in most simple case, but could be a list of attributes or containings functions
    placeHolder is the text to display in the search input
    searchVariables are the technical tracer of current searchs, columns and values
    redefinition can define specific search, sort or render functions instead of the default ones
*/  

export function setReactComponentRenderer(dataEvalConfig, searchVariables, predef) {
    // init data eval config obj if only a simple string was passed
    let dataConfig = dataEvalConfig;
    if(typeof dataEvalConfig === 'string') {   
        dataConfig = {
            index: dataEvalConfig,
            eval: dataEvalConfig
        }
    }
    else if(Array.isArray(dataEvalConfig)) {
        dataConfig = {
            index: dataEvalConfig[dataEvalConfig.length - 1],
            eval: dataEvalConfig
        }
    }

    // look for predef render or set the default one
    return (predef?.render 
        || reactComponentRenderer(dataConfig.index, dataConfig.eval,
            searchVariables.searchTexts, searchVariables.searchedColumn
        )
    )
}

export function setReactComponentSorter(dataEvalConfig, searchVariables, predef) {
    // init data eval config obj if only a simple string was passed
    let dataConfig = dataEvalConfig;
    if(typeof dataEvalConfig === 'string') {   
        dataConfig = {
            index: dataEvalConfig,
            eval: dataEvalConfig
        }
    }
    else if(Array.isArray(dataEvalConfig)) {
        dataConfig = {
            index: dataEvalConfig[dataEvalConfig.length - 1],
            eval: dataEvalConfig
        }
    }

    // look for predef sorter or set the default one
    return (predef?.sorter 
        || reactComponentSorter(dataConfig.index)
    )
}

export function setReactComponentSearch(dataEvalConfig, placeHolder, searchVariables, predef) {
    // init data eval config obj if only a simple string was passed
    let dataConfig = dataEvalConfig;
    if(typeof dataEvalConfig === 'string') {   
        dataConfig = {
            index: dataEvalConfig,
            eval: dataEvalConfig
        }
    }
    else if(Array.isArray(dataEvalConfig)) {
        dataConfig = {
            index: dataEvalConfig[dataEvalConfig.length - 1],
            eval: dataEvalConfig
        }
    }

    return {
        // look for predef onFilter, or set onFilter for search or filtering
        ...(predef?.onFilter 
            || predef.filters ? reactComponentFiltered(dataConfig.index, dataConfig.eval) 
            : reactComponentSearch(dataConfig.index, dataConfig.eval)),
            
        // look for predef search menu and items, or set search menu if it's not a filtering column
        ...(predef?.search 
            || predef.filters ? {}
              : reactComponentSearchMenu(dataConfig.index, dataConfig.eval, 
                    placeHolder, 
                    searchVariables.searchInput, searchVariables.searchInput,
                    searchVariables.searchTexts, searchVariables.setSearchTexts, 
                    searchVariables.searchedColumn, searchVariables.setSearchedColumn
                )
        ),
        filters: predef?.filters,
    }
}

export function getFilters(data, dataIndex) {
    if(data === undefined) return undefined;

    let filters = new Set();
    for(let row of data) {
        filters.add(accessColumnData(row, dataIndex, true))
    }

    return Array.from(filters).map( f => ({ text: f, value: f}));
}

export function addSearchAndSorterToColumns(columns, searchVariables, clientsData) {
    for(let column of columns) {
        if(column.children) {
            addSearchAndSorterToColumns(column.children, searchVariables);
        }
        else {
            let predef = {
                filters: column.filters 
                    || column.defaultFilters ? getFilters(clientsData, column.key) : undefined,
                onFilter: column.onFilter,
                sorter: column.sorter,
                render: column.render,
            };

            if(column.defaultRenderer !== false) {
                column.render = setReactComponentRenderer(
                    column.dataIndex, searchVariables, predef
                );
            }

            if(column.defaultSorter !== false) {
                column.sorter = setReactComponentSorter(column.dataIndex, 
                    searchVariables, predef, searchVariables.searchInput
                )
            }

            if(column.defaultSearch !== false)
                Object.assign(column, 
                    setReactComponentSearch(column.dataIndex, column.title, 
                        searchVariables, predef, searchVariables.searchInput
                    )
                );
        }
    }
}

export function accessColumnData(row, column, stringify) {
    let dataEval = column;

    let value = selectDataEvalFunction(dataEval)(row);

    if(stringify) {
        return getInnerText(value);
    }

    return selectDataEvalFunction(dataEval)(row);
}

import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Col, Row } from "antd";
import { useState } from "react";

const OrderItem = (props) => {
  const { id, dragOverlay } = props;

  const [itemStyle, setItemStyle] = useState({});

  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
    cursor: dragOverlay ? "grabbing" : "grab",
    width: 300,
  };

  return (
    <li style={style} ref={setNodeRef} {...attributes} {...listeners}>
      <Row
        className="criteria-line order-row"
        style={itemStyle}
        onMouseEnter={(e) => {
          setItemStyle({
            backgroundColor: "rgba(69,105,248,0.1)",
          });
        }}
        onMouseLeave={(e) => {
          setItemStyle({
            backgroundColor: "transparent",
          });
        }}
      >
        <Col span={24}>
          <p className="order-report-name">{props.name}</p>
        </Col>
      </Row>
    </li>
  );
};

export default OrderItem;

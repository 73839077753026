import { ELocalStorage } from "enums/ELocalStorage";
import { useParams } from "react-router-dom";

function Logout(props) {
  const params = useParams();

  if (params.origin) {
    localStorage.removeItem(ELocalStorage.PAGE_BEFORE_LOGOUT);
    localStorage.setItem(ELocalStorage.LOGOUT_ORIGIN, params.origin);
  }

  localStorage.removeItem("token");
  localStorage.removeItem("theme");
  window.location.href = "/";
  return <></>;
}

export default Logout;

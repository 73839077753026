import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  LockFilled,
  SearchOutlined,
} from "@ant-design/icons";
import { Button, Input, Radio, Space, Table, Tag } from "antd";
import { useEffect, useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import proabonoIcon from "../../../assets/images/proabono.png";
import logAction from "../../../utils/logActions";

import { EFeatures } from "enums/EFeatures";
import { hasFeatures } from "utils/security";
import * as API from "../../../api/API";
import LoaderRaw from "../../../utils/LoaderRaw";
import openNotification from "../../../utils/notification";
import CreateAccountingFirm from "./CreateAccountingFirm.js";
import EditAccountingFirm from "./EditAccountingFirm.js";

const howManyElementSince = (datesArray = []) => {
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
  let newArray = [];
  for (let index = 0; index < datesArray.length; index++) {
    const element = datesArray[index];
    if (new Date(element) > thirtyDaysAgo) newArray.push(element);
  }
  return newArray.length;
};

function AccountingFirmDashboard(props) {
  const [selectedRows, setSelectedRows] = useState([]);
  const [firmListUpdate, setFirmListUpdate] = useState(false);
  const [switchTotal, setSwitchTotal] = useState(false);
  const [displayHeader, setDisplayHeader] = useState(false);
  const [displayEdit, setDisplayEdit] = useState(false);
  const [displayDelete, setDisplayDelete] = useState(false);
  const [dataFirms, setDataFirms] = useState();
  const [dataFirmsData, setDataFirmsData] = useState();
  const [dataHubspot, setDataHubspot] = useState([]);
  const [dataSource, setdataSource] = useState();
  const [selectionType] = useState("checkbox");
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [offers, setOffers] = useState([]);
  const [proabonoList, setProabonoList] = useState([]);
  const [secondPhaseLoaded, setSecondPhaseLoaded] = useState(false);

  const searchInput = useRef(null);

  const dataProviderList = [
    {
      text: "ACD",
      value: "ACD",
    },
    {
      text: "AGIRIS",
      value: "AGIRIS",
    },
    {
      text: "AGIRIS old",
      value: "AGIRIS old",
    },
    {
      text: "CEGID EXPERT v1",
      value: "CEGID EXPERT v1",
    },
    {
      text: "CEGID EXPERT",
      value: "CEGID EXPERT",
    },
    {
      text: "CEGID LOOP",
      value: "CEGID LOOP",
    },
    {
      text: "CEGID QUADRA",
      value: "CEGID QUADRA",
    },
    {
      text: "EBP",
      value: "EBP",
    },
    {
      text: "IBIZA",
      value: "IBIZA",
    },
    {
      text: "HORUS",
      value: "HORUS",
    },
    {
      text: "INQOM",
      value: "INQOM",
    },
    {
      text: "MYUNISOFT",
      value: "MYUNISOFT",
    },
    {
      text: "QUICKBOOKS",
      value: "QUICKBOOKS",
    },
    {
      text: "SAGE",
      value: "SAGE",
    },
    {
      text: "FULLL",
      value: "FULLL",
    },
  ];

  // 1. Initial loading
  useEffect(() => {
    const fetchFirms = async () => {
      const response = await API.getDashboardFirms();
      const firms = await response.json();
      setDataFirms(firms);
      setdataSource(firms.map((obj) => ({ ...obj, key: obj._id })));
      setFirmListUpdate(true); // Marks the end of the first phase
    };
    fetchFirms();
  }, []);

  // 2.1. Load additional firm data
  useEffect(() => {
    const fetchAdditionalFirmData = async () => {
      if (!firmListUpdate) return;

      const firmsDataRes = await API.getDashboardFirmsData();
      const firmsData = await firmsDataRes.json();

      setDataFirmsData(firmsData);
      setFirmListUpdate(false);
      setSecondPhaseLoaded(true);
    };

    fetchAdditionalFirmData();
  }, [firmListUpdate]);

  // 2.2. Load Hubspot data
  useEffect(() => {
    const fetchHubspotData = async () => {
      if (!firmListUpdate) return;

      const hubspotRes = await API.getDashboardHubspot();
      const hubspotData = await hubspotRes.json();

      setDataHubspot(hubspotData);
    };

    fetchHubspotData();
  }, [firmListUpdate]);

  // 3. Final data merge when everything is loaded
  useEffect(() => {
    const mergeData = () => {
      const dataToMerge = [dataFirms, dataFirmsData];
      let mergedData = dataToMerge.pop();

      dataToMerge.forEach((data) => {
        mergedData = mergedData.map((item) => ({
          ...item,
          ...data.find((entry) => entry._id === item._id), // Merges data based on _id
        }));
      });

      // Include Hubspot data only if it is available
      if (dataHubspot && dataHubspot.length > 0) {
        mergedData = mergedData.map((item) => ({
          ...item,
          ...dataHubspot.find((entry) => entry._id === item._id),
        }));
      }

      mergedData?.forEach((obj) => {
        obj.key = obj._id; // Adds a key property to each object
      });

      setdataSource(mergedData);
    };

    // Execute when firm data and additional firm data are ready
    if (dataFirms && dataFirmsData) {
      mergeData();
    }
  }, [dataFirms, dataFirmsData, dataHubspot]);

  // 4. Loading offers (independent of other data)
  useEffect(() => {
    const fetchOffers = async () => {
      const response = await API.getOffers();
      const offers = await response.json();
      setOffers(
        offers.offers.map((offer) => ({
          id: offer.ReferenceOffer,
          value: offer.ReferenceOffer,
          text: offer.Name,
        }))
      );
    };
    fetchOffers();
  }, []);

  // 5. Loading customers (independent of other data)
  useEffect(() => {
    const fetchCustomers = async () => {
      const response = await API.getCustomers();
      const customers = await response.json();
      setProabonoList(customers.data);
    };
    fetchCustomers();
  }, []);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={"Nom du cabinet"}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 120 }}
          >
            Rechercher
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 100 }}>
            Réinitialiser
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "var(--blue)" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : "",
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput && searchInput.current && searchInput.current.select());
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const handleDelete = () => {
    if (
      window.confirm(
        `Voulez-vous supprimer les cabinets suivants :\r ${selectedRows.map((r) => r.name)}?`
      )
    ) {
      selectedRows.forEach((elt) => {
        (async () => {
          let res = await API.deleteAccountingFirm(
            dataSource.find((firm) => firm._id === elt._id)._id
          );
          if (res.status === 200) {
            setFirmListUpdate(true);
            setDisplayHeader(false);
            openNotification("success", "Cabinet(s) comptable(s) supprimé(s)");
            logAction(60, -1, null, dataSource.find((firm) => firm._id === elt._id)._id);
          } else if (res.status === 403) {
            res = await res.json();
            openNotification("error", "Erreur ProAbono: " + res.message);
          } else openNotification("error", "Erreur lors de la suppression");
        })();
      });
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      if (selectedRows.length === 1) {
        setSelectedRows(selectedRows);
        setDisplayHeader(true);
        setDisplayEdit(true);
        setDisplayDelete(true);
      } else if (selectedRows.length >= 1) {
        setSelectedRows(selectedRows);
        setDisplayHeader(true);
        setDisplayEdit(false);
        setDisplayDelete(true);
      } else {
        setSelectedRows(null);
        setDisplayHeader(false);
        setDisplayEdit(false);
        setDisplayDelete(false);
      }
    },
  };

  function displayLoadingData(data) {
    return data === undefined ? <i className="data-cell-loading"></i> : data;
  }

  const columns = [
    {
      title: "Nom du cabinet",
      dataIndex: ["name", "active"],
      width: "180px",
      fixed: "left",
      sorter: (rowA, rowB) =>
        rowA.name.localeCompare(rowB.name, undefined, {
          numeric: true,
          sensitivity: "base",
        }),
      ...getColumnSearchProps("name"),
      render: (text, record) => {
        return (
          <>
            {proabonoList.some((el) => el.ReferenceCustomer === record._id) ? (
              <a
                href={
                  "https://via.proabono.com/App/" +
                  (process.env.REACT_APP_ENV === "prod" ? "1177" : "1194") +
                  "/Sub/Customer/" +
                  proabonoList.find((el) => el.ReferenceCustomer === record._id).Id
                }
                target="_blank"
                rel="noreferrer"
                style={{ verticalAlign: "text-top" }}
              >
                <img
                  src={proabonoIcon}
                  alt="Proabono"
                  style={{ width: "12px", marginRight: "5px" }}
                />
              </a>
            ) : null}

            {searchedColumn === "name" ? (
              <Highlighter
                highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={record.name}
              />
            ) : (
              record.name
            )}
            {!record.active ? (
              <>
                {" "}
                <LockFilled title="Cabinet désactivé" style={{ color: "#c32028" }} />
              </>
            ) : null}
          </>
        );
      },
    },
    {
      title: "Hubspot",
      dataIndex: "hubspot",
      align: "center",
      width: "75px",
      sorter: (a, b) => a.hubspot - b.hubspot,
      render: (hubspot, row) =>
        hubspot === undefined ? (
          displayLoadingData(hubspot)
        ) : hubspot ? (
          <a
            href={
              "https://app-eu1.hubspot.com/contacts/" +
              process.env.REACT_APP_HUBSPOT_ID +
              "/deal/" +
              row.hubspotId
            }
            target="_blank"
            rel="noreferrer"
          >
            <CheckCircleOutlined title="Transaction synchronisée" style={{ color: "#4caf50" }} />
          </a>
        ) : (
          <CloseCircleOutlined title="Transaction non synchronisée" style={{ color: "#c32028" }} />
        ),
    },
    {
      title: "Ouverture du service",
      dataIndex: "creationDate",
      align: "center",
      width: "100px",
      defaultSortOrder: "ascend",
      sorter: (a, b) => {
        let x, y;
        a.creationDate ? (x = new Date(a.creationDate)) : (x = new Date("2020-01-01T00:00:00Z"));
        b.creationDate ? (y = new Date(b.creationDate)) : (y = new Date("2020-01-01T00:00:00Z"));
        return y - x;
      },
      render: (creationDate) =>
        creationDate
          ? new Date(creationDate).toLocaleString("fr-FR", {
              year: "numeric",
              month: "short",
              day: "numeric",
            })
          : "",
    },
    {
      title: "Offre précédente",
      dataIndex: "previousOfferName",
      align: "center",
      width: "120px",
      render: (previousOfferName) =>
        displayLoadingData(previousOfferName ? previousOfferName.join(", ") : undefined),
    },
    {
      title: "Offre actuelle",
      dataIndex: "currentOfferName",
      align: "center",
      width: "120px",
      filters: offers,
      onFilter: (value, record) => record.currentOfferId?.indexOf(value) >= 0,
      render: (currentOfferName) =>
        displayLoadingData(currentOfferName ? currentOfferName.join(", ") : undefined),
    },
    {
      title: "Logiciel comptable",
      dataIndex: "dataProvider",
      align: "center",
      width: "100px",
      sorter: (a, b) => a.dataProvider.localeCompare(b.dataProvider),
      filters: dataProviderList,
      onFilter: (value, record) => record.dataProvider.indexOf(value) === 0,
    },
    {
      title: "Mode",
      dataIndex: "fec",
      align: "center",
      width: "60px",
      render: (fec) => (fec ? "FEC" : "BDD"),
      filters: [
        {
          text: "FEC",
          value: true,
        },
        {
          text: "BDD",
          value: false,
        },
      ],
      onFilter: (value, record) => record.fec === value,
    },
    {
      title: "Dossiers clients",
      dataIndex: "accountingFirms",
      align: "center",
      sorter: (a, b) => a.accountingFirms - b.accountingFirms,
      render: (clients, row) => {
        let match;
        row.currentOfferName?.forEach((offer) => {
          if (offer.match(/\d+/)) match = offer.match(/\d+/)[0];
        });
        if (match <= 14) match = undefined; // remove false positives

        if (!match) return clients;
        if (clients >= match)
          return (
            <Tag color="red" style={{ marginRight: 0 }}>
              {clients}
            </Tag>
          );
        else if (clients >= match - 10)
          return (
            <Tag color="gold" style={{ marginRight: 0 }}>
              {clients}
            </Tag>
          );
        return clients;
      },
    },
    {
      title: "Rapports",
      dataIndex: "reportCount",
      align: "center",
      sorter: (a, b) => a.reportCount - b.reportCount,
      render: (text, row) => displayLoadingData(row.reportCount),
    },
    {
      title: "Nombre de cliques sur la VPF",
      dataIndex: "clickedVPFCount",
      align: "center",
      sorter: (a, b) => a.clickedVPFCount - b.clickedVPFCount,
      render: (text, row) => displayLoadingData(row.clickedVPFCount),
    },
    {
      title: "Dernière connexion (jours)",
      dataIndex: "lastLogin",
      align: "center",
      width: "90px",
      sorter: (a, b) =>
        new Date(b.lastLogin || "2020-01-01") - new Date(a.lastLogin || "2020-01-01"),
      render: (_, row) => {
        if (!secondPhaseLoaded) return displayLoadingData(undefined);
        if (!row.lastLogin) return "Jamais";

        const daysSinceLastLogin = Math.floor(
          (new Date() - new Date(row.lastLogin)) / (1000 * 60 * 60 * 24)
        );
        return daysSinceLastLogin === 0 ? "Aujourd'hui" : daysSinceLastLogin;
      },
    },
    {
      title: "Utilisateurs",
      children: [
        {
          title: "M-2",
          dataIndex: "usersM2",
          align: "center",
          sorter: (a, b) => a.usersM2 - b.usersM2,
          render: (text, row) => displayLoadingData(row.usersM2),
        },
        {
          title: "M-1",
          dataIndex: "usersM1",
          align: "center",
          sorter: (a, b) => a.usersM1 - b.usersM1,
          render: (text, row) => displayLoadingData(row.usersM1),
        },
        {
          title: "Habilités",
          dataIndex: "usersM",
          align: "center",
          sorter: (a, b) => a.usersM - b.usersM,
          render: (text, row) => displayLoadingData(row.usersM),
        },
        {
          title: "Actifs",
          dataIndex: "activeUsers",
          align: "center",
          sorter: (a, b) => a.activeUsers - b.activeUsers,
          render: (text, row) => displayLoadingData(row.activeUsers),
        },
        {
          title: "Inactifs",
          dataIndex: ["usersM", "activeUsers"],
          align: "center",
          sorter: (a, b) => {
            let x, y;
            x = a.usersM - a.activeUsers;
            y = b.usersM - b.activeUsers;
            return x - y;
          },
          render: (text, row) =>
            displayLoadingData(
              isNaN(row.usersM - row.activeUsers) ? undefined : row.usersM - row.activeUsers
            ),
        },
      ],
    },
    {
      title: "Analyses générées",
      dataIndex: "analysisGenerated",
      align: "center",
      switchTotal: true,
      sorter: (a, b) => {
        let x, y;
        a.analysisGenerated ? (x = a.analysisGenerated.length) : (x = 0);
        b.analysisGenerated ? (y = b.analysisGenerated.length) : (y = 0);
        return x - y;
      },
      render: (analysisGenerated) => displayLoadingData(analysisGenerated?.length),
    },
    {
      title: "Analyses générées 30 jours glissants",
      dataIndex: "analysisGenerated",
      align: "center",
      switchTotal: false,
      sorter: (a, b) => {
        let x, y;
        a.analysisGenerated ? (x = howManyElementSince(a.analysisGenerated)) : (x = 0);
        b.analysisGenerated ? (y = howManyElementSince(b.analysisGenerated)) : (y = 0);
        return x - y;
      },
      render: (analysisGenerated) => displayLoadingData(howManyElementSince(analysisGenerated)),
    },
    {
      title: "Emails",
      switchTotal: true,
      children: [
        {
          title: "Analyses",
          dataIndex: "analysisEmailSent",
          align: "center",
          switchTotal: true,
          sorter: (a, b) => {
            let x, y;
            a.analysisEmailSent ? (x = a.analysisEmailSent.length) : (x = 0);
            b.analysisEmailSent ? (y = b.analysisEmailSent.length) : (y = 0);
            return x - y;
          },
          render: (analysisEmailSent) => displayLoadingData(analysisEmailSent?.length),
        },
        {
          title: "Pièces manquantes",
          dataIndex: "missingDocEmailSent",
          align: "center",
          switchTotal: true,
          sorter: (a, b) => {
            let x, y;
            a.missingDocEmailSent ? (x = a.missingDocEmailSent.length) : (x = 0);
            b.missingDocEmailSent ? (y = b.missingDocEmailSent.length) : (y = 0);
            return x - y;
          },
          render: (missingDocEmailSent) => displayLoadingData(missingDocEmailSent?.length),
        },
        {
          title: "Encours clients",
          dataIndex: "outstandingsClientsEmailSent",
          align: "center",
          switchTotal: true,
          sorter: (a, b) => {
            let x, y;
            a.outstandingsClientsEmailSent ? (x = a.outstandingsClientsEmailSent.length) : (x = 0);
            b.outstandingsClientsEmailSent ? (y = b.outstandingsClientsEmailSent.length) : (y = 0);
            return x - y;
          },
          render: (outstandingsClientsEmailSent) =>
            displayLoadingData(outstandingsClientsEmailSent?.length),
        },
        {
          title: "Encours fournisseurs",
          dataIndex: "outstandingsSuppliersEmailSent",
          align: "center",
          switchTotal: true,
          sorter: (a, b) => {
            let x, y;
            a.outstandingsSuppliersEmailSent
              ? (x = a.outstandingsSuppliersEmailSent.length)
              : (x = 0);
            b.outstandingsSuppliersEmailSent
              ? (y = b.outstandingsSuppliersEmailSent.length)
              : (y = 0);
            return x - y;
          },
          render: (outstandingsSuppliersEmailSent) =>
            displayLoadingData(outstandingsSuppliersEmailSent?.length),
        },
      ],
    },
    {
      title: "Emails sur 30 jours glissants",
      switchTotal: false,
      children: [
        {
          title: "Analyses",
          dataIndex: "analysisEmailSent",
          align: "center",
          switchTotal: false,
          sorter: (a, b) => {
            let x, y;
            a.analysisEmailSent ? (x = howManyElementSince(a.analysisEmailSent)) : (x = 0);
            b.analysisEmailSent ? (y = howManyElementSince(b.analysisEmailSent)) : (y = 0);
            return x - y;
          },
          render: (analysisEmailSent) => displayLoadingData(howManyElementSince(analysisEmailSent)),
        },
        {
          title: "Pièces manquantes",
          dataIndex: "missingDocEmailSent",
          align: "center",
          switchTotal: false,
          sorter: (a, b) => {
            let x, y;
            a.missingDocEmailSent ? (x = howManyElementSince(a.missingDocEmailSent)) : (x = 0);
            b.missingDocEmailSent ? (y = howManyElementSince(b.missingDocEmailSent)) : (y = 0);
            return x - y;
          },
          render: (missingDocEmailSent) =>
            displayLoadingData(howManyElementSince(missingDocEmailSent)),
        },
        {
          title: "Encours clients",
          dataIndex: "outstandingsClientsEmailSent",
          align: "center",
          switchTotal: false,
          sorter: (a, b) => {
            let x, y;
            a.outstandingsClientsEmailSent
              ? (x = howManyElementSince(a.outstandingsClientsEmailSent))
              : (x = 0);
            b.outstandingsClientsEmailSent
              ? (y = howManyElementSince(b.outstandingsClientsEmailSent))
              : (y = 0);
            return x - y;
          },
          render: (outstandingsClientsEmailSent) =>
            displayLoadingData(howManyElementSince(outstandingsClientsEmailSent)),
        },
        {
          title: "Encours fournisseurs",
          dataIndex: "outstandingsSuppliersEmailSent",
          align: "center",
          switchTotal: false,
          sorter: (a, b) => {
            let x, y;
            a.outstandingsSuppliersEmailSent
              ? (x = howManyElementSince(a.outstandingsSuppliersEmailSent))
              : (x = 0);
            b.outstandingsSuppliersEmailSent
              ? (y = howManyElementSince(b.outstandingsSuppliersEmailSent))
              : (y = 0);
            return x - y;
          },
          render: (outstandingsSuppliersEmailSent) =>
            displayLoadingData(howManyElementSince(outstandingsSuppliersEmailSent)),
        },
      ],
    },
  ];

  return (
    <div className="accountingFirmDashboard">
      <CreateAccountingFirm
        {...props}
        updateFirm={(data) => {
          setFirmListUpdate(data);
        }}
      />

      <div className="wrapper">
        <div className="switchTotal"></div>
        <Radio.Group value={switchTotal} onChange={(e) => setSwitchTotal(e.target.value)}>
          <Radio className="radio" value={false}>
            <span>Total</span>
          </Radio>
          <Radio className="radio" value={true}>
            <span>30 jours glissants</span>
          </Radio>
        </Radio.Group>
      </div>

      {displayHeader ? (
        <div className="header-table">
          {displayDelete ? (
            <div
              style={{
                display: "inline-block",
                float: "right",
                padding: "9px",
              }}
            >
              <Button
                className="call-action-btn"
                key="delete"
                onClick={handleDelete}
                icon={<DeleteOutlined />}
              >
                Supprimer
              </Button>
            </div>
          ) : null}
          {displayEdit ? (
            <div
              style={{
                display: "inline-block",
                float: "right",
                padding: "9px",
              }}
            >
              <EditAccountingFirm
                {...props}
                updateFirm={(data) => {
                  setFirmListUpdate(data);
                }}
                selectedRow={selectedRows[0]}
              />
            </div>
          ) : null}
        </div>
      ) : null}
      <Table
        rowSelection={
          hasFeatures([EFeatures.SUPERADMIN_WRITE, EFeatures.SUPERADMIN_TECH])
            ? {
                type: selectionType,
                ...rowSelection,
              }
            : false
        }
        columns={columns.filter((col) => col.switchTotal !== switchTotal)}
        dataSource={dataSource}
        size="small"
        bordered
        scroll={{ x: 1300 }}
        pagination={{ defaultPageSize: 50, showSizeChanger: true }}
        loading={{ indicator: <LoaderRaw />, spinning: !dataSource }}
      />
    </div>
  );
}

export default AccountingFirmDashboard;

import { Table } from "antd";
import { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import * as API from "../../../api/API";
import { selectClient } from "../../../slices/clientSlice";
import CommentCollabOutstandings from "./CommentCollabOutstandings";

const ExpandedRowRender = (props) => {
  const [localRecord, setLocalRecord] = useState(props.record);
  const [data, setData] = useState(
    props.record.operations
      .filter((op) => !op.filteredParams)
      .filter((op) => !op.filteredDueDays)
      .map((op) => ({ ...op, key: op.id }))
  );
  const [expandedRowKeys, setExpandedRowKeys] = useState(
    props.record.operations
      .filter((op) => !op.filteredParams)
      .filter((op) => !op.filteredDueDays)
      .map(({ id }) => id)
  );

  const client = useSelector(selectClient);

  useEffect(() => {
    if (JSON.stringify(localRecord) !== JSON.stringify(props.record)) {
      setData(
        props.record.operations
          .filter((op) => !op.filteredParams)
          .filter((op) => !op.filteredDueDays)
          .map((op) => ({ ...op, key: op.id }))
      );
      setLocalRecord(props.record);
      setExpandedRowKeys(
        props.record.operations
          .filter((op) => !op.filteredParams)
          .filter((op) => !op.filteredDueDays)
          .map(({ id }) => id)
      );
    }
  }, [props.record]);

  return (
    <>
      <Table
        className="outstandings-sub-table"
        columns={props.operationColumns}
        dataSource={data}
        rowSelection={{
          onSelect: (recordSelect, selected) => {
            if (selected) {
              props.uncheckOperations([recordSelect.key], false);
              API.putOutstandings(client._id, props.type, props.record._id, recordSelect.key, {
                unchecked: false,
              });
            } else {
              props.uncheckOperations([recordSelect.key], true);
              API.putOutstandings(client._id, props.type, props.record._id, recordSelect.key, {
                unchecked: true,
              });
            }
          },
          selectedRowKeys: props.activeOperationsIds,
          hideSelectAll: true,
        }}
        pagination={{ defaultPageSize: 10, showSizeChanger: true }}
        showExpandColumn={false}
        showSorterTooltip={false}
        rowClassName={(record, index) => {
          if (record.commentClient) {
            return "green-row";
          } else {
            return "blue-row";
          }
        }}
        expandable={{
          expandedRowRender: (record) => <CommentCollabOutstandings {...props} record={record} />,
          expandedRowKeys: expandedRowKeys,
        }}
      />

      <p
        className="show-all-op-link"
        onClick={(e) => {
          e.preventDefault();
          props.setShowModal(true);
          props.setRecord(props.record);
        }}
      >
        Afficher l'ensemble des opérations du compte
      </p>
    </>
  );
};

export default ExpandedRowRender;

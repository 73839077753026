function CommentCollab(props) {
  return (
    <>
      {props.record.commentCollab ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            paddingBottom: "15px",
            paddingLeft: "15px",
          }}
        >
          <span className="popover-comment-text">
            <b>Commentaire cabinet : </b>
            {props.record.commentCollab}
          </span>
        </div>
      ) : null}
    </>
  );
}

export default CommentCollab;

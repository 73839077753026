import {
  CheckOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
  MailOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { Button, Checkbox, Col, Form, Input, Row, Typography } from "antd";
import * as dayjs from "dayjs";
import "dayjs/locale/fr";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as API from "../../api/API";
import { ReactComponent as EditIcon } from "../../assets/images/edit.svg";
import { selectAccountingFirm } from "../../slices/accountingFirmSlice";
import { selectClient, updateClient } from "../../slices/clientSlice";
import { selectUser } from "../../slices/userSlice";
import * as generateMailText from "../../utils/generateMailText";
import logAction from "../../utils/logActions";
import openNotification from "../../utils/notification";
import AnalyseTableMail from "./AnalyseTableMail";
import "./Mail.css";
import TextEditorAnalysis from "./TextEditorAnalysis";

const { Text } = Typography;

const monthNames = [
  "Janvier",
  "Février",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juillet",
  "Août",
  "Septembre",
  "Octobre",
  "Novembre",
  "Décembre",
];

function monthNumToName(num) {
  return monthNames[num - 1];
}

function monthShortToLong(month) {
  for (var i = 0; i < monthNames.length; i++) {
    if (monthNames[i].toLowerCase().includes(month.split(".")[0])) {
      return monthNames[i].toLowerCase();
    }
  }
}

function MailAnalysis(props) {
  const dispatch = useDispatch();

  const accountingFirm = useSelector(selectAccountingFirm);
  const user = useSelector(selectUser);
  const client = useSelector(selectClient);

  const clientId = props.match.params.clientId;

  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const monthPreposition = (monthWithYear) => {
    let monthOnly = monthWithYear.replace(/ .*/, "");
    let months = ["Avril", "Août", "Octobre"];

    if (months.includes(monthOnly)) return "d'" + monthWithYear;
    return "de " + monthWithYear;
  };

  const date =
    props.date !== undefined
      ? monthNumToName(props.date.month) + " " + props.date.fiscal_year
      : capitalize(props.sendData[0].date);

  const signature = user.signature
    ? user.signature
    : `<p>Cordialement</p><br />
      <p>${user.firstName} ${user.lastName}</p>
      <span style="color:#4569f8; font-size: 16px">${accountingFirm.name}</span>
  `;

  const [form] = Form.useForm();
  const [componentSize, setComponentSize] = useState("default");
  const [subject, setSubject] = useState("");
  const [editSubject, setEditSubject] = useState(true);
  const [showEditSubject, setShowEditSubject] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const [addCC, setCC] = useState(client.email?.analysis?.cc?.length > 0);
  const [intro, setIntro] = useState("");
  const [initFormValues, setInitFormValues] = useState({});
  const [indics, setIndics] = useState([]);
  const [editIntro, setEditIntro] = useState(true);
  const [recepientAlert, setRecepientAlert] = useState(false);
  const [ccAlert, setCcAlert] = useState(false);
  const [loading, setLoading] = useState(true);
  const [htmlTable, setHtmlTable] = useState([]);
  const [logo, setLogo] = useState();
  const [sending, setSending] = useState(false);
  const mail = useRef(null);
  const [editPara, setEditPara] = useState([]);
  const [showEditPara, setShowEditPara] = useState([]);
  const [showPara, setShowPara] = useState([]);
  const [signatureImage, setSignatureImage] = useState(null);

  const onFormLayoutChange = ({ size }) => {
    setComponentSize(size);
  };

  const checkEmail = (e, inputType) => {
    const text = e.target.value;
    const regex = /^(([a-zA-Z\-0-9\\.]+@)([a-zA-Z\-0-9\\.]+)[;]*)+$/g;

    switch (inputType) {
      case "recipient":
        setRecepientAlert(!regex.test(text));
        break;
      case "cc":
        setCcAlert(!regex.test(text));
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setIndics(props.sendData);
    setEditPara(
      props.sendData.map((indic) => ({
        para: indic.para,
        edit: true,
      }))
    );

    setShowEditPara(
      props.sendData.map((indic) => ({
        para: indic.para,
        showedit: false,
      }))
    );

    setShowPara(
      props.sendData.map((indic) => ({
        para: indic.para,
        show: true,
      }))
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.sendData]);

  useEffect(() => {
    if (accountingFirm.logoFile) {
      (async () => {
        let res = await API.getAccountingFirmLogo(accountingFirm._id);
        if (res.status === 200) {
          const data = await res.blob();
          setLogo(URL.createObjectURL(data));
        } else {
          console.log("error");
        }
      })();
    }
    setLoading(false);
  }, [accountingFirm.logoFile, accountingFirm._id]);

  useEffect(() => {
    if (user.signatureImage) {
      (async () => {
        let res = await API.getUserSignatureImage(user._id, 1);
        if (res.status === 200) {
          const data = await res.blob();
          setSignatureImage(URL.createObjectURL(data));
        } else {
          console.log("Error getUserSignatureImage");
        }
      })();
    }
  }, [user._id]);

  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client._id, indics]);

  useEffect(() => {
    if (props.selectedMailType === "analysis") {
      setSubject(
        generateMailText
          .generateSubject(props, accountingFirm, client, user, [])
          .replaceAll("<nom_dossier_client>", client.name)
          .replaceAll("<mois_analyse>", date)
          .replaceAll("<prenom_collab>", user.firstName)
          .replaceAll("<nom_collab>", user.lastName)
          .replaceAll("<signature_collab>", signature)
      );
      setIntro(
        generateMailText
          .generateBegining(props, accountingFirm, client, user, [])
          .replaceAll("&lt;nom_dossier_client&gt;", client.name)
          .replaceAll("&lt;mois_analyse&gt;", monthPreposition(date))
          .replaceAll("&lt;signature_collab&gt;", signature)
          .replaceAll("&lt;prenom_collab&gt;", user.firstName)
          .replaceAll("&lt;nom_collab&gt;", user.lastName)
      );
    }
  }, [props.selectedMailType]);

  const onSubmit = (data) => {
    window.scroll(0, 0);
    setSending(true);
    const sendCopy = data.copy ? ";" + user.email : "";

    function addPopUpTextToTitleHtml(titleHtml, popUpText) {
      const htmlObj = document.createElement("div");
      htmlObj.innerHTML = titleHtml;

      const popUpHtml = document.createElement("i");
      popUpHtml.innerHTML = popUpText;

      htmlObj.lastElementChild.append(popUpHtml);
      return htmlObj.innerHTML;
    }

    function getHtmlTitle(paraName) {
      const elt = props.sendData.find((elt) => elt !== undefined && elt.para === paraName);
      if (elt.popupText) return addPopUpTextToTitleHtml(elt.htmltitle, elt.popupText);
      else return elt.htmltitle;
    }

    const mailpara = [
      {
        text: showPara.find((obj) => obj.para === "table") ? "" : undefined,
        title: showPara.find((obj) => obj.para === "table") ? "table" : undefined,
        titletable: "yoy",
        titlehtml:
          props.sendData[
            props.sendData.findIndex((elt) => elt !== undefined && elt.para === "table")
          ] !== undefined
            ? client.pastYearComp
              ? `<div id="title"><span classname="section-title"> ${capitalize(
                  monthShortToLong(
                    props.sendData
                      .find((elt) => elt !== undefined && elt.para === "table")
                      .date.split(" ")[0]
                  ) +
                    " " +
                    props.sendData
                      .find((elt) => elt !== undefined && elt.para === "table")
                      .date.split(" ")[1]
                )}</span> <div classname="blue-bar space"/></div>`
              : `<div id="title"><span classname="section-title"> ${capitalize(
                  monthShortToLong(
                    props.sendData
                      .find((elt) => elt !== undefined && elt.para === "table")
                      .date.split(" ")[0]
                  ) +
                    " " +
                    props.sendData
                      .find((elt) => elt !== undefined && elt.para === "table")
                      .date.split(" ")[1]
                )} ${
                  client.forecast?.settings?.active?.global
                    ? "par rapport au prévisionnel"
                    : "par rapport à " +
                      monthShortToLong(
                        props.sendData
                          .find((elt) => elt !== undefined && elt.para === "table")
                          .date.split(" ")[0]
                      ) +
                      " " +
                      (props.sendData
                        .find((elt) => elt !== undefined && elt.para === "table")
                        .date.split(" ")[1] -
                        1)
                } </span> <div classname="blue-bar space"/></div>`
            : undefined,
        img: htmlTable.find((elem) => elem.id === "yoy")?.html,
      },
      {
        text: showPara.find((obj) => obj.para === "table") ? "" : undefined,
        title: showPara.find((obj) => obj.para === "table") ? "table" : undefined,
        titletable: "cumul",
        titlehtml:
          props.sendData[
            props.sendData.findIndex((elt) => elt !== undefined && elt.para === "table")
          ] !== undefined
            ? client.pastYearComp
              ? `<div id="title"><span classname="section-title">Cumul ${
                  props.sendData
                    .find((elt) => elt !== undefined && elt.para === "table")
                    .date.split(" ")[1]
                }</span> <div classname="blue-bar space"/></div>`
              : `<div id="title"><span classname="section-title">Cumul ${
                  props.sendData
                    .find((elt) => elt !== undefined && elt.para === "table")
                    .date.split(" ")[1]
                } par rapport au cumul  ${
                  client.forecast?.settings?.active?.global
                    ? "prévisionnel"
                    : props.sendData
                        .find((elt) => elt !== undefined && elt.para === "table")
                        .date.split(" ")[1] - 1
                }</span> <div classname="blue-bar space"/></div>`
            : undefined,
        img: htmlTable.find((elem) => elem.id === "cumul")?.html,
      },
    ];

    props.sendData.forEach((elt) => {
      if (elt.para !== "table") {
        mailpara.push({
          text: data[elt.para] ? data[elt.para] : undefined,
          title: elt.para.split("-")[1],
          titlehtml: data[elt.para] ? getHtmlTitle(elt.para) : undefined,
          img: data[elt.para] ? elt.img.img : undefined,
          description: elt.popupText,
        });
      }
    });

    const email = {
      subject: subject,
      replyTo: user.email,
      intro: data.intro,
      recipient: data.recipient,
      cc: data.cc + sendCopy,
      user: user.firstName + " " + user.lastName,
      clientSiren: client.siren,
      analyzeMonth: props.date.month,
      analyzeYear: props.date.fiscal_year,
      color: accountingFirm.color || "#eaf4fe",
      sendToMcf: data.sendToMcf,
      sendToJalios: data.sendToJalios,
      para: mailpara,
    };

    (async () => {
      let res = await API.postAnalysisMail(email);
      if (res.status === 200) {
        API.postCollabTracker(clientId, {
          author: user.firstName + " " + user.lastName,
          action: "ANALYSIS_MAIL",
          recipient: data.recipient ? data.recipient.split(";") : [],
          cc:
            data.cc || sendCopy
              ? (data.cc + sendCopy).split(";").filter(function (item) {
                  return item !== "undefined";
                })
              : [],
          analysisMonth: monthNumToName(props.date.month),
        });
        logAction(80, 1, clientId);
        openNotification("success", "L'analyse a bien été envoyée.");
        let resclient = await API.putClient(clientId, {
          "email.analysis": {
            lastEmailSent: dayjs(),
            recipient: data.recipient,
            cc: data.cc,
            prefSendCopy: data.copy,
            prefSendToMcf: data.sendToMcf,
          },
        });
        if (resclient.status === 201) {
          dispatch(
            updateClient({
              email: {
                ...client.email,
                analysis: { ...client.email?.analysis, lastEmailSent: dayjs() },
              },
            })
          );
          setSending(false);
          data.sendToMcf && logAction(222, 1, clientId);
          props.setSelectedMailType("");
        }
      } else {
        openNotification("error", "Erreur lors de l'envoi du mail.");
        setSending(false);
      }
    })();
  };

  const ccInput = (
    <>
      <Form.Item name="cc" label="CC :" className="cc-item">
        <Input
          className={ccAlert ? "to comma-detected" : "custom-input-mail cc"}
          onChange={(e) => checkEmail(e, "cc")}
        />
      </Form.Item>
      <MinusCircleOutlined
        className="remove-cc"
        style={{ margin: "0 8px" }}
        onClick={() => {
          setCC(false);
        }}
      />
    </>
  );

  useEffect(() => {
    let initialvaluesobj = {
      size: componentSize,
      sender: "noreply@sesha.expert",
      recipient: client.email?.analysis?.recipient || "",
      cc: client.email?.analysis?.cc || "",
      copy: client.email?.analysis?.prefSendCopy,
      sendToMcf: client.email?.analysis?.prefSendToMcf,
    };

    props.sendData.forEach((elt) => {
      if (elt.para !== "table") {
        initialvaluesobj[elt.para] = elt.html.innerHTML
          .replaceAll('class="color-red"', 'style="background-color: #FFEED6;"')
          .replaceAll('class="color-green"', 'style="background-color: #D9FFED;"')
          .replaceAll('<span style="display:none;">. </span>', "");
      }
    });
    setInitFormValues(initialvaluesobj);

    /* function switch_index(arr, oldi, newi) {
      if (newi >= arr.length) {
        var k = newi - arr.length + 1;
        while (k--) {
          arr.push(undefined);
        }
      }
      arr.splice(newi, 0, arr.splice(oldi, 1)[0]);
    }

    switch_index(
      props.sendData,
      props.sendData.findIndex((elt) => elt !== undefined && elt.para === "table"),
      0
    );
    switch_index(
      props.sendData,
      props.sendData.findIndex((elt) => elt !== undefined && elt.para === "editca"),
      1
    );
    switch_index(
      props.sendData,
      props.sendData.findIndex((elt) => elt !== undefined && elt.para === "editmarge"),
      2
    );
    switch_index(
      props.sendData,
      props.sendData.findIndex((elt) => elt !== undefined && elt.para === "editmassesal"),
      3
    );
    switch_index(
      props.sendData,
      props.sendData.findIndex((elt) => elt !== undefined && elt.para === "edittreso"),
      4
    );*/
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading ? null : (
        <div className="mail-analysis" ref={mail}>
          <Form
            form={form}
            layout="vertical"
            initialValues={initFormValues}
            onValuesChange={onFormLayoutChange}
            size={componentSize}
            name="email-form"
            onFinish={onSubmit}
          >
            <Row justify="center">
              <Col xs={22} sm={22} md={22} lg={22} xl={22} className="mail-box">
                <div style={{ position: "absolute" }}>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="submit-mail-btn link-button call-action-btn send-mail-btn"
                      loading={htmlTable.length === 0 || sending}
                      disabled={htmlTable.length === 0}
                      icon={htmlTable.length === 0 ? "" : <MailOutlined />}
                    >
                      Envoyer
                    </Button>
                  </Form.Item>
                </div>
                <Row justify="center" className="mail-row" style={{ marginTop: "10px" }}>
                  <Col xs={22} sm={22} md={13} lg={13} xl={13}>
                    <Form.Item name="sender" label="De :">
                      <Input disabled className="form-input from" />
                    </Form.Item>
                    <Form.Item
                      name="recipient"
                      label="À (séparer les destinataires par un point-virgule) :"
                      rules={[
                        {
                          required: true,
                          message: "Veuillez renseigner un destinataire",
                        },
                      ]}
                    >
                      <Input
                        className={recepientAlert ? "to comma-detected" : "custom-input-mail to"}
                        onChange={(e) => checkEmail(e, "recipient")}
                      />
                    </Form.Item>
                    {!addCC ? (
                      <Button
                        className="other-action-btn add-cc"
                        onClick={() => {
                          setCC(true);
                        }}
                      >
                        Ajouter un destinataire en CC <PlusCircleOutlined className="add-icon" />
                      </Button>
                    ) : (
                      ccInput
                    )}
                    <Form.Item name="copy" valuePropName="checked">
                      <Checkbox>Recevoir une copie</Checkbox>
                    </Form.Item>
                    {client.mcfState === 0 ? (
                      <Form.Item
                        name="sendToMcf"
                        valuePropName="checked"
                        style={{
                          marginTop: "-23px",
                        }}
                      >
                        <Checkbox>Envoyer l'analyse dans MyCompanyFiles</Checkbox>
                      </Form.Item>
                    ) : null}
                    {[
                      "92ee310c3f18cb250992e411669def76",
                      "8489861cb7a15a0626fe092d6dfb98ed",
                    ].includes(accountingFirm._id) ? (
                      <Form.Item
                        name="sendToJalios"
                        valuePropName="checked"
                        style={{
                          marginTop: "-23px",
                        }}
                      >
                        <Checkbox>Envoyer l'analyse dans le Portail Pyramide Conseils</Checkbox>
                      </Form.Item>
                    ) : null}

                    {showEditSubject || !editSubject ? (
                      <div className="edit-btn">
                        <EditIcon />
                      </div>
                    ) : null}
                    {!editSubject ? (
                      <Button
                        icon={<CheckOutlined />}
                        className="validate-edit-btn"
                        onClick={() => {
                          setShowEditSubject(false);
                          setEditSubject(true);
                        }}
                      />
                    ) : null}
                    <div
                      onMouseOver={(e) => {
                        e.preventDefault();
                        setShowEditSubject(true);
                      }}
                      onMouseOut={(e) => {
                        e.preventDefault();
                        setShowEditSubject(false);
                      }}
                      onClick={(e) => {
                        setEditSubject(false);
                      }}
                    >
                      {" "}
                      <Form.Item
                        name="subject"
                        className="form-subject"
                        label="Objet :"
                        rules={[
                          {
                            required: false,
                            message: "Merci de renseigner l'objet du mail",
                          },
                        ]}
                      >
                        <Input
                          className="form-input subject "
                          value={subject}
                          onChange={(e) => {
                            setSubject(e.target.value);
                          }}
                        />{" "}
                      </Form.Item>
                    </div>

                    <div className="info-mail">
                      <InfoCircleOutlined style={{ color: "#a2b8ce" }} />
                      <div className="mail-info-select">
                        <div className="info-select-text">
                          Pratique, complétez ou modifiez un paragraphe en cliquant sur le texte
                        </div>
                      </div>
                    </div>
                    {editShow || !editIntro ? (
                      <div className="edit-btn">
                        <EditIcon />
                      </div>
                    ) : null}
                    {!editIntro ? (
                      <Button
                        icon={<CheckOutlined />}
                        className="validate-edit-btn"
                        onClick={() => {
                          setEditShow(false);
                          setEditIntro(true);
                        }}
                      />
                    ) : null}
                    <div
                      className={editShow && editIntro ? "intro-blue blue-edit" : "intro-blue"}
                      onMouseOver={(e) => {
                        e.preventDefault();
                        setEditShow(true);
                      }}
                      onMouseOut={(e) => {
                        e.preventDefault();
                        setEditShow(false);
                      }}
                      onClick={(e) => setEditIntro(false)}
                    >
                      <Form.Item
                        name="intro"
                        className={!editIntro ? "text-tosend intro" : "test intro"}
                        initialValue={intro}
                      >
                        <TextEditorAnalysis {...props} />
                      </Form.Item>
                      {logo && user.signatureDisplayLogo ? (
                        <img src={logo} alt="logo" className="logo" />
                      ) : null}
                      {user.signatureImage && signatureImage ? (
                        <div>
                          <img
                            src={signatureImage}
                            alt="signature collaborateur"
                            style={{ maxWidth: "380px", maxHeight: "300px" }}
                          />
                        </div>
                      ) : null}
                    </div>

                    {props.sendData.map((elt, index) => {
                      if (elt !== undefined && elt.para === "table") {
                        return showPara.find((obj) => obj.para === elt.para).show ? (
                          <div key={index} className="img-content">
                            <Button
                              icon={<DeleteOutlined style={{ color: "#C0C0C0" }} />}
                              className="delete-btn-mail"
                              onClick={(e) => {
                                e.preventDefault();
                                logAction(223, 1, clientId);
                                setShowPara([...showPara, { para: elt.para, show: false }]);
                              }}
                            />
                            <AnalyseTableMail
                              {...props}
                              date={elt.date}
                              html={(data) => setHtmlTable(data)}
                            />
                          </div>
                        ) : null;
                      } else {
                        if (elt !== undefined) {
                          return showPara.find((obj) => obj.para === elt.para).show ? (
                            <>
                              <div key={index} id={"mail-para-" + index}>
                                <div className="mail-para">
                                  <div>
                                    <Text className="section-title">{elt.title}</Text>
                                    <Button
                                      icon={<DeleteOutlined style={{ color: "#C0C0C0" }} />}
                                      className="delete-btn-mail"
                                      onClick={(e) => {
                                        logAction(223, 1, clientId);
                                        e.preventDefault();
                                        const array = [...showPara];
                                        array.find((item) => item.para === elt.para).show = false;
                                        setShowPara(array);
                                      }}
                                    />
                                    <div className="blue-bar space"></div>

                                    {elt.popupText ? (
                                      <i>
                                        <span className="popupText">{elt.popupText}</span>
                                      </i>
                                    ) : null}
                                    {showEditPara.find((obj) => obj.para === elt.para).showedit ||
                                    !editPara.find((obj) => obj.para === elt.para).edit ? (
                                      <div
                                        className={
                                          !elt.popupText ? "edit-btn space-popup" : "edit-btn"
                                        }
                                      >
                                        <EditIcon />
                                      </div>
                                    ) : null}
                                    {!editPara.find((obj) => obj.para === elt.para).edit ? (
                                      <>
                                        <Button
                                          icon={<CheckOutlined />}
                                          className={
                                            !elt.popupText
                                              ? "validate-edit-btn space-popup"
                                              : "validate-edit-btn"
                                          }
                                          onClick={() => {
                                            const array1 = [...editPara];
                                            const array2 = [...showEditPara];

                                            array1.find(
                                              (item) => item.para === elt.para
                                            ).edit = true;
                                            setEditPara(array1);

                                            array2.find(
                                              (item) => item.para === elt.para
                                            ).showedit = false;
                                            setShowEditPara(array2);
                                          }}
                                        />
                                      </>
                                    ) : null}
                                    <div
                                      className={
                                        showEditPara.find((obj) => obj.para === elt.para)
                                          .showedit &&
                                        editPara.find((obj) => obj.para === elt.para).edit
                                          ? "intro-blue blue-edit"
                                          : "intro-blue"
                                      }
                                      onMouseOver={(e) => {
                                        e.preventDefault();
                                        const array = [...showEditPara];
                                        array.find(
                                          (item) => item.para === elt.para
                                        ).showedit = true;
                                        setShowEditPara(array);
                                      }}
                                      onMouseOut={(e) => {
                                        e.preventDefault();
                                        const array = [...showEditPara];
                                        array.find(
                                          (item) => item.para === elt.para
                                        ).showedit = false;
                                        setShowEditPara(array);
                                      }}
                                      onClick={(e) => {
                                        const array = [...editPara];
                                        array.find((item) => item.para === elt.para).edit = false;
                                        setEditPara(array);
                                      }}
                                    >
                                      <Form.Item
                                        key={elt.para}
                                        name={elt.para}
                                        className={
                                          !editPara.find((obj) => obj.para === elt.para).edit
                                            ? "text-tosend"
                                            : "test"
                                        }
                                      >
                                        <TextEditorAnalysis {...props} key={elt.para + index} />
                                      </Form.Item>
                                    </div>
                                  </div>
                                  <img src={elt.img?.img} alt="graph" />
                                </div>
                              </div>
                            </>
                          ) : null;
                        } else {
                          return null;
                        }
                      }
                    })}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </div>
      )}
    </>
  );
}

export default MailAnalysis;

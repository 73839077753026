import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Input } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import logAction from "utils/logActions";
import * as API from "../../../../api/API";
import { selectClient } from "../../../../slices/clientSlice";

const { TextArea } = Input;

function updateOperation(props, propertyToUpdate, value) {
  const categoryId = props.mode === 3 ? props.category.categoryId : props.category._id;
  const categoryToUpdate = props.data.find((cat) => cat._id === categoryId);

  if (!categoryToUpdate) {
    console.error("Category not found");
    return;
  }

  // Get the index of the operation to update
  const operationIndex = categoryToUpdate.operations.findIndex(
    (op) => op._id === props.operation._id
  );
  if (operationIndex === -1) {
    console.error("Operation not found");
    return;
  }

  // Perform the state update directly
  props.setAllData((prev) =>
    prev.map(
      (el) =>
        el.id === props.id
          ? {
              ...el,
              data: el.data.map(
                (cat) =>
                  cat._id === categoryId
                    ? {
                        ...cat,
                        operations: cat.operations.map((op, index) =>
                          index === operationIndex
                            ? { ...op, [propertyToUpdate]: value } // Update the specific property
                            : op
                        ),
                      }
                    : cat // Keep other categories unchanged
              ),
            }
          : el // Keep other elements unchanged
    )
  );
}

function CommentCollab(props) {
  const client = useSelector(selectClient);
  const [operation, setOperation] = useState({});
  const [commentCollab, setCommentCollab] = useState("");
  const [currentCommentCollab, setCurrentCommentCollab] = useState("");
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    setOperation(props.operation);
    setCommentCollab(props.operation?.commentCollab);
    setCurrentCommentCollab(props.operation?.commentCollab);
  }, [props.operation]);

  const updateCommentCollab = (value) => {
    (async () => {
      const res = await API.putOperation(client._id, encodeURIComponent(operation._id), {
        commentCollab: value || null,
      });
      if (res.status === 201) {
        console.log("Comment collab updated");

        updateOperation(props, "commentCollab", value);

        setCommentCollab(value);
        logAction(232, 1, client._id);
      } else console.log("Error");
    })();
  };

  const deleteCommentCollab = () => {
    (async () => {
      const res = await API.putOperation(client._id, encodeURIComponent(operation._id), {
        commentCollab: null,
      });
      if (res.status === 201) {
        console.log("Comment collab deleted");
        updateOperation(props, "commentCollab", "");

        setCommentCollab("");
      } else console.log("Error");
    })();
  };

  return (
    <div className="comment-div">
      {commentCollab || editing ? (
        <>
          <div
            style={{
              display: "flex",
              // alignItems: !editing ? "center" : "",
              paddingTop: "0px",
              paddingBottom: "20px",
            }}
          >
            {!editing ? (
              <>
                <b>Commentaire cabinet :</b>
                <span
                  className="commentaire-cabinet-span"
                  style={{
                    paddingRight: "10px",
                    paddingLeft: "3px",
                  }}
                >
                  {commentCollab}
                </span>
              </>
            ) : (
              <span className="commentaire-cabinet-edit-span">
                <TextArea
                  className="other-edit"
                  defaultValue={commentCollab}
                  placeholder="Ajouter un commentaire"
                  onChange={(e) => {
                    setCurrentCommentCollab(e.target.value);
                  }}
                  onPressEnter={(e) => {
                    setEditing(false);
                    updateCommentCollab(e.target.value);
                  }}
                />
                <span className="help-txt">Appuyez sur « Entrée » pour valider</span>
              </span>
            )}
            <Button
              className="other-action-btn"
              shape="circle"
              icon={<EditOutlined />}
              onClick={(e) => {
                if (editing) {
                  updateCommentCollab(currentCommentCollab);
                  setEditing(false);
                } else {
                  setEditing(true);
                }
              }}
            />
            <Button
              className="other-action-btn other-delete-btn"
              shape="circle"
              icon={<DeleteOutlined />}
              onClick={(e) => {
                deleteCommentCollab();
                setEditing(false);
              }}
            />
          </div>
        </>
      ) : (
        <Button
          type="text"
          className="add-comment my-collapsible__toggle"
          onClick={(e) => {
            setEditing(true);
          }}
        >
          Ajouter un commentaire
        </Button>
      )}
    </div>
  );
}

export default CommentCollab;

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import React from "react";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import { createRoot } from "react-dom/client";
import "./assets/fonts/Roboto-Regular.ttf";
import "./global.css";

// import "antd/dist/antd.css";

import { detect } from "detect-browser";
import jwt_decode from "jwt-decode";
import { Provider } from "react-redux";
import App from "./app/App";
import { store } from "./app/store";
import * as serviceWorker from "./serviceWorker";
import { fetchAccountingFirmAsync } from "./slices/accountingFirmSlice";
import { fetchUserAsync } from "./slices/userSlice";
import InternetExplorer from "./utils/InternetExplorer";

const browser = detect();
const decodedToken = localStorage.getItem("token")
  ? jwt_decode(localStorage.getItem("token"))
  : null;

const LightTheme = React.lazy(() => import("./themes/light"));
const DarkTheme = React.lazy(() => import("./themes/dark"));

const ThemeSelector = ({ children }) => {
  const CHOSEN_THEME = localStorage.getItem("theme") || "LIGHT";
  return (
    <>
      <React.Suspense fallback={<></>}>
        {CHOSEN_THEME === "LIGHT" && <LightTheme />}
        {CHOSEN_THEME === "DARK" && <DarkTheme />}
      </React.Suspense>
      {children}
    </>
  );
};

if (decodedToken) {
  store.dispatch(fetchAccountingFirmAsync(decodedToken.accountingFirmId));
  store.dispatch(fetchUserAsync(decodedToken.id));
}

Sentry.init({
  dsn:
    process.env.REACT_APP_ENV !== "local"
      ? "https://77714e7be6624e63b34eec11a8364699@o263241.ingest.sentry.io/6041131"
      : "",
  environment: process.env.REACT_APP_ENV,
  release: "sesha-interface-front@" + process.env.REACT_APP_VERSION,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: process.env.REACT_APP_ENV === "prod" ? 0.01 : 0,
});

if (decodedToken) {
  Sentry.configureScope((scope) => {
    scope.setUser({
      id: decodedToken?.id,
      email: decodedToken?.email,
      role: decodedToken?.newRole,
      accountingFirmId: decodedToken?.accountingFirmId,
    });
    scope.setTag("firm", decodedToken?.accountingFirmId);
  });
}

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <>
    {browser.name === "ie" ? (
      <InternetExplorer />
    ) : (
      <ThemeSelector>
        <Provider store={store}>
          <App />
        </Provider>
      </ThemeSelector>
    )}
  </>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import { LinkOutlined } from "@ant-design/icons";
import { Table, Typography } from "antd";
import { useEffect, useState } from "react";

import * as API from "../../../api/API";
import LoaderRaw from "../../../utils/LoaderRaw";

const { Text } = Typography;

function Hubspot() {
  const [firmList, setFirmList] = useState([]);
  const [dataSource, setDataSource] = useState();

  useEffect(() => {
    (async () => {
      let deals = await API.getHubspotDeals();
      deals = await deals.json();
      setDataSource(
        deals.filter(
          (el) =>
            el.properties.dealstage ===
            (process.env.REACT_APP_ENV !== "prod" ? "48166588" : "5316627")
        )
      );
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      let firms = await API.getAccountingFirms();
      firms = await firms.json();
      const merge = firms;
      merge.forEach(function (obj) {
        obj.key = obj._id;
      });
      console.log( merge.map( r => r.key).join(', '))
      setFirmList(merge);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      width: "40px",
      align: "center",
      render: (text, record) => (
        <a
          href={
            "https://app-eu1.hubspot.com/contacts/" +
            process.env.REACT_APP_HUBSPOT_ID +
            "/deal/" +
            record.id
          }
          target="_blank"
          rel="noreferrer"
        >
          <LinkOutlined />
        </a>
      ),
    },
    {
      title: "Nom transaction Hubspot",
      sorter: (a, b) => a.properties.dealname?.localeCompare(b.properties.dealname),
      render: (text, record) => record.properties.dealname,
    },
    {
      title: "ID Sesha renseigné",
      align: "center",
      sorter: (a, b) => a.properties.id_sesha?.localeCompare(b.properties.id_sesha),
      render: (text, record) => record.properties.id_sesha,
    },
    {
      title: "Cabinet Sesha correspondant",
      render: (text, record) => firmList.find((el) => el.key === record.properties.id_sesha)?.name,
    },
    {
      title: "Offre actuelle ProAbono",
      render: (text, record) => {
        const offers = firmList.find(
          (el) => el.key === record.properties.id_sesha
        )?.currentOfferName;
        return offers ? offers.join(", ") : "";
      },
    },
  ];

  return (
    <div className="userDashboard">
      <br />
      <Text className="section-title">
        Transactions Hubspot - Abonnements signés ({dataSource?.length})
      </Text>
      <div className="blue-bar"></div>
      <br />

      <Table
        columns={columns}
        dataSource={dataSource}
        size="small"
        bordered
        pagination={{ defaultPageSize: 10, showSizeChanger: true }}
        loading={{ indicator: <LoaderRaw />, spinning: !dataSource }}
      />
    </div>
  );
}

export default Hubspot;

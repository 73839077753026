/* eslint-disable jsx-a11y/anchor-is-valid */
import { PlusCircleOutlined } from "@ant-design/icons";
import { Button, Col, Row } from "antd";
import { useState } from "react";
import { ReactComponent as Rocket } from "../../../assets/images/rocket-nice.svg";
import { ReactComponent as ExampleTable } from "../../../assets/images/tableauexample.svg";
import { ReactComponent as Guys } from "../../../assets/images/vpf_modal_1.svg";
import ModalIndex from "./ModalIndex";
import templateList from "./templateList";
import "./vpf.css";

function Noreports(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [report, setReport] = useState();
  const [currentComponent, setCurrentComponent] = useState();

  const onClickStart = () => {
    setIsModalOpen(true);
    setReport("");
    setCurrentComponent("SelectPreset");
  };

  const onClickPreset = (colors, presetId) => {
    setIsModalOpen(true);
    setReport({ ...templateList.find((el) => el.id === presetId), colors });
    setCurrentComponent("EditReport");
  };

  return (
    <>
      <div className="vpf-header vpf-homepage">
        <Row>
          <Col span={10}>
            <span className="homepage-title espacement-ligne">
              Vos dossiers clients méritent toute votre attention 👍
            </span>
          </Col>
        </Row>
        <Row>
          <Button className="homepage-start-button" onClick={onClickStart}>
            <span>
              <Rocket className="homepage-start-button-icon" />
            </span>
            Commencer maintenant
          </Button>
          <ModalIndex
            {...props}
            component={currentComponent}
            report={report}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            type={"create"}
          />
        </Row>
        <Row>
          <Col span={10}>
            <div className="homepage-second-title">Comment ça marche?</div>
            <div style={{ fontSize: "18px" }} className="espacement-ligne">
              En quelques clics, créez des tableaux personnalisés et identifiez les actions
              prioritaires à mener.{" "}
            </div>
          </Col>
        </Row>

        <Row>
          <Col span={14} className="homepage-select-preset">
            <br />
            <Button
              className="modal-1-button homepage-analysis-example-button vpf-homepage-point"
              style={{ background: "#DDDCFF" }}
              onClick={() => onClickPreset({ light: "#DDDCFF", dark: "#370092" }, 17)}
            >
              <PlusCircleOutlined
                style={{ color: "#370092", marginLeft: "0px" }}
                className="modal-1-showbutton"
              />
              <span className="modal-1-button-text" style={{ color: "#370092" }}>
                {templateList.find((el) => el.id === 17).name}
              </span>
            </Button>
            <Button
              className="modal-1-button homepage-analysis-example-button vpf-homepage-point"
              style={{ background: "#FFEDDC" }}
              onClick={() => onClickPreset({ light: "#FFEDDC", dark: "#925700" }, 5)}
            >
              <PlusCircleOutlined
                style={{ color: "#925700", marginLeft: "0px" }}
                className="modal-1-showbutton"
              />
              <span className="modal-1-button-text" style={{ color: "#925700" }}>
                {templateList.find((el) => el.id === 5).name}
              </span>
            </Button>
            <Button
              className="modal-1-button homepage-analysis-example-button vpf-homepage-point"
              style={{ background: "#FFDCDC" }}
              onClick={() => onClickPreset({ light: "#FFDCDC", dark: "#920900" }, 18)}
            >
              <PlusCircleOutlined
                style={{ color: "#920900", marginLeft: "0px" }}
                className="modal-1-showbutton"
              />
              <span className="modal-1-button-text" style={{ color: "#920900" }}>
                {templateList.find((el) => el.id === 18).name}
              </span>
            </Button>
            <Button
              className="modal-1-button homepage-analysis-example-button vpf-homepage-point"
              style={{ background: "#DCFFE4" }}
              onClick={() => onClickPreset({ light: "#DCFFE4", dark: "#00920F" }, 16)}
            >
              <PlusCircleOutlined
                style={{ color: "#00920F", marginLeft: "0px" }}
                className="modal-1-showbutton"
              />
              <span className="modal-1-button-text" style={{ color: "#00920F" }}>
                {templateList.find((el) => el.id === 16).name}
              </span>
            </Button>

            <Button
              className="modal-1-button homepage-analysis-example-button vpf-homepage-point"
              style={{ background: "#DCF7FF" }}
              onClick={() => onClickPreset({ light: "#DCF7FF", dark: "#000F92" }, 1)}
            >
              <PlusCircleOutlined
                style={{ color: "#000F92", marginLeft: "0px" }}
                className="modal-1-showbutton"
              />
              <span className="modal-1-button-text" style={{ color: "#000F92" }}>
                {templateList.find((el) => el.id === 1).name}
              </span>
            </Button>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            {" "}
            <div className="homepage-second-title espacement-ligne">
              Utilisez nos tableaux pré-configurés ou concevez les vôtres !
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={10}>
            <div style={{ marginTop: "10px" }}>
              <ExampleTable style={{ position: "relative", right: "10px" }} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={10} style={{ fontSize: "18px" }}>
            <span>
              Créez dès maintenant{" "}
              <a style={{ color: "#4569F8", fontWeight: "600" }} onClick={onClickStart}>
                votre premier tableau.
              </a>
            </span>
          </Col>
        </Row>
        <Row>
          <Col span={8} className="homepage-guys">
            <Guys />
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Noreports;

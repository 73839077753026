import { Checkbox } from "antd";
import { EFeatures } from "enums/EFeatures";
import { useState } from "react";
import { useSelector } from "react-redux";
import openNotification from "utils/notification";
import { renewToken } from "utils/security";
import * as API from "../../../api/API";
import { selectUser } from "../../../slices/userSlice";

function CheckboxFeat(props) {
  const user = useSelector(selectUser);

  const [checked, setChecked] = useState(props.defaultChecked);

  const onChange = async (roleId, feature, e) => {
    if (
      feature === EFeatures.MANAGE_USERS_AND_RIGHTS &&
      !e.target.checked &&
      user.newRole === roleId
    ) {
      openNotification("error", "Impossible de modifier ce paramètre sur votre propre rôle");
      return;
    }
    setChecked(e.target.checked);
    const newState = e.target.checked
      ? [...props.roleFeatures, feature]
      : props.roleFeatures?.filter((el) => el !== feature);
    props.setRoleFeatures(newState);
    await API.putRole(roleId, { features: newState });
    renewToken();
  };

  return (
    <Checkbox checked={checked} onChange={(e) => onChange(props.role._id, props.feature._id, e)}>
      {props.feature.label}
    </Checkbox>
  );
}

export default CheckboxFeat;

import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Col, Modal, Row } from "antd";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import "./Analysis.css";

function CustomPrompt(props) {
  const { when } = props;

  const history = useHistory();

  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState("");

  const unblockRef = useRef();

  function handleShowModal() {
    setShowPrompt(true);
  }

  function onCancel() {
    setShowPrompt(false);
  }

  useEffect(() => {
    unblockRef.current = history.block((location) => {
      if (when) {
        setCurrentPath(location.pathname);
        handleShowModal();
        return false;
      }
      return true;
    });
    return () => {
      unblockRef.current && unblockRef.current();
    };
  }, [when]);

  const handleConfirm = () => {
    if (unblockRef) {
      unblockRef.current();
    }
    setShowPrompt(false);
    history.push(currentPath);
  };

  return (
    <Modal
      className="edit-user-modal"
      title=""
      open={showPrompt}
      onCancel={onCancel}
      footer={null}
      closable={false}
      maskClosable={false}
    >
      <>
        <Row>
          <Col span={1}>
            <ExclamationCircleOutlined className="prompt-icon-custom" />
          </Col>
          <Col span={23}>
            <div className="prompt-content">
              Attention ! Un mail est en cours d’édition sur ce dossier client. Si vous quittez ce
              dossier, les modifications effectuées sur celui-ci seront perdues. Êtes-vous sûr de
              vouloir quitter la page ?
            </div>
            <div className="prompt-btns">
              <Button className="other-action-btn" onClick={onCancel}>
                Rester sur le dossier
              </Button>
              <Button className="call-action-btn" onClick={handleConfirm}>
                Quitter
              </Button>
            </div>
          </Col>
        </Row>
      </>
    </Modal>
  );
}

export default CustomPrompt;

export class TimeMeasurement {
  constructor() {
    this.startTime = Date.now();
    this.endTime = Date.now();
  }

  start() {
    this.startTime = Date.now();
  }

  stop() {
    this.endTime = Date.now();
  }

  getElapsedTime() {
    return Date.now() - this.startTime;
  }
}

import React from "react";
import "./Alert.css";
import LogoSesha from "../../assets/images/logo-sesha.png";

function Alert(props) {
  return (
    <article className="alert-component">
      <div className="alert-component-sub">
        <span className="logo-sesha">
          <img src={LogoSesha} alt="cloud" height={"147px"} width={"216px"} />
        </span>
        <h1>{props.title}</h1>
        <div>
          <p>{props.text}</p>
        </div>
      </div>
    </article>
  );
}

export default Alert;

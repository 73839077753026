import { Form, Row, Select, Typography } from "antd";
import { useEffect, useState } from "react";
import * as API from "../../../../api/API";
import SentryTable from "../SentryTable";
import ClientTable from "./ClientTable";
import FirmsTable from "./FirmsTable";
import LastUpdateTable from "./LastUpdateTable";
import MonitoringTable from "./MonitoringTable";
import UsersTable from "./UsersTable";

const { Text } = Typography;

const firmLabel = (firm) => firm.name;
const userLabel = (user) => user.firstName + " " + user.lastName;
const clientLabel = (client) => client.name;

function Support() {
  const [form] = Form.useForm();

  const [accFirms, setAccFirms] = useState();
  const [firmClients, setFirmClients] = useState();
  const [firmUsers, setFirmUsers] = useState();

  const [currentAccFirm, setCurrentAccFirm] = useState();

  const [displayedClients, setDisplayedClients] = useState();
  const [displayedUsers, setDisplayedUsers] = useState();
  const [loadingDisplayedClients, setLoadingDisplayedClients] = useState(true);
  const [loadingDisplayedUsers, setLoadingDisplayedUsers] = useState(true);

  useEffect(() => {
    (async () => {
      let res = await API.getAccountingFirms();
      res = await res.json();
      setAccFirms(res.sort((fa, fb) => firmLabel(fa).localeCompare(firmLabel(fb))));
    })();
  }, []);
  
  // use both next use effects to do the first firm selection and user or client selection faster than selecting them manually
  useEffect(() => {
    if (false && accFirms && currentAccFirm === undefined) {
      let value = "618cc453131eed6438ae94cd0aff1339" //"618cc453131eed6438ae94cd0aff1339";
      onChangeAccFirm(undefined, { value: value });
      form.setFieldsValue({
        accountingFirmItem: { label: accFirms.find((f) => f._id === value).name, value: value },
      });
    }
  }, [accFirms]);

  useEffect(() => {
    if (firmClients || firmUsers) {
      // select either client or user id, but you can't do both
      //let value = "6728cfae58140d8b32031941"; onChangeClient(undefined, { value: value}); form.setFieldsValue({ clientItem: { label: firmClients.find(c => c._id === value).name, value}});
      //let value = "671b546627234c2b7a601d47"; onChangeUser(undefined, { value: value}); form.setFieldsValue({ userItem: { label: firmUsers.find(c => c._id === value).firstName, value}});
    }
  }, [firmClients, firmUsers]);

  useEffect(() => {
    // look for a new displayed list that was put in the form and not computed from the clients list change
    if (displayedClients && form.getFieldValue("clientItem") !== null) {
      setLoadingDisplayedUsers(false);
      setDisplayedUsers(
        displayedClients.flatMap((selectedClient) => {
          if (currentAccFirm.connectedRights) {
            // all if it's an admin or only its clients
            return firmUsers.filter(
              (u) =>
                checkRightsForAll(u) ||
                selectedClient.collaboratorsIdConnected?.length === 0 ||
                selectedClient.collaboratorsIdConnected?.includes(u._id)
            );
          } else {
            // all if it's an admin or only its clients
            return firmUsers.filter(
              (u) =>
                checkRightsForAll(u) ||
                selectedClient.collaboratorsId?.length === 0 ||
                selectedClient.collaboratorsId?.includes(u._id)
            );
          }
        })
      );
    }
  }, [currentAccFirm, displayedClients]);

  useEffect(() => {
    // look for a new displayed users list that was in the form and not computed from the clients list change
    if (displayedUsers && form.getFieldValue("userItem") !== null) {
      setLoadingDisplayedClients(false);
      setDisplayedClients(null);
      /*
        displayedUsers.flatMap((selectedUser) => {
          if (currentAccFirm.connectedRights) {
            // all if it's an admin or only its clients
            return checkRightsForAll(selectedUser)
              ? firmClients
              : firmClients.filter(
                  (c) =>
                    c.collaboratorsIdConnected?.length === 0 ||
                    c.collaboratorsIdConnected?.includes(selectedUser._id)
                );
          } else {
            // all if it's an admin or only its clients
            return checkRightsForAll(selectedUser)
              ? firmClients
              : firmClients.filter(
                  (c) =>
                    c.collaboratorsId?.length === 0 || c.collaboratorsId?.includes(selectedUser._id)
                );
          }
        })
      );
      */
    }
  }, [currentAccFirm, displayedUsers]);

  const onChangeAccFirm = (_, options) => {
    (async () => {
      let selectedAccFirmId = options.value;
      let selectedAccFirm = accFirms.find((f) => f._id === selectedAccFirmId);

      setDisplayedClients(null);
      setDisplayedUsers(null);

      form.setFieldsValue({ clientItem: null });
      form.setFieldsValue({ userItem: null });

      // look for the necessary data for this firm
      let dataFetch = [
        API.getClientsByAccountingFirmId(selectedAccFirmId, {
          projection: {
            siren: 1,
            dataTuning: 1,
            templates: 1,
          },
        }),
        API.getUsersByAccountingFirmId(selectedAccFirmId),
        API.getAnalysisConfigTemplates({ accountingFirmId: selectedAccFirmId }),
        API.getClientsRemoteId(selectedAccFirmId),
        API.getRoles({ accountingFirmId: selectedAccFirmId }),
      ];

      // retrieve rights only when connected rights are enabled
      if (selectedAccFirm.connectedRights) {
        dataFetch.push(
          API.getDataUserRights({ accountingFirmId: selectedAccFirmId, synchro: false })
        );
        //dataFetch.push(API.getAllDataProviderCodes({ accountingFirmId: selectedAccFirmId }));
      }

      let allResults = await Promise.all(dataFetch);

      // last results would exist only if connected rights are enabled
      let [
        clients,
        users,
        allFirmTemplates,
        allFirmRemoteIds,
        roles,
        allFirmRights,
        //dataProviderCodes,
      ] = await Promise.all(allResults.map((res) => (res.status === 200 ? res.json() : undefined)));

      selectedAccFirm.roles = roles;
      selectedAccFirm.allImportedClients = allFirmRemoteIds;
      selectedAccFirm.allRights = allFirmRights || [];
      //selectedAccFirm.allExcludedClients = selectedAccFirm.excludedList.map(c => c.split("*****")[1]);
      //selectedAccFirm.allDataProviderCodes = dataProviderCodes?.codes;

      setFirmClients(clients.sort((ca, cb) => clientLabel(ca).localeCompare(clientLabel(cb))));

      setFirmUsers(users.sort((ua, ub) => userLabel(ua).localeCompare(userLabel(ub))));

      // look for each template in the client data
      for (let currentClient of clients) {
        currentClient.templatesData = {};

        for (let key of Object.keys(currentClient.templates || {})) {
          let template = allFirmTemplates?.find((t) => t._id === currentClient.templates[key]);
          if (template) {
            currentClient.templatesData[template.type] = template;
          }
        }
      }

      setCurrentAccFirm(selectedAccFirm);
    })();
  };

  const onChangeClient = (_, options) => {
    let selectedClientId = options.value;
    let selectedClient = firmClients.find((c) => c._id === selectedClientId);

    form.setFieldsValue({ userItem: null });
    setLoadingDisplayedUsers(true);

    // set clients to display, and let the use effect changes users
    setDisplayedClients([selectedClient]);
    setLoadingDisplayedClients(false);
  };

  function checkRightsForAll(user) {
    return (
      user.newRoleLabel === "Super Admin Tech" ||
      user.newRoleLabel === "Super Admin" ||
      user.newRoleLabel === "Admin"
    );
  }

  const onChangeUser = (_, options) => {
    let selectedUserId = options.value;
    let selectedUser = firmUsers.find((u) => u._id === selectedUserId);

    form.setFieldsValue({ clientItem: null });
    setLoadingDisplayedClients(true);

    // set users to display, and let the use effect changes users
    setDisplayedUsers([selectedUser]);
    setLoadingDisplayedUsers(false);
  };

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };

  return (
    <div>
      <div className="sesha-support">
        <Form form={form} {...layout} name="control-ref">
          <Form.Item name="accountingFirmItem" label={<b>Cabinet comptable</b>}>
            <Select
              style={{ marginLeft: "20px", width: "250px" }}
              showSearch
              placeholder="Choisir un cabinet"
              onChange={onChangeAccFirm}
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
              }
              options={accFirms?.map(({ _id, name }) => ({ value: _id, label: name }))}
              popupClassName="ant-select-dropdown-zindex"
              placement="bottomLeft"
              dropdownAlign={{ overflow: { adjustY: false } }}
            />
          </Form.Item>
          <Row>
            <Form.Item name="userItem" label={<b>Utilisateur</b>}>
              <Select
                showSearch
                placeholder="Choisir un utilisateur"
                onChange={onChangeUser}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                }
                options={firmUsers?.map((user) => ({ value: user._id, label: userLabel(user) }))}
                disabled={!currentAccFirm}
                popupClassName="ant-select-dropdown-zindex-test"
                placement="bottomLeft"
                dropdownAlign={{ overflow: { adjustY: false } }}
                style={{ width: "10vw" }}
              />
            </Form.Item>
            <Form.Item name="clientItem" label={<b>Client</b>}>
              <Select
                showSearch
                placeholder="Choisir un client"
                onChange={onChangeClient}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                }
                options={firmClients?.map((client) => ({
                  value: client._id,
                  label: clientLabel(client),
                }))}
                disabled={!currentAccFirm}
                popupClassName="ant-select-dropdown-zindex"
                placement="bottomLeft"
                dropdownAlign={{ overflow: { adjustY: false } }}
                style={{ width: "15vw" }}
              />
            </Form.Item>
          </Row>
        </Form>
      </div>

      {currentAccFirm ? (
        <div className="sesha-support-table">
          <span>
            <Text className="section-title">Récap' cabinets</Text>
            <div className="blue-bar"></div>
          </span>
          <FirmsTable currentAccFirm={currentAccFirm} />
        </div>
      ) : null}

      {displayedClients ? (
        <div className="sesha-support-table">
          <span>
            <Text className="section-title">Récap' dossiers</Text>
            <div className="blue-bar"></div>
          </span>
          <ClientTable
            currentAccFirm={currentAccFirm}
            displayedClients={displayedClients}
            loadingDisplayedClients={loadingDisplayedClients}
          />
        </div>
      ) : null}

      {displayedClients ? (
        <div className="sesha-support-table">
          <span>
            <Text className="section-title">Dernières mises à jour</Text>
            <div className="blue-bar"></div>
          </span>
          <LastUpdateTable
            currentAccFirmId={currentAccFirm._id}
            displayedClients={displayedClients}
          />
        </div>
      ) : null}

      {displayedUsers ? (
        <div className="sesha-support-table">
          <span>
            <Text className="section-title">Récap' utilisateurs</Text>
            <div className="blue-bar"></div>
          </span>
          <UsersTable
            currentAccFirm={currentAccFirm}
            displayedUsers={displayedUsers}
            allFirmClients={firmClients}
            loadingDisplayedUsers={loadingDisplayedUsers}
          />
        </div>
      ) : null}

      {displayedUsers && displayedClients ? (
        <div className="sesha-support-table">
          <span>
            <Text className="section-title">Historique des appels dossier</Text>
            <div className="blue-bar"></div>
          </span>
          <MonitoringTable
            currentAccFirmId={currentAccFirm._id}
            displayedUsers={displayedUsers}
            displayedClients={displayedClients}
          />
        </div>
      ) : null}

      {displayedClients ? (
        <div className="sesha-support-table">
          <span>
            <Text className="section-title">Dernières erreurs pour ce dossier</Text>
            <div className="blue-bar"></div>
          </span>
          <SentryTable currentAccFirm={currentAccFirm._id} displayedClients={displayedClients} />
        </div>
      ) : null}
    </div>
  );
}

export default Support;

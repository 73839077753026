import { Button, Progress } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as API from "../../../../api/API";
import { selectClient } from "../../../../slices/clientSlice";
import { getDownloadDate } from "../../../../utils/getDate";
import logAction from "../../../../utils/logActions";
import openNotification from "../../../../utils/notification";

function Header(props) {
  const clientId = props.match.params.clientId;

  const client = useSelector(selectClient);

  const [downloading, setDownloading] = useState(false);
  const [downloadPart, setDownloadPart] = useState(false);

  useEffect(() => {
    if (!clientId) return;
    (async () => {
      let res = await API.postVerifyDownload({ clientId });
      res = await res.json();
      setDownloadPart(res.downloadPart);
    })();
  }, [clientId]);

  async function downloadAll(allOp) {
    try {
      setDownloading(true);
      const res = await API.postDownloadAll({ clientId, allOp });
      if (res.status !== 200) {
        throw new Error("Failed to download");
      }

      const blob = await res.blob();
      const link = document.createElement("a");
      const zipName = (client.client?.name || "client").replace(/\s/g, "");
      const dateDownload = getDownloadDate();
      const fileName = `${zipName}_${dateDownload}.zip`;

      const url = URL.createObjectURL(blob);
      link.href = url;
      link.download = fileName;
      link.style.visibility = "hidden";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      URL.revokeObjectURL(url);

      setDownloadPart(false);
    } catch (error) {
      console.error("Error downloading file:", error);
      openNotification("error", "Erreur lors du téléchargement");
    } finally {
      setDownloading(false);
    }
  }

  return (
    <div id="received-container">
      <div className="small-card-received">
        <Progress
          type="circle"
          percent={100}
          format={() => props.nbrReceivedTotal || "N/A"}
          strokeColor={"#007543"}
          strokeWidth={"7"}
          size={80}
        />
        <p className="small-title" style={{ marginLeft: "4%" }}>
          Justificatifs déposés
        </p>
      </div>
      <div className="small-card-received" style={{ flexDirection: "column" }}>
        <span className="blue-text" style={{ color: "#007543" }}>
          {props.amountReceivedDoc
            .toFixed(2)
            .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
            .replace(".", ",") + " €" || "N/A"}
        </span>
        <span style={{ fontSize: "15px" }}>justifiés</span>
      </div>
      <div className="small-card-received" style={{ boxShadow: "none", flexDirection: "column" }}>
        {props.nbrReceivedTotal > 0 && (
          <Button
            className="call-action-btn"
            onClick={(e) => {
              e.preventDefault();
              console.log("download all");
              logAction(237, 1, client._id);
              downloadAll(true);
            }}
            loading={downloading}
            style={{ width: "100%" }}
          >
            Télécharger tous les justificatifs
          </Button>
        )}
        {downloadPart && (
          <Button
            className="other-action-btn"
            onClick={(e) => {
              e.preventDefault();
              console.log("download part");
              logAction(238, 1, client._id);
              downloadAll(false);
            }}
            loading={downloading}
            style={
              downloading
                ? { width: "108%", marginTop: "10px" }
                : { width: "100%", marginTop: "10px" }
            }
          >
            Télécharger uniquement les nouveaux justificatifs
          </Button>
        )}
      </div>
    </div>
  );
}

export default Header;

import React, { useState, useEffect } from "react";
import { Button, Switch } from "antd";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import * as API from "../../../api/API";
import openNotification from "../../../utils/notification";

const modules = {
  toolbar: [
    ["bold", "italic", "underline"],
    [{ header: 1 }, { header: 2 }],
    [{ list: "bullet" }],
    [{ align: [] }],
    [
      {
        color: [],
      },
    ],
  ],
};
const formats = [
  "bold",
  "italic",
  "underline",
  "color",
  "list",
  "bullet",
  "indent",
  "align",
  "header",
];

function SeshaParams() {
  const [text, setText] = useState();
  const [newsDisplay, setNewsDisplay] = useState();

  useEffect(() => {
    (async () => {
      let res = await API.getNewsText("news");
      res = await res.json();
      setText(res.text);
      setNewsDisplay(res.active);
    })();
  }, []);

  const submitText = () => {
    (async () => {
      const res = await API.postNewsText("news", {
        text,
      });
      if (res.status === 200) openNotification("success", "Modifications enregistrées");
      else openNotification("error", "Erreur lors de l'enregistrement du nouveau texte");
    })();
  };

  const onNewsSwitchChange = (checked) => {
    setNewsDisplay(checked);

    (async () => {
      const res = await API.postNewsText("news", {
        active: checked,
      });
      if (res.status === 200) openNotification("success", "Modifications enregistrées");
      else openNotification("error", "Erreur");
    })();
  };

  return (
    <div className="sesha-params">
      <div className="sesha-params-active">
        <b>Activer l'affichage de l'encart nouveautés</b>
        <Switch checked={newsDisplay} onChange={onNewsSwitchChange} />
      </div>
      {text ? (
        <ReactQuill
          theme={"snow"}
          defaultValue={text}
          modules={modules}
          formats={formats}
          onChange={(value) => setText(value)}
        />
      ) : null}

      <Button className="call-action-btn sesha-params-submit-button" onClick={submitText}>
        Valider
      </Button>
    </div>
  );
}

export default SeshaParams;

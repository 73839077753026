import { DownloadOutlined, HistoryOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useState } from "react";

import * as API from "../../../../api/API";

import openNotification from "../../../../utils/notification";
import Tracking from "../../../tracking/Tracking";

function ClientTableDownloadButtons(props) {
  const [buttonLoadingFEC, setButtonLoadingFEC] = useState(false);
  const [buttonLoadingS3, setButtonLoadingS3] = useState(false);

  const [showTracking, setShowTracking] = useState(false);

  const downloadFec = () => {
    (async () => {
      setButtonLoadingFEC(true);
      let res = await API.postExportFec({
        accountingFirmId: props.currentAccFirmId,
        clientRemoteId: props.client.remoteId,
      });
      if (res.status === 500) openNotification("error", "Erreur lors du téléchargement des FEC");
      else {
        res = await res.blob();
        const url = URL.createObjectURL(res);
        const link = document.createElement("a");
  
        link.setAttribute("href", url);
        link.setAttribute(
          "download",
          "FEC - " + props.client.name + ".zip"
        );
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      setButtonLoadingFEC(false);
    })();
  };
  
  const downloadS3 = () => {
    (async () => {
      setButtonLoadingS3(true);
      let res = await API.getExportS3(props.client._id);
      if (res.status === 500)
        openNotification("error", "Erreur lors de la récupération dans le glacier");
      else {
        res = await res.blob();
        const url = URL.createObjectURL(res);
        const link = document.createElement("a");
  
        link.setAttribute("href", url);
        link.setAttribute(
          "download",
          "Fichiers - " + props.client.name + ".zip"
        );
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      setButtonLoadingS3(false);
    })();
  };
  
  return (
    <div className="dl-buttons"
    style={{
      display: "flex",
    }}>
      <Button
        className="call-action-btn"
        onClick={downloadFec}
        loading={buttonLoadingFEC}
        disabled={!props.client}
      >
        <DownloadOutlined />
        FEC
      </Button>
      <Button
        style={{ marginLeft: "10px" }}
        className="call-action-btn"
        onClick={downloadS3}
        loading={buttonLoadingS3}
        disabled={!props.client}
      >
        <DownloadOutlined /> Sauvegarde de fichiers
      </Button>
      <Button
        style={{ marginLeft: "10px" }}
        className="call-action-btn"
        onClick={() => setShowTracking(true)}
      >
        <HistoryOutlined /> Historique
      </Button>
      <Tracking
        showModal={showTracking}
        showModalTracking={(data) => setShowTracking(data)}
        clientId={props.client._id}
        source="support"
      />
    </div>
  );
}

export default ClientTableDownloadButtons;

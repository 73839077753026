import { useDroppable } from "@dnd-kit/core";
import { SortableContext, rectSortingStrategy } from "@dnd-kit/sortable";
import { Alert, Badge, Typography } from "antd";
import { useSelector } from "react-redux";
import { selectAccountingFirm } from "../../../../slices/accountingFirmSlice";

import IndicatorItem from "./IndicatorItem.js";

const { Text } = Typography;

const IndicatorsContainer = (props) => {
  const { id, items } = props;
  const accountingFirm = useSelector(selectAccountingFirm);

  const { setNodeRef } = useDroppable({
    id,
  });

  return (
    <div style={{ marginBottom: "20px" }}>
      <div style={{ margin: "20px 0 20px 0" }}>
        <Text className="section-title">{props.label}</Text>
        <div className="blue-bar"></div>
      </div>
      {id === "container2" && items.length === 0 && props.total !== 5 ? (
        <Badge
          count={"Pro"}
          color="gold"
          style={{
            visibility: !accountingFirm.proAbonoFeatures
              ? "hidden"
              : !accountingFirm.proAbonoFeatures?.includes("analysis_config")
              ? "visible"
              : "hidden",
          }}
        >
          {" "}
          <Alert
            message="Vous n’avez pas d’autres indicateurs disponibles. Pour en créer de nouveaux,
        rendez-vous dans “administration” puis “paramétrage des comptes”."
            type="info"
            showIcon
          />
        </Badge>
      ) : null}
      <SortableContext id={id} items={items} strategy={rectSortingStrategy}>
        <div
          ref={setNodeRef}
          style={{
            display: "flex",
            gap: "1rem",
            justifyContent: "left",
            flexFlow: "row wrap",
            minHeight: id === "container2" && items.length === 0 && props.total !== 5 ? 10 : 110,
          }}
        >
          {items.map((elt, i) => (
            <IndicatorItem key={elt.id} id={elt.id} index={i} name={elt.name} containerId={id} />
          ))}
        </div>{" "}
      </SortableContext>
    </div>
  );
};

export default IndicatorsContainer;

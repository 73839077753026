import { Col, Row } from "antd";
import jwt_decode from "jwt-decode";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as API from "../../api/API";
import { selectAccountingFirm } from "../../slices/accountingFirmSlice";
import Footer from "../footer/Footer.js";
import Nav from "../nav/Nav.js";

import "./subscription.css";

function Subscribe(props) {
  const accountingFirm = useSelector(selectAccountingFirm);
  const [iframe, setIframe] = useState("");

  // redirection vers login si token expiré
  useEffect(() => {
    if (localStorage.getItem("token")) {
      const { exp } = jwt_decode(localStorage.getItem("token"));
      if (Date.now() > exp * 1000) {
        localStorage.removeItem("token");
        props.history.push({
          pathname: "/",
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (accountingFirm.status === "ready" && accountingFirm.proAbono) {
      (async () => {
        let res = await API.getSubscriptions(accountingFirm.proAbono.ReferenceCustomer);
        res = await res.json();
        if (!res.data?.length && !props.location.state?.link) {
          props.history.push({
            pathname: "/offers",
          });
        } else {
          const url =
            props.location.state?.link ||
            accountingFirm.proAbono.Links?.find((link) => link.rel === "hosted-home").href;
            if( url)
              setIframe(
                '<iframe src="' + url + '" style="width:100%; height:800px;" frameborder="0"></iframe>'
          );
        }
      })();
    }
  }, [accountingFirm]);

  useEffect(() => {
    if (accountingFirm.status === "ready") {
      (async () => {
        let response = await API.postGenerateNewToken({
          token: localStorage.getItem("token"),
        });
        if (response.status === 200) {
          response = await response.json();
          localStorage.setItem("token", response.token);
        } else {
          console.log("Error while generating a new token");
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountingFirm]);

  function createMarkup() {
    return { __html: iframe };
  }

  return (
    <>
      <div className="subscription">
        <Nav {...props} />
        <Row justify="center" className="subscription-content">
          <Col xs={22} sm={22} md={22} lg={22} xl={22} className="subscription-box">
            <div dangerouslySetInnerHTML={createMarkup()} />
          </Col>
        </Row>
        <Footer />
      </div>
    </>
  );
}

export default Subscribe;

const allMailTemplatesInit = [
  {
    type: "analysis",
    subject: "[<nom_dossier_client>] Votre analyse comptable - <mois_analyse>",
    body: [
      {
        para: "begining",
        text: "Bonjour, <br/><br/>Veuillez trouver ci-dessous l’analyse comptable de la société &lt;nom_dossier_client&gt; pour le mois &lt;mois_analyse&gt;.<br/><br/>&lt;signature_collab&gt;",
      },
    ],
  },
  {
    type: "missingdoc",
    subject: "[<nom_dossier_client>] Vos justificatifs manquants",
    linkText: "Accéder à la liste des justificatifs manquants",
    body: [
      {
        para: "begining",
        text: "Bonjour,<br/><br/>Il manque actuellement &lt;nb_justif&gt; justificatifs à votre dossier pour un montant total de &lt;montant_justif&gt; € TTC.<br/><br/>Je vous invite à me les transmettre dès que possible. Je reste à votre disposition pour toute question.",
      },
      {
        para: "ending",
        text: "(Ce lien est valable pour une durée de 60 jours) <br/><br/> &lt;signature_collab&gt;",
      },
    ],
  },
  {
    type: "missingdocreminder",
    subject: "[<nom_dossier_client>] Vos justificatifs manquants",
    linkText: "Accéder à la liste des justificatifs manquants",
    body: [
      {
        para: "begining",
        text: "Bonjour,<br/><br/>Nous nous permettons de vous relancer car &lt;nb_justif&gt; points en suspens sont encore en attente d'un justificatif.<br/><br/>Je vous invite à me les transmettre dès que possible. Je reste à votre disposition pour toute question.",
      },
      {
        para: "ending",
        text: "(Ce lien est valable pour une durée de 60 jours) <br/><br/> &lt;signature_collab&gt;",
      },
    ],
  },
  {
    type: "outstandingsclients",
    subject: "[<nom_dossier_client>] Vos encours clients",
    linkText: "Accéder à la liste des factures non réglées",
    body: [
      {
        para: "begining",
        text: "Bonjour,<br/><br/>&lt;nb_factures&gt; factures clients n'ont pas été réglées pour un montant total de &lt;montant_factures&gt; € TTC.<br/><br/>Je vous invite à réviser ces éléments et à indiquer, le cas échéant, le mode de paiement utilisé et la date de celui-ci.",
      },
      {
        para: "ending",
        text: "(Ce lien est valable pour une durée de 60 jours) <br/><br/> &lt;signature_collab&gt;",
      },
    ],
  },
  {
    type: "outstandingsproviders",
    subject: "[<nom_dossier_client>] Vos encours fournisseurs",
    linkText: "Accéder à la liste des factures non réglées",
    body: [
      {
        para: "begining",
        text: "Bonjour,<br/><br/>&lt;nb_factures&gt; factures fournisseurs n'ont pas été réglées pour un montant total de &lt;montant_factures&gt; € TTC.<br/><br/>Je vous invite à réviser ces éléments et à indiquer, le cas échéant, le mode de paiement utilisé et la date de celui-ci.",
      },
      {
        para: "ending",
        text: "(Ce lien est valable pour une durée de 60 jours) <br/><br/> &lt;signature_collab&gt;",
      },
    ],
  },
  {
    type: "global",
    subject: "[<nom_dossier_client>] Vos points en suspens",
    linkText: "Accéder au détail",
    body: [
      {
        para: "begining",
        text: "Bonjour,<br/><br/>Voici les points actuellement en suspens sur votre dossier :",
      },
      {
        para: "missingdoc",
        text: "<ul><li>&lt;nb_justif&gt; justificatifs manquants pour un montant total de &lt;montant_justif&gt; € TTC</li></ul>",
      },
      {
        para: "outstandingsclients",
        text: "<ul><li>&lt;nb_factures&gt; factures clients non réglées pour un montant total de &lt;montant_factures&gt; € TTC</li></ul>",
      },
      {
        para: "outstandingsproviders",
        text: "<ul><li>&lt;nb_factures&gt; factures fournisseurs non réglées pour un montant total de &lt;montant_factures&gt; € TTC</li></ul>",
      },
      {
        para: "ending",
        text: "(Ce lien est valable pour une durée de 60 jours) <br/><br/> &lt;signature_collab&gt;",
      },
    ],
  },
];

function generateSubject(props, accountingFirm, client, user, settings) {
  var subjectTemplate = "";

  const signature = user.signature
    ? user.signature
    : `<p>Cordialement</p><br />
      <p>${user.firstName} ${user.lastName}</p>
      <span style="color:#4569f8; font-size: 16px">${accountingFirm.name}</span>
  `;

  if (props.type !== "analysis") {
    const tabsToRefresh = settings.filter((elt) => elt.refresh === true);

    if (tabsToRefresh.length > 1) {
      subjectTemplate = accountingFirm.templateMailGlobal
        ? accountingFirm.templateMailGlobal.subject
        : allMailTemplatesInit.find((elt) => elt.type === "global").subject;
    } else {
      switch (tabsToRefresh[0].type) {
        case "missingdoc":
          subjectTemplate = accountingFirm.templateMailMissingDoc
            ? accountingFirm.templateMailMissingDoc.subject
            : allMailTemplatesInit.find((elt) => elt.type === tabsToRefresh[0].type).subject;
          break;
        case "missingdocreminder":
          subjectTemplate = accountingFirm.templateMailMissingDocReminder
            ? accountingFirm.templateMailMissingDocReminder.subject
            : allMailTemplatesInit.find((elt) => elt.type === tabsToRefresh[0].type).subject;
          break;
        case "outstandingsclients":
          subjectTemplate = accountingFirm.templateMailOutstandingsClients
            ? accountingFirm.templateMailOutstandingsClients.subject
            : allMailTemplatesInit.find((elt) => elt.type === tabsToRefresh[0].type).subject;
          subjectTemplate = subjectTemplate
            .replaceAll(
              "<nb_factures>",
              props.emailData.find((elt) => elt.type === "outstandingsclients").nbr
            )
            .replaceAll(
              "<montant_factures>",
              props.emailData.find((elt) => elt.type === "outstandingsclients").amount
            );
          break;
        case "outstandingsproviders":
          subjectTemplate = accountingFirm.templateMailOutstandingsProviders
            ? accountingFirm.templateMailOutstandingsProviders.subject
            : allMailTemplatesInit.find((elt) => elt.type === tabsToRefresh[0].type).subject;

          subjectTemplate = subjectTemplate
            .replaceAll(
              "<nb_factures>",
              props.emailData.find((elt) => elt.type === "outstandingsproviders").nbr
            )
            .replaceAll(
              "<montant_factures>",
              props.emailData.find((elt) => elt.type === "outstandingsproviders").amount
            );
          break;
        default:
          break;
      }
    }

    subjectTemplate = subjectTemplate
      .replaceAll("<nb_justif>", props.totaldoc)
      .replaceAll("<montant_justif>", props.amount)
      .replaceAll("<nom_dossier_client>", client.name)
      .replaceAll("<prenom_collab>", user.firstName)
      .replaceAll("<nom_collab>", user.lastName)
      .replaceAll("<signature_collab>", signature);
  } else {
    subjectTemplate = accountingFirm.templateMailAnalysis
      ? accountingFirm.templateMailAnalysis.subject
      : allMailTemplatesInit.find((elt) => elt.type === "analysis").subject;
  }

  return subjectTemplate;
}

function generateBegining(props, accountingFirm, client, user, settings) {
  var beginingTemplate = "";

  const signature = user.signature
    ? user.signature
    : `<p>Cordialement</p><br />
      <p>${user.firstName} ${user.lastName}</p>
      <span style="color:#4569f8; font-size: 16px">${accountingFirm.name}</span>
  `;

  if (props.type !== "analysis") {
    const tabsToRefresh = settings.filter((elt) => elt.refresh === true);

    if (tabsToRefresh.length > 1) {
      beginingTemplate = accountingFirm.templateMailGlobal
        ? accountingFirm.templateMailGlobal.body.find((txt) => txt.para === "begining").text
        : allMailTemplatesInit
            .find((elt) => elt.type === "global")
            .body.find((txt) => txt.para === "begining").text;

      tabsToRefresh.forEach((elt) => {
        //console.log(elt.type);
        switch (elt.type) {
          case "missingdoc":
            if (accountingFirm.templateMailGlobal) {
              beginingTemplate =
                beginingTemplate +
                accountingFirm.templateMailGlobal.body.find((txt) => txt.para === elt.type)?.text;
            } else {
              beginingTemplate =
                beginingTemplate +
                allMailTemplatesInit
                  .find((elt) => elt.type === "global")
                  .body.find((txt) => txt.para === elt.type)?.text;
            }
            break;
          case "outstandingsclients":
            if (accountingFirm.templateMailGlobal) {
              beginingTemplate =
                beginingTemplate +
                accountingFirm.templateMailGlobal.body.find((txt) => txt.para === elt.type)?.text;
            } else {
              beginingTemplate =
                beginingTemplate +
                allMailTemplatesInit
                  .find((elt) => elt.type === "global")
                  .body.find((txt) => txt.para === elt.type)?.text;
            }
            beginingTemplate = beginingTemplate
              .replaceAll(
                "&lt;nb_factures&gt;",
                props.emailData.find((elt) => elt.type === "outstandingsclients").nbr
              )
              .replaceAll(
                "&lt;montant_factures&gt;",
                props.emailData.find((elt) => elt.type === "outstandingsclients").amount
              );
            break;
          case "outstandingsproviders":
            if (accountingFirm.templateMailGlobal) {
              beginingTemplate =
                beginingTemplate +
                accountingFirm.templateMailGlobal.body.find((txt) => txt.para === elt.type)?.text;
            } else {
              beginingTemplate =
                beginingTemplate +
                allMailTemplatesInit
                  .find((elt) => elt.type === "global")
                  .body.find((txt) => txt.para === elt.type)?.text;
            }
            beginingTemplate = beginingTemplate
              .replaceAll(
                "&lt;nb_factures&gt;",
                props.emailData.find((elt) => elt.type === "outstandingsproviders").nbr
              )
              .replaceAll(
                "&lt;montant_factures&gt;",
                props.emailData.find((elt) => elt.type === "outstandingsproviders").amount
              );
            break;
          default:
            break;
        }
      });
    } else {
      switch (tabsToRefresh[0].type) {
        case "missingdoc":
          beginingTemplate = accountingFirm.templateMailMissingDoc
            ? accountingFirm.templateMailMissingDoc.body.find((elt) => elt.para === "begining").text
            : allMailTemplatesInit
                .find((elt) => elt.type === tabsToRefresh[0].type)
                .body.find((elt) => elt.para === "begining").text;
          break;
        case "missingdocreminder":
          beginingTemplate = accountingFirm.templateMailMissingDocReminder
            ? accountingFirm.templateMailMissingDocReminder.body.find(
                (elt) => elt.para === "begining"
              ).text
            : allMailTemplatesInit
                .find((elt) => elt.type === tabsToRefresh[0].type)
                .body.find((elt) => elt.para === "begining").text;
          break;
        case "outstandingsclients":
          beginingTemplate = accountingFirm.templateMailOutstandingsClients
            ? accountingFirm.templateMailOutstandingsClients.body.find(
                (elt) => elt.para === "begining"
              ).text
            : allMailTemplatesInit
                .find((elt) => elt.type === tabsToRefresh[0].type)
                .body.find((elt) => elt.para === "begining").text;
          beginingTemplate = beginingTemplate
            .replaceAll(
              "&lt;nb_factures&gt;",
              props.emailData.find((elt) => elt.type === "outstandingsclients").nbr
            )
            .replaceAll(
              "&lt;montant_factures&gt;",
              props.emailData.find((elt) => elt.type === "outstandingsclients").amount
            );
          break;
        case "outstandingsproviders":
          beginingTemplate = accountingFirm.templateMailOutstandingsProviders
            ? accountingFirm.templateMailOutstandingsProviders.body.find(
                (elt) => elt.para === "begining"
              ).text
            : allMailTemplatesInit
                .find((elt) => elt.type === "global")
                .body.find((elt) => elt.para === "begining").text;
          beginingTemplate = beginingTemplate
            .replaceAll(
              "&lt;nb_factures&gt;",
              props.emailData.find((elt) => elt.type === "outstandingsproviders").nbr
            )
            .replaceAll(
              "&lt;montant_factures&gt;",
              props.emailData.find((elt) => elt.type === "outstandingsproviders").amount
            );
          break;
        default:
          break;
      }
    }

    // console.log(props);

    // missingdocreminder => Relance mail, donc on garde les balises de nbr de pièces justi et de montant pour les remplacer au moment d'envoyer le mail de relance
    if (tabsToRefresh[0].type !== "missingdocreminder") {
      beginingTemplate = beginingTemplate
        .replaceAll(
          "&lt;nb_justif&gt;",
          props.emailData.find((elt) => elt.type === "missingdoc").nbr
        )
        .replaceAll(
          "&lt;montant_justif&gt;",
          props.emailData.find((elt) => elt.type === "missingdoc").amount
        );
    }

    beginingTemplate = beginingTemplate
      .replaceAll("&lt;nom_dossier_client&gt;", client.name)
      .replaceAll("&lt;prenom_collab&gt;", user.firstName)
      .replaceAll("&lt;nom_collab&gt;", user.lastName)
      .replaceAll("&lt;signature_collab&gt;", signature);

    //console.log(beginingTemplate);
  } else {
    beginingTemplate = accountingFirm.templateMailAnalysis
      ? accountingFirm.templateMailAnalysis.body.find((elt) => elt.para === "begining").text
      : allMailTemplatesInit
          .find((elt) => elt.type === "analysis")
          .body.find((elt) => elt.para === "begining").text;
  }

  return beginingTemplate;
}

function generateEnding(props, accountingFirm, client, user, settings) {
  // console.log(accountingFirm);
  var endingTemplate = "";
  const tabsToRefresh = settings.filter((elt) => elt.refresh === true);

  const signature = user.signature
    ? user.signature
    : `<p>Cordialement</p><br />
      <p>${user.firstName} ${user.lastName}</p>
      <span style="color:#4569f8; font-size: 16px">${accountingFirm.name}</span>
  `;

  if (tabsToRefresh.length > 1) {
    endingTemplate = accountingFirm.templateMailGlobal
      ? accountingFirm.templateMailGlobal.body.find((elt) => elt.para === "ending").text
      : allMailTemplatesInit
          .find((elt) => elt.type === "global")
          .body.find((elt) => elt.para === "ending").text;
  } else {
    switch (tabsToRefresh[0].type) {
      case "missingdoc":
        endingTemplate = accountingFirm.templateMailMissingDoc
          ? accountingFirm.templateMailMissingDoc.body.find((elt) => elt.para === "ending").text
          : allMailTemplatesInit
              .find((elt) => elt.type === tabsToRefresh[0].type)
              .body.find((elt) => elt.para === "ending").text;
        break;
      case "missingdocreminder":
        endingTemplate = accountingFirm.templateMailMissingDocReminder
          ? accountingFirm.templateMailMissingDocReminder.body.find((elt) => elt.para === "ending")
              .text
          : allMailTemplatesInit
              .find((elt) => elt.type === tabsToRefresh[0].type)
              .body.find((elt) => elt.para === "ending").text;
        break;
      case "outstandingsclients":
        endingTemplate = accountingFirm.templateMailOutstandingsClients
          ? accountingFirm.templateMailOutstandingsClients.body.find((elt) => elt.para === "ending")
              .text
          : allMailTemplatesInit
              .find((elt) => elt.type === tabsToRefresh[0].type)
              .body.find((elt) => elt.para === "ending").text;
        endingTemplate = endingTemplate
          .replaceAll(
            "&lt;nb_factures&gt;",
            props.emailData.find((elt) => elt.type === "outstandingsclients").nbr
          )
          .replaceAll(
            "&lt;montant_factures&gt;",
            props.emailData.find((elt) => elt.type === "outstandingsclients").amount
          );
        break;
      case "outstandingsproviders":
        endingTemplate = accountingFirm.templateMailOutstandingsProviders
          ? accountingFirm.templateMailOutstandingsProviders.body.find(
              (elt) => elt.para === "ending"
            ).text
          : allMailTemplatesInit
              .find((elt) => elt.type === tabsToRefresh[0].type)
              .body.find((elt) => elt.para === "ending").text;
        endingTemplate = endingTemplate
          .replaceAll(
            "&lt;nb_factures&gt;",
            props.emailData.find((elt) => elt.type === "outstandingsproviders").nbr
          )
          .replaceAll(
            "&lt;montant_factures&gt;",
            props.emailData.find((elt) => elt.type === "outstandingsproviders").amount
          );
        break;
      default:
        break;
    }
  }

  endingTemplate = endingTemplate
    .replaceAll("&lt;nb_justif&gt;", props.emailData.find((elt) => elt.type === "missingdoc").nbr)
    .replaceAll(
      "&lt;montant_justif&gt;",
      props.emailData.find((elt) => elt.type === "missingdoc").amount
    )
    .replaceAll("&lt;nom_dossier_client&gt;", client.name)
    .replaceAll("&lt;prenom_collab&gt;", user.firstName)
    .replaceAll("&lt;nom_collab&gt;", user.lastName)
    .replaceAll("&lt;signature_collab&gt;", signature);
  //console.log(endingTemplate);

  return endingTemplate;
}

function generateLinkText(settings) {
  var linkTxt = "";
  const tabsToRefresh = settings.filter((elt) => elt.refresh === true);

  if (tabsToRefresh.length > 1) {
    linkTxt = allMailTemplatesInit.find((elt) => elt.type === "global").linkText;
  } else {
    linkTxt = allMailTemplatesInit.find((elt) => elt.type === tabsToRefresh[0].type).linkText;
  }
  return linkTxt;
}

export { generateBegining, generateEnding, generateLinkText, generateSubject };

import React from "react";
import { Row, Col, Tabs } from "antd";
import UserProfile from "./UserProfile.js";
import UserSignature from "./UserSignature.js";
import UserNotifications from "./UserNotifications.js";
import Nav from "../nav/Nav.js";
import Footer from "../footer/Footer.js";
import { useSelector } from "react-redux";
import "./Settings.css";
import ConnexionACD from "./ConnexionACD.js";
import { selectAccountingFirm } from "../../slices/accountingFirmSlice";
import hasFeature from "utils/dataProviderFeatures";

const { TabPane } = Tabs;

function Settings(props) {
  const accountingFirm = useSelector(selectAccountingFirm);

  return (
    <>
      <div className="user-profile">
        <Nav {...props} />
        <Row justify="center" className="user-profile-content">
          <Col xs={22} sm={22} md={22} lg={22} xl={22} className="user-profile-box">
            <Tabs defaultActiveKey={window.location.hash}>
              <TabPane tab="Votre profil" key="1">
                <UserProfile {...props} />
              </TabPane>
              <TabPane tab="Votre signature" key="2">
                <UserSignature {...props} />
              </TabPane>
              <TabPane tab="Notifications" key="3">
                <UserNotifications {...props} />
              </TabPane>
              {hasFeature(accountingFirm.fec, accountingFirm.dataProvider, "USER_CREDENTIALS") ? (
                <TabPane tab="Connexion ACD" key="#4">
                  <ConnexionACD {...props} />
                </TabPane>
              ) : null}
            </Tabs>
          </Col>
        </Row>
        <Footer />
      </div>
    </>
  );
}

export default Settings;

import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import SelectPreset from "./Modal/SelectPreset";
import CreateTable from "./Modal/CreateTable";
import PreviewReport from "./Modal/Preview";

import "./vpf.css";

function ModalIndex(props) {
  const [isModalOpen, setIsModalOpen] = useState(props.isModalOpen);
  const [currentComponent, setCurrentComponent] = useState(props.component);
  const [report, setReport] = useState(props.report);

  const handleCancel = () => {
    setIsModalOpen(false);
    props.setIsModalOpen(false);
    setCurrentComponent(props.component);
  };

  useEffect(() => {
    setCurrentComponent(props.component);
  }, [props.component]);

  useEffect(() => {
    setReport(props.report);
  }, [props.report]);

  useEffect(() => {
    setIsModalOpen(props.isModalOpen);
  }, [props.isModalOpen]);

  const selectComponent = () => {
    switch (currentComponent) {
      case "SelectPreset":
        return (
          <SelectPreset
            {...props}
            setCurrentComponent={setCurrentComponent}
            setReport={setReport}
          />
        );
      case "EditReport":
        return (
          <CreateTable
            {...props}
            setCurrentComponent={setCurrentComponent}
            report={report}
            setReport={setReport}
          />
        );
      case "PreviewReport":
        return (
          <PreviewReport {...props} setCurrentComponent={setCurrentComponent} report={report} />
        );
      default:
        break;
    }
  };

  return (<>
    <Modal
      className="vpf-modal vpf-modal-index"
      title="Ajouter un tableau"
      open={isModalOpen}
      footer={false}
      onCancel={handleCancel}
      destroyOnClose={true}
      width={1100}
    >
      {selectComponent()}
    </Modal>
  </>);
}

export default ModalIndex;

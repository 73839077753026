import { Table } from "antd";
import * as number from "../../utils/number";
import { useEffect, useState } from "react";
import * as API from "../../api/API";
import LoaderRaw from "../../utils/LoaderRaw";

const operationColumns = [
  { title: "Journal", dataIndex: "journal", key: "journal" },
  { title: "Lib. opération", dataIndex: "label", key: "label" },
  {
    title: "Date de facturation",
    dataIndex: "date",
    key: "date",
    align: "center",
    render: (text) => (
      <span
        style={{
          float: "right",
        }}
      >
        {new Date(text)?.toLocaleDateString()}
      </span>
    ),
  },
  { title: "N° pièce", dataIndex: "piece_ref", key: "piece_ref", width: "150px" },
  {
    title: "Débit",
    dataIndex: "debit",
    key: "debit",
    align: "center",
    render: (text) => (
      <span
        style={{
          float: "right",
        }}
      >
        {number.parseToAmount(text)}
      </span>
    ),
  },
  {
    title: "Crédit",
    dataIndex: "credit",
    key: "credit",
    align: "center",
    render: (text) => (
      <span
        style={{
          float: "right",
        }}
      >
        {number.parseToAmount(text)}
      </span>
    ),
  },
];

const AccountGlobalTable = (props) => {
  const [accountData, setAccountData] = useState();

  useEffect(() => {
    setAccountData();
    (async () => {
      let res = await API.postToGetFullAccount({
        companyId: props.client.remoteId,
        account: props.account,
      });
      res = await res.json();
      setAccountData(res);
    })();
  }, [props.account, props.client.remoteId]);

  return (
    <>
      <Table
        className="account-global-table"
        columns={operationColumns}
        dataSource={accountData}
        pagination={{ defaultCurrent: 1 }}
        loading={{ indicator: <LoaderRaw />, spinning: !accountData }}
      />
    </>
  );
};

export default AccountGlobalTable;

import {
  BoldOutlined,
  DeleteOutlined,
  FontColorsOutlined,
  ItalicOutlined,
  PaperClipOutlined,
  SmileOutlined,
  UnderlineOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import Picker from "@emoji-mart/react";
import { mergeAttributes } from "@tiptap/core";
import Bold from "@tiptap/extension-bold";
import BulletList from "@tiptap/extension-bullet-list";
import { Color } from "@tiptap/extension-color";
import Document from "@tiptap/extension-document";
import Highlight from "@tiptap/extension-highlight";
import History from "@tiptap/extension-history";
import Italic from "@tiptap/extension-italic";
import ListItem from "@tiptap/extension-list-item";
import Paragraph from "@tiptap/extension-paragraph";
import Placeholder from "@tiptap/extension-placeholder";
import Text from "@tiptap/extension-text";
import TextStyle from "@tiptap/extension-text-style";
import Underline from "@tiptap/extension-underline";

import { EditorProvider, useCurrentEditor } from "@tiptap/react";
import { Button, Card, ColorPicker, Divider, Tag, Upload } from "antd";
import { useState } from "react";

// define your extension array
const extensions = [
  Document,
  Underline,
  BulletList,
  Color,
  Italic,
  ListItem,
  Text,
  Bold,
  TextStyle,
  Paragraph,
  Placeholder.configure({
    placeholder: "Ici, demandez d’autres justificatifs à vos clients...",
  }),
  Highlight.configure({ multicolor: true }),
  Paragraph.extend({
    parseHTML() {
      return [{ tag: "div" }];
    },
    renderHTML({ HTMLAttributes }) {
      return ["div", mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
    },
  }),
  History,
];

const MenuBar = (props) => {
  const { editor } = useCurrentEditor();
  const [localFileList, setLocalFileList] = useState(props.fileList || []);
  const [visibleEmoji, setVisibleEmoji] = useState(false);

  const handleUploadFile = (info) => {
    //TODO: save file complete instead of fileId ans fileName (?)
    setLocalFileList([
      ...localFileList,
      { fileId: info.file.uid, fileName: info.file.name, fileInfo: info.file },
    ]);
    props.setFileList([
      ...localFileList,
      { fileId: info.file.uid, fileName: info.file.name, fileInfo: info.file },
    ]);
  };

  const handleDeleteFile = (fileId) => {
    console.log(fileId);
    const updatedFileList = localFileList.filter((item) => item.fileId !== fileId);
    setLocalFileList(updatedFileList);
    // props.setFileList(updatedFileList);
    props.deleteFile(fileId);
  };

  const handleButtonClick = () => {
    setVisibleEmoji(!visibleEmoji);
  };

  const handleEmojiSelect = (emoji) => {
    setVisibleEmoji(false); // Hide the picker after selecting an emoji
  };

  if (!editor) {
    return null;
  }

  return (
    <div>
      {localFileList && (
        <div style={{ marginTop: "10px" }}>
          {localFileList.map((file, fileIndex) => (
            <Tag
              key={fileIndex}
              color="grey"
              icon={<PaperClipOutlined />}
              closeIcon={<DeleteOutlined />}
              onClose={(e) => {
                e.preventDefault();
                handleDeleteFile(file.fileId);
              }}
            >
              {file.fileName}
            </Tag>
          ))}
        </div>
      )}
      <Card className="editor-settings" style={{ display: "inline-block" }}>
        <Button
          style={{ padding: "4px" }}
          type="text"
          onClick={() => editor.chain().focus().toggleBold().run()}
          className={editor.isActive("bold") ? "bold is-active" : ""}
        >
          <BoldOutlined />
        </Button>
        <Button
          style={{ padding: "4px" }}
          type="text"
          onClick={() => editor.chain().focus().toggleItalic().run()}
          className={editor.isActive("italic") ? "italic is-active" : ""}
        >
          <ItalicOutlined />
        </Button>
        <Button
          style={{ padding: "4px" }}
          type="text"
          onClick={() => editor.chain().focus().toggleUnderline().run()}
          className={editor.isActive("underline") ? "underline is-active" : ""}
        >
          <UnderlineOutlined />
        </Button>
        <Divider type="vertical" />
        <ColorPicker
          value={editor.getAttributes("setColor").color}
          onChange={(color, hex) => editor.chain().focus().setColor(hex).run()}
          disabledAlpha
        >
          <Button type="text" style={{ backgroundColor: editor.getAttributes("setColor").color }}>
            <FontColorsOutlined style={{ color: editor.getAttributes("setColor").color }} />
          </Button>
        </ColorPicker>
        {/* NOTE - à ajouter pour d'autres éditeurs cf. mail*/}
        {/*  <ColorPicker
          value={editor.getAttributes("highlightTextStyle").color}
          onChange={(color, hex) => editor.chain().focus().toggleHighlight({ color: hex }).run()}
          allowClear
          disabledAlpha
          
        >
          <Button
            type="text"
            style={{ background: editor.getAttributes("highlightTextStyle").color }}
          >
            <HighlightOutlined />
          </Button> 
        </ColorPicker>*/}
        <Divider type="vertical" />
        <Button
          style={{ padding: "4px" }}
          type="text"
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={editor.isActive("bulletList") ? "is-active" : ""}
        >
          <UnorderedListOutlined />
        </Button>
        <Divider type="vertical" />
        <Button type="text" style={{ padding: "4px" }} onClick={handleButtonClick}>
          <SmileOutlined />
        </Button>
        <Upload
          method="get"
          onChange={handleUploadFile}
          showUploadList={false}
          beforeUpload={() => false}
        >
          <Button style={{ padding: "4px" }} type="text">
            <PaperClipOutlined />
          </Button>
        </Upload>
      </Card>

      <Button
        className="call-action-btn"
        onClick={async () => {
          try {
            await props.setOtherInfo({ content: editor.getHTML(), fileList: localFileList });
            editor.commands.clearContent();
          } catch (error) {
            console.log(error);
          }
          setLocalFileList([]);
        }}
        style={{ display: "inline-block", float: "right", marginTop: "15px" }}
        disabled={!editor.getText().trim().length}
      >
        {props.content ? "Valider" : "Ajouter"}
      </Button>

      {props.content ? (
        <>
          <Button
            className="other-action-btn"
            onClick={props.cancelEditing}
            style={{
              display: "inline-block",
              float: "right",
              marginTop: "15px",
              marginRight: "10px",
            }}
          >
            Annuler
          </Button>
        </>
      ) : (
        ""
      )}
      {visibleEmoji && (
        <Picker
          onSelect={handleEmojiSelect}
          locale="fr"
          theme="light"
          onEmojiSelect={(emoji) => {
            editor.chain().focus().insertContent(emoji.native).run();
          }}
        />
      )}
    </div>
  );
};

function TipTapEditor(props) {
  return (
    <div style={{ height: "auto" }}>
      <EditorProvider
        extensions={extensions}
        slotAfter={<MenuBar {...props} />}
        className="tiptap-custom"
        content={props.content || null}
      />
    </div>
  );
}

export default TipTapEditor;

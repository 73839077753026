
const colorMapping = {
    "preprod": {
        '#4569F8': "#ff7f00"
    },
    "dev":  {
        '#4569F8': "#e20000"
    },
    "local": {
        '#4569F8': "#007f3f"
    },
};

function parseSVG(svgString) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(svgString, 'image/svg+xml');
    return doc;
}
  
function getElementColor(el) {
    return el.getAttribute('fill');
}
  
  // find all colors used in svg
 async function urlToString(url) {
    const req = await fetch(url, { mode: 'cors' });
    const svgString = await req.text();
    return svgString;
}

// replace colors in svg string
// map is an objects with "oldColor: newColor" values
export async function seshaLogoModifier(url, env) {
    let svgString = await urlToString(url);
    
    if( ['preprod', "dev", "local"].includes(env)) {
        const mapForEnv = colorMapping[env];
        // we can do some RegExp magic here
        // but I will just manually check every element

        const doc = parseSVG(svgString);
        var elements = doc.getElementsByTagName('*');

        for (const element of elements) {
            const color = getElementColor(element);
            if (mapForEnv[color]) {
                element.setAttribute('fill', mapForEnv[color]);
            }
        }
        // set env in logo
        let envName = env.charAt(0).toUpperCase() + env.slice(1)
        let textElement = doc.getElementById('sesha-logo-env');
        textElement.appendChild(doc.createTextNode(envName));
        /*
        textElement.setAttribute('style', 'cursor: move');
        textElement.setAttribute('font-style', 'normal');
        textElement.setAttribute('font-weight', 'bold');
        textElement.setAttribute('xml:space', 'preserve');
        textElement.setAttribute('text-anchor', 'start');
        textElement.setAttribute('font-family', "'Roboto Mono'");
        
        textElement.appendChild(doc.createTextNode(env));

        doc.getRootNode().children[0].appendChild(textElement);
        */

        // <text style="cursor: move;" font-style="normal" font-weight="bold" xml:space="preserve" text-anchor="start" font-family="'Roboto Mono'" font-size="13" stroke-width="0" fill="#ff7f00" id="svg_2" y="36.75" x="80">Préprod</text>
        // serialize DOM back into string
        var xmlSerializer = new XMLSerializer();
        svgString = xmlSerializer.serializeToString(doc);
        //svgString = svgString.replace('<path fill-rule="evenodd"', `<g><path fill-rule="evenodd"`);
        //svgString = svgString.replace("</svg>", `</g><g><text style="cursor: move;" font-style="normal" font-weight="bold" xml:space="preserve" text-anchor="start" font-family="'Roboto Mono'" font-size="13" stroke-width="0" fill="#ff7f00" id="svg_2" y="36.75" x="80">Préprod</text><g></svg>`);
    }

  return svgString;
}
import {
  BarChartOutlined,
  InfoCircleOutlined,
  LineChartOutlined,
  ManOutlined,
  TeamOutlined,
  UserOutlined,
  WomanOutlined,
} from "@ant-design/icons";
import { Button, Checkbox, Col, Form, Input, Popover, Radio, Row } from "antd";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import CustomSelect from "./CustomSelect";
import IndicDescription from "./IndicDescription";

function CustomIndicator(props) {
  const [form] = Form.useForm();

  const [, setIsFormFilled] = useState(true);
  const [newIndicDescription, setNewIndicDescription] = useState("");

  const onSubmitCustom = (values) => {
    props.setCurrentTemplate({
      ...props.currentTemplate,
      templateAnalysis: [
        ...props.currentTemplate?.templateAnalysis,
        {
          id: uuidv4(),
          name: values.title,
          chart: values.chart,
          number: values.number,
          gender: values.gender,
          rule_ca: values.rule_ca,
          add_accounts: values.addAccounts,
          remove_accounts: values.removeAccounts,
          remove_journals: values.removeJournals,
          description: newIndicDescription,
        },
      ],
    });
    props.setIsChanging(true);
    props.setIndicHasBeenAdded(true);
  };

  return (
    <div className="add-indicator-div">
      <Form
        form={form}
        layout="horizontal"
        className="user-profile-form"
        onFinish={onSubmitCustom}
        onFinishFailed={() => setIsFormFilled(false)}
      >
        <div style={{ paddingBottom: "5px" }}>Nom de l'indicateur</div>
        <div className="add-indicator-form">
          <Form.Item
            name="title"
            className="indic-title-item"
            rules={[
              { required: true, message: "Veuillez entrer un nom d'indicateur" },
              {
                validator(_, value) {
                  return props.currentTemplate?.templateAnalysis
                    .map((e) => ({
                      name: e.name || props.indicName[e.id],
                    }))
                    .some((e) => value === e.name)
                    ? Promise.reject(new Error("Ce nom existe déjà"))
                    : Promise.resolve();
                },
              },
            ]}
          >
            <Input
              bordered={true}
              className={"email-input template-name-input"}
              style={{ width: "492px" }}
            />
          </Form.Item>
          <Form.Item name={"description"} style={{ marginBottom: "16px" }}>
            <IndicDescription
              {...props}
              name={"description"}
              newIndic={true}
              setNewIndicDescription={setNewIndicDescription}
            />
          </Form.Item>
          <Row>
            <Popover
              placement="top"
              content="graphique en barres / graphique en courbes"
              overlayClassName="navbar-popover-overlay"
            >
              <Form.Item
                name="chart"
                rules={[{ required: true, message: "" }]}
                className="radiobtn-params"
                style={{
                  position: "relative",
                  top: "6.5px",
                }}
              >
                <Radio.Group defaultValue={""} buttonStyle="solid">
                  <div className="graph-btn">
                    <Radio.Button
                      value="C"
                      style={{
                        borderBottomLeftRadius: "10px",
                        borderTopLeftRadius: "10px",
                      }}
                    >
                      <div style={{ marginLeft: "-3px", marginTop: "-3px" }}>
                        <BarChartOutlined />
                      </div>
                    </Radio.Button>
                    <Radio.Button
                      value="L"
                      style={{
                        borderBottomRightRadius: "10px",
                        borderTopRightRadius: "10px",
                      }}
                    >
                      <div style={{ marginLeft: "-5px", marginTop: "-3px" }}>
                        <LineChartOutlined />
                      </div>
                    </Radio.Button>
                  </div>
                </Radio.Group>
              </Form.Item>
            </Popover>
            <Popover
              placement="top"
              content="singulier / pluriel"
              overlayClassName="navbar-popover-overlay"
            >
              <Form.Item
                name="number"
                rules={[{ required: true, message: "" }]}
                className="radiobtn-params"
                style={{
                  marginLeft: "10px",
                  position: "relative",
                  top: "6.5px",
                }}
              >
                <Radio.Group defaultValue={""} buttonStyle="solid">
                  <div className="graph-btn">
                    <Radio.Button
                      value="S"
                      style={{
                        borderBottomLeftRadius: "10px",
                        borderTopLeftRadius: "10px",
                      }}
                    >
                      <div style={{ marginLeft: "-3px", marginTop: "-3px" }}>
                        <UserOutlined />
                      </div>
                    </Radio.Button>
                    <Radio.Button
                      value="P"
                      style={{
                        borderBottomRightRadius: "10px",
                        borderTopRightRadius: "10px",
                      }}
                    >
                      <div style={{ marginLeft: "-5px", marginTop: "-3px" }}>
                        <TeamOutlined />
                      </div>
                    </Radio.Button>
                  </div>
                </Radio.Group>
              </Form.Item>
            </Popover>
            <Popover
              placement="top"
              content="masculin / féminin"
              overlayClassName="navbar-popover-overlay"
            >
              <Form.Item
                name="gender"
                rules={[{ required: true, message: "" }]}
                className="radiobtn-params"
                style={{
                  marginLeft: "10px",
                  position: "relative",
                  top: "6.5px",
                }}
              >
                <Radio.Group defaultValue={""} buttonStyle="solid">
                  <div className="graph-btn">
                    <Radio.Button
                      value="M"
                      style={{
                        borderBottomLeftRadius: "10px",
                        borderTopLeftRadius: "10px",
                      }}
                    >
                      <div style={{ marginLeft: "-3px", marginTop: "-3px" }}>
                        <ManOutlined />
                      </div>
                    </Radio.Button>
                    <Radio.Button
                      value="F"
                      style={{
                        borderBottomRightRadius: "10px",
                        borderTopRightRadius: "10px",
                      }}
                    >
                      <div style={{ marginLeft: "-5px", marginTop: "-3px" }}>
                        <WomanOutlined />
                      </div>
                    </Radio.Button>
                  </div>
                </Radio.Group>
              </Form.Item>
            </Popover>
          </Row>
          <div className="type-form-para">
            <p>
              Racines de comptes
              <Popover
                placement="right"
                content="Personnalisez les racines de comptes utilisées dans le calcul de l’indicateur."
                className="info-popover"
                overlayClassName="info-popover-overlay"
              >
                <span>
                  <InfoCircleOutlined />
                </span>
              </Popover>
            </p>
            <Form.Item name={"addAccounts"} label="+">
              <CustomSelect
                name={"addAccounts"}
                cssClassName={"form-input add-tags"}
                //template={currentTemplate}
              />
            </Form.Item>
            <Form.Item name={"removeAccounts"} label="-">
              <CustomSelect
                name={"removeAccounts"}
                cssClassName={"form-input remove-tags"}
                //template={currentTemplate}
              />
            </Form.Item>

            <p>
              Journaux
              <Popover
                placement="right"
                content="Choisissez les journaux à exclure."
                className="info-popover"
                overlayClassName="info-popover-overlay"
              >
                <span>
                  <InfoCircleOutlined />
                </span>
              </Popover>
            </p>
            <Form.Item name={"removeJournals"} label="-">
              <CustomSelect
                name={"removeJournals"}
                cssClassName={"form-input remove-tags"}
                //template={currentTemplate}
              />
            </Form.Item>
          </div>
          <Form.Item name={"rule_ca"} valuePropName="checked" style={{ marginLeft: "30px" }}>
            <Checkbox className={"checkbox-rule-1"}>
              <span>Comparer l’indicateur par rapport au chiffre d’affaires</span>
            </Checkbox>
          </Form.Item>

          <Row style={{ marginTop: "30px", marginLeft: "76%", paddingBottom: "10px" }}>
            <Col span={24}>
              <Button
                className="other-action-btn cancel-btn"
                onClick={() => {
                  props.setCustomIndicator(false);
                }}
              >
                Annuler
              </Button>
              <Form.Item className="save-template-item">
                <Button htmlType="submit" className="call-action-btn modal-submit-btn">
                  Valider
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
  );
}

export default CustomIndicator;

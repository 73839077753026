import React from "react";
import { Redirect } from "react-router-dom";
import * as API from "../../api/API";
import jwt_decode from "jwt-decode";

const OAuth2 = (props) => {
  const decodedToken = jwt_decode(localStorage.getItem("token"));
  const code = props.match.params.code;

  API.postCode({
    code: code,
    accountingFirmId: decodedToken.accountingFirmId,
    email: decodedToken.email,
    role: decodedToken.role,
  });

  return <Redirect to="/homepage" />;
};

export default OAuth2;

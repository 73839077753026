import { EyeOutlined, PlusCircleOutlined, SaveOutlined } from "@ant-design/icons";
import { DndContext, MouseSensor, useDroppable, useSensor, useSensors } from "@dnd-kit/core";
import { arrayMove, SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { Button, Col, Input, Row, Tag, TreeSelect } from "antd";
import { useEffect, useRef, useState } from "react";
import * as API from "../../../../api/API";
import ArrowLeft from "../../../../assets/images/arrow-left.svg";
import logAction from "../../../../utils/logActions";
import columnList from "../columnList";
import "../vpf.css";
import AlertCreateTable from "./AlertCreateTable";
import CreateTableItem from "./CreateTableItem";

const { TreeNode } = TreeSelect;

const colors = [
  { light: "#FFDCFB", dark: "#6C0092" }, // pink
  { light: "#DCFFE4", dark: "#00920F" }, // green
  { light: "#DDDCFF", dark: "#370092" }, // violet
  { light: "#FFEDDC", dark: "#925700" }, // brown
  { light: "#DCF7FF", dark: "#000F92" }, // blue
  { light: "#FFDCDC", dark: "#920900" }, // red
];

function addAlpha(color, opacity) {
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
}

const preset = (title, colors) => (
  <Button className="modal-1-button" style={{ background: colors.light }}>
    <span className="modal-1-button-text" style={{ color: colors.dark }}>
      {title}
    </span>
  </Button>
);

function CreateTable(props) {
  const [reportName, setReportName] = useState("");
  const [columns, setColumns] = useState(props.report?.column?.map(({ name }) => name) || []);
  const [criteria, setCriteria] = useState(props.report?.criteria || {});
  const [sortInfo, setSortInfo] = useState({});
  const [originalColors, setOriginalColors] = useState(
    props.report?.colors || { light: "#FFDCFB", dark: "#6C0092" }
  );
  const [modifiedColors, setModifiedColors] = useState(
    props.report?.colors || { light: "#FFDCFB", dark: "#6C0092" }
  );

  const [activeId, setActiveId] = useState(null);
  const [reportCopy, setReportCopy] = useState();
  const [alert, setAlert] = useState(false);

  const column = useRef(props.report?.column || []);

  const { setNodeRef } = useDroppable({
    id: "droppable",
  });

  useEffect(() => {
    if (props.report.colors) setOriginalColors(props.report.colors);
    setReportCopy(props.report);
    setReportName(props.report.name);
  }, [props.report]);

  const onChangeColumns = (value) => {
    setAlert(false);
    setColumns(value);
    column.current = column.current.filter((col) => value.includes(col.name));

    // also remove criteria
    const newObj = {};
    value.forEach((el) => {
      newObj[el] = criteria[el];
    });
    if (value.includes("keyPoints")) {
      newObj.keyPointsListExclusive = criteria.keyPointsListExclusive;
      newObj.keyPointsListInclusive = criteria.keyPointsListInclusive;
    } else if (value.includes("collaboratorsId")) {
      newObj.collaboratorsExclusive = criteria.collaboratorsExclusive;
      newObj.collaboratorsInclusive = criteria.collaboratorsInclusive;
    } else if (value.includes("fiscalEndMonth")) {
      newObj.fiscalEndMonthExclusive = criteria.fiscalEndMonthExclusive;
    }
    setCriteria(newObj);
  };

  const onRemoveColumns = (value) => {
    console.log(value);

    var newlist = columns.filter((elt) => elt !== value);
    setColumns(newlist);
  };

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  );

  const handleDragStart = ({ active }) => setActiveId(active.id);

  const handleDragEnd = ({ active, over }) => {
    if (!over) {
      setActiveId(null);
      return;
    }
    if (active.id !== over.id) {
      const activeIndex = active.data.current.sortable.index;
      const overIndex = over.id in columns ? columns.length + 1 : over.data.current.sortable.index;

      setColumns(arrayMove(columns, activeIndex, overIndex));
      column.current = arrayMove(column.current, activeIndex, overIndex);

      setActiveId(null);
    }
  };

  useEffect(() => {
    setModifiedColors({ light: addAlpha(originalColors.light, 0.35), dark: originalColors.dark });
  }, [originalColors]);

  const previewTable = () => {
    const data = {};
    data.criteria = criteria;
    data.column = column.current;
    data.name = reportName;
    data.colors = originalColors;
    data.sortInfo = sortInfo || {
      sortField: "name",
      sortOrder: "ascend",
    };
    props.setReport({ ...props.report, ...data });
    props.setCurrentComponent("PreviewReport");
  };

  const onCreateReport = async () => {
    if (!reportName || column.current.length === 0) {
      setAlert(true);
    } else {
      const data = {};
      data.criteria = criteria;
      data.column = column.current;
      data.name = reportName;
      data.colors = originalColors;
      data.sortInfo = sortInfo || {
        sortField: "name",
        sortOrder: "ascend",
      };

      props.setIsModalOpen(false);

      if (props.report._id) {
        await API.putReport(props.report._id, data);
      } else {
        for (let i = 0; i < props.reports?.length; i++) {
          await API.putReport(props.reports[i]._id, {
            positionIndex: i + 1,
          });
        }
        await API.postReport({ ...data, positionIndex: 0 });
        logAction(31, 1);
      }

      if (props.type === "edit") props.setReloadReport(!props.reloadReport);
      else {
        props.setReloadAll(!props.reloadAll);
        props.setCurrentComponent("SelectPreset");
      }
    }
  };

  return (
    <>
      {props.report.id ? (
        <>
          <div
            style={{
              fontSize: "18px",
              paddingLeft: "5px",
              marginBottom: "20px",
              paddingTop: "18px",
            }}
          >
            <img
              src={ArrowLeft}
              style={{ height: "17px", marginBottom: "-2px", marginRight: "15px" }}
              alt="Flèche vers la gauche"
              onClick={() => props.setCurrentComponent("SelectPreset")}
            />
            <b>Vous avez sélectionné le tableau pré-configuré: </b>
          </div>
          <div style={{ paddingLeft: "30px", marginBottom: "5px" }}>
            {preset(props.report.name, originalColors)}
          </div>
        </>
      ) : null}

      <Row>
        <Col span={10}>
          <p className="vpf-label">Nom de votre nouveau tableau</p>
          <div className="vpf-table-name">
            <Input
              className="vpf-table-name-input"
              placeholder="Nom"
              style={{ color: originalColors.dark }}
              value={reportCopy?.name || ""}
              onChange={(el) => {
                setReportName(el.target.value);
                setAlert(false);
                setReportCopy({ ...reportCopy, name: el.target.value });
              }}
            />
          </div>
        </Col>
        <Col span={10}>
          <p className="vpf-label">Couleur de votre tableau</p>
          <div className="vpf-color">
            {colors.map((color) => {
              return (
                <Button
                  shape="circle"
                  size="large"
                  key={color.dark}
                  style={{
                    border: `3px solid ${
                      originalColors.dark === color.dark ? color.dark : "transparent"
                    }`,
                    backgroundColor: color.light,
                    marginRight: "10px",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    setOriginalColors({ light: color.light, dark: color.dark });
                  }}
                >
                  {" "}
                </Button>
              );
            })}
          </div>
        </Col>
      </Row>

      <p className="vpf-label">Rechercher un élement à afficher en colonne</p>
      <TreeSelect
        className="select-col-tree vpf-col-tree"
        showSearch
        filterTreeNode={(search, item) => {
          return item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0;
        }}
        style={{ width: "80%" }}
        value={columns}
        placeholder="Sélection des colonnes"
        allowClear
        autoClearSearchValue={false}
        multiple
        treeDefaultExpandAll
        treeCheckable
        maxTagCount={0}
        maxTagPlaceholder={(omittedValues) => {
          return (
            <Tag
              style={{
                marginRight: 3,
                backgroundColor: originalColors.light,
                color: originalColors.dark,
                border: "none",
                borderRadius: "16px",
              }}
            >
              {omittedValues.length}
              {omittedValues.length > 1 ? " colonnes sélectionnées" : " colonne sélectionnée"}
            </Tag>
          );
        }}
        onChange={onChangeColumns}
        placement="bottomLeft"
        dropdownAlign={{ overflow: { adjustY: false } }}
      >
        <TreeNode value="Indicateur Sesha" title="Indicateur Sesha">
          {columnList
            .filter((col) => col.family === "Indicateur Sesha")
            .map((col) => (
              <TreeNode value={col.label} title={col.name} key={col.label} />
            ))}
        </TreeNode>
        <TreeNode value="Indicateur chiffre d'affaires" title="Indicateur chiffre d'affaires">
          {columnList
            .filter((col) => col.family === "Indicateur chiffre d'affaires")
            .map((col) => (
              <TreeNode value={col.label} title={col.name} key={col.label} />
            ))}
        </TreeNode>
        <TreeNode value="Indicateur marge" title="Indicateur marge">
          {columnList
            .filter((col) => col.family === "Indicateur marge")
            .map((col) => (
              <TreeNode value={col.label} title={col.name} key={col.label} />
            ))}
        </TreeNode>
        <TreeNode value="Indicateur masse salariale" title="Indicateur masse salariale">
          {columnList
            .filter((col) => col.family === "Indicateur masse salariale")
            .map((col) => (
              <TreeNode value={col.label} title={col.name} key={col.label} />
            ))}
        </TreeNode>
        <TreeNode value="Indicateur trésorerie" title="Indicateur trésorerie">
          {columnList
            .filter((col) => col.family === "Indicateur trésorerie")
            .map((col) => (
              <TreeNode value={col.label} title={col.name} key={col.label} />
            ))}
        </TreeNode>
        <TreeNode value="Indicateur charges externes" title="Indicateur charges externes">
          {columnList
            .filter((col) => col.family === "Indicateur charges externes")
            .map((col) => (
              <TreeNode value={col.label} title={col.name} key={col.label} />
            ))}
        </TreeNode>
        <TreeNode value="Indicateur résultat" title="Indicateur résultat">
          {columnList
            .filter((col) => col.family === "Indicateur résultat")
            .map((col) => (
              <TreeNode value={col.label} title={col.name} key={col.label} />
            ))}
        </TreeNode>
        <TreeNode value="Surveillance de production" title="Surveillance de production">
          {columnList
            .filter((col) => col.family === "Surveillance de production")
            .map((col) => (
              <TreeNode value={col.label} title={col.name} key={col.label} />
            ))}
        </TreeNode>
      </TreeSelect>
      <Row>
        <Col span={8}>
          <p className="vpf-label">Colonnes à afficher</p>
        </Col>
        <Col span={8}>
          <p className="vpf-label vpf-label-criteria">Filtre</p>
        </Col>
      </Row>
      <DndContext sensors={sensors} onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
        <SortableContext id={activeId} items={columns} strategy={verticalListSortingStrategy}>
          <div className="droppable" ref={setNodeRef}>
            {columns.map((col) => {
              return (
                <CreateTableItem
                  {...props}
                  key={col}
                  id={col}
                  originalColors={modifiedColors}
                  criteria={
                    col === "keyPoints"
                      ? criteria["keyPointsListInclusive"] || criteria["keyPointsListExclusive"]
                      : col === "collaboratorsId"
                      ? criteria["collaboratorsExclusive"] || criteria["collaboratorsInclusive"]
                      : col === "fiscalEndMonth"
                      ? criteria["fiscalEndMonthExclusive"]
                      : criteria[col]
                  }
                  setCriteria={(data) => setCriteria({ ...criteria, ...data })}
                  isVisible={column.current.find((elt) => elt.name === col)?.show}
                  column={(data) => {
                    if (column.current.find((elt) => elt.name === data.name)) {
                      column.current.find((elt) => elt.name === data.name).show = data.show;
                    } else {
                      column.current = [...column.current, data];
                    }
                  }}
                  sortInfo={(data) => {
                    setSortInfo({ ...data });
                  }}
                  deleteColumn={(data) => {
                    onRemoveColumns(data);
                    var i = column.current.indexOf(column.current.find((elt) => elt.name === data));
                    if (i !== -1) column.current.splice(i, 1);

                    if (data === "keyPoints")
                      setCriteria({
                        ...criteria,
                        keyPointsListExclusive: null,
                        keyPointsListInclusive: null,
                      });
                    else if (data === "collaboratorsId")
                      setCriteria({
                        ...criteria,
                        collaboratorsExclusive: null,
                        collaboratorsInclusive: null,
                      });
                    else if (data === "fiscalEndMonth")
                      setCriteria({ ...criteria, fiscalEndMonthExclusive: null });
                    else setCriteria({ ...criteria, [data]: null });
                  }}
                  dragOverlay={col === activeId ? true : false}
                />
              );
            })}
          </div>
        </SortableContext>
      </DndContext>
      <AlertCreateTable alert={alert} reportName={reportName} column={column.current} />
      <div className="vpf-modal-footer">
        {props.type === "create" ? (
          <Button
            className="other-action-btn vpf-btn"
            onClick={() => props.setCurrentComponent("SelectPreset")}
          >
            Retour
          </Button>
        ) : (
          <Button className="other-action-btn vpf-btn" onClick={() => props.setIsModalOpen(false)}>
            Annuler l'édition
          </Button>
        )}

        <Button
          className="call-action-btn vpf-btn"
          icon={<EyeOutlined />}
          onClick={() => {
            if (!reportName || column.current.length === 0) setAlert(true);
            else previewTable();
          }}
        >
          Voir l’aperçu du tableau
        </Button>
        <Button
          className="call-action-btn"
          icon={props.type === "create" ? <PlusCircleOutlined /> : <SaveOutlined />}
          onClick={onCreateReport}
        >
          {props.type === "create"
            ? " Ajouter à ma vue portefeuille"
            : "Appliquer les modifications"}
        </Button>
      </div>
    </>
  );
}

export default CreateTable;

import { ELocalStorage } from "enums/ELocalStorage";
import jwt_decode from "jwt-decode";
import * as API from "../api/API";
import { EFeatures } from "../enums/EFeatures";

const manageFalse = () => {
  if (window.location.pathname !== "/") {
    localStorage.setItem(
      ELocalStorage.PAGE_BEFORE_LOGOUT,
      window.location.pathname + window.location.hash
    );
  }
  return false;
};

const isAuthenticated = () => {
  if (localStorage.getItem("token") === null) return manageFalse();

  const { exp } = jwt_decode(localStorage.getItem("token"));
  if (Date.now() >= exp * 1000) {
    return manageFalse();
  }
  return true;
};

const hasFeatures = (featuresComponent) => {
  if (!featuresComponent) return true;
  if (!localStorage.getItem("token")) return manageFalse();

  const { exp, features } = jwt_decode(localStorage.getItem("token"));
  if (Date.now() >= exp * 1000) return false;

  if (
    !featuresComponent.some((featureToCheck) => !Object.keys(EFeatures).includes(featureToCheck))
  ) {
    return features?.some((feature) => featuresComponent.includes(feature)) ? true : manageFalse();
  }
  return manageFalse();
};

const renewToken = async () => {
  let response = await API.postGenerateNewToken({
    token: localStorage.getItem("token"),
  });
  if (response.status === 200) {
    response = await response.json();
    localStorage.setItem("token", response.token);
  } else {
    console.log("Error while generating a new token");
  }
};

const shouldPay = (path) => {
  if (localStorage.getItem("token") === null) return false;

  const allowedRoutes = [
    "/logout",
    "/settings",
    "/hosted",
    "/admin",
    "/dashboard",
    "/offers",
    "/offers-iframe",
  ];
  if (allowedRoutes.includes(path)) return false;

  const { shouldPay } = jwt_decode(localStorage.getItem("token"));
  return shouldPay;
};

const firstCo = () => {
  if (localStorage.getItem("token") === null) return false;

  const { exp, firstCo } = jwt_decode(localStorage.getItem("token"));
  if (Date.now() >= exp * 1000) {
    return false;
  }

  return firstCo;
};

export { firstCo, hasFeatures, isAuthenticated, renewToken, shouldPay };

import { useEffect, useRef, useState } from "react";

import * as dayjs from "dayjs";
import "dayjs/locale/fr";
import * as API from "../../api/API";

import {
  BarChartOutlined,
  CloseCircleOutlined,
  CloudUploadOutlined,
  DeleteOutlined,
  FileUnknownOutlined,
  FormOutlined,
  MailOutlined,
  PhoneOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import "animate.css";
import { Button } from "antd";
import {
  default as LogoComment,
  default as LogoDelete,
  default as LogoLost,
  default as LogoNature,
  default as LogoOtherAdd,
  default as LogoOtherDelete,
  default as LogoOtherEdit,
} from "../../assets/images/mcfvalid.png";
import { ReactComponent as OutstandingsLogo } from "../../assets/images/outstandingslogo.svg";

const selectEntryLogo = (type) => {
  switch (type) {
    case "MISSING_DOC_MAIL":
      return (
        <FileUnknownOutlined
          className="tracking-icon"
          style={{ fontSize: "22px", stroke: "#4368fa" }}
        />
      );
    case "ANALYSIS_MAIL":
      return (
        <BarChartOutlined
          className="tracking-icon"
          style={{ fontSize: "22px", stroke: "#4368fa" }}
        />
      );
    case "OUTSTANDING_EMAIL_CLIENTS":
      return (
        <OutstandingsLogo className="tracking-icon" style={{ width: "22px", height: "22px" }} />
      );
    case "OUTSTANDING_EMAIL_PROVIDERS":
      return (
        <OutstandingsLogo className="tracking-icon" style={{ width: "22px", height: "22px" }} />
      );
    case "OTHER_ADD":
      return <img src={LogoOtherAdd} alt="Logo Other Add" className="entry-logo" />;
    case "OTHER_EDIT":
      return <img src={LogoOtherEdit} alt="Logo Other Edit" className="entry-logo" />;
    case "OTHER_DELETE":
      return <img src={LogoOtherDelete} alt="Logo Other Delete" className="entry-logo" />;
    case "INVALIDATION":
      return (
        <CloseCircleOutlined
          className="tracking-icon"
          style={{ fontSize: "22px", stroke: "#4368fa" }}
        />
      );
    case "RESET":
      return (
        <UndoOutlined className="tracking-icon" style={{ fontSize: "22px", stroke: "#4368fa" }} />
      );
    case "DAILY_SUMMARY":
      return (
        <CloudUploadOutlined
          className="tracking-icon"
          style={{ fontSize: "22px", stroke: "#70ad47" }}
        />
      );
    case "LOST":
      return <img src={LogoLost} alt="Logo Lost" className="entry-logo" />;
    case "COMMENT":
      return <img src={LogoComment} alt="Logo Comment" className="entry-logo" />;
    case "DELETE":
      return <img src={LogoDelete} alt="Logo Delete" className="entry-logo" />;
    case "NATURE":
      return <img src={LogoNature} alt="Logo Nature" className="entry-logo" />;
    case "EMAIL":
      return (
        <MailOutlined className="tracking-icon" style={{ fontSize: "22px", stroke: "#4368fa" }} />
      );
    case "PHONE":
      return (
        <PhoneOutlined className="tracking-icon" style={{ fontSize: "22px", stroke: "#4368fa" }} />
      );
    case "NOTE":
      return (
        <FormOutlined className="tracking-icon" style={{ fontSize: "22px", stroke: "#4368fa" }} />
      );
    default:
      return false;
  }
};

function Messenger(props) {
  const messengerEndRef = useRef(null);
  const [loading, setLoading] = useState();
  const [isShown, setIsShown] = useState(false);

  const scrollToBottom = () => {
    messengerEndRef.current.scrollIntoView({
      behavior: "instant",
      block: "end",
    });
  };

  useEffect(scrollToBottom, [props.data]);

  function deleteEntry(entryId) {
    API.deleteCollabTracker(props.clientId, entryId);
    props.update(true);
    setLoading(false);
  }

  return (
    <>
      <div className="tracker-modal-messenger">
        <p className="p-old">
          Pour afficher l’historique antérieur, veuillez télécharger l’historique complet.
        </p>{" "}
        {props.data.map((entry, i) => {
          if (entry.type === "CLIENT") {
            return (
              <div key={i} className="client-tracker-entry">
                <div className="entry-title">
                  {" "}
                  <span className="entry-title-span">
                    {"Le " + dayjs(entry.date).format("DD/MM/YYYY") + " -  Client  "}
                  </span>{" "}
                  {selectEntryLogo(entry.action)}{" "}
                </div>{" "}
                <div className="entry-text"> {entry.text} </div>{" "}
              </div>
            );
          } else {
            return (
              <div
                key={i}
                className="collaborator-tracker-entry"
                onMouseEnter={() => setIsShown(entry.id)}
                onMouseLeave={() => setIsShown("")}
              >
                <div className="new-entry-txt-div">
                  <div className="entry-title">
                    {" "}
                    {selectEntryLogo(entry.action)}{" "}
                    <span className="entry-title-span">
                      {"Le " +
                        dayjs(entry.date).format("DD/MM/YYYY") +
                        " à " +
                        dayjs(entry.date).format("HH") +
                        "h" +
                        dayjs(entry.date).format("mm") +
                        " - " +
                        entry.author}
                    </span>
                  </div>{" "}
                  <div className="entry-text"> {entry.text} </div>{" "}
                </div>
                {(entry.action === "EMAIL" ||
                  entry.action === "PHONE" ||
                  entry.action === "NOTE") &&
                isShown === entry.id ? (
                  <div className="animate__animated animate__fadeIn delete-entry-btn">
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        deleteEntry(entry.id);
                        setLoading(entry.id);
                      }}
                      loading={entry.id === loading ? true : false}
                      shape="circle"
                      icon={<DeleteOutlined style={{ color: "#4368fa" }} />}
                    />
                  </div>
                ) : null}
              </div>
            );
          }
        })}{" "}
        <div ref={messengerEndRef} />
      </div>{" "}
    </>
  );
}

export default Messenger;

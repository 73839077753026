import React from "react";
import { Row, Col } from "antd";

import OverviewGraphComponent from "./OverviewGraphComponent.js";
import OverviewGraphComponentGroup from "./OverviewGraphComponentGroup.js";
import OverviewGraphLine from "./OverviewGraphLine.js";

function OverviewGraphs(props) {
  return (
    <div className="overview-charts">
      <Row>
        <Col xs={24} sm={24} md={{ span: 6, offset: 1 }} lg={{ span: 6, offset: 1 }}>
          <OverviewGraphComponent
            data={props.dataAnalysis[0].analysisArray}
            title={"Nombre total d'analyses générées par mois"}
          />
        </Col>
        <Col xs={24} sm={24} md={{ span: 6, offset: 1 }} lg={{ span: 6, offset: 2 }}>
          <OverviewGraphComponent
            data={props.dataAnalysis[0].analysisMailArray}
            title={"Nombre total de mails analyse envoyés par mois"}
          />
        </Col>
        <Col xs={24} sm={24} md={{ span: 6, offset: 1 }} lg={{ span: 6, offset: 2 }}>
          <OverviewGraphComponent
            data={props.dataAnalysis[0].missingDocMailArray}
            title={"Nombre total de relances pièces manquantes par mois"}
          />
        </Col>
      </Row>

      <Row className="overview-chart-row">
        <Col xs={24} sm={24} md={{ span: 6, offset: 1 }} lg={{ span: 6, offset: 1 }}>
          <OverviewGraphComponent
            data={props.dataAnalysis[0].allUsers}
            title={"Nombre d'utilisateurs ajoutés par mois"}
          />
        </Col>
        <Col xs={24} sm={24} md={{ span: 6, offset: 1 }} lg={{ span: 6, offset: 2 }}>
          <OverviewGraphComponent
            data={props.dataAnalysis[0].allFirms}
            title={"Nombre de cabinets comptables ajoutés par mois"}
          />
        </Col>
        <Col xs={24} sm={24} md={{ span: 6, offset: 1 }} lg={{ span: 6, offset: 2 }}>
          <OverviewGraphComponentGroup
            data={props.dataAnalysis[0].outstandingsClientsMailArray.concat(
              props.dataAnalysis[0].outstandingsSuppliersMailArray
            )}
            title={"Nombre total de mail d'encours envoyés"}
          />
        </Col>
      </Row>
      <Row className="overview-chart-row">
        <Col xs={24} sm={24} md={{ span: 6, offset: 1 }} lg={{ span: 6, offset: 1 }}>
          <OverviewGraphComponent
            data={props.dataAnalysis[0].activeUsersChart}
            title={"Utilisateurs actifs en fin de mois"}
          />
        </Col>
        <Col xs={24} sm={24} md={{ span: 6, offset: 1 }} lg={{ span: 6, offset: 2 }}>
          <OverviewGraphComponent
            data={props.dataAnalysis[0].regularUsersChart}
            title={"Utilisateurs réguliers en fin de mois"}
          />
        </Col>
      </Row>
      <Row className="overview-chart-row">
        <Col xs={24} sm={24} md={{ span: 23, offset: 1 }} lg={{ span: 22, offset: 1 }}>
          <OverviewGraphLine
            data={props.dataAnalysis[0].connectionsChart}
            title={"Nombre d'utilisateurs uniques par jour"}
          />
        </Col>
      </Row>
    </div>
  );
}

export default OverviewGraphs;

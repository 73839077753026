import { Col, Row, Typography } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as API from "../../api/API";
import { selectAccountingFirm } from "../../slices/accountingFirmSlice";
import { selectUser } from "../../slices/userSlice";
import LoaderRaw from "../../utils/LoaderRaw.js";
import Footer from "../footer/Footer.js";
import Nav from "../nav/Nav.js";
import PaymentRequired from "../unauthorized/PaymentRequired";
import VPF from "./vpfv2/VPF.js";

import logAction from "utils/logActions";
import { TimeMeasurement } from "utils/timeMeasurement";
import "./manager.css";

const { Text } = Typography;

function ManagerView(props) {
  const accountingFirm = useSelector(selectAccountingFirm);
  const user = useSelector(selectUser);
  const [loading, setLoading] = useState(true);

  const timeMeasurement = new TimeMeasurement();
  useEffect(() => {
    return () => {
      logAction(262, timeMeasurement.getElapsedTime(), user._id, accountingFirm._id);
    };
  }, []);

  useEffect(() => {
    (async () => {
      if (accountingFirm.status === "ready") {
        setLoading(false);
      }
      if (user.status === "ready") {
        await API.clickedVPF({ userId: user._id });
      }
    })();
  }, [accountingFirm._id]);

  return (
    <div className="manager">
      <Nav {...props} />
      <Row justify="center" className="admin-content">
        <Col xs={22} sm={22} md={22} lg={22} xl={22} className="admin-box">
          {loading ? (
            <LoaderRaw />
          ) : (
            <>
              {!accountingFirm.proAbonoFeatures ? null : !accountingFirm.proAbonoFeatures?.includes(
                  "portfolio"
                ) ? (
                <PaymentRequired {...props} page={"portfolio"} />
              ) : (
                <>
                  <div className="vpf">
                    <Text className="section-title">Vue portefeuille</Text>
                    <div className="blue-bar"> </div>
                    <VPF {...props} />
                  </div>
                </>
              )}
            </>
          )}
        </Col>
      </Row>
      <Footer />
    </div>
  );
}

export default ManagerView;

import React, { useState, useEffect } from "react";
import { Table, Typography } from "antd";
import { LinkOutlined, WarningOutlined } from "@ant-design/icons";

import * as API from "../../../api/API";
import LoaderRaw from "../../../utils/LoaderRaw";

const { Text } = Typography;

function Proabono() {
  const [firmList, setFirmList] = useState([]);
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    (async () => {
      let customers = await API.getCustomers();
      customers = await customers.json();
      setDataSource(customers.data);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      let resA = await API.getAccountingFirms();
      resA = await resA.json();
      setFirmList(resA);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      width: "40px",
      align: "center",
      render: (text, record) => (
        <a
          href={
            "https://via.proabono.com/App/" +
            (process.env.REACT_APP_ENV === "prod" ? "1177" : "1194") +
            "/Sub/Customer/" +
            record.Id
          }
          target="_blank"
          rel="noreferrer"
        >
          <LinkOutlined />
        </a>
      ),
    },
    {
      title: "Abonnement Proabono",
      dataIndex: "Name",
      sorter: (a, b) => a.Name?.localeCompare(b.Name),
    },
    {
      title: "Abonnement Sesha",
      dataIndex: "ReferenceCustomer",
      render: (text, record) =>
        firmList.find((el) => el._id === record.ReferenceCustomer)?.name || (
          <WarningOutlined style={{ color: "#c32028" }} />
        ),
    },
  ];

  return (
    <div className="userDashboard">
      <br />
      <Text className="section-title">
        Abonnement ProAbono ({dataSource?.length} / {firmList?.length})
      </Text>
      <div className="blue-bar"></div>
      <br />

      <Table
        columns={columns}
        dataSource={dataSource}
        size="small"
        bordered
        pagination={{ defaultPageSize: 10, showSizeChanger: true }}
        loading={{ indicator: <LoaderRaw />, spinning: !dataSource }}
      />
    </div>
  );
}

export default Proabono;

import { Button, Form, Input } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as API from "../../api/API";
import { selectAccountingFirm, updateAccountingFirm } from "../../slices/accountingFirmSlice";
import openNotification from "../../utils/notification";
import TemplateBtns from "./TemplateBtns";
import TemplateMailTextEditor from "./TemplateMailTextEditor.js";

const dictTemplates = {
  form: "templateMailForm",
  formreminder: "templateMailFormReminder",
};

function TemplateMailForms(props) {
  const accountingFirm = useSelector(selectAccountingFirm);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [subject, setSubject] = useState("");
  const [cursorPosition, setCursorPosition] = useState(0);
  const [hideSubjectBtns, setHideSubjectBtns] = useState(true);
  const [editTemplate, setEditTemplate] = useState("");
  const [templateTxt, setTemplateTxt] = useState([]);

  const currentTemplate = dictTemplates[props.type];

  useEffect(() => {
    setSubject(props.mailTemplate.subject);
    setTemplateTxt(props.mailTemplate.body);
  }, []);

  const onSubmit = (values, type) => {
    setEditTemplate("");
    const data = {
      subject: values.subject,
      body: templateTxt,
    };

    (async () => {
      const res = await API.putAccountingFirm(accountingFirm._id, {
        [currentTemplate]: data,
      });

      if (res.status === 201) {
        openNotification("success", "Modification prise en compte");
        dispatch(
          updateAccountingFirm({
            [currentTemplate]: data,
          })
        );
        switch (type) {
          case "form":
            //logAction(122, 1);
            break;
          case "formreminder":
            // logAction(123, 1);
            break;

          default:
            break;
        }
      } else openNotification("error", "Erreur lors de la modification");
    })();
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={(values) => onSubmit(values, props.type)}
      //className="mail-template-form"
      initialValues={{
        subject: props.mailTemplate?.subject,
      }}
    >
      <Form.Item name="subject" label="Objet du mail">
        <Input
          bordered={true}
          onChange={(e) => setSubject(e.target.value)}
          className={"form-input"}
          onClick={(e) => {
            setCursorPosition(e.target.selectionStart);
            setHideSubjectBtns(false);
          }}
        />
      </Form.Item>
      {!hideSubjectBtns ? (
        <TemplateBtns
          template={props.type}
          insertText={(data) => {
            let textBeforeCursorPosition = subject.substring(0, cursorPosition);
            let textAfterCursorPosition = subject.substring(cursorPosition, subject.length);
            form.setFieldsValue({
              subject: textBeforeCursorPosition + data + textAfterCursorPosition,
            });
            setSubject(textBeforeCursorPosition + data + textAfterCursorPosition);
          }}
        />
      ) : null}

      <p>Corps du mail</p>

      {templateTxt.map((elt, i) => {
        return (
          <div key={props.type + "-" + elt.para + "-" + i}>
            {i === props.mailTemplate.body.length - 1 && props.mailTemplate.linkText ? (
              <p className="fake-link fake-link-template">{props.mailTemplate.linkText}</p>
            ) : null}
            <Form.Item
              //name={props.type + "-" + elt.para}
              className={
                editTemplate.split("-")[0] === props.type && editTemplate.split("-")[1] === elt.para
                  ? "edit-signature"
                  : ""
              }
              onClick={(e) => {
                e.preventDefault();
                setEditTemplate(props.type + "-" + elt.para);
                setHideSubjectBtns(true);
              }}
            >
              <TemplateMailTextEditor
                {...props}
                template={props.type === "global" ? elt.para : props.type}
                text={elt.text}
                setText={(data) => {
                  if (editTemplate !== "") {
                    var bodyText = [...templateTxt];
                    var index = props.mailTemplateInit.body.indexOf(
                      props.mailTemplateInit.body.find(
                        (elt) => elt.para === editTemplate.split("-")[1]
                      )
                    );
                    if (templateTxt.find((elt) => elt.para === editTemplate.split("-")[1])) {
                      bodyText[index] = { para: editTemplate.split("-")[1], text: data };
                    }
                    setTemplateTxt(bodyText);
                  }
                }}
                setHide={(data) => {
                  if (data) {
                    setHideSubjectBtns(true);
                  }
                }}
                hide={
                  editTemplate.split("-")[0] === props.type &&
                  editTemplate.split("-")[1] === elt.para &&
                  hideSubjectBtns === true
                    ? false
                    : true
                }
              />
            </Form.Item>
          </div>
        );
      })}
      <div>
        <Button
          className="other-action-btn cancel-btn"
          onClick={(e) => {
            e.preventDefault();
            form.setFieldsValue({
              subject: props.mailTemplateInit.subject,
              [editTemplate]: props.mailTemplateInit.body,
            });
            setEditTemplate("");
            setSubject(props.mailTemplateInit.subject);
            setTemplateTxt(props.mailTemplateInit.body);

            (async () => {
              const resInit = await API.putAccountingFirm(accountingFirm._id, {
                [currentTemplate]: props.mailTemplateInit,
              });

              if (resInit.status === 201) {
                openNotification("success", "Modification prise en compte");
                dispatch(
                  updateAccountingFirm({
                    [currentTemplate]: props.mailTemplateInit,
                  })
                );
              } else openNotification("error", "Erreur lors de la modification");
            })();
          }}
        >
          Réinitialiser
        </Button>
        <Form.Item className="submit-changes-btn">
          <Button
            type="primary"
            htmlType="submit"
            className="call-action-btn modal-submit-btn save-signature"
          >
            Sauvegarder
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
}

export default TemplateMailForms;

import React from "react";
import { useSelector } from "react-redux";
import { selectAccountingFirm } from "../../../slices/accountingFirmSlice";
import { selectClient } from "../../../slices/clientSlice";
import "../Nav.css";
import { Button, Popover } from "antd";

import LogoMcfOn from "../../../assets/images/mcfvalid.png";
import LogoMcfOff from "../../../assets/images/mcfoff.png";
import LogoMcfRed from "../../../assets/images/mcfnotvalid.png";

function Mcf() {
  const accountingFirm = useSelector(selectAccountingFirm);
  const client = useSelector(selectClient);

  const getPopoverText = (num) => {
    switch (num) {
      case 0:
        return "Dossier connecté à MyCompanyFiles !";
      case 1:
        return "Dossier non connecté à MyCompanyFiles. \nVeuillez renseigner un numéro de SIREN";
      case 2:
        return "Dossier non connecté à MyCompanyFiles. \nLe numéro de SIREN renseigné \nn’a pas permis de trouver le dossier client.";
      case 3:
        return "Dossier non connecté à MyCompanyFiles. \nErreur dans la saisie du token.";
      // 4 = Token null, cabinet non connecté à MCF volontairement
      default:
        return "Error";
    }
  };

  return (
    <>
      {accountingFirm.mcf && client.mcfState !== 4 ? (
        <Popover
          placement="top"
          content={getPopoverText(client.mcfState)}
          className="info-popover"
          overlayClassName={
            client.mcfState === 0
              ? "mcf-success-popover mcf-success-popover-subnav"
              : "mcf-fail-popover mcf-fail-popover-subnav"
          }
        >
          <Button
            className={
              client.mcfState === 0 || client.mcfState === 1
                ? "client-icon"
                : "client-icon client-icon-mcf-fail"
            }
            shape="circle"
            style={{ color: "black" }}
            icon={
              <img
                src={
                  client.mcfState === 0
                    ? LogoMcfOn
                    : client.mcfState === 2 || client.mcfState === 3
                    ? LogoMcfRed
                    : LogoMcfOff
                }
                alt="Logo MCF"
                width="24px"
              />
            }
          />
        </Popover>
      ) : null}
    </>
  );
}

export default Mcf;

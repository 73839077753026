import React, { useState, useEffect } from "react";
import * as API from "../../api/API";
import { Button, List, Form, Tooltip, Select, Row, Col } from "antd";
import { ArrowRightOutlined, DeleteOutlined } from "@ant-design/icons";
import openNotification from "../../utils/notification";
import { useSelector } from "react-redux";
import { selectAccountingFirm } from "../../slices/accountingFirmSlice";
import LoaderRaw from "../../utils/LoaderRaw";

const { Option } = Select;

function ExcludeSettings(props) {
  const accountingFirm = useSelector(selectAccountingFirm);
  const [allCodes, setAllCodes] = useState([]);
  const [excludedCodesWithNames, setExcludedCodesWithNames] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      let response = await API.getDataProviderCodes();
      if (response.status === 200) {
        response = await response.json();
        setAllCodes(
          response.codes.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
        );

        setLoading(false);
      } else {
        console.log("error");
      }
      let resA = await API.getAccountingFirm(accountingFirm._id);
      resA = await resA.json();
      setExcludedCodesWithNames(resA.excludedList);
    })();
  }, []);

  const onFinish = (values) => {
    setExcludedCodesWithNames([
      ...excludedCodesWithNames,
      ...values.dataProviderCodes.filter((elt) => !excludedCodesWithNames.includes(elt)),
    ]);

    (async () => {
      try {
        await API.putAccountingFirm(accountingFirm._id, {
          excludedList: [
            ...excludedCodesWithNames,
            ...values.dataProviderCodes.filter((elt) => !excludedCodesWithNames.includes(elt)),
          ],
        });
        openNotification("success", "Modifications enregistrées");
      } catch (error) {
        console.log(error);
        openNotification("error", "Erreur lors de l'enregistrement");
      }
    })();
  };

  const removeExcluded = (code) => {
    setExcludedCodesWithNames(excludedCodesWithNames.filter((item) => item !== code));
    (async () => {
      try {
        await API.putAccountingFirm(accountingFirm._id, {
          excludedList: excludedCodesWithNames.filter((item) => item !== code),
        });
        openNotification("success", "Modifications enregistrées");
      } catch (error) {
        console.log(error);
        openNotification("error", "Erreur lors de l'enregistrement");
      }
    })();
  };

  return (
    <div className="excluded-settings">
      <h3 style={{ marginBottom: "0px" }}>
        <strong>Dossiers à exclure</strong>
      </h3>
      <div className="blue-bar" style={{ marginBottom: "20px" }}></div>
      {loading ? (
        <LoaderRaw />
      ) : (
        <div className="exclude">
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <span className="add-acd-txt">
                Les dossiers exclus ne pourront plus êtres importés dans Sesha via la page "Ajouter
                un client". Si ceux-ci sont déjà importés, vous pouvez les masquer aux utilisateurs
                classiques via la colonne "Masquer" à droite du tableau des clients.
              </span>
              <Form className="add-acd-form" name="acd" onFinish={onFinish} layout="inline">
                <Form.Item name="dataProviderCodes" className="select-acd">
                  <Select
                    mode="multiple"
                    notFoundContent="Pas de dossier correspondant"
                    placeholder="Codes des dossiers"
                    allowClear
                    popupClassName="ant-select-dropdown-zindex"
                    placement="bottomLeft"
                    dropdownAlign={{ overflow: { adjustY: false } }}
                  >
                    {allCodes.map((code) => {
                      return (
                        <Option key={code.name + "*****" + code.code}>
                          {code.name} - {code.code}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item className="submit-acd">
                  <Button
                    type="submit"
                    htmlType="submit"
                    className="call-action-btn link-button-fec"
                  >
                    Exclure <ArrowRightOutlined />
                  </Button>
                </Form.Item>
              </Form>
              <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                <br />
                <p className="settings-category-p">Liste des dossiers exclus</p>
                <List
                  dataSource={excludedCodesWithNames}
                  locale={{ emptyText: "Aucun" }}
                  renderItem={(item) => (
                    <List.Item>
                      {item.split("*****")[0]} - {item.split("*****")[1]}
                      <Tooltip placement="top" title="Retirer de la liste">
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            removeExcluded(item);
                          }}
                          className="remove-list-btn"
                        >
                          <DeleteOutlined />
                        </Button>
                      </Tooltip>
                    </List.Item>
                  )}
                  className="excluded-list"
                />
              </Col>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
}

export default ExcludeSettings;

import * as dayjs from "dayjs";
import "dayjs/locale/fr";

function getDate() {
  var d = new Date();
  var n = d.getDate();
  var date_table = [];
  dayjs.locale("fr");
  var start_date = n < 15 ? dayjs().subtract(2, "months") : dayjs().subtract(1, "months");

  for (var i = 0; i < 13; i++) {
    date_table.push(dayjs().subtract(i, "months").format("MMMM YYYY"));
  }

  return {
    start_date: start_date.format("MMMM YYYY"),
    date_table,
    date_obj: start_date.startOf("month"),
  };
}

const getMailDate = (date) => {
  const mailDate = dayjs(date);
  return "le " + mailDate.format("DD/MM/YYYY") + " à " + mailDate.format("HH[h]mm");
};

function getDownloadDate() {
  const d = new Date();
  const month = d.getMonth() + 1;
  const day = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
  const year = d.getFullYear();
  return day + "" + month + "" + year;
}

function findPreviousMonthClosestDay(date, monthOffset, dayNumber) {
  // set the day number on the date and get the previous month
  let closestDay = date.set("date", dayNumber).subtract(monthOffset, "month");

  // if you are in the same month, you skipped month while setting the day
  if( closestDay.month() ===  date.month() ) {
    // it means the day number was too high and you should fall back to the last day
    // get that from month start minus 1 day
    closestDay = closestDay.set("date", 1).subtract(1, "day");
  }

  return closestDay;
}

export { findPreviousMonthClosestDay, getDate, getDownloadDate, getMailDate };


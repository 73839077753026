const parseNum = (num) =>
  parseFloat(num).toLocaleString("fr-FR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

const parseToAmount = (x) =>
  parseNum(x)
    .toString()
    .replace(/[\u202F\u00A0]/g, " ") + " €";

const withThousandSeparator = (x) => x.toString().replace(/[\u202F\u00A0]/g, " ") + " €";

export { parseNum, parseToAmount, withThousandSeparator };



// don't blame me, I just don't know
export function selectEndDateType(missingDocEndDate) {
    switch (missingDocEndDate) {
        // corresponding selected mode and default mode
        case 101:
        case undefined:
            return "Date du jour";
        // last day of previous month mode
        case 102:
            return "Dernier jour m-1";
      default:
        // why not?
        if (missingDocEndDate > 31) return "Date du jour";

        // value should be between 1 and 31 already
        return "Le dernier " + missingDocEndDate
    }
}
import { useEffect, useState } from "react";
import * as sentry from "../../utils/sentry";

import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import * as API from "../../api/API";
import { selectAccountingFirm } from "../../slices/accountingFirmSlice";
import { resetClient, selectClient, updateClient } from "../../slices/clientSlice";

import hasFeature from "utils/dataProviderFeatures";
import { getDate } from "../../utils/getDate";
import openNotification from "../../utils/notification";
import Alert from "../alert/Alert.js";
import ClientParams from "../clientsettings/ClientParams";
import Footer from "../footer/Footer.js";
import Nav from "../nav/Nav.js";
import SubNav from "../nav/SubNav.js";
import Tracking from "../tracking/Tracking";
import CustomPrompt from "./CustomPrompt";
import IndexAnalysisTabs from "./IndexAnalysisTabs";
import MailDrawer from "./MailDrawer";

const { confirm } = Modal;

var months = [
  "janvier",
  "février",
  "mars",
  "avril",
  "mai",
  "juin",
  "juillet",
  "août",
  "septembre",
  "octobre",
  "novembre",
  "décembre",
];

function monthNameToNum(monthname) {
  var month = months.indexOf(monthname);
  return month + 1;
}
const resdate = getDate();

const startDate = {
  month: monthNameToNum(resdate.start_date.split(" ")[0]),
  fiscal_year: resdate.start_date.split(" ")[1],
};

function NewIndexAnalysis(props) {
  const clientId = props.match.params.clientId;
  const clientRemoteId = props.match.params.clientRemoteId;

  const dispatch = useDispatch();
  const client = useSelector(selectClient);
  const accountingFirm = useSelector(selectAccountingFirm);

  const [forbidden, setForbidden] = useState(false);
  const [isImportActive, setImportActive] = useState(false);
  const [refreshDP, setRefreshDP] = useState(false);
  const [showTracker, setShowTracker] = useState(false);
  const [, setActiveKey] = useState("1");
  const [reloadAnalysis, setReloadAnalysis] = useState(true);

  const [wait, setWait] = useState(true);
  const [date, setDate] = useState(startDate);

  const [selectedMailType, setSelectedMailType] = useState("");

  const [totalNbr, setTotalNbr] = useState(0);
  const [missingAmount, setMissingAmount] = useState(0);
  const [filteredOperations, setFilteredOperations] = useState([]);
  const [others, setOthers] = useState([]);
  const [nbrMissing, setMissing] = useState(0);
  const [nbrOutstandingClients, setNbrOutstandingClients] = useState();
  const [nbrOutstandingProviders, setNbrOutstandingProviders] = useState();
  const [outstandingsClientsAmount, setOutstandingsClientsAmount] = useState(0);
  const [outstandingsProvidersAmount, setOutstandingsProvidersAmount] = useState(0);
  const [selectedOperationsProviders, setSelectedOperationsProviders] = useState([]);
  const [selectedOperationsClients, setSelectedOperationsClients] = useState([]);
  const [nbrReceived, setReceived] = useState(0);
  const [count, setCount] = useState(0);

  const [resetBadges, setResetBadges] = useState(false);

  const [sendData, setSendData] = useState([]);
  const [analysisData, setAnalysisData] = useState();

  useEffect(() => {
    const uuid = uuidv4();
    sentry.addTag("eventId", uuid);
  }, []);

  useEffect(() => {
    setDate(startDate);
  }, [clientId]);

  const showEmailEditAlert = (type) => {
    confirm({
      title: "",
      icon: (
        <ExclamationCircleOutlined
          className="prompt-icon"
          style={{ height: "200px", top: "-84px" }}
        />
      ),
      className: "modal-invalid modal-invalid-mail",
      maskClosable: false,
      closable: false,
      content: (
        <div>
          <div className="prompt-text-style">
            <p>
              Attention ! Un mail est déjà en cours d'édition sur ce dossier client. Si vous
              continuez, les modifications effectuées sur celui-ci seront perdues.
            </p>
            <p style={{ margin: "0px" }}>Êtes-vous sûr de vouloir continuer ?</p>
            <p>
              <i>Pour accéder à votre email, cliquez sur le volet à droite de votre fenêtre.</i>
            </p>
          </div>
          <div className="prompt-btns">
            <Button
              className="other-action-btn invalid-cancel"
              onClick={(e) => {
                e.preventDefault();
                console.log("annuler");
                Modal.destroyAll();
              }}
            >
              Annuler
            </Button>
            <Button
              className="call-action-btn"
              onClick={(e) => {
                e.preventDefault();
                console.log("continuer");
                // setSelectedMailType("");
                setSelectedMailType(type);
                Modal.destroyAll();
                setCount(0);
              }}
            >
              Continuer
            </Button>
          </div>
        </div>
      ),
    });
  };

  const refreshDataProvider = async () => {
    const res = await API.postDataProviderClient({
      clientRemoteId,
      clientId,
    });

    if (res.status === 500) {
      openNotification(
        "error",
        "Une erreur interne est survenue, merci de réessayer dans quelques minutes."
      );
    } else if (res.status !== 200) {
      openNotification("error", "Impossible de mettre à jour le dossier");
    }
    return;
  };

  useEffect(() => {
    if (accountingFirm.status === "ready")
      (async () => {
        setWait(true);
        setResetBadges(true);
        setActiveKey("1");
        setSelectedMailType("");

        let clientResponse = await API.getClient(clientId);
        if (clientResponse.status === 200) {
          clientResponse = await clientResponse.json();
          if (hasFeature(accountingFirm.fec, accountingFirm.dataProvider, "DAILY_UPDATE")) {
            const today = new Date();
            const clientUpdateDate = new Date(clientResponse.updateDate);

            if (
              clientUpdateDate.getDate() !== today.getDate() ||
              clientUpdateDate.getMonth() !== today.getMonth() ||
              clientUpdateDate.getFullYear() !== today.getFullYear()
            )
              await refreshDataProvider();
          }
          await dispatch(resetClient());
          await dispatch(updateClient(clientResponse));
        }

        setWait(false);
      })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, clientId, accountingFirm.status, reloadAnalysis]);

  useEffect(() => {
    // check auth
    (async () => {
      const res = await API.getClient(clientId);
      if (res.status === 401) setForbidden(true);
      else if (res.status === 403) setForbidden(true);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (refreshDP) {
      (async () => {
        await refreshDataProvider();
        setReloadAnalysis(!reloadAnalysis);
        setRefreshDP(false);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshDP]);

  useEffect(() => {
    (async () => {
      if (client.status === "ready") {
        if (accountingFirm.mcf) {
          let resMcf = await API.testMCFConnections({
            accountingFirmId: accountingFirm._id,
            clients: [{ _id: clientId, siren: client.siren }],
          });
          dispatch(updateClient({ mcfState: resMcf[0].statusCode }));
        }
        if (accountingFirm.iDocus) {
          let resIDocus = await API.testIDocusConnection({ clientId });
          dispatch(updateClient({ iDocusState: resIDocus.statusCode }));
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client.status]);

  const handleRefUpdate = (ref) => {
    setCount(ref.current);
  };

  return (
    <>
      {!forbidden ? (
        <>
          <div className="analysis">
            {selectedMailType !== "" ? (
              <CustomPrompt title="Voulez-vous vraiment quitter la page ?" when={true} />
            ) : null}
            <MailDrawer
              {...props}
              type={selectedMailType}
              missingdoc={nbrMissing}
              totaldoc={totalNbr}
              amount={missingAmount}
              filteredOp={filteredOperations}
              selectedOperationsClients={selectedOperationsClients}
              selectedOperationsProviders={selectedOperationsProviders}
              others={others}
              clientSiren={client.siren}
              emailData={[
                {
                  type: "missingdoc",
                  nbr: others ? nbrMissing + others.length : nbrMissing,
                  amount: missingAmount,
                },
                {
                  type: "outstandingsclients",
                  nbr: nbrOutstandingClients,
                  amount: outstandingsClientsAmount,
                },
                {
                  type: "outstandingsproviders",
                  nbr: nbrOutstandingProviders,
                  amount: outstandingsProvidersAmount,
                },
              ]}
              sendData={sendData}
              analysisData={analysisData}
              setSelectedMailType={(data) => {
                setSelectedMailType(data);
              }}
              date={date}
              count={count}
            />
            <div>
              <Nav
                {...props}
                setKey={(data) => {
                  setActiveKey(data);
                  setImportActive(false);
                }}
              />
              <SubNav
                {...props}
                importActive={(data) => setImportActive(data)}
                refreshDP={refreshDP}
                reload={(data) => {
                  setReloadAnalysis(!reloadAnalysis);
                }}
                refreshDPSetter={(data) => setRefreshDP(data)}
                refreshAGIRIS={() => refreshDataProvider()}
                showHistory={(data) => {
                  setShowTracker(data);
                }}
                // ongoingSync={ongoingSync} à retirer ?
              />
              {isImportActive ? (
                /*<Import
                  {...props}
                  clientUpdate={true}
                  clientId={clientId}
                  nbrReceived={nbrReceived}
                  activeKeyImport={(data) => setActiveKey(data)}
                  importActive={(data) => setImportActive(data)}
              />*/

                <ClientParams
                  {...props}
                  clientId={clientId}
                  nbrReceived={nbrReceived}
                  activeKeyImport={(data) => setActiveKey(data)}
                  importActive={(data) => setImportActive(data)}
                />
              ) : (
                <IndexAnalysisTabs
                  {...props}
                  date={date}
                  setDate={setDate}
                  wait={wait}
                  setTotalNbr={setTotalNbr}
                  setMissingAmount={setMissingAmount}
                  setFilteredOperations={setFilteredOperations}
                  setOthers={setOthers}
                  setMissing={setMissing}
                  setSelectedMailType={(data) => {
                    if (selectedMailType !== "" && selectedMailType !== data) {
                      showEmailEditAlert(data);
                    } else {
                      setSelectedMailType(data);
                    }
                  }}
                  setAnalysisData={setAnalysisData}
                  setSendData={setSendData}
                  setNbrOutstandingClients={setNbrOutstandingClients}
                  setNbrOutstandingProviders={setNbrOutstandingProviders}
                  setOutstandingsClientsAmount={setOutstandingsClientsAmount}
                  setOutstandingsProvidersAmount={setOutstandingsProvidersAmount}
                  setSelectedOperationsProviders={setSelectedOperationsProviders}
                  setSelectedOperationsClients={setSelectedOperationsClients}
                  setReceived={setReceived}
                  selectedMailType={selectedMailType}
                  resetBadges={resetBadges}
                  onRefUpdate={(data) => {
                    handleRefUpdate(data);

                    /*if (selectedMailType !== "" && selectedMailType !== data) {
                      showEmailEditAlert(data);
                    } else {
                    }*/
                  }}
                />
              )}
              <Footer />
            </div>
            <Tracking
              {...props}
              showModal={showTracker}
              showModalTracking={(data) => setShowTracker(data)}
              clientId={clientId}
            />
          </div>
        </>
      ) : (
        <Alert
          {...props}
          title="Accès refusé"
          text="Vous n'êtes pas autorisé à accéder à cette page."
        />
      )}
    </>
  );
}
export default NewIndexAnalysis;

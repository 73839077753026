import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAccountingFirm } from "../api/API";

const initialState = {
  mcfFolder: "ZONE DE DEPOT",
  mcfSubFolder: "SUSPENS TRANSMIS",
  mcfSiren: "Siren",
  status: "empty",
};

export const fetchAccountingFirmAsync = createAsyncThunk(
  "accountingFirm/fetchAccountingFirm",
  async (id) => {
    if (id) {
      const res = await getAccountingFirm(id);
      return await res.json();
    }
  }
);

export const accountingFirmSlice = createSlice({
  name: "accountingFirm",
  initialState,
  reducers: {
    updateAccountingFirm: (state, action) => {
      state = Object.assign(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAccountingFirmAsync.pending, (state) => {
        state.status = "busy";
      })
      .addCase(fetchAccountingFirmAsync.fulfilled, (state, action) => {
        state = Object.assign(state, action.payload);
        state.status = "ready";
      });
  },
});

export const { updateAccountingFirm } = accountingFirmSlice.actions;

export const selectAccountingFirm = (state) => state.accountingFirm;

export default accountingFirmSlice.reducer;

import { UserAddOutlined } from "@ant-design/icons";
import { Alert, Button, DatePicker, Form, Input, Modal, Select } from "antd";
import locale from "antd/es/date-picker/locale/fr_FR";
import "dayjs/locale/fr";
import { EFeatures } from "enums/EFeatures";
import { useState } from "react";
import { hasFeatures } from "utils/security";
import * as API from "../../../api/API";
import openNotification from "../../../utils/notification";

const { Option } = Select;

function CreateUser(props) {
  const [form] = Form.useForm();
  const [createUser, setCreateUser] = useState(false);
  const [alert, setAlert] = useState(false);
  const [currentAF, setCurrentAF] = useState();

  // tri des cabinets par ordre alphabétique
  const firmList = props.firmList.sort(function (a, b) {
    var textA = a.name.toUpperCase();
    var textB = b.name.toUpperCase();
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });

  const onFinish = (values) => {
    var randompwd = Math.random().toString(36).slice(-8);
    values.password = randompwd;
    values.passwordConf = randompwd;

    (async () => {
      let res = await API.postUser(values);
      res = await res.json();
      if (res.status === 404) {
        setAlert(true);
      } else {
        setCreateUser(false);
        props.updateUser(true);

        if (values.schedule) {
          const resschedule = await API.postScheduleUserCreation({
            userId: res._id,
            date: values.schedule,
          });
          if (resschedule.status === 201)
            openNotification("success", "Utilisateur créé avec succès et mail programmé");
          else openNotification("error", "Erreur lors de la programmation du mail");
        } else {
          const resmail = await API.postUserCreationMail({
            email: values.email,
            firstName: values.firstName,
            pwd: randompwd,
            schedule: values.schedule,
          });
          if (resmail.status === 200) openNotification("success", "Utilisateur créé avec succès");
          else openNotification("error", "Erreur lors de la création de l'utilisateur");
        }
      }
    })();
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const validateMessages = {
    // eslint-disable-next-line no-template-curly-in-string
    required: "${label} doit être renseigné",
    types: {
      email: "L'email n'est pas valide",
    },
  };

  return (
    <div className="create-user">
      {hasFeatures([EFeatures.SUPERADMIN_WRITE, EFeatures.SUPERADMIN_TECH]) && (
        <Button
          className="call-action-btn"
          onClick={(e) => {
            e.preventDefault();
            setCreateUser(!createUser);
          }}
          icon={<UserAddOutlined />}
        >
          Ajouter un utilisateur
        </Button>
      )}
      <Modal
        title="Création d'un nouvel utilisateur"
        width={620}
        className="dashboard-modal"
        open={createUser}
        onCancel={(e) => {
          e.preventDefault();
          setCreateUser(false);
        }}
        footer={null}
        forceRender
      >
        {alert === true ? (
          <Alert
            className="alert-duplicate"
            message="Un utilisateur existe déjà avec cette adresse mail."
            type="error"
            closable
            banner
            onClose={() => setAlert(false)}
          />
        ) : null}
        <Form
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          labelAlign="left"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          validateMessages={validateMessages}
        >
          <Form.Item
            name="firstName"
            label="Prénom"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="lastName"
            label="Nom"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="accountingFirmId"
            label="Cabinet Comptable"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              showSearch={true}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              placement="bottomLeft"
              dropdownAlign={{ overflow: { adjustY: false } }}
              onSelect={(value) => setCurrentAF(value)}
            >
              {firmList.map((item) => (
                <Option key={item._id}>{item.name}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            className="select-role"
            name="newRole"
            label="Droits associés"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select>
              {props.roleList
                .filter(
                  (el) => el.accountingFirmId === currentAF || el.accountingFirmId === "SESHA_ADMIN"
                )
                .map((role) => (
                  <Option key={role._id} value={role._id}>
                    {role.label}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="email"
            label="Adresse email"
            rules={[
              {
                type: "email",
                required: true,
                message: "Merci de renseigner l'adresse email",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="schedule" label="Programmer l'envoi du mail">
            <DatePicker locale={locale} />
          </Form.Item>
          <Form.Item wrapperCol={{ span: 24 }}>
            <Button
              block
              className="call-action-btn modal-submit-btn"
              htmlType="submit"
              icon={<UserAddOutlined />}
            >
              Créer
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default CreateUser;

import React from "react";
import "../unauthorized.css";
import svg from "../../../assets/images/payment/analysisConfig_2.svg";

function AnalysisConfig(props) {
  return (
    <>
      <p style={{ marginTop: "30px" }}>
        Grâce au <b>paramétrage des comptes,</b> vous pouvez redéfinir les règles de calcul de
        toutes les notions essentielles de vos dossiers clients : chiffre d’affaires, marge, masse
        salariale, suspens...
      </p>
      <img src={svg} alt="Configuration analyse" />
      <p style={{ marginTop: "20px" }}>
        Vos règles personnalisées peuvent être enregistrées comme des modèles que vous pourrez
        appliquer <b>aux dossiers clients de votre choix.</b>
      </p>
    </>
  );
}

export default AnalysisConfig;

import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Col, Popover, Row, Typography } from "antd";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { selectClient } from "../../../slices/clientSlice";
import TextAnalysis from "./TextAnalysis";

const { Text } = Typography;

let textColor = "#2F2F2F";
if (localStorage.getItem("theme") && localStorage.getItem("theme") === "DARK")
  textColor = "#ffffff";

am4core.useTheme(am4themes_animated);

const indicName = {
  ca: "Chiffre d'affaires",
  marge: "Marge",
  masse_sal: "Masse salariale",
  treso: "Trésorerie",
  charges_ext: "Charges externes",
  result: "Résultat",
};

function Report(props) {
  const client = useSelector(selectClient);

  const [imgIndics, setImgIndics] = useState([]);

  const textRefs = useRef([]);
  const chartRefs = useRef([]);

  const monthNames = [
    "janv.",
    "févr.",
    "mars",
    "avr.",
    "mai",
    "juin",
    "juil.",
    "août",
    "sept.",
    "oct.",
    "nov.",
    "déc.",
  ];

  function monthNumToName(num) {
    return monthNames[num - 1];
  }

  function isDifferenceLessThan(diff, data, pastYearComp) {
    const allValues = data.reduce((acc, obj) => {
      if (obj.current_year !== null) {
        acc.push(parseFloat(obj.current_year));
      }
      if (!pastYearComp && obj.past_year !== null) {
        acc.push(parseFloat(obj.past_year));
      }
      return acc;
    }, []);

    const min = Math.min(...allValues);
    const max = Math.max(...allValues);

    return max - min < diff;
  }

  useEffect(() => {
    //TODO : add filter on visible
    props.setAllEmailImagesReady(
      props.analysisTemplate.templateAnalysis.filter(
        (elt) => elt.visible || elt.visible === undefined
      ).length -
        1 ===
        imgIndics.length && imgIndics.length > 0
    );
  }, [JSON.stringify(imgIndics)]);

  useEffect(() => {
    (async () => {
      /* Bar charts function */
      function barChart(divid, title, data, chartcurrent) {
        let x = am4core.create(divid, am4charts.XYChart);

        x.data = data;
        x.data = data.map((el) => ({
          ...el,
          month: monthNumToName(el.month),
          date: client.forecast?.settings?.active?.global
            ? monthNumToName(el.month) + " " + el.year.slice(-2)
            : "",
        }));

        x.colors.step = 2;

        var topContainer = x.chartContainer.createChild(am4core.Container);
        topContainer.layout = "absolute";
        topContainer.toBack();
        topContainer.paddingBottom = 35;
        topContainer.width = am4core.percent(100);

        var htmlObject = document.createElement("span");
        htmlObject.innerHTML = title;

        var chartTitle = topContainer.createChild(am4core.Label);
        chartTitle.text = htmlObject.innerText;
        chartTitle.fontSize = 12;
        chartTitle.align = "left";
        chartTitle.fill = am4core.color(textColor);

        var legendContainer = topContainer.createChild(am4core.Container);
        legendContainer.width = am4core.percent(100);
        legendContainer.height = am4core.percent(100);
        legendContainer.paddingTop = 15;

        x.legend = new am4charts.Legend();
        x.legend.parent = legendContainer;
        x.legend.contentAlign = "right";
        x.legend.position = "top";
        x.legend.fill = textColor;
        x.legend.useDefaultMarker = true;
        var markerTemplate = x.legend.markers.template.children.getIndex(0);
        markerTemplate.cornerRadius(12, 12, 12, 12);
        markerTemplate.width = 8;
        markerTemplate.height = 8;
        markerTemplate.y = 5;
        markerTemplate.x = 5;
        markerTemplate.verticalCenter = "top";

        // Display numbers as € or %
        if (divid !== "chart-marge") {
          x.numberFormatter.numberFormat = "#.a'€'";
        } else {
          x.numberFormatter.numberFormat = "#.a'%'";
        }

        var xAxis = x.xAxes.push(new am4charts.CategoryAxis());
        xAxis.dataFields.category = client.forecast?.settings?.active?.global ? "date" : "month";
        xAxis.renderer.labels.template.rotation = client.forecast?.settings?.active?.global
          ? 45
          : 0;
        xAxis.renderer.cellStartLocation = 0.2;
        xAxis.renderer.cellEndLocation = 0.8;
        xAxis.renderer.grid.template.location = 0;
        xAxis.renderer.grid.template.disabled = true;
        xAxis.renderer.labels.template.fontSize = 10;
        xAxis.renderer.labels.template.fill = am4core.color(textColor);
        xAxis.renderer.labels.template.dx = client.forecast?.settings?.active?.global ? 20 : 0;
        xAxis.renderer.minGridDistance = data.length > 15 ? 30 : 10;

        var yAxis = x.yAxes.push(new am4charts.ValueAxis());
        yAxis.min = 0;
        yAxis.renderer.grid.template.disabled = true;
        yAxis.renderer.labels.template.fontSize = 10;
        yAxis.renderer.labels.template.fill = am4core.color(textColor);

        function createSeries(value, name, color) {
          var series = x.series.push(new am4charts.ColumnSeries());
          series.fill = am4core.color(color);
          series.stroke = am4core.color(color);
          series.dataFields.valueY = value;
          series.columns.template.width = am4core.percent(55);
          series.columns.template.column.cornerRadiusTopLeft = 2;
          series.columns.template.column.cornerRadiusTopRight = 2;
          series.columns.template.column.cornerRadiusBottomLeft = 2;
          series.columns.template.column.cornerRadiusBottomRight = 2;
          series.dataFields.categoryX = client.forecast?.settings?.active?.global
            ? "date"
            : "month";
          series.name = name;
          series.tooltipText = "{valueY}";
          series.tooltip.background.cornerRadius = 16;
          series.tooltip.background.strokeOpacity = 0;
          series.tooltip.pointerOrientation = "vertical";
          series.tooltip.label.minWidth = 55;
          series.tooltip.label.minHeight = 20;
          series.tooltip.label.textAlign = "middle";
          series.tooltip.autoTextColor = false;
          series.tooltip.label.fill = am4core.color("#FFFFFF");

          if (divid === "chart-marge") {
            series.calculatePercent = true;
          }
          series.showOnInit = false;

          series.events.on("hidden", arrangeColumns);
          series.events.on("shown", arrangeColumns);

          x.cursor = new am4charts.XYCursor();
          x.cursor.lineY.disabled = true;
          x.cursor.lineX.disabled = true;
          xAxis.cursorTooltipEnabled = false;
          yAxis.cursorTooltipEnabled = false;

          return series;
        }

        createSeries("current_year", "Courant", "#4569F8");
        if (!client.pastYearComp) {
          if (client.forecast?.settings?.active?.global)
            createSeries("past_year", "Prévisionnel", "#D0DBE6");
          else createSeries("past_year", "Précédent", "#D0DBE6");
        }

        function arrangeColumns() {
          var series = x.series.getIndex(0);

          var w = 1 - xAxis.renderer.cellStartLocation - (1 - xAxis.renderer.cellEndLocation);
          if (series.dataItems.length > 1) {
            var x0 = xAxis.getX(series.dataItems.getIndex(0), "categoryX");
            var x1 = xAxis.getX(series.dataItems.getIndex(1), "categoryX");
            var delta = ((x1 - x0) / x.series.length) * w;
            if (am4core.isNumber(delta)) {
              var middle = x.series.length / 2;

              var newIndex = 0;
              x.series.each(function (series) {
                if (!series.isHidden && !series.isHiding) {
                  series.dummyData = newIndex;
                  newIndex++;
                } else {
                  series.dummyData = x.series.indexOf(series);
                }
              });
              var visibleCount = newIndex;
              var newMiddle = visibleCount / 2;

              x.series.each(function (series) {
                var trueIndex = x.series.indexOf(series);
                var newIndex = series.dummyData;

                var dx = (newIndex - trueIndex + middle - newMiddle) * delta;

                series.animate(
                  { property: "dx", to: dx },
                  series.interpolationDuration,
                  series.interpolationEasing
                );
                series.bulletsContainer.animate(
                  { property: "dx", to: dx },
                  series.interpolationDuration,
                  series.interpolationEasing
                );
              });
            }
          }
        }

        chartcurrent = x;

        return chartcurrent;
      }

      /* Line charts function */

      function lineChart(divid, title, data, chartcurrent) {
        let a = am4core.create(divid, am4charts.XYChart);

        var topContainer = a.chartContainer.createChild(am4core.Container);
        topContainer.layout = "absolute";
        topContainer.toBack();
        topContainer.paddingBottom = 37;
        topContainer.width = am4core.percent(100);

        var htmlObject = document.createElement("span");
        htmlObject.innerHTML = title;

        var chartTitle = topContainer.createChild(am4core.Label);
        chartTitle.text = htmlObject.innerText;
        chartTitle.fontSize = 12;
        chartTitle.fill = am4core.color(textColor);
        chartTitle.align = "left";

        var legendContainer = topContainer.createChild(am4core.Container);
        legendContainer.width = am4core.percent(100);
        legendContainer.height = am4core.percent(100);
        legendContainer.paddingTop = 15;

        a.legend = new am4charts.Legend();
        a.legend.parent = legendContainer;
        a.legend.contentAlign = "right";
        a.legend.useDefaultMarker = true;
        var markerTemplate = a.legend.markers.template.children.getIndex(0);
        markerTemplate.cornerRadius(12, 12, 12, 12);
        markerTemplate.width = 8;
        markerTemplate.height = 8;
        markerTemplate.y = 5;
        markerTemplate.x = 5;
        markerTemplate.verticalCenter = "top";

        a.paddingRight = 20;

        a.data = data;

        a.data = data.map((el) => ({
          ...el,
          month: monthNumToName(el.month),
          date: client.forecast?.settings?.active?.global
            ? monthNumToName(el.month) + " " + el.year.slice(-2)
            : "",
        }));

        var categoryAxis = a.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = client.forecast?.settings?.active?.global
          ? "date"
          : "month";
        categoryAxis.renderer.labels.template.rotation = client.forecast?.settings?.active?.global
          ? 45
          : 0;
        categoryAxis.renderer.grid.template.disabled = true;
        categoryAxis.renderer.minGridDistance = data.length > 15 ? 30 : 10;
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.labels.template.fontSize = 10;
        categoryAxis.renderer.labels.template.dx = client.forecast?.settings?.active?.global
          ? 15
          : 0;
        categoryAxis.renderer.labels.template.fill = am4core.color(textColor);
        categoryAxis.startLocation = 0;
        categoryAxis.endLocation = 0.5;

        // Display numbers as € or %
        if (divid !== "chart-marge") {
          if (isDifferenceLessThan(2000, data, client.pastYearComp))
            a.numberFormatter.numberFormat = "#.0a'€'";
          else a.numberFormatter.numberFormat = "#.a'€'";
        } else {
          a.numberFormatter.numberFormat = "#.a'%'";
        }
        var valueAxis = a.yAxes.push(new am4charts.ValueAxis());
        valueAxis.baseValue = 0;
        valueAxis.renderer.grid.template.disabled = true;
        valueAxis.renderer.labels.template.fontSize = 10;
        valueAxis.renderer.labels.template.fill = am4core.color(textColor);

        var series = a.series.push(new am4charts.LineSeries());
        series.tooltipText = "{valueY}";
        series.dataFields.valueY = "current_year";
        series.dataFields.categoryX = client.forecast?.settings?.active?.global ? "date" : "month";

        series.strokeWidth = 2;
        series.tensionX = 0.77;
        series.tooltip.pointerOrientation = "up";

        series.tooltip.background.cornerRadius = 16;
        series.tooltip.background.strokeOpacity = 0;
        series.tooltip.pointerOrientation = "vertical";
        series.tooltip.label.minWidth = 55;
        series.tooltip.label.minHeight = 20;
        series.tooltip.label.textAlign = "middle";
        series.tooltip.autoTextColor = false;
        series.tooltip.label.fill = am4core.color("#FFFFFF");
        //series.name = props.analysisData.general.analysed_fiscal_year.toString();
        series.name = "Courant";
        series.zIndex = 10;
        series.stroke = am4core.color("#4569F8");
        series.fill = am4core.color("#4569F8");
        series.showOnInit = false;

        if (data.filter((elt) => elt.current_year !== null).length === 1) {
          var bullet = series.bullets.push(new am4charts.CircleBullet());
          bullet.disabled = false;
          bullet.propertyFields.disabled = "disabled";
          bullet.circle.strokeWidth = 1;
          bullet.circle.radius = 2;
        }

        if (!client.pastYearComp) {
          var series2 = a.series.push(new am4charts.LineSeries());
          if (divid === "chart-marge") {
            series2.calculatePercent = true;
          }
          series2.dataFields.valueY = "past_year";
          series2.dataFields.categoryX = client.forecast?.settings?.active?.global
            ? "date"
            : "month";

          series2.strokeWidth = 2;
          series2.tensionX = 0.77;
          series2.tooltip.pointerOrientation = "down";
          series2.tooltipText = "{valueY}";
          series2.tooltip.background.cornerRadius = 16;
          series2.tooltip.background.strokeOpacity = 0;
          series2.tooltip.pointerOrientation = "vertical";
          series2.tooltip.label.minWidth = 55;
          series2.tooltip.label.minHeight = 20;
          series2.tooltip.label.textAlign = "middle";
          series2.tooltip.autoTextColor = false;
          series2.tooltip.label.fill = am4core.color("#FFFFFF");
          //series2.name = (props.analysisData.general.analysed_fiscal_year - 1).toString();
          series2.name = client.forecast?.settings?.active?.global ? "Prévisionnel" : "Précédent";
          series2.stroke = am4core.color("#D0DBE6");
          series2.fill = am4core.color("#D0DBE6");
          series2.showOnInit = false;
        }

        a.cursor = new am4charts.XYCursor();
        a.cursor.lineY.disabled = true;
        a.cursor.lineX.disabled = true;
        valueAxis.cursorTooltipEnabled = false;
        categoryAxis.cursorTooltipEnabled = false;

        chartcurrent = a;

        return chartcurrent;
      }

      function createChart(divid, title, data, chartcurrent, type) {
        var chart;
        if (type === "C") {
          chart = barChart(divid, title, data, chartcurrent);
        } else {
          chart = lineChart(divid, title, data, chartcurrent);
        }
        return chart;
      }

      const promises = props.analysisTemplate.templateAnalysis
        .filter((elt) => elt.visible || elt.visible === undefined)
        .filter((elt) => elt.id !== "treso_dispo")
        .map(async (indic) => {
          const data =
            indic.id === "marge"
              ? props.analysisData.graphs.charts_marge.map((el) => ({
                  ...el,
                  current_year: el.current_year ? (el.current_year * 100).toFixed(2) : null,
                  past_year: (el.past_year * 100).toFixed(2),
                }))
              : props.analysisData.graphs["charts_" + indic.id];

          if (client.forecast?.settings?.active?.global) {
            const beginYear = client.forecast?.settings?.start_year;
            let currentYear = beginYear;
            data.forEach((item, index) => {
              item.year = currentYear.toString();
              if (item.month === 12 && index !== data.length - 1) {
                currentYear++;
              }
            });
          }

          const indicChart = createChart(
            "chart-" + indic.id,
            props.analysisData.text[indic.id]?.graph_title,
            data,
            chartRefs.current["chartRef-" + indic.id],
            indic.chart
          );

          const imgObj = await new Promise((resolve) => {
            indicChart.events.once("ready", () => {
              setTimeout(function () {
                var options = indicChart.exporting.getFormatOptions("png");
                options.quality = 1.5;
                indicChart.exporting.setFormatOptions("png", options);
                indicChart.exporting.getImage("png").then(function (imgData) {
                  resolve({ name: indic.id, img: imgData });
                });
              }, 2500);
            });
          });
          return imgObj;
        });

      const imgIndics = await Promise.all(promises);
      setImgIndics(imgIndics);

      return () => {
        am4core.disposeAllCharts();
      };
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const divReport = [];
    props.analysisTemplate.templateAnalysis
      .filter((elt) => elt.visible || elt.visible === undefined)
      .forEach((indic) => {
        divReport.push({
          id: indic.id,
          para: "edit-" + indic.id,
          html: textRefs.current["textRef-" + indic.id],
          img: imgIndics.find((img) => img.name === indic.id) || null,
          title: indic.name || indicName[indic.id],
          htmltitle: props.analysisData.text[indic.id]?.title,
          popupText: indic.description || null,
        });
      });
    props.selectDivReport(divReport);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(imgIndics), props.update]);

  return (
    <div className="report">
      <div className="div-charts">
        {props.analysisTemplate.templateAnalysis
          .filter((elt) => elt.visible || elt.visible === undefined)
          .filter((elt) => elt.id !== "treso_dispo")
          .map((indic) => {
            return (
              <Row className="report-par" key={indic.id}>
                <Col xs={24} sm={24} md={10} lg={13}>
                  <Text className="section-title">{indic.name || indicName[indic.id]}</Text>
                  {indic.description ? (
                    <Popover
                      placement="right"
                      content={indic.description}
                      className="info-popover"
                      overlayClassName="info-popover-description"
                    >
                      <span>
                        <InfoCircleOutlined />
                      </span>
                    </Popover>
                  ) : null}

                  <div className="blue-bar"></div>
                  <span className="responsive-popover">{indic.description}</span>
                  <div
                    className="text"
                    ref={(el) => (textRefs.current["textRef-" + indic.id] = el)}
                  >
                    <TextAnalysis {...props} type={indic.id} />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={10} lg={{ span: 10, offset: 1 }}>
                  <div
                    id={"chart-" + indic.id}
                    className="chart"
                    ref={(el) => (textRefs.current["chartRef-" + indic.id] = el)}
                  ></div>
                </Col>
              </Row>
            );
          })}
      </div>
    </div>
  );
}

export default Report;

import { ReactComponent as GreyFileCircle } from "../assets/images/GreyFileCircle.svg";

function TooltipConnect(props) {
  return (
    <>
      <div className="how-to-box">
        <div className="how-to-link">
          <GreyFileCircle className="how-to-img" style={{ fontSize: "33px" }} />
          <a href={props.url} target="_blank" rel="noreferrer">
            {props.text}
          </a>
        </div>
      </div>
    </>
  );
}

export default TooltipConnect;

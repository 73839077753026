import React, { useState, useEffect, useRef } from "react";
import ModalIndex from "./ModalIndex";
import { Col, Row, Button, Modal } from "antd";
import { RedoOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Tooltip, Progress } from "antd";
import * as API from "../../../api/API";
import SelectMonth from "./SelectMonth";
import { ReactComponent as ImgSort } from "../../../assets/images/vpf-sort.svg";
import { useSelector } from "react-redux";
import { selectAccountingFirm } from "../../../slices/accountingFirmSlice";
import Order from "./Modal/Order";
import logAction from "../../../utils/logActions";

function Header(props) {
  const accountingFirm = useSelector(selectAccountingFirm);

  const [clientsCount, setClientCount] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isOrderModalOpen, setIsOrderModalOpen] = useState(false);
  const [triggerUpdate, setTriggerUpdate] = useState(0);
  const [loadingPortfolioSync, setLoadingPortfolioSync] = useState(true);
  const [portfolioSync, setPortfolioSync] = useState(false);
  const [, setForceUpdate] = useState(true);

  const portfolioPercent = useRef(0);

  const showModal = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    let count = 0;
    props.opportTotal.current.forEach((element) => {
      count += element.length;
    });
    setClientCount(count);
    if (props.opportTotal.current.length >= 1) {
      setIsLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.triggerRef]);

  useEffect(() => {
    (async () => {
      if (accountingFirm.status === "ready") {
        let res = await API.getAccountingFirm(accountingFirm._id);
        res = await res.json();
        if (res) {
          setPortfolioSync(res.portfolioSync);
          if (!res.portfolioSync) {
            props.setLoadingSync(false);
            portfolioPercent.current = 0;
            setLoadingPortfolioSync(false);
          } else {
            setForceUpdate(true);
          }
        }
      }
    })();
  }, [accountingFirm._id]);

  useEffect(() => {
    (async () => {
      if (accountingFirm.status === "ready" && portfolioSync) {
        let res = await API.getListOfClientsThatNeedSync();
        res = await res.json();

        props.setLoadingSync(false);

        if (res) {
          if (res.total === 0) portfolioPercent.current = 100;
          else if (res.synchronized !== res.total) {
            portfolioPercent.current = Math.round((res.synchronized / res.total) * 100);

            setTimeout(() => setTriggerUpdate(triggerUpdate + 1), 1000);
          } else if (res.synchronized === res.total) {
            portfolioPercent.current = 100;
            setForceUpdate(true);
          }
          if (portfolioPercent.current > 0) setLoadingPortfolioSync(false);
        }
      }
    })();
  }, [accountingFirm.status, triggerUpdate, portfolioSync]);

  const refreshAll = async () => {
    setLoadingPortfolioSync(true);
    portfolioPercent.current = 0;
    await API.synchroClientsManagerView();
    setPortfolioSync(true);
    setForceUpdate(false);
    logAction(32, 1);
  };

  const handleOrderModalCancel = () => {
    setIsOrderModalOpen(false);
  };

  return (
    <div className="vpf-header">
      <Row>
        <Col span={10}>
          <div style={{ position: "relative", bottom: "16px" }}>
            <h1 style={{ fontWeight: 700, marginBottom: "10px" }}>
              {isLoading ? (
                <>
                  <div className="espacement-ligne">
                    <span className="loader__dot">.</span>
                    <span className="loader__dot">.</span>
                    <span className="loader__dot">.</span>
                    <span> résultats méritent votre attention</span>
                  </div>
                </>
              ) : clientsCount >= 1 ? (
                <div className="espacement-ligne">
                  <span>
                    {clientsCount} résultat{clientsCount > 1 ? "s" : ""} mérite
                    {clientsCount > 1 ? "nt" : ""} votre attention
                  </span>
                </div>
              ) : (
                <span className="espacement-ligne" style={{ position: "relative", bottom: "4px" }}>
                  Vos dossiers clients méritent toute votre attention 👍
                </span>
              )}{" "}
            </h1>
            <p className="header-text">
              Vos tableaux personnalisés pour détecter des
              <br />
              opportunités parmi vos dossiers clients :
            </p>
          </div>

          <Button
            className="call-action-btn button-header"
            onClick={showModal}
            style={{ bottom: "3px", marginRight: "10px" }}
          >
            <PlusCircleOutlined style={{ fontSize: 15 }} /> Créer un nouveau tableau
          </Button>
          <Button
            className="call-action-btn-white button-header"
            style={{ position: "relative", bottom: "2px" }}
            onClick={() => setIsOrderModalOpen(true)}
            disabled={props.opportTotal.current.length !== props.reports.length}
          >
            <div style={{ display: "flex" }}>
              <div>
                <ImgSort
                  width={15}
                  height={15}
                  fill={"#4569f8"}
                  style={{ marginTop: "3px", marginRight: "10px" }}
                />
              </div>
              <div style={{ fontSize: "13px", marginTop: "1px" }}>Réorganiser</div>
            </div>
          </Button>
          <ModalIndex
            {...props}
            component="SelectPreset"
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            type={"create"}
          />
          <Modal
            className="vpf-modal vpf-modal-index order-modal"
            title="Modifier l’ordre des tableaux"
            open={isOrderModalOpen}
            footer={false}
            onCancel={handleOrderModalCancel}
            destroyOnClose={true}
            width={700}
            height={500}
          >
            <Order {...props} handleOrderModalCancel={handleOrderModalCancel} />
          </Modal>
        </Col>
        <Col span={14} style={{ textAlign: "right" }}>
          <>
            {portfolioSync && portfolioPercent.current > 0 ? (
              <Tooltip
                placement="top"
                title={
                  portfolioPercent.current < 100
                    ? "Un collaborateur a lancé l'actualisation, merci de patienter"
                    : ""
                }
              >
                <b style={{ marginRight: "10px", fontFamily: "Roboto-Bold" }}>
                  Mise à jour{" "}
                  {portfolioPercent.current === 100
                    ? "terminée. Veuillez recharger la page."
                    : "en cours"}
                </b>
                <Progress
                  className="progress-circle"
                  type="circle"
                  strokeColor={{
                    "0%": "#4569f8",
                    "100%": "#87d068",
                  }}
                  percent={portfolioPercent.current}
                  size={50}
                />
              </Tooltip>
            ) : (
              <Button
                className="call-action-btn refresh-all"
                onClick={(e) => {
                  e.preventDefault();
                  refreshAll();
                }}
                icon={<RedoOutlined />}
                disabled={loadingPortfolioSync}
                loading={loadingPortfolioSync}
              >
                Rafraîchir les données
              </Button>
            )}
          </>
          <br />
          <div style={{ marginBottom: "10px" }}></div>
          <SelectMonth {...props} setSelectedDate={props.setSelectedDate} />
        </Col>
      </Row>
      <hr style={{ border: "1px solid #DFEBFD", marginTop: "30px", marginBottom: "10px" }} />
    </div>
  );
}

export default Header;

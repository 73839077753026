import React, { useState, useEffect } from "react";
import { Select, Form, Typography } from "antd";
import * as API from "../../../../api/API";
import SentryTable from "../SentryTable";

const { Text } = Typography;

function Sentry() {
  const [form] = Form.useForm();

  const [users, setUsers] = useState();
  const [currentUserId, setCurrentUserId] = useState();

  useEffect(() => {
    (async () => {
      let res = await API.getUsers();
      res = await res.json();
      setUsers(res);
    })();
  }, []);

  const onChangeUser = (label, options) => {
    setCurrentUserId(options.value);
  };

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };

  return (
    <div>
      <div className="sesha-support">
        <Form form={form} {...layout} name="control-ref">
          <Form.Item name="accountingFirmItem" label={<b>Liste des utilisateurs</b>}>
            <Select
              style={{ marginLeft: "20px", width: "260px" }}
              showSearch
              placeholder="Rechercher un utilisateur"
              onChange={onChangeUser}
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
              }
              options={users?.map(({ _id, email }) => ({ value: _id, label: email }))}
              popupClassName="ant-select-dropdown-zindex"
              placement="bottomLeft"
              dropdownAlign={{ overflow: { adjustY: false } }}
            />
          </Form.Item>
        </Form>
      </div>

      {currentUserId ? (
        <div style={{ marginTop: "40px" }}>
          <Text className="section-title">Dernières erreurs pour cet utilisateur</Text>
          <div className="blue-bar"></div>
          <SentryTable userId={currentUserId} />
        </div>
      ) : null}
    </div>
  );
}

export default Sentry;

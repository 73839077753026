import { Button, Col, Drawer, Row } from "antd";
import MailUnique from "components/mail/MailUnique";
import React, { useEffect, useState } from "react";
import { animated, useSpring } from "react-spring";
import { ReactComponent as MailButton } from "../../assets/images/MailButton.svg";
import { ReactComponent as ArrowsIcon } from "../../assets/images/Vector.svg";
import MailAnalysis from "../mail/MailAnalysis.js";

function MailDrawer(props) {
  const [openUniqueMail, setOpenUniqueMail] = useState(false);
  const [selectedMailType, setSelectedMailType] = useState("");
  const [isHovered, setIsHovered] = React.useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const showUniqueMail = () => {
    setOpenUniqueMail(true);
  };
  const closeUniqueMail = () => {
    setOpenUniqueMail(false);
    handleMouseLeave();
  };

  useEffect(() => {
    if (props.count > 0 && props.type === selectedMailType) setOpenUniqueMail(true);
    else if (props.type !== "") setOpenUniqueMail(true);
    else setOpenUniqueMail(false);

    setSelectedMailType(props.type);
  }, [props.count, props.type]);

  const springProps = useSpring({
    transform: isHovered ? "translateX(10px)" : "translateX(30px)",
    config: {
      tension: 300,
      friction: 10,
    },
    marginRight: selectedMailType !== "" ? "0px" : "-70px",
    right: 0,
    top: 0,
  });

  return (
    <>
      <animated.div
        onMouseEnter={openUniqueMail ? null : handleMouseEnter}
        onMouseLeave={openUniqueMail ? null : handleMouseLeave}
        className={"volet-unique-mail"}
        style={openUniqueMail ? null : springProps}
      >
        <div className="volet-btns" style={{ marginRight: "32px" }}>
          <div>
            <Button onClick={showUniqueMail} className="volet-arrows-button">
              <ArrowsIcon className="volet-icon-arrows" />
            </Button>
          </div>
          <div>
            <MailButton onClick={showUniqueMail} className="volet-icon-mail-button" />
          </div>
        </div>
        <Drawer
          extra={
            <Button
              className="back-to-analysis"
              onClick={(e) => {
                e.preventDefault();
                setOpenUniqueMail(false);
                //setSelectedMailType("");
                props.setSelectedMailType("");
              }}
            >
              <span
                className="back-txt"
                style={{ marginTop: "0.5px", position: "relative", left: "7px" }}
              >
                Annuler
              </span>
            </Button>
          }
          title={
            <Row>
              <Col span={16} style={{ marginLeft: "12px" }}>
                <span className="section-title">Personnaliser votre email</span>
                <div className="blue-bar"></div>
                <div></div>
              </Col>
            </Row>
          }
          placement="right"
          width="685px"
          onClose={closeUniqueMail}
          open={openUniqueMail}
          closeIcon={<ArrowsIcon className="volet-icon-arrows-rotated" />}
          rootClassName="volet-unique-mail-content"
        >
          <div>
            {selectedMailType === "analysis" ? (
              <MailAnalysis
                {...props}
                selectedMailType={selectedMailType}
                setSelectedMailType={props.setSelectedMailType}
              />
            ) : selectedMailType !== "" ? (
              <MailUnique
                {...props}
                selectedMailType={selectedMailType}
                setSelectedMailType={props.setSelectedMailType}
              />
            ) : null}
          </div>
        </Drawer>
      </animated.div>
    </>
  );
}
export default MailDrawer;

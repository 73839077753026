import { ClockCircleFilled, DeleteOutlined, LockFilled, SearchOutlined } from "@ant-design/icons";
import { Button, Input, Radio, Space, Table, Tag } from "antd";
import { EFeatures } from "enums/EFeatures";
import { useEffect, useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import { hasFeatures } from "utils/security";
import * as API from "../../../api/API";
import LoaderRaw from "../../../utils/LoaderRaw";
import openNotification from "../../../utils/notification";
import CreateUser from "./CreateUser.js";
import EditUser from "./EditUser.js";
import ExpandedComponent from "./ExpandedComponent";

function UsersDashboard(props) {
  const [, setUserList] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [userListUpdate, setUserListUpdate] = useState(false);
  const [firmList, setFirmList] = useState([]);
  const [switchTotal, setSwitchTotal] = useState(false);
  const [displayHeader, setDisplayHeader] = useState(false);
  const [displayEdit, setDisplayEdit] = useState(false);
  const [displayDelete, setDisplayDelete] = useState(false);
  const [dataSource, setDataSource] = useState();
  const [selectionType] = useState("checkbox");
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [roleList, setRoleList] = useState([]);
  const searchInput = useRef(null);

  useEffect(() => {
    const fetchDataAndProcess = async () => {
      try {
        // Retrieve all data in parallel
        const [firmsData, users, schedule, roles, reports] = await Promise.all([
          API.getAccountingFirmLight(),
          API.getUsersLight(),
          API.getScheduleUsers(),
          API.getDashboardRoles(),
          API.getDashboardReports(),
        ]).then((responses) => Promise.all(responses.map((res) => res.json())));

        // Transform accounting firms data
        const accountingFirms = firmsData.map((firm) => ({
          accountingFirmId: firm._id,
          accountingFirmName: firm.name,
          accountingFirmActive: firm.active,
        }));

        // Merge users data with schedule, firms and reports
        const mergedData = users.map((user) => {
          const userSchedule = schedule.find((s) => s.userId === user._id) || {};
          const firm =
            accountingFirms.find((f) => f.accountingFirmId === user.accountingFirmId) || {};
          const reportCount = reports.find((rep) => rep.userId === user._id)?.count || 0;

          return {
            ...user,
            ...userSchedule,
            ...firm,
            reportCount,
            key: user._id,
            fullName: `${user.firstName} ${user.lastName}`,
            scheduledDate: userSchedule.date,
          };
        });

        setFirmList(firmsData);
        setUserList(users);
        setRoleList(roles);
        setUserListUpdate(false);
        setDataSource(mergedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchDataAndProcess();
  }, [userListUpdate]);

  const getColumnSearchProps = (dataIndex, title) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={title}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 120 }}
          >
            Rechercher
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 100 }}>
            Réinitialiser
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "var(--blue)" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : "",
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput && searchInput.current && searchInput.current.select());
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const handleDelete = () => {
    if (
      window.confirm(
        `Voulez-vous supprimer les utilisateurs suivants :\r ${selectedRows.map(
          (r) => r.firstName + " " + r.lastName
        )}?`
      )
    ) {
      selectedRows.forEach((elt) => {
        (async () => {
          const user = await dataSource.find((user) => user.email === elt.email);
          const res = await API.deleteUser(user._id);
          if (res.status === 200) {
            setUserListUpdate(true);
            setDisplayHeader(false);
            if (user.scheduledId) API.deleteScheduleUser(user.scheduledId);
            openNotification("success", "Utilisateur supprimé");
          } else openNotification("error", "Erreur lors de la suppression");
        })();
      });
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      if (selectedRows.length === 1) {
        setSelectedRows(selectedRows);
        setDisplayHeader(true);
        setDisplayEdit(true);
        setDisplayDelete(true);
      } else if (selectedRows.length >= 1) {
        setSelectedRows(selectedRows);
        setDisplayHeader(true);
        setDisplayEdit(false);
        setDisplayDelete(true);
      } else {
        setSelectedRows(null);
        setDisplayHeader(false);
        setDisplayEdit(false);
        setDisplayDelete(false);
      }
    },
  };

  function displayLoadingData(data) {
    return data === undefined ? <i className="data-cell-loading"></i> : data;
  }

  const columns = [
    {
      title: "Nom",
      dataIndex: "fullName",
      fixed: "left",
      sorter: (a, b) => a.fullName.localeCompare(b.fullName),
      ...getColumnSearchProps("fullName", "Nom ou Prénom"),
      render: (text, record) => {
        return (
          <span style={{ display: "flex", flexWrap: "nowrap" }}>
            {searchedColumn === "fullName" ? (
              <Highlighter
                highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text}
              />
            ) : (
              text
            )}
            {record.scheduledDate ? (
              <span
                style={{
                  flexGrow: 1,
                  color: "#ffad21",
                  textAlign: "right",
                  alignSelf: "center",
                }}
              >
                <ClockCircleFilled title="Activation programmée" />
              </span>
            ) : null}
          </span>
        );
      },
    },
    {
      title: "Cabinet Comptable",
      dataIndex: ["accountingFirmName", "accountingFirmActive"],
      align: "center",
      sorter: (a, b) => a.accountingFirmName.localeCompare(b.accountingFirmName),
      ...getColumnSearchProps("accountingFirmName", "Cabinet Comptable"),
      render: (text, record) => {
        return (
          <>
            {searchedColumn === "accountingFirmName" ? (
              <Highlighter
                highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={record.accountingFirmName}
              />
            ) : (
              record.accountingFirmName
            )}
            {!record.accountingFirmActive ? (
              <>
                {" "}
                <LockFilled title="Cabinet désactivé" style={{ color: "#c32028" }} />
              </>
            ) : null}
          </>
        );
      },
    },
    {
      title: "Email",
      dataIndex: ["email"],
      align: "center",
      ellipsis: true,
      sorter: (a, b) => a.email?.localeCompare(b.email),
      ...getColumnSearchProps("email", "Email"),
      render: (text, record) => {
        return (
          <>
            {searchedColumn === "email" ? (
              <Highlighter
                highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text}
              />
            ) : (
              text
            )}
          </>
        );
      },
    },
    {
      title: "Dernière connexion (en jours)",
      dataIndex: "lastLogin",
      align: "center",
      defaultSortOrder: "ascend",
      sorter: (a, b) =>
        new Date(b.lastLogin || "2020-01-01") - new Date(a.lastLogin || "2020-01-01"),
      render: (_, row) => {
        if (!row.lastLogin) return "Jamais";

        const daysSinceLastLogin = Math.floor(
          (new Date() - new Date(row.lastLogin)) / (1000 * 60 * 60 * 24)
        );
        return daysSinceLastLogin === 0 ? "Aujourd'hui" : daysSinceLastLogin;
      },
    },
    {
      title: "Nombre de connexions",
      dataIndex: "loginCount",
      align: "center",
      sorter: (a, b) => a.loginCount - b.loginCount,
      render: (text, row) => displayLoadingData(row.loginCount),
    },
    {
      title: "Analyses générées",
      dataIndex: "analysisGeneratedCount",
      align: "center",
      switchTotal: true,
      sorter: (a, b) => a.analysisGeneratedCount - b.analysisGeneratedCount,
      render: (text, row) => displayLoadingData(row.analysisGeneratedCount),
    },
    {
      title: "Analyses générées  30 jours glissants",
      dataIndex: "analysisGeneratedCount30Days",
      align: "center",
      switchTotal: false,
      sorter: (a, b) => a.analysisGeneratedCount30Days - b.analysisGeneratedCount30Days,
      render: (text, row) => displayLoadingData(row.analysisGeneratedCount30Days),
    },
    {
      title: "Nombre de cliques sur la VPF",
      dataIndex: "vpfCount",
      align: "center",
      sorter: (a, b) => a.vpfCount - b.vpfCount,
      render: (text, row) => displayLoadingData(row.vpfCount),
    },
    {
      title: "Rapports",
      dataIndex: "reportCount",
      align: "center",
      sorter: (a, b) => a.reportCount - b.reportCount,
      render: (text, row) => displayLoadingData(row.reportCount),
    },
    {
      title: "Emails",
      switchTotal: true,
      children: [
        {
          title: "Analyses",
          dataIndex: "analysisEmailSent",
          align: "center",
          sorter: (a, b) => a.analysisEmailSentCount - b.analysisEmailSentCount,
          render: (text, row) => displayLoadingData(row.analysisEmailSentCount),
        },
        {
          title: "Pièces manquantes",
          dataIndex: "missingDocEmailSent",
          align: "center",
          sorter: (a, b) => a.missingDocEmailSentCount - b.missingDocEmailSentCount,
          render: (text, row) => displayLoadingData(row.missingDocEmailSentCount),
        },
        {
          title: "Encours clients",
          dataIndex: "outstandingsClientsEmailSent",
          align: "center",
          sorter: (a, b) =>
            a.outstandingsClientsEmailSentCount - b.outstandingsClientsEmailSentCount,
          render: (text, row) => displayLoadingData(row.outstandingsClientsEmailSentCount),
        },
        {
          title: "Encours fournisseurs",
          dataIndex: "outstandingsSuppliersEmailSent",
          align: "center",
          sorter: (a, b) =>
            a.outstandingsSuppliersEmailSentCount - b.outstandingsSuppliersEmailSentCount,
          render: (text, row) => displayLoadingData(row.outstandingsSuppliersEmailSentCount),
        },
      ],
    },
    {
      title: "Emails sur 30 jours glissants",
      switchTotal: false,
      children: [
        {
          title: "Analyses",
          dataIndex: "analysisEmailSentCount30Days",
          align: "center",
          sorter: (a, b) => a.analysisEmailSentCount30Days - b.analysisEmailSentCount30Days,
          render: (text, row) => displayLoadingData(row.analysisEmailSentCount30Days),
        },
        {
          title: "Pièces manquantes",
          dataIndex: "missingDocEmailSent",
          align: "center",
          sorter: (a, b) => a.missingDocEmailSent - b.missingDocEmailSent,
          render: (text, row) => displayLoadingData(row.missingDocEmailSentCount30Days),
        },
        {
          title: "Encours clients",
          dataIndex: "outstandingsClientsEmailSent",
          align: "center",
          sorter: (a, b) =>
            a.outstandingsClientsEmailSentCount30Days - b.outstandingsClientsEmailSentCount30Days,
          render: (text, row) => displayLoadingData(row.outstandingsClientsEmailSentCount30Days),
        },
        {
          title: "Encours fournisseurs",
          dataIndex: "outstandingsSuppliersEmailSent",
          align: "center",
          sorter: (a, b) =>
            a.outstandingsSuppliersEmailSentCount30Days -
            b.outstandingsSuppliersEmailSentCount30Days,
          render: (text, row) => displayLoadingData(row.outstandingsSuppliersEmailSentCount30Days),
        },
      ],
    },
    {
      title: "Droits ouverts",
      dataIndex: "newRole",
      align: "center",
      sorter: (a, b) => a.newRoleLabel.localeCompare(b.newRoleLabel),
      render: (text, row) => {
        const label = roleList.find((el) => el._id === row.newRole)?.label;
        const tagColor = label?.includes("SA ")
          ? "red"
          : label?.includes("Administrateur")
          ? "gold"
          : label?.includes("Utilisateur")
          ? "geekblue"
          : "green";
        return label ? <Tag color={tagColor}>{label}</Tag> : "";
      },
      filters: [{ text: "SUPERADMIN", value: "SA " }],
      onFilter: (value, record) => {
        const label = roleList.find((el) => el._id === record.newRole)?.label;
        return label && label.includes(value);
      },
    },
  ];

  return (
    <div className="userDashboard">
      <CreateUser
        {...props}
        updateUser={(data) => {
          setUserListUpdate(data);
        }}
        firmList={firmList}
        roleList={roleList}
      />

      <div className="wrapper">
        <div className="switchTotal"></div>
        <Radio.Group value={switchTotal} onChange={(e) => setSwitchTotal(e.target.value)}>
          <Radio className="radio" value={false}>
            <span>Total</span>
          </Radio>
          <Radio className="radio" value={true}>
            <span>30 jours glissants</span>
          </Radio>
        </Radio.Group>
      </div>

      {displayHeader ? (
        <div className="header-table">
          {displayDelete ? (
            <div
              style={{
                display: "inline-block",
                float: "right",
                padding: "9px",
              }}
            >
              <Button
                className="call-action-btn"
                key="delete"
                onClick={handleDelete}
                icon={<DeleteOutlined />}
              >
                Supprimer
              </Button>
            </div>
          ) : null}
          {displayEdit ? (
            <div
              style={{
                display: "inline-block",
                float: "right",
                padding: "9px",
              }}
            >
              <EditUser
                {...props}
                firmList={firmList}
                updateUser={(data) => {
                  setUserListUpdate(data);
                }}
                selectedRow={selectedRows[0]}
                roleList={roleList}
              />
            </div>
          ) : null}
        </div>
      ) : null}

      <Table
        rowSelection={
          hasFeatures([EFeatures.SUPERADMIN_WRITE, EFeatures.SUPERADMIN_TECH])
            ? {
                type: selectionType,
                ...rowSelection,
              }
            : false
        }
        columns={columns.filter((col) => col.switchTotal !== switchTotal)}
        dataSource={dataSource}
        expandable={{
          expandedRowRender: (record) => <ExpandedComponent data={record} />,
        }}
        size="small"
        bordered
        scroll={{ x: 1300 }}
        pagination={{ defaultPageSize: 50, showSizeChanger: true }}
        loading={{ indicator: <LoaderRaw />, spinning: !dataSource }}
      />
    </div>
  );
}

export default UsersDashboard;

import { InfoCircleFilled } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Modal,
  Popconfirm,
  Progress,
  Row,
  Select,
  Skeleton,
} from "antd";
import locale from "antd/es/date-picker/locale/fr_FR";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import isLeapYear from "dayjs/plugin/isLeapYear";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectAccountingFirm } from "slices/accountingFirmSlice";
import * as API from "../../../api/API";
import { selectClient, updateClient } from "../../../slices/clientSlice";
import { findPreviousMonthClosestDay } from "../../../utils/getDate";
import openNotification from "../../../utils/notification";

dayjs.extend(isBetween);
dayjs.extend(isLeapYear);

const { Option } = Select;

const viewOptions = [
  { value: 1, label: "Encaissements/Décaissements" },
  { value: 2, label: "Achats/Ventes" },
  { value: 3, label: "Journaux" },
];

function Header(props) {
  const dispatch = useDispatch();
  const client = useSelector(selectClient);
  const accountingFirm = useSelector(selectAccountingFirm);
  const clientId = props.match.params.clientId;

  const [loading, setLoading] = useState(true);
  const [paramsModal, setParamsModal] = useState(false);
  const [displayAccount, setDisplayAccount] = useState(false);
  const [locker, setLocker] = useState(false);

  useEffect(() => {
    selectEndDate(accountingFirm?.missingDocEndDate);
  }, [accountingFirm.missingDocEndDate]);

  function selectEndDate(missingDocEndDate) {
    switch (missingDocEndDate) {
      // current day mode and default mode
      case 101:
      case undefined:
        props.setEndDate(dayjs());
        break;
      // last day of previous month mode
      case 102:
        props.setEndDate(dayjs().subtract(1, "month").endOf("month"));
        break;
      default:
        // either a valid specific value to set previous month's day number with
        if (missingDocEndDate <= 31)
          props.setEndDate(findPreviousMonthClosestDay(dayjs(), 1, missingDocEndDate));
        // or numbers above normal day value, seen as default mode
        else props.setEndDate(dayjs());
        break;
    }
  }

  useEffect(() => {
    console.debug("useEffect setLoading Header.js");
    if (
      client.status === "ready" &&
      props.badgeMissing !== undefined &&
      props.missingDocAmount !== undefined
    ) {
      setLoading(false);
    } else if (props.missingDocAmount === undefined) {
      setLoading(false);
    }
    if (!props.wait && client) setDisplayAccount(!!client.showZeroAccounts);
  }, [
    client.status,
    client.showZeroAccounts,
    props.badgeMissing,
    props.missingDocAmount,
    props.wait,
  ]);

  useEffect(() => {
    if (!props.wait && client.operationCategories) {
      const hasPendingDownloads = client.operationCategories?.some((category) =>
        category.operations.some((op) => !op.downloadedOnce && op.files?.length > 0)
      );
      setLocker(hasPendingDownloads);
    } else {
      setLocker(false);
    }
  }, [props.wait, client.operationCategories]);

  function onChangeView(value) {
    (async () => {
      await API.putClient(clientId, {
        missingDocView: value,
      });
    })();

    props.setAllExpanded(false);

    dispatch(updateClient({ missingDocView: value }));
  }

  const resetJournals = () => {
    (async () => {
      await API.putClient(clientId, {
        hiddenJournals: [],
        journalList: [],
      });
      dispatch(updateClient({ hiddenJournals: [], journalList: [] }));
      props.setRefreshMissingDoc(!props.refreshMissingDoc);
    })();
    setParamsModal(false);
  };

  const onFinish = (values) => {
    const { startDate, endDate, journalList } = values;
    if (dayjs(startDate).isAfter(dayjs(endDate))) {
      openNotification("error", "La date de début doit être antérieure à la date de fin");
      return;
    }
    if (journalList.length === 0) {
      openNotification("error", "Veuillez sélectionner au moins un journal");
      return;
    }
    props.setStartDate(dayjs(startDate));
    props.setEndDate(dayjs(endDate));
    props.setJournalListFiltered(journalList);

    let combinedJournals = [
      ...new Set([...client.journalList.map((s) => s.trim()), ...props.journalListInit]),
    ].filter((j) => !journalList.includes(j));

    if (client.hiddenJournals && client.hiddenJournals.length > 0) {
      combinedJournals = [
        ...new Set([
          ...combinedJournals,
          ...client.hiddenJournals.filter(
            (j) => !combinedJournals.includes(j) && !journalList.includes(j)
          ),
        ]),
      ];
    }

    (async () => {
      await API.putClient(clientId, {
        showZeroAccounts: displayAccount,
        hiddenJournals: combinedJournals,
        filterStartDate: dayjs(startDate),
        journalList: journalList,
      });
    })();
    dispatch(
      updateClient({
        showZeroAccounts: displayAccount,
        hiddenJournals: combinedJournals,
        filterStartDate: dayjs(startDate),
        journalList: journalList,
      })
    );
    setParamsModal(false);
    props.setRefreshMissingDoc(!props.refreshMissingDoc);
  };

  return (
    <>
      {loading ? (
        <Skeleton active={true} />
      ) : (
        <>
          {client.status === "ready" ? (
            <>
              <div className="card-container">
                <div className="large-card">
                  <Progress
                    type="circle"
                    percent={100}
                    format={() => props.badgeMissing || "N/A"}
                    strokeColor={"#FF9700"}
                    strokeWidth={"7"}
                  />
                  <p className="small-title">Justificatifs manquants</p>
                </div>
                <div style={{ flex: "2" }}>
                  <Row
                    gutter={{
                      sm: 20,
                    }}
                    style={{ marginBottom: "1.8%" }}
                  >
                    <Col span={12} xs={16} sm={12}>
                      <div className="small-card ">
                        <p className="blue-text" style={{ color: "black" }}>
                          {props.missingDocAmount
                            ? props.missingDocAmount
                                .toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                                .replace(".", ",") + " €"
                            : "N/A"}
                        </p>
                        <span className="small-title">Non justifiés</span>
                      </div>
                    </Col>
                    <Col span={12} xs={16} sm={12}>
                      <div
                        className="small-card small-card-clickable"
                        onClick={() => setParamsModal(!paramsModal)}
                        style={{ cursor: "pointer" }}
                      >
                        <span className="small-title">Période d'analyse</span>
                        <p style={{ margin: "0" }}>
                          Du
                          <span className="blue-date">{props.startDate.format("DD/MM/YYYY")}</span>
                          au
                          <span className="blue-date">{props.endDate.format("DD/MM/YYYY")}</span>
                        </p>
                      </div>
                    </Col>
                  </Row>
                  <Row
                    gutter={{
                      sm: 20,
                    }}
                  >
                    <Col span={12} xs={16} sm={12}>
                      <div
                        className="small-card small-card-clickable"
                        onClick={() => setParamsModal(!paramsModal)}
                        style={{ cursor: "pointer" }}
                      >
                        <span className="small-title">Journaux pris en compte</span>
                        <p className="blue-text">{props.journalListFiltered.join(", ")}</p>
                      </div>
                    </Col>
                    <Col span={12} xs={16} sm={12}>
                      <div id="modal-param">
                        <Button
                          type="link"
                          onClick={() => setParamsModal(true)}
                          style={{ padding: "0" }}
                        >
                          <span style={{ color: "#9F9F9F", fontWeight: "600", fontSize: "13px" }}>
                            Paramétrer la période prise en compte et les journaux
                          </span>
                        </Button>
                        <p style={{ margin: "2% 0 0 0" }}>Choisir une vue :</p>
                        <Select
                          options={viewOptions}
                          defaultValue={viewOptions[client.missingDocView - 1] || viewOptions[0]}
                          className="view-select"
                          onChange={(value) => onChangeView(value)}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
              <Modal
                title="Paramétrer la période d'analyse et les journaux"
                className="edit-user-modal"
                open={paramsModal}
                onCancel={(e) => {
                  e.preventDefault();
                  setParamsModal(false);
                }}
                footer={null}
                destroyOnClose={true}
              >
                <Form
                  layout="vertical"
                  name="datepicker"
                  onFinish={onFinish}
                  initialValues={{
                    startDate: props.startDate,
                    endDate: props.endDate,
                    journalList: props.journalListFiltered,
                    showZeroAccounts: displayAccount,
                  }}
                  preserve={false}
                >
                  <Form.Item name="startDate" label="Date de début :" className="form-datepicker">
                    <DatePicker
                      locale={locale}
                      allowClear={false}
                      className="import-input"
                      format="DD/MM/YYYY"
                    />
                  </Form.Item>
                  <Form.Item name="endDate" label="Date de fin :" className="form-datepicker">
                    <DatePicker
                      locale={locale}
                      allowClear={false}
                      className="import-input"
                      format="DD/MM/YYYY"
                    />
                  </Form.Item>
                  <Form.Item
                    name="journalList"
                    label="Retirer ou ajouter les journaux à prendre en compte : "
                  >
                    <Select
                      mode="tags"
                      placement="topLeft"
                      dropdownAlign={{ overflow: { adjustY: false } }}
                    >
                      {[
                        ...new Set([
                          ...(client.journalList ? client.journalList.map((s) => s.trim()) : []),
                          ...props.journalListInit,
                        ]),
                      ]?.map((journal, i) => (
                        <Option key={i} value={journal}>
                          {journal}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  {props.excludedJournals?.length > 0 &&
                  props.currentTemplate?.name !== undefined ? (
                    <span
                      style={{
                        position: "relative",
                        top: "-20px",
                        fontSize: "0.9em",
                        fontStyle: "italic",
                        color: "grey",
                      }}
                    >
                      <p style={{ margin: "0px" }}>
                        Le modèle {'"'}
                        {props.currentTemplate?.name}
                        {'" '}
                        {props.excludedJournals?.length > 1
                          ? "exclut les journaux : "
                          : "exclut le journal : "}
                        {props.excludedJournals?.join(", ")}
                        {"."}
                      </p>
                    </span>
                  ) : null}
                  <Form.Item
                    name="showZeroAccounts"
                    valuePropName="checked"
                    style={{ position: "relative", top: "-16px", marginBottom: "0" }}
                  >
                    <Checkbox
                      className="balancedAccountCheckbox"
                      onChange={() => setDisplayAccount(!displayAccount)}
                      disabled={locker}
                    >
                      Afficher les comptes soldés
                    </Checkbox>
                  </Form.Item>
                  {displayAccount && locker ? (
                    <div className="blue-box">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <InfoCircleFilled style={{ color: "var(--blue)", fontSize: "1.2em" }} />
                        <span style={{ marginLeft: "8px", fontWeight: "lighter" }}>
                          Des pièces ont été déposées pour les comptes soldés. Téléchargez les
                          pièces et rafraichissez la page pour en désactiver l’affichage.
                        </span>
                      </div>
                    </div>
                  ) : null}

                  <Popconfirm
                    title="Voulez-vous réinitialiser la liste des journaux ?"
                    onConfirm={resetJournals}
                    okText="Oui"
                    cancelText="Non"
                  >
                    {" "}
                    <Button className=" other-action-btn init-journals-btn">
                      Réinitialiser la liste des journaux (détection automatique)
                    </Button>
                  </Popconfirm>

                  <Form.Item>
                    <Button
                      type="submit"
                      htmlType="submit"
                      className="call-action-btn link-button-fec submit-date"
                    >
                      Valider
                    </Button>
                  </Form.Item>
                </Form>
              </Modal>
            </>
          ) : null}
        </>
      )}
    </>
  );
}

export default Header;

import React from "react";
import { Alert } from "antd";

const AlertCreateTable = (props) => {
  const { reportName, column, alert } = props;

  return (
    <>
      {alert ? (
        <Alert
          className="alert-create-table"
          message={
            !reportName
              ? "Merci de renseigner un nom de rapport"
              : column.length === 0
              ? "Merci d'ajouter des colonnes"
              : null
          }
          type="error"
          //closable={true}
          showIcon
        />
      ) : null}
    </>
  );
};

export default AlertCreateTable;

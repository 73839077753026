import { EditOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Button, Form, Input, List, Modal, Popover, Tooltip, Typography } from "antd";
import { useEffect, useState } from "react";
import * as API from "../../../api/API";
import LoaderRaw from "../../../utils/LoaderRaw";
import logAction from "../../../utils/logActions";
import openNotification from "../../../utils/notification";
import CustomSelect from "./CustomSelect";

function FeatureForm(props) {
  const [form] = Form.useForm();

  const [currentTemplate, setCurrentTemplate] = useState({});
  const [isBusy, setIsBusy] = useState(true);
  const [isChanging, setIsChanging] = useState(false);
  const [clientsUsingTemplate, setClientUsingTemplate] = useState([]);
  const [visibleModal, setVisibleModal] = useState(false);

  useEffect(() => {
    setCurrentTemplate({ ...props.selectedTemplate });
    setIsBusy(false);
  }, [props.selectedTemplate, props.type]);

  useEffect(() => {
    (async () => {
      if (!props.selectedTemplate.sesha) {
        let res = await API.getClientsTemplate(props.selectedTemplate._id, props.type);
        res = await res.json();
        setClientUsingTemplate(res);
      }
    })();
  }, [props.selectedTemplate, props.type]);

  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTemplate]);

  const onValuesChange = (changed, allvalues) => {
    setIsChanging(true);
  };

  const onSubmit = (values) => {
    (async () => {
      const data = {
        name: values.name,
        type: props.type,
        default: currentTemplate.default,
        template: {
          add_accounts: values.add_accounts,
          remove_accounts: values.remove_accounts,
          add_journals: values.add_journals,
          remove_journals: values.remove_journals,
        },
      };
      const res = await API.putAnalysisConfigTemplate(currentTemplate._id, data);
      if (res.status === 200) {
        openNotification("success", "Votre modèle a été sauvegardé");
        props.setSelectedTemplate(await res.json());
        props.setReloadTemplates(true);
      } else {
        openNotification("error", "Erreur lors de l'enregistrement du modèle");
      }
    })();
  };

  const deleteTemplate = () => {
    (async () => {
      const res = await API.deleteAnalysisConfigTemplate(currentTemplate._id);
      if (res.status === 200) {
        switch (props.type) {
          case "MISSING_DOC":
            logAction(91, -1);
            break;
          case "OUTSTANDINGS_CLIENTS":
            logAction(92, -1);
            break;
          case "OUTSTANDINGS_PROVIDERS":
            logAction(93, -1);
            break;
          default:
            break;
        }
        props.setSelectedTemplate(null);
        props.setReloadTemplates(true);
        openNotification("success", "Votre modèle a été supprimé");
        window.scroll(0, 0);
      } else {
        openNotification("error", "Erreur lors de la suppression");
      }
    })();
  };

  const onClickDelete = () => {
    if (clientsUsingTemplate.length) setVisibleModal(true);
    else deleteTemplate();
  };

  const onReset = () => {
    setIsChanging(true);
    props.setSelectedTemplate({
      ...props.selectedTemplate,
      template: props.defaultTemplates.find((el) => el.type === props.type).template,
    });
  };
  return (
    <>
      {isBusy ? (
        <LoaderRaw />
      ) : (
        <div className="type-form">
          <Form
            form={form}
            layout="horizontal"
            className="user-profile-form"
            onValuesChange={onValuesChange}
            onFinish={onSubmit}
            initialValues={{
              name: currentTemplate?.name,
              add_journals: currentTemplate?.template?.add_journals || [],
              remove_journals: currentTemplate?.template?.remove_journals || [],
              add_accounts: currentTemplate?.template?.add_accounts || [],
              remove_accounts: currentTemplate?.template?.remove_accounts || [],
            }}
          >
            <Form.Item
              name="name"
              label={<EditOutlined style={{ fontSize: "20px" }} />}
              className="type-form-title"
            >
              <Input
                bordered={true}
                className={"form-input template-name-input"}
                disabled={currentTemplate.sesha}
              />
            </Form.Item>

            <p>
              <b>Racines de comptes</b>
              <Popover
                placement="right"
                content="Personnalisez les racines de comptes utilisées dans le calcul de l’indicateur."
                className="info-popover"
                overlayClassName="info-popover-overlay"
              >
                <span>
                  <InfoCircleOutlined />
                </span>
              </Popover>
            </p>

            <div className="type-form-para">
              <Form.Item name="add_accounts" label="+">
                <CustomSelect
                  name={"add_accounts"}
                  cssClassName={"form-input add-tags"}
                  template={currentTemplate}
                />
              </Form.Item>
              <Form.Item name="remove_accounts" label="-">
                <CustomSelect
                  name={"remove_accounts"}
                  cssClassName={"form-input remove-tags"}
                  template={currentTemplate}
                />
              </Form.Item>
            </div>
            {props.type === "MISSING_DOC" && props.selectedTemplate.sesha ? null : (
              <>
                <p>
                  <b>Journaux</b>
                  <Popover
                    placement="right"
                    content={
                      props.type === "MISSING_DOC"
                        ? "Choisissez les journaux à exclure."
                        : "Choisissez les journaux à inclure."
                    }
                    className="info-popover"
                    overlayClassName="info-popover-overlay"
                  >
                    <span>
                      <InfoCircleOutlined />
                    </span>
                  </Popover>
                </p>
                <div className="type-form-para">
                  <Form.Item
                    name={props.type === "MISSING_DOC" ? "remove_journals" : "add_journals"}
                    label={props.type === "MISSING_DOC" ? "-" : "+"}
                  >
                    <CustomSelect
                      name={props.type === "MISSING_DOC" ? "remove_journals" : "add_journals"}
                      cssClassName={
                        props.type === "MISSING_DOC"
                          ? "form-input remove-tags"
                          : "form-input add-tags"
                      }
                      template={currentTemplate}
                    />
                  </Form.Item>
                </div>
              </>
            )}
            <>
              <p>
                <i>
                  La liste des journaux est automatiquement récupérée sur chaque dossier client.
                  Pour en savoir plus sur la génération de la liste des pièces manquantes, veuillez
                  consulter{" "}
                  <a
                    href="https://knowledge.sesha.expert/knowledge/comment-est-g%C3%A9n%C3%A9r%C3%A9e-la-liste-des-pi%C3%A8ces-manquantes"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    cet article.
                  </a>
                  <br />
                  Il est possible d’ajouter ou de supprimer des journaux au sein de chaque dossier
                  client en cliquant sur “Paramétrer la période d’analyse et les journaux".
                </i>
                <br />
                <br />
              </p>
            </>

            {currentTemplate.sesha ? null : (
              <>
                <Button className="other-action-btn cancel-btn" onClick={onReset}>
                  Rétablir le modèle Sesha
                </Button>
                <Form.Item className="save-template-item">
                  <Button
                    className={
                      !isChanging
                        ? "call-action-btn modal-submit-bnt disable-submit"
                        : "call-action-btn modal-submit-btn"
                    }
                    htmlType="submit"
                    disabled={!isChanging}
                  >
                    Sauvegarder
                  </Button>
                </Form.Item>

                {currentTemplate.sesha ? null : (
                  <Tooltip
                    title={
                      currentTemplate.default
                        ? "Le modèle par défaut ne peut pas être supprimé"
                        : null
                    }
                  >
                    <Button
                      className={
                        currentTemplate.default
                          ? "template-delete-btn-disabled"
                          : "call-action-btn template-delete-btn"
                      }
                      onClick={onClickDelete}
                      disabled={currentTemplate.default}
                    >
                      Supprimer
                    </Button>
                  </Tooltip>
                )}
              </>
            )}
          </Form>

          <Modal
            className="reset-modal"
            title="Avertissement avant suppression"
            centered
            open={visibleModal}
            onCancel={(e) => {
              e.preventDefault();
              setVisibleModal(false);
            }}
            footer={[
              <Button
                key="confirm"
                className="call-action-btn template-delete-btn"
                style={{ float: "unset" }}
                danger
                onClick={(e) => {
                  e.preventDefault();
                  setVisibleModal(false);
                  deleteTemplate();
                }}
              >
                Confirmer la suppression
              </Button>,
              <Button
                key="cancel"
                className="other-action-btn"
                onClick={(e) => {
                  e.preventDefault();
                  setVisibleModal(false);
                }}
              >
                Annuler
              </Button>,
            ]}
            width={500}
          >
            <p>
              Attention, ce modèle est utilisé sur un ou plusieurs dossiers client. Le supprimer
              réinitialisera ces dossiers avec le modèle par défaut
            </p>
            <p>Liste des clients utilisant ce modèle:</p>
            <List
              bordered
              dataSource={clientsUsingTemplate?.map((el) => el.name)}
              renderItem={(item) => (
                <List.Item>
                  <Typography.Text mark></Typography.Text> {item}
                </List.Item>
              )}
            />
          </Modal>
        </div>
      )}
    </>
  );
}

export default FeatureForm;

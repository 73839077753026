import { CheckCircleOutlined, CloseCircleOutlined, SyncOutlined } from "@ant-design/icons";
import { Tag } from "antd";
import { useEffect, useState } from "react";
import * as API from "../../api/API";
import openNotification from "../../utils/notification";

function DataProviderTag(props) {
  const [codeState, setCodeState] = useState("processing");
  const [errorCode, setErrorCode] = useState();
  const [errorText, setErrorText] = useState();

  useEffect(() => {
    if (
      (props.index === 0 && props.statusList[0] === "empty") ||
      props.statusList[props.index - 1] === "ready"
    ) {
      const code = props.allCodes.find((elt) => elt.code === props.code).code;
      (async () => {
        let response = await API.postDataProviderClient({
          dataProviderCode: code ? code : props.code,
        });
        if (response.status === 500) {
          openNotification(
            "error",
            "Une erreur interne est survenue, merci de réessayer dans quelques minutes."
          );
        }

        const statusListLocal = [...props.statusList];
        if (props.index !== 0) statusListLocal.splice(props.index - 1, 1, "done");

        statusListLocal.splice(props.index, 1, "ready");
        props.updateStatusList(statusListLocal);

        if (response.status === 200) setCodeState("success");
        else {
          setErrorCode(response.status);
          setErrorText(response.statusText);
          setCodeState("error");
        }
        if (props.index + 1 === props.length) props.sync(false);
      })();
    }
  }, [props.statusList]);

  const errorTxt = (errorstatus) => {
    switch (errorstatus) {
      case 400:
        return "Dossier inexistant";
      case 401:
        return "Droits insuffisants";
      case 206:
        return "Pas de données sur l’année courante";
      default:
        return false;
    }
  };

  return (
    <div className="acd-tag">
      <Tag
        icon={
          codeState === "processing" ? (
            <SyncOutlined spin />
          ) : codeState === "success" ? (
            <CheckCircleOutlined />
          ) : (
            <CloseCircleOutlined />
          )
        }
        color={codeState}
      >
        {props.name}
      </Tag>
      <span
        className={
          codeState === "processing"
            ? "processing-txt"
            : codeState === "success"
            ? "success-txt"
            : "error-txt "
        }
      >
        {codeState === "processing"
          ? "Synchronisation en cours"
          : codeState === "success"
          ? "Synchronisation réussie"
          : "Echec de la synchronisation - " +
            (errorCode === 204 ? errorText : errorTxt(errorCode))}
      </span>
    </div>
  );
}

export default DataProviderTag;

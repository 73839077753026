import React, { useState } from "react";
import { Button, Col, Row, Select } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";

import { ReactComponent as Guys } from "../../../../assets/images/vpf_modal_1.svg";
import Trending from "../../../../assets/images/vpf_populaire.svg";
import templateList from "../templateList";

import "../vpf.css";

function SelectPreset(props) {
  const [selectedReport, setSelectedReport] = useState();

  const onClickPreset = (colors, presetId) => {
    props.setReport({ ...templateList.find((el) => el.id === presetId), colors });
    props.setCurrentComponent("EditReport");
  };

  return (
    <Row className="select-preset">
      <Col span={14} offset={1} style={{ marginTop: "20px" }}>
        <p style={{ fontSize: "18px" }}>
          <b>Créez un tableau totalement personnalisé</b>
          <br />
          Choisissez parmi tous les critères et filtres disponibles
          <br />
          pour créer le tableau qui vous convient parfaitement :
        </p>
        <Button
          className="call-action-btn"
          style={{ marginBottom: "30px" }}
          onClick={() => {
            props.setReport({});
            props.setCurrentComponent("EditReport");
          }}
        >
          <PlusCircleOutlined /> Créer un nouveau tableau
        </Button>
        <br />

        <span style={{ fontSize: "18px" }}>
          <b>ou Gagnez du temps !</b>
          <p style={{ marginTop: "0px", marginBottom: "17px" }}>
            Sélectionnez l’un de nos tableaux déjà préconfigurés
            <br />& adaptez-le à vos besoins :
          </p>
        </span>

        <img
          src={Trending}
          style={{ height: "14px", marginTop: "5px", marginBottom: "2px" }}
          alt="Populaire sur Sesha"
        />
        <br />

        <Button
          className="modal-1-button"
          style={{ background: "#DDDCFF" }}
          onClick={() => onClickPreset({ light: "#DDDCFF", dark: "#370092" }, 17)}
        >
          <PlusCircleOutlined
            style={{ color: "#370092", marginLeft: "0px" }}
            className="modal-1-showbutton"
          />
          <span className="modal-1-button-text" style={{ color: "#370092" }}>
            {templateList.find((el) => el.id === 17).name}
          </span>
        </Button>
        <Button
          className="modal-1-button"
          style={{ background: "#FFEDDC" }}
          onClick={() => onClickPreset({ light: "#FFEDDC", dark: "#925700" }, 5)}
        >
          <PlusCircleOutlined
            style={{ color: "#925700", marginLeft: "0px" }}
            className="modal-1-showbutton"
          />
          <span className="modal-1-button-text" style={{ color: "#925700" }}>
            {templateList.find((el) => el.id === 5).name}
          </span>
        </Button>
        <Button
          className="modal-1-button"
          style={{ background: "#FFDCDC" }}
          onClick={() => onClickPreset({ light: "#FFDCDC", dark: "#920900" }, 18)}
        >
          <PlusCircleOutlined
            style={{ color: "#920900", marginLeft: "0px" }}
            className="modal-1-showbutton"
          />
          <span className="modal-1-button-text" style={{ color: "#920900" }}>
            {templateList.find((el) => el.id === 18).name}
          </span>
        </Button>
        <Button
          className="modal-1-button"
          style={{ background: "#DCFFE4" }}
          onClick={() => onClickPreset({ light: "#DCFFE4", dark: "#00920F" }, 16)}
        >
          <PlusCircleOutlined
            style={{ color: "#00920F", marginLeft: "0px" }}
            className="modal-1-showbutton"
          />
          <span className="modal-1-button-text" style={{ color: "#00920F" }}>
            {templateList.find((el) => el.id === 16).name}
          </span>
        </Button>

        <Button
          className="modal-1-button"
          style={{ background: "#DCF7FF" }}
          onClick={() => onClickPreset({ light: "#DCF7FF", dark: "#000F92" }, 1)}
        >
          <PlusCircleOutlined
            style={{ color: "#000F92", marginLeft: "0px" }}
            className="modal-1-showbutton"
          />
          <span className="modal-1-button-text" style={{ color: "#000F92" }}>
            {templateList.find((el) => el.id === 1).name}
          </span>
        </Button>

        <p style={{ fontSize: "11px", color: "#4C68F0", fontWeight: "600", marginTop: "15px" }}>
          Rechercher un tableau pré-configuré :{" "}
        </p>

        <Select
          className="vpf-modal-index-selection"
          showSearch
          placeholder="Montant total en compte d’attente (47)..."
          optionFilterProp="children"
          style={{
            width: "400px",
            marginBottom: "30px",
          }}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          options={templateList.map(({ id, name }) => ({ value: id, label: name }))}
          onChange={(value) => setSelectedReport(value)}
          placement="topLeft"
          dropdownAlign={{ overflow: { adjustY: false } }}
        />
        <Button
          className="call-action-btn"
          style={{ marginLeft: "10px" }}
          onClick={() => onClickPreset({ light: "#FFDCDC", dark: "#920900" }, selectedReport)}
        >
          Éditer
        </Button>
      </Col>
      <Col span={8} style={{ textAlign: "center" }}>
        <Guys style={{ marginTop: "18px" }} />
      </Col>
    </Row>
  );
}

export default SelectPreset;

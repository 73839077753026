import * as dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import * as API from "../../../api/API";
import { selectAccountingFirm } from "../../../slices/accountingFirmSlice";
import { selectUser } from "../../../slices/userSlice";
import LoaderRaw from "../../../utils/LoaderRaw.js";
import openNotification from "../../../utils/notification";

import Header from "./Header";
import Noreports from "./Noreports";
import Tableau from "./Table";

const getRandomInt = (max) => Math.floor(Math.random() * max);

function VPF(props) {
  const accountingFirm = useSelector(selectAccountingFirm);

  const [loading, setLoading] = useState(true);
  const [loadingSync, setLoadingSync] = useState(true);
  const [reports, setReports] = useState([]);
  const user = useSelector(selectUser);
  const [rowNumber, setRowNumber] = useState(0);
  const [deleteReport, setDeleteReport] = useState();
  const [apeCodes, setApeCodes] = useState([]);
  const [templates, setTemplates] = useState([]);

  const [users, setUsers] = useState([]);
  const [reloadAll, setReloadAll] = useState(false);
  const [clientTotal, setClientTotal] = useState();

  const opportTotal = useRef([]);
  const [triggerRef, setTriggerRef] = useState(0);

  const [selectedDate, setSelectedDate] = useState({
    year:
      dayjs().date() < 15
        ? dayjs().subtract(2, "month").year()
        : dayjs().subtract(1, "month").year(),
    month:
      dayjs().date() < 15
        ? dayjs().subtract(2, "month").month() + 1
        : dayjs().subtract(1, "month").month() + 1,
  });

  useEffect(() => {
    (async () => {
      let res = await API.getReportsByUser();
      res = await res.json();
      res = res.sort((a, b) => a.positionIndex - b.positionIndex);
      setReports(res);
      setLoading(false);
    })();
  }, [reloadAll]);

  useEffect(() => {
    if (accountingFirm.status === "ready") {
      (async () => {
        let res = await API.getClientsByAccountingFirmId(accountingFirm._id);
        res = await res.json();
        setClientTotal(res.length);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountingFirm.status]);

  useEffect(() => {
    (async () => {
      let resAPE = await API.getAPECodes();
      resAPE = await resAPE.json();
      setApeCodes(resAPE);
      let resUsers = await API.getUsersByAccountingFirmId(accountingFirm._id);
      resUsers = await resUsers.json();
      setUsers(resUsers);
      let templates = await API.getAnalysisConfigTemplates();
      templates = await templates.json();
      setTemplates(templates);
    })();
  }, []);

  useEffect(() => {
    if (rowNumber !== 0) {
      (async () => {
        await API.putReport(props.report._id, {
          rowNumber,
        });
      })();
    }
  }, [rowNumber]);

  useEffect(() => {
    if (deleteReport)
      (async () => {
        const res = await API.deleteReport(deleteReport);
        setReports(reports.filter((report) => report._id !== deleteReport));
        if (res.status === 200) openNotification("success", "Votre tableau a été supprimé");
        else openNotification("error", "Erreur lors de la suppression");
      })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteReport]);

  return (
    <>
      <span className="vpf-global">
        {loading ? null : reports.length === 0 ? (
          <Noreports
            {...props}
            templates={templates}
            reloadAll={reloadAll}
            setReloadAll={setReloadAll}
            selectedDate={selectedDate}
          />
        ) : (
          <>
            <Header
              {...props}
              reports={reports}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              reloadAll={reloadAll}
              setReloadAll={setReloadAll}
              users={users}
              apeCodes={apeCodes}
              templates={templates}
              opportTotal={opportTotal}
              triggerRef={triggerRef}
              setLoadingSync={setLoadingSync}
            />
            {loadingSync ? (
              <LoaderRaw />
            ) : (
              reports.map((report, i) =>
                users.length && apeCodes.length && !loadingSync ? (
                  <Tableau
                    {...props}
                    key={report._id}
                    report={report}
                    selectedDate={selectedDate}
                    currentUser={user}
                    setRowNumber={setRowNumber}
                    setDeleteReport={setDeleteReport}
                    users={users}
                    connectedRights={accountingFirm?.connectedRights}
                    apeCodes={apeCodes}
                    templates={templates}
                    clientTotal={clientTotal}
                    setOpportTotal={(data) => {
                      if (opportTotal.current.some((el) => el._id === data._id))
                        opportTotal.current.find((el) => el._id === data._id).length = data.length;
                      else opportTotal.current.push(data);
                      setTriggerRef(getRandomInt(1000));
                    }}
                    deleteOpportTotal={(data) => {
                      const i = opportTotal.current.findIndex((el) => el._id === data);
                      opportTotal.current.splice(i, 1);
                      setTriggerRef(getRandomInt(1000));
                    }}
                    setTriggerRef={() => setTriggerRef(getRandomInt(1000))}
                    type="real"
                  />
                ) : null
              )
            )}
          </>
        )}
      </span>
    </>
  );
}

export default VPF;

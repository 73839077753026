import { Table } from "antd";
import { useEffect, useRef, useState } from "react";

import * as dayjs from "dayjs";
import "dayjs/locale/fr";

import * as API from "../../../../api/API";
import { selectEndDateType } from "../../../../utils/missing";
import { addSearchAndSorterToColumns } from "../../../../utils/table";
import labels from "./ClientTableLabels";

import "dayjs/locale/fr";

function ClientTable(props) {
  const [clientsData, setClientsData] = useState();
  const [loadingData, setLoadingData] = useState(true);

  const [searchTexts, setSearchTexts] = useState({});
  const [searchedColumn, setSearchedColumn] = useState([]);
  const searchInput = useRef(null);

  let searchVariables = {
    searchTexts,
    setSearchTexts,
    searchedColumn,
    setSearchedColumn,
    searchInput: searchInput,
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "ID",
      key: "ID",
      align: "center",
      width: "20px",
      fixed: "left",
    },
    {
      title: "Remote ID",
      dataIndex: "remoteId",
      key: "remoteId",
      align: "center",
      width: "40px",
      fixed: "left",
    },
    {
      title: "Nom du client",
      dataIndex: "name",
      key: "name",
      align: "center",
      width: "8%",
      fixed: "left",
    },
    {
      title: "Type d'analyse",
      dataIndex: "analysisType",
      key: "analysisType",
      align: "center",
      width: "8%",
    },
    {
      title: "Dernière analyse",
      dataIndex: "lastAnalysisEmailSent",
      key: "lastAnalysisEmailSent",
      align: "center",
      width: "5.5%",
    },
    {
      title: "Mois de clotûre du dossier",
      dataIndex: "fiscalStartMonth",
      key: "fiscalStartMonth",
      align: "center",
      width: "5.5%",
      defaultFilters: true,
    },
    {
      title: "Stock",
      dataIndex: ["stock"],
      key: "stock",
      align: "center",
      width: "7.5%",
      defaultFilters: true,
    },
    {
      title: "Extrapolation",
      dataIndex: ["extrapolation"],
      key: "extrapolation",
      align: "center",
      width: "6%",
    },
    {
      title: "Modèle Analyse",
      dataIndex: ["templateAnalysis"],
      key: "templateAnalysis",
      align: "center",
      width: "5%",
    },
    {
      title: "Modèle PM",
      dataIndex: ["templatePM"],
      key: "templatePM",
      align: "center",
      width: "5%",
    },
    {
      title: "Modèle EC",
      dataIndex: ["templateEC"],
      key: "templateEC",
      align: "center",
      width: "5%",
    },
    {
      title: "Modèle EF",
      dataIndex: ["templateEF"],
      key: "templateEF",
      align: "center",
      width: "5%",
    },
    {
      title: "Dernière relance",
      children: [
        {
          title: "Pièces manquantes",
          dataIndex: "lastMissingDocEmailSent",
          key: "lastMissingDocEmailSent",
          align: "center",
          width: "5.5%",
        },
        {
          title: "Encours clients",
          dataIndex: "lastOutstandingsClientsEmailSent",
          key: "lastOutstandingsClientsEmailSent",
          align: "center",
          width: "5.5%",
        },
        {
          title: "Encours fournisseurs",
          dataIndex: "lastOutstandingsProvidersEmailSent",
          key: "lastOutstandingsProvidersEmailSent",
          align: "center",
          width: "5.5%",
        },
        {
          title: "Accès client",
          dataIndex: "accesClient",
          key: "accesClient",
          align: "center",
          width: "5%",
          defaultSearch: false,
          defaultSorter: false,
        },
      ],
    },
    {
      title: "Mise à jour du dossier",
      dataIndex: "lastUpdate",
      key: "lastUpdate",
      align: "center",
      width: "5.5%",
    },
    {
      title: "Mail to box",
      dataIndex: "mailToBox",
      key: "mailToBox",
      align: "center",
      width: "5%",
    },
    {
      title: "GED",
      dataIndex: "ged",
      key: "ged",
      align: "center",
      width: "4%",
      defaultSearch: false,
      defaultSorter: false,
    },
    {
      title: "Download",
      dataIndex: "download",
      key: "download",
      align: "center",
      width: "4%",
      defaultSearch: false,
      defaultSorter: false,
    },
  ];

  addSearchAndSorterToColumns(columns, searchVariables, clientsData);

  useEffect(() => {
    (async () => {
      if (props.currentAccFirm) {
        setLoadingData(true);
        const idepotStatus = await API.testIDepotConnectionDashboard({
          accountingFirmId: props.currentAccFirm._id,
        });

        const allMcfStatus = await API.testMCFConnections({
          accountingFirmId: props.currentAccFirm._id,
          clients: props.displayedClients.map(c => ({ _id: c._id, siren: c.siren })),
        });

        let newData = []
        for(let c of props.displayedClients) {
          // retrieve missing documents and outstandings clients journals
          let outstandingsClientsEndDate = (c.outstandingsParam && c.outstandingsParam.OUTSTANDINGS_CLIENTS?.endDate) 
            || dayjs().endOf("day").format("DD/MM/YYYY");
          let outstandingsProvidersEndDate = (c.outstandingsParam && c.outstandingsParam.OUTSTANDINGS_PROVIDERS?.endDate) 
            || dayjs().endOf("day").format("DD/MM/YYYY");
            
          const journals = [
            API.getMissingDocuments(c._id, 
              { synchro: false, accountingFirmId: props.currentAccFirm._id}
            ),
            API.getOutstandings(c._id,
              new Date(outstandingsClientsEndDate).year,
              new Date(outstandingsClientsEndDate).month,
              "OUTSTANDINGS_CLIENTS",
              { synchro: false, accountingFirmId: props.currentAccFirm._id}
            )
          ]
          const [missingInfo, outstandingsInfo] = await Promise.all(
            (await Promise.all(journals)).map(r => { return r.status === 200 ? r.json() : undefined })
          );
          let outstandingsClientsInfo = outstandingsInfo?.journals?.clients;
          let outstandingsProvInfo = outstandingsInfo?.journals?.providers;

          let obj = ({
            key: c._id,
            _id: c._id,
            siren: c.siren,

            ID: labels.idLabel('_id')(c._id),
            remoteId: labels.idLabel('remoteId')(c.remoteId),
            name: c.name,
            
            analysisType: labels.typeAnalysisLabel(c),
            lastAnalysisEmailSent: labels.lastAnalysisLabel(c),
            fiscalStartMonth: labels.fiscalStartMonthLabel(c.fiscalStartMonth),
            
            stock: labels.stockLabel(c.dataTuning?.active),
            extrapolation: labels.extrapolationLabel(c.dataTuning?.extrapolate),
            
            templateAnalysis: labels.analysisTemplateLabel(c.templatesData.ANALYSIS),

            templatePM: labels.analysisTemplateLabel(c.templatesData.MISSING_DOC, { 
              startDate: dayjs(c.filterStartDate).format("DD/MM/YYYY"),
              endDate: selectEndDateType(props.currentAccFirm.missingDocEndDate),
              detected: missingInfo?.journals?.missing_documents || [], 
              added: (c.journalList || [])
                .filter(j => !missingInfo?.journals.missing_documents || !missingInfo?.journals.missing_documents.includes(j)), 
              hidden: c.hiddenJournals || [], 
              excluded: c.templatesData.MISSING_DOC?.template?.remove_journals || [], 
            }),

            templateEC: labels.analysisTemplateLabel(c.templatesData.OUTSTANDINGS_CLIENTS, { 
              startDate: (c.outstandingsParam && c.outstandingsParam.OUTSTANDINGS_CLIENTS?.startDate) 
                || dayjs().subtract(15, "month").startOf("day").format("DD/MM/YYYY"),
              endDate: outstandingsClientsEndDate,
              dueDays: c.outstandingsParam.clients.dueDays,
              detected: outstandingsClientsInfo || [], 
              added: (c.outstandingsParam.clients.journalList || [])
                .filter(j => !outstandingsClientsInfo || !outstandingsClientsInfo.includes(j)), 
              hidden: c.outstandingsParam.clients.hiddenJournals || [], 
              included: c.templatesData.OUTSTANDINGS_CLIENTS?.template?.add_journals || [], 
            }),
            
            templateEF: labels.analysisTemplateLabel(c.templatesData.OUTSTANDINGS_PROVIDERS, { 
              startDate: (c.outstandingsParam && c.outstandingsParam.OUTSTANDINGS_PROVIDERS?.startDate) 
                || dayjs().subtract(15, "month").startOf("day").format("DD/MM/YYYY"),
              endDate: outstandingsProvidersEndDate,
              dueDays: c.outstandingsParam.providers.dueDays,
              detected: outstandingsProvInfo || [], 
              added: (c.outstandingsParam.providers.journalList || [])
                .filter(j => !outstandingsProvInfo || !outstandingsProvInfo.includes(j)),
              hidden: c.outstandingsParam.providers.hiddenJournals || [], 
              included: c.templatesData.OUTSTANDINGS_PROVIDERS?.template?.add_journals || [], 
            }),

            lastMissingDocEmailSent: labels.lastLabel(c.email?.missingdoc?.lastEmailSent),
            lastOutstandingsClientsEmailSent: labels.lastLabel(c.email?.outstandingsclients?.lastEmailSent),
            lastOutstandingsProvidersEmailSent: labels.lastLabel(c.email?.outstandingsproviders?.lastEmailSent),
            accesClient: labels.accesClientLabel(c.clientToken),

            lastUpdate: labels.lastUpdate(c.lastUpdate),
            mailToBox: labels.mailToBoxLabel(c.mailToBox),
            ged: labels.gedLabel(idepotStatus)(allMcfStatus.find( r => r._id === c._id)?.statusCode),
            download: labels.downloadButtons(props.currentAccFirm._id, c),
          })

        newData.push(obj);
      };

      setClientsData(newData);
      setLoadingData(false);
    }
    })();
  }, [props.displayedClients, props.currentAccFirm]);

  return (
    <Table
      columns={columns}
      dataSource={clientsData}
      loading={props.loadingDisplayedClients || loadingData}
      size="small"
      bordered
      pagination={true}
      rowKey={(record) => record.key}
      scroll={{
        x: "max-content",
      }}
    />
  );
}

export default ClientTable;


// states : ok, ko, meh, off
function bgColoring(status) {
    switch (status) {
        case "ok":
            return "MediumSeaGreen";
        case "ko":
            return "IndianRed";
        case "meh":
            return "sandybrown";
        case "off":
            return "grey";
        default:
            return "grey";
    }
}

function ftColoring(status) {
    switch (status) {
        case "ok":
        case "ko":
        case "meh":
        case "off":
        default:
            return "white";
    }
}
function ColoredCapsule(props) {
    return <span style={{
        paddingTop: "3px",
        paddingBottom: "3px",
        paddingInline: "15px",
        borderRadius: "20px",
        color: ftColoring(props.status),
        backgroundColor: bgColoring(props.status),
        justifyContent: "center",
    }}>
        {props.content}
    </span>
}

export default ColoredCapsule;

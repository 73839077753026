import { useEffect, useRef, useState } from "react";
import * as API from "../../api/API";
import openNotification from "../../utils/notification";

import { EditOutlined } from "@ant-design/icons";
import { Button, Input, Select } from "antd";

const { Option } = Select;

function RenderCell(props) {
  const [newCellValue, setNewCellValue] = useState(props.text);
  const [editMode, setEditMode] = useState(false);

  const cellRef = useRef(null);
  const inputRef = useRef(null);
  const selectRef = useRef(null);

  useEffect(() => {
    if (editMode) {
      inputRef.current?.focus();
      selectRef.current?.focus();
    }
  }, [editMode]);

  useEffect(() => {
    setNewCellValue(props.text);
  }, [props.text]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (cellRef.current && !cellRef.current?.contains(event.target) && props.editedCell) {
        onFinish(props.record, props.editedCell.type, newCellValue);
        props.setEditedCell({});
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [cellRef, props.editedCell, newCellValue]);

  useEffect(() => {
    setEditMode(
      props.editedCell?.type === props.type && props.editedCell?.user_id === props.record._id
    );
  }, [props.editedCell, props.record._id, props.type]);

  const onFinish = (record, type, value) => {
    (async () => {
      var userInfo = {};
      userInfo[type] = value;
      const resUser = await API.putUser(record._id, userInfo);
      if (resUser.status === 409) openNotification("error", "L'adresse email est déjà utilisée");
      else if (resUser.status === 201) {
        props.setUserListUpdate(true);
        API.getDataUserRights();
        props.setUpdateRoles(!props.updateRoles);
      } else {
        openNotification("error", "Erreur lors de la modification");
      }
    })();
  };

  const inputType = () => {
    switch (props.type) {
      case "lastName":
        return (
          <div ref={cellRef}>
            <Input
              ref={inputRef}
              value={newCellValue}
              onChange={(e) => {
                setNewCellValue(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  props.setEditedCell({});
                  onFinish(props.record, "lastName", newCellValue);
                }
              }}
            />
          </div>
        );
      case "firstName":
        return (
          <div ref={cellRef}>
            <Input
              ref={inputRef}
              value={newCellValue}
              onChange={(e) => {
                setNewCellValue(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  props.setEditedCell({});
                  onFinish(props.record, "firstName", newCellValue);
                }
              }}
            />
          </div>
        );
      case "email":
        return (
          <div ref={cellRef}>
            <Input
              ref={inputRef}
              value={newCellValue}
              onChange={(e) => {
                setNewCellValue(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  props.setEditedCell({});
                  onFinish(props.record, "email", newCellValue);
                }
              }}
            />
          </div>
        );
      case "newRole":
        return (
          <div ref={cellRef}>
            {" "}
            <Select
              value={newCellValue}
              onMouseDown={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              onSelect={(value) => {
                setNewCellValue(value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  props.setEditedCell({});
                  onFinish(props.record, "newRole", newCellValue);
                }
              }}
            >
              {props.roleList.map((role) => (
                <Option key={role._id} value={role._id}>
                  {role.label}
                </Option>
              ))}
            </Select>
          </div>
        );
      case "rights":
        return (
          <div ref={cellRef}>
            <Select
              className="user-other-rights"
              dropdownRender={(menu) => <div ref={cellRef}>{menu}</div>}
              onMouseDown={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              mode="multiple"
              value={newCellValue}
              onChange={(value) => {
                setNewCellValue(value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  props.setEditedCell({});
                  onFinish(props.record, "rights", newCellValue);
                }
              }}
              placement="bottomLeft"
              dropdownAlign={{ overflow: { adjustY: false } }}
              ref={selectRef}
            >
              {props.userRightsList.map((elt, i) => {
                return (
                  <Option key={i} value={elt.user_id}>
                    {elt.user_id}
                  </Option>
                );
              })}
            </Select>
          </div>
        );
      default:
        return "Error";
    }
  };

  return (
    <div>
      {editMode ? (
        <>{inputType()}</>
      ) : (
        <>
          <div>{props.display}</div>{" "}
          {props.showEditCell?.type === props.type &&
          props.showEditCell?.user_id === props.record._id ? (
            <Button
              type="primary"
              shape="circle"
              icon={
                <EditOutlined
                  fontSize="9px"
                  style={{ clipPath: "inset(0% 10% 16% 10%)", position: "relative", top: "-1.5px" }}
                />
              }
              size="small"
              style={{
                position: "absolute",
                top: "4px",
                right: "0px",
                minWidth: "18px",
                minHeight: "18px",
                width: "18px",
                height: "18px",
                padding: "0px",
              }}
            />
          ) : null}
        </>
      )}
    </div>
  );
}
export default RenderCell;

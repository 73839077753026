import { FrownOutlined } from "@ant-design/icons";
import { Button, List, Progress, Result, Skeleton } from "antd";
import { useEffect, useState } from "react";
import * as API from "../../../api/API";
import { ReactComponent as ImportantIcon } from "../../../assets/images/important.svg";
import "./AttentionPoints.css";

const createHTML = (html) => {
  return { __html: html };
};

const endOfTheMonth = (year, month) => {
  let date1 = new Date(year, month, 0);
  return date1.toLocaleString("fr-FR", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

function AttentionPoints(props) {
  const clientRemoteId = props.match.params.clientRemoteId;
  const clientId = props.match.params.clientId;

  const [keyPointsData, setKeyPointsData] = useState();
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!props.wait)
      (async () => {
        setKeyPointsData();
        let res = await API.getKeyPoints(
          clientId,
          clientRemoteId,
          props.date.fiscal_year,
          props.date.month
        );
        if (res.status === 200) {
          res = await res.json();
          setKeyPointsData(res);
          props.setBadgeKeyPoints(res?.attention_points_nlg?.length || 0);
        } else {
          props.setBadgeKeyPoints(0);
          setError(true);
        }
      })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.wait, clientRemoteId, props.date]);

  return (
    <div className="div-attention-point">
      {error ? (
        <Result
          icon={<FrownOutlined style={{ color: "#4569f8" }} />}
          title={"Nous ne parvenons pas à générer les points d'attention"}
        />
      ) : !keyPointsData ? (
        <Skeleton
          className="skeleton-analysis"
          active={true}
          paragraph={{
            rows: 4,
          }}
        />
      ) : (
        <>
          {keyPointsData?.attention_points_nlg?.length > 0 ? (
            <>
              <div className="circle-points-attention">
                <Progress
                  type="circle"
                  percent={100}
                  size={80}
                  strokeColor="#FF9700"
                  format={() => (
                    <span className="circular-text">
                      {keyPointsData.attention_points_nlg.length}
                    </span>
                  )}
                />
                <div className="circle-text-block">
                  <span className="total-amount">
                    Point{keyPointsData.attention_points_nlg.length > 1 ? "s" : ""} d'attention
                  </span>
                  <span className="total-amount-text">
                    au {endOfTheMonth(props.date.fiscal_year, props.date.month)}
                  </span>
                  <br />
                  <Button
                    type="link"
                    className="change-period"
                    onClick={(e) => {
                      e.preventDefault();
                      props.activeKeyAttentionPoints("1");
                    }}
                  >
                    Changer la période d'analyse
                  </Button>
                </div>
              </div>

              <List
                bordered={false}
                dataSource={keyPointsData.attention_points_nlg}
                renderItem={(element, i) => (
                  <List.Item key={i} className="site-collapse-custom-header">
                    {element ? (
                      <div>
                        <span className="actionsIcons">
                          <ImportantIcon />
                        </span>
                        <div
                          className="attention-point-bullet"
                          dangerouslySetInnerHTML={createHTML(element)}
                        />
                      </div>
                    ) : (
                      false
                    )}
                  </List.Item>
                )}
              />
            </>
          ) : (
            <Result title={"Aucun point d'attention détecté sur la période"} />
          )}
        </>
      )}
    </div>
  );
}
export default AttentionPoints;

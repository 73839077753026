import React, { useState, useEffect } from "react";
import { Select } from "antd";
import * as dayjs from "dayjs";
import "dayjs/locale/fr";
import "./vpf.css";

const { Option } = Select;

function SelectMonth(props) {
  const [selectDates, setSelectDates] = useState();

  const handleChange = (value) =>
    props.setSelectedDate({
      year: dayjs(value).year(),
      month: dayjs(value).month() + 1,
    });

  useEffect(() => {
    const monthNames = [
      "Janvier",
      "Février",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Août",
      "Septembre",
      "Octobre",
      "Novembre",
      "Décembre",
    ];

    const today = dayjs().date() < 15 ? dayjs().subtract(2, "month") : dayjs().subtract(1, "month");
    const dates = [];
    for (let index = 4; index >= -2; index--) {
      const date = today.subtract(index, "month");
      dates.push({
        value: date.year() + "-" + (date.month() + 1),
        label: monthNames[date.month()] + " " + date.year(),
      });
    }
    setSelectDates(dates.reverse());
  }, []);

  return (
    <span className="select-month">
      {selectDates ? (
        <>
          <span className="select-month-text">Mois d'analyse :</span>
          <Select
            defaultValue={
              dayjs().date() < 15
                ? selectDates[2].value.toString()
                : selectDates[1].value.toString()
            }
            style={{ width: 160 }}
            onChange={handleChange}
            popupClassName="ant-select-dropdown-zindex"
            placement="bottomLeft"
            dropdownAlign={{ overflow: { adjustY: false } }}
          >
            {selectDates.map((date) => (
              <Option value={date.value.toString()} key={date.label}>
                {date.label}
              </Option>
            ))}
          </Select>
        </>
      ) : null}
    </span>
  );
}

export default SelectMonth;

import {
  CheckOutlined,
  CloseOutlined,
  DownloadOutlined,
  ExclamationCircleOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  LeftCircleOutlined,
  PaperClipOutlined,
  RightCircleOutlined,
} from "@ant-design/icons";
import { Button, Card, Carousel, Divider, Modal, Popover, Spin, Tag, Tooltip } from "antd";
import { ReceivedDocHelper } from "helpers/analysis/received/receivedDocHelper";
import * as uploadHelper from "helpers/client/clientUploadHelper";
import { useEffect, useRef, useState } from "react";
import { pdfjs } from "react-pdf";
import { useDispatch, useSelector } from "react-redux";
import logAction from "utils/logActions";
import * as API from "../../../../api/API";
import ConciliatorLogoNotValid from "../../../../assets/images/conciliator-error.png";
import ConciliatorLogoValid from "../../../../assets/images/conciliator-valid.png";
import IDocusLogoNotValid from "../../../../assets/images/idocusnotvalid.png";
import IDocusLogoValid from "../../../../assets/images/idocusvalid.png";
import IDepotLogo from "../../../../assets/images/logo-isuite-depot.png";
import MailToBoxLogoValid from "../../../../assets/images/mailtobox.png";
import MCFLogoNotValid from "../../../../assets/images/mcfnotvalid.png";
import MCFLogoValid from "../../../../assets/images/mcfvalid.png";
import { selectAccountingFirm } from "../../../../slices/accountingFirmSlice";
import { selectClient, updateClient } from "../../../../slices/clientSlice";
import { selectUser } from "../../../../slices/userSlice";

import openNotification from "../../../../utils/notification";
import InvalidFile from "../InvalidFile";
import PdfViewer from "../PdfViewer";
import ResetOperation from "../ResetOperation";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const { confirm } = Modal;

function Other(props) {
  const dispatch = useDispatch();
  const client = useSelector(selectClient);
  const accountingFirm = useSelector(selectAccountingFirm);
  const user = useSelector(selectUser);

  const [showPreview, setShowPreview] = useState(false);
  const [fileShown, setFileShown] = useState();
  const [loading, setLoading] = useState(false);
  const [blobForPreview, setBlobForPreview] = useState();
  const [otherMissingDocuments, setOtherMissingDocuments] = useState();
  const [invalidated, setInvalidated] = useState([]);
  const [invalidComment, setInvalidComment] = useState([]);
  const [reseted, setReseted] = useState([]);
  const [downloadedOnce, setDownloadedOnce] = useState([]);

  const filePreview = useRef();

  let displayOthersOnce = true;

  useEffect(() => {
    if (client.status === "ready") {
      setOtherMissingDocuments(client.otherMissingDocuments);
      const other = [];
      client.otherMissingDocuments?.forEach((op) => {
        if (op.fileId || op.comment || op.files?.length > 0) {
          other.push(op);
        }
      });
      props.other(other.length);
    }
  }, [client]);

  const handleAttachmentDownload = async (file) => {
    await uploadHelper.downloadAttachmentHelper(file);
  };

  function openPreview(operation, element) {
    setLoading(true);
    setFileShown(operation);
    setShowPreview(true);
    // eslint-disable-next-line array-callback-return
    ReceivedDocHelper.previewFile(client, operation.files, operation._id).then((returnedData) => {
      setBlobForPreview(returnedData);
      setLoading(false);
    });
  }

  const closePreview = () => {
    setShowPreview(false);
    for (let i = 0; i < blobForPreview.length; i++) {
      URL.revokeObjectURL(blobForPreview[i]);
    }
  };

  function confirmDeletion(onConfirm) {
    confirm({
      title:
        "La validation de la pièce entraînera sa suppression dans les pièces manquantes ainsi que dans les pièces reçues. Assurez-vous d'avoir bien téléchargé les pièces avant de valider.",
      icon: <ExclamationCircleOutlined style={{ bottom: "45px" }} />,
      className: "modal-lost modal-others",
      okText: "Valider",
      cancelText: "Annuler",
      closable: true,
      onOk() {
        onConfirm();
      },
    });
  }

  const deleteOMD = (idOMD, idFile) => {
    (async () => {
      const res = await API.deleteOperation(client._id, idOMD);
      if (res.status === 200) {
        const newList = [...otherMissingDocuments.filter((op) => op._id !== idOMD)];
        setOtherMissingDocuments(newList);
        dispatch(
          updateClient({
            otherMissingDocuments: newList,
          })
        );
      } else openNotification("error", "Impossible de supprimer l'opération");
    })();

    if (idFile) {
      (async () => {
        const res = await API.deleteMissingDocumentFile(idFile);
        if (res.status === 201) {
          console.log("File deleted");
        } else console.log("Can't delete file");
      })();
    }
  };

  return (
    <>
      <Modal
        title={null}
        footer={null}
        open={showPreview}
        onCancel={() => closePreview()}
        closable={false}
        className="modal-preview"
      >
        {fileShown?.files.length > 1 ? (
          <>
            <Button
              icon={
                <LeftCircleOutlined
                  style={{
                    fontSize: "1.4em",
                    color: "#2F2F2F",
                  }}
                />
              }
              size="large"
              shape="round"
              type="text"
              onClick={() => filePreview.current.prev()}
              className="transparent-btn"
              style={{ left: "-50%", top: "250px" }}
            />
            <Button
              icon={
                <RightCircleOutlined
                  style={{
                    fontSize: "1.4em",
                    color: "#2F2F2F",
                  }}
                />
              }
              size="large"
              shape="round"
              type="text"
              onClick={() => filePreview.current.next()}
              className="transparent-btn"
              style={{
                float: "right",
                right: "-10%",
                top: "250px",
              }}
            />
          </>
        ) : null}
        <Carousel ref={filePreview} dots={false}>
          {fileShown?.files.map((file, index) => {
            const fileExtension = file.fileName.split(".").pop();
            const allowedExtensions = ["jpg", "png", "jpeg", "pdf"];

            if (!allowedExtensions.includes(fileExtension)) {
              return (
                <div>
                  <div className="no-preview" key={index}>
                    <span>
                      <EyeInvisibleOutlined style={{ fontSize: "1.7em" }} />
                      <p>Apperçu non disponible</p>
                      <p>{file.fileName}</p>
                    </span>
                  </div>
                </div>
              );
            }

            return (
              <>
                {loading ? (
                  <div className="no-preview">
                    <Spin size="large" />
                  </div>
                ) : (
                  <div key={index}>
                    <p
                      style={{
                        color: "#373737",
                        fontWeight: "bold",
                        marginTop: "0px",
                        position: "relative",
                        left: "-20%",
                      }}
                    >
                      {file.fileName}
                    </p>
                    {fileExtension === "pdf" ? (
                      <PdfViewer file={blobForPreview[index]} />
                    ) : (
                      <img
                        src={blobForPreview[index]}
                        alt="preview"
                        className="ratio-img"
                        style={{ margin: "auto", height: "auto !important" }}
                      />
                    )}
                  </div>
                )}
              </>
            );
          })}
        </Carousel>
      </Modal>
      {client.status === "ready" ? (
        <div className="other-received">
          {otherMissingDocuments?.map((operation, k) => {
            if (operation.fileId || operation.comment || operation.files?.length > 0) {
              return (
                <div style={{ marginBottom: "20px" }} key={k}>
                  {displayOthersOnce ? (
                    <>
                      {" "}
                      <div className="type-title">Autres justificatifs</div>
                      <div className="type-separation-other"></div>
                    </>
                  ) : null}
                  {(displayOthersOnce = false)}
                  <div style={{ display: "block" }}>
                    <Card
                      style={{
                        border:
                          invalidated.includes(operation._id) || reseted.includes(operation._id)
                            ? ""
                            : "2px solid #C7E0FF",
                        background: "#EAF4FE",
                        display: "inline-block",
                        width: "100%",
                      }}
                      className={
                        invalidated.includes(operation._id) || reseted.includes(operation._id)
                          ? "other-card status-grey "
                          : "other-card"
                      }
                    >
                      <div className="other-op-received">
                        <div>
                          <div
                            dangerouslySetInnerHTML={{ __html: operation.text }}
                            style={{ display: "inline-block" }}
                          />

                          <div style={{ display: "inline-block", float: "right" }}>
                            {invalidated.includes(operation._id) ? (
                              <span className="not-valid-other">Invalidé</span>
                            ) : reseted.includes(operation._id) ? (
                              <span className="reseted-other">Réinitialisée</span>
                            ) : operation.files.length > 0 ? (
                              <>
                                <Tooltip title="Aperçu de la pièce">
                                  <Button
                                    onClick={(e) => {
                                      e.preventDefault();
                                      openPreview(operation, "other");
                                    }}
                                    shape="circle"
                                    size="small"
                                    icon={
                                      <EyeOutlined
                                        style={{
                                          top: "1.5px",
                                          position: "relative",
                                        }}
                                      />
                                    }
                                  />
                                </Tooltip>
                                <Tooltip
                                  title={
                                    downloadedOnce.includes(operation._id)
                                      ? "Télécharger à nouveau"
                                      : "Télécharger"
                                  }
                                >
                                  <Button
                                    size="small"
                                    shape="circle"
                                    className={
                                      downloadedOnce.includes(operation._id)
                                        ? "downloaded-once"
                                        : ""
                                    }
                                    onClick={(e) => {
                                      e.preventDefault();
                                      logAction(239, 1, client._id);
                                      if (!downloadedOnce.includes(operation._id)) {
                                        setDownloadedOnce([...downloadedOnce, operation._id]);
                                      }
                                      if (operation.files.length > 0) {
                                        ReceivedDocHelper.downloadFiles(
                                          client,
                                          operation.files,
                                          operation._id,
                                          true
                                        );
                                      }
                                    }}
                                    icon={<DownloadOutlined />}
                                    style={{ marginLeft: "5px" }}
                                  />
                                </Tooltip>
                              </>
                            ) : null}

                            {!invalidated.includes(operation._id) &&
                            !reseted.includes(operation._id) ? (
                              <Popover
                                title=""
                                align={{ offset: [10, -16] }}
                                overlayClassName="remove-op-popover"
                                content={
                                  <>
                                    <InvalidFile
                                      {...props}
                                      files={operation.files}
                                      opId={operation._id}
                                      categoryId={null}
                                      other={true}
                                      operationText={operation.text}
                                      invalidated={(data) => {
                                        setInvalidated([...invalidated, data.opId]);
                                        setInvalidComment([
                                          ...invalidComment,
                                          [data.opId, data.invalidcomment],
                                        ]);
                                      }}
                                    />
                                    <ResetOperation
                                      {...props}
                                      files={operation.files}
                                      opId={operation._id}
                                      categoryId={null}
                                      other={true}
                                      operationText={operation.text}
                                      reseted={(data) => {
                                        setReseted([...reseted, data.opId]);
                                      }}
                                    />
                                  </>
                                }
                              >
                                <Button
                                  shape="circle"
                                  size="small"
                                  icon={<CloseOutlined />}
                                  style={{ marginLeft: "5px" }}
                                />
                              </Popover>
                            ) : null}
                            {invalidated.includes(operation._id) ||
                            reseted.includes(operation._id) ? null : (
                              <Tooltip title="Valider la pièce">
                                <Button
                                  shape="circle"
                                  size="small"
                                  style={{
                                    backgroundColor: "#0CD344",
                                    color: "white",
                                    marginLeft: "5px",
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    confirmDeletion(() => {
                                      deleteOMD(operation._id, operation.fileId);
                                    });
                                  }}
                                  icon={<CheckOutlined />}
                                />
                              </Tooltip>
                            )}
                          </div>
                        </div>
                      </div>
                      {operation.attachments && operation.attachments.length > 0 && (
                        <>
                          <div style={{ display: "block", width: "90%", marginTop: "10px" }}>
                            {operation.attachments.map((file, fileIndex) => (
                              <Tag
                                key={fileIndex}
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={async () => await handleAttachmentDownload(file)}
                                color="grey"
                                icon={<PaperClipOutlined />}
                              >
                                {file.fileName}
                              </Tag>
                            ))}
                          </div>
                        </>
                      )}
                      {invalidated.includes(operation._id) || operation.comment ? (
                        <Divider
                          style={{
                            margin: "8px 0px",
                          }}
                        />
                      ) : null}
                      {invalidated.includes(operation._id) ? (
                        <p>
                          <b>
                            Invalidation collaborateur ({user.firstName} {user.lastName}) :{" "}
                          </b>
                          {invalidComment.find((comment) => comment[0] === operation._id)?.[1]}
                        </p>
                      ) : null}

                      {operation.comment ? (
                        <>
                          <span>
                            <b>Commentaire client : </b> {operation.comment}
                          </span>
                        </>
                      ) : null}
                    </Card>
                    <div className="img-ged">
                      {accountingFirm.mcf &&
                      operation.files.length > 0 &&
                      operation.files.length ===
                        operation.files.filter((f) => f.receivedByMCF === true).length ? (
                        <Popover
                          placement="top"
                          content="Justificatif chargé dans MyCompanyFiles"
                          overlayClassName="mcf-success-popover"
                        >
                          <img
                            src={MCFLogoValid}
                            alt="MCF valid"
                            className="mcf-logo"
                            style={{ display: "block !important" }}
                          />
                        </Popover>
                      ) : accountingFirm.mcf &&
                        operation.files.length > 0 &&
                        operation.files.filter((f) => f.receivedByMCF === false).length > 0 ? (
                        <Popover
                          placement="top"
                          content="Erreur de chargement dans MyCompanyFiles"
                          overlayClassName="mcf-fail-popover"
                        >
                          <img
                            src={MCFLogoNotValid}
                            alt="MCF invalid"
                            className="mcf-logo"
                            style={{ display: "block !important" }}
                          />
                        </Popover>
                      ) : null}
                      {accountingFirm.iDocus &&
                      operation.files.length > 0 &&
                      operation.files.length ===
                        operation.files.filter((f) => f.receivedByIDocus === true).length ? (
                        <Popover
                          placement="top"
                          content="Justificatif chargé dans iDocus"
                          overlayClassName="mcf-success-popover"
                        >
                          <img
                            src={IDocusLogoValid}
                            alt="iDocus valid"
                            className="idocus-logo"
                            style={{ display: "block !important" }}
                          />
                        </Popover>
                      ) : accountingFirm.iDocus &&
                        operation.files.length > 0 &&
                        operation.files.filter((f) => f.receivedByIDocus === false).length > 0 ? (
                        <Popover
                          placement="top"
                          content="Erreur de chargement dans iDocus"
                          overlayClassName="mcf-fail-popover"
                        >
                          <img
                            src={IDocusLogoNotValid}
                            alt="iDocus invalid"
                            className="idocus-logo"
                            style={{ display: "block !important" }}
                          />
                        </Popover>
                      ) : null}
                      {accountingFirm.conciliator &&
                      operation.files.length > 0 &&
                      operation.files.length ===
                        operation.files.filter((f) => f.receivedByConciliator === true).length ? (
                        <Popover
                          placement="top"
                          content="Justificatif chargé dans Conciliator"
                          overlayClassName="mcf-success-popover"
                        >
                          <img
                            src={ConciliatorLogoValid}
                            alt="Conciliator valid"
                            className="idocus-logo"
                            style={{ display: "block !important" }}
                          />
                        </Popover>
                      ) : accountingFirm.iDocus &&
                        operation.files.length > 0 &&
                        operation.files.filter((f) => f.receivedByConciliator === false).length >
                          0 ? (
                        <Popover
                          placement="top"
                          content="Erreur de chargement dans Conciliator"
                          overlayClassName="mcf-fail-popover"
                        >
                          <img
                            src={ConciliatorLogoNotValid}
                            alt="Conciliator invalid"
                            className="idocus-logo"
                            style={{ display: "block !important" }}
                          />
                        </Popover>
                      ) : null}
                      {accountingFirm.mailToBox &&
                      /*  (client.mailToBox || client.mailToBoxOther) && */
                      operation.files.length > 0 &&
                      operation.files.length ===
                        operation.files.filter((f) => f.receivedByMailToBox === true).length ? (
                        <Popover
                          placement="top"
                          content="Justificatif envoyé par Mail to Box"
                          overlayClassName="mcf-success-popover"
                        >
                          <img
                            src={MailToBoxLogoValid}
                            alt="Mail to Box valid"
                            className="mcf-logo"
                            style={{ display: "block !important" }}
                          />
                        </Popover>
                      ) : null}
                      {accountingFirm.iDepot &&
                      operation.files.length > 0 &&
                      operation.files.length ===
                        operation.files.filter((f) => f.receivedByIDepot === true).length ? (
                        <Popover
                          placement="top"
                          content="Justificatif déposé dans la panière"
                          overlayClassName="mcf-success-popover"
                        >
                          <img
                            src={IDepotLogo}
                            alt="IDepot valid"
                            className="mcf-logo"
                            style={{ display: "block !important" }}
                          />
                        </Popover>
                      ) : null}
                    </div>
                  </div>
                </div>
              );
            } else return null;
          })}
        </div>
      ) : null}
    </>
  );
}

export default Other;

import { CopyOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Table, notification } from "antd";
import { useEffect, useState } from "react";
import ColoredCapsule from "../../../../utils/ColoredCapsule";
import { selectEndDateType } from "../../../../utils/missing";

function FirmsTable(props) {
  const [firmsData, setFirmsData] = useState();
  const [firmsDataLoading, setFirmsDataLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setFirmsData([{ 
        ...props.currentAccFirm, 
        key: props.currentAccFirm._id,
        ged: props.currentAccFirm.iDocus ? "iDocus" :
          props.currentAccFirm.iDepot ? "iDépot" :
          props.currentAccFirm.conciliator ? "Concilator" :
          props.currentAccFirm.mcf ? "MCF" :
          "Non renseigné"
      }]);
      setFirmsDataLoading(false);
    })();
  }, [props.currentAccFirm]);

  const columns = [
    {
      title: "ID",
      dataIndex: "_id",
      key: "_id",
      align: "center",
      width: "50px",
      render: (_id) => {
        const openNotification = () => {
          notification.info({
            message: "acountingFirmID copié",
            description: `{_id: ObjectId("${_id}")}`,
          });
        };
        return (
          <>
            <Button
              type="text"
              onClick={() => {
                openNotification();
                navigator.clipboard.writeText(`{_id: ObjectId("${_id}")}`);
              }}
            >
              <CopyOutlined style={{ fontSize: "20px", color: "#4569F8" }} />
            </Button>
          </>
        );
      },
    },
    {
      title: "Offre",
      dataIndex: "proAbono",
      key: "proAbono",
      width: "fit-content",
      align: "center",
      render: (proAbono) => (
        <>
          {firmsDataLoading ? (
            <LoadingOutlined />
          ) : proAbono?.currentOfferName?.length > 0 ? (
            proAbono?.currentOfferName.join(", ")
          ) : (
            "Aucune"
          )}
        </>
      ),
    },
    {
      title: "Data Provider",
      dataIndex: "dataProvider",
      key: "dataProvider",
      align: "center",
      width: "fit-content",
      render: (dataProvider) => {
        return <>{firmsDataLoading ? <LoadingOutlined /> : dataProvider}</>;
      },
    },
    {
      title: "Mode",
      dataIndex: "fec",
      key: "fec",
      align: "center",
      width: "100px",
      render: (fecMode) => {
        return <>{firmsDataLoading ? <LoadingOutlined /> : fecMode ? "FEC" : "BDD"}</>;
      },
    },
    {
      title: "Gestion des droits",
      dataIndex: "connectedRights",
      key: "connectedRights",
      align: "center",
      width: "200px",
      render: (connectedRights, row) => {
        return (
          <>
            {firmsDataLoading ? (
              <LoadingOutlined />
            ) : (
              <ColoredCapsule
                status={connectedRights ? "ok" : "ko"}
                content={connectedRights ? "Activé" : "Désactivé"}
              />
            )}
          </>
        );
      },
    },
    {
      title: "Connexion GED",
      dataIndex: "ged",
      key: "ged",
      align: "center",
      width: "200px",
      render: (ged, row) => {
        return (
          <> {ged} </>
        );
      },
    },
    {
      title: "Mail to box",
      dataIndex: "mailToBox",
      key: "mailToBox",
      align: "center",
      width: "200px",
      render: (mailToBox, row) => {
        return (
          <ColoredCapsule
            status={mailToBox ? "ok" : "ko"}
            content={mailToBox ? "Activé" : "Désactivé"}
          />
        );
      },
    },
    {
      title: "PM fin de période",
      dataIndex: "missingDocEndDate",
      key: "missingDocEndDate",
      align: "center",
      width: "200px",
      render: (missingDocEndDate, row) => (<> 
        {selectEndDateType(missingDocEndDate)} 
      </>),
    }
  ];

  return (
    <Table
      columns={columns}
      loading={firmsDataLoading}
      dataSource={firmsData}
      size="small"
      bordered
      pagination={false}
      style={{ width: "fit-content" }}
      scroll={{
        x: "max-content",
      }}
    />
  );
}

export default FirmsTable;

import { Col, Form, Row } from "antd";
import { useEffect, useState } from "react";

import "animate.css";
import { ReactComponent as LogoSeshaWhite } from "../../assets/images/logo-sesha-white.svg";
import logoSesha from "../../assets/images/logo-sesha.svg";
import { seshaLogoModifier } from "./../nav/logoModifier";

import "../login/Login.css";
import "./subscription.css";

function HostedPage(props) {
  const [iframe, setIframe] = useState("");
  const [modifiedSeshaLogo, setModifiedSeshaLogo] = useState("");

  let hostedURL = props.location.search
    .substring(7)
    .replaceAll("%253a", ":")
    .replaceAll("%252f", "/");

  useEffect(() => {
    (async () => { 
      setModifiedSeshaLogo(btoa(await seshaLogoModifier(logoSesha, process.env.REACT_APP_ENV)))
    })();
  }, []);
  
  useEffect(() => {
    if (
      hostedURL.startsWith("https://SeshaTEST-EUR.proabono.com") ||
      hostedURL.startsWith("https://Sesha-EUR.proabono.com")
    ) {
      setIframe(
        '<iframe src="' +
          hostedURL +
          '" style="width:100%; height:1000px;" frameborder="0"></iframe>'
      );
    }
  }, [hostedURL]);

  function createMarkup() {
    return { __html: iframe };
  }

  return (
    <div className="hosted-page">
      <Row justify="center" className="login-row">
        <Col xs={24} sm={24} md={16} lg={16} xl={16} className="left-box">
          <div className="logo">
            <img hidden={modifiedSeshaLogo===""} src={"data:image/svg+xml;base64,"+modifiedSeshaLogo} alt="Sesha"></img>
          </div>
          <div className="animate__animated animate__fadeIn">
            <Form className="send-email-form">
              <div dangerouslySetInnerHTML={createMarkup()} />
            </Form>
          </div>
        </Col>

        <Col xs={0} sm={0} md={8} lg={8} xl={8} className="login-box">
          <div className="login-logo-white-parent">
            <div className="login-logo-white-child">
              <LogoSeshaWhite />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default HostedPage;

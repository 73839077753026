import { Pagination } from "antd";
import { useEffect, useState } from "react";
import Operation from "./Operation";

function Category(props) {
  const [currentPage, setCurrentPage] = useState(1);
  const [operationsPerPage, setOperationsPerPage] = useState(10);
  const [currentOperations, setCurrentOperations] = useState([]);

  // Update the operations to display whenever the page or page size changes
  useEffect(() => {
    const indexOfLastOperation = currentPage * operationsPerPage;
    const indexOfFirstOperation = indexOfLastOperation - operationsPerPage;
    setCurrentOperations(
      props.category.operations.slice(indexOfFirstOperation, indexOfLastOperation)
    );
  }, [currentPage, operationsPerPage, props.category.operations]);

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const onShowSizeChange = (current, size) => {
    setOperationsPerPage(size);
    setCurrentPage(1); // Reset to the first page after page size change
  };

  return (
    <>
      {currentOperations.map((operation) => (
        <Operation {...props} operation={operation} key={operation._id} />
      ))}

      {props.category.operations.length > operationsPerPage && (
        <Pagination
          current={currentPage}
          pageSize={operationsPerPage}
          total={props.category.operations.length}
          onChange={onPageChange}
          onShowSizeChange={onShowSizeChange}
          showSizeChanger
          style={{ marginTop: "20px", textAlign: "center" }}
          size="small"
        />
      )}
    </>
  );
}

export default Category;

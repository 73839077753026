import React from "react";
import { Row, Col, Button } from "antd";
import { useHistory } from "react-router-dom";

import "./unauthorized.css";

import BodyAnalysisConfig from "./includes/AnalysisConfig";
import BodyOutstandings from "./includes/Outstandings";
import BodyPortfolio from "./includes/Portfolio";

import analysisConfigSVG from "../../assets/images/payment/analysisConfig_1.svg";
import outstandingsSVG from "../../assets/images/payment/outstandings_1.svg";
import portfolioSVG from "../../assets/images/payment/portfolio_1.svg";

function PaymentRequired(props) {
  const history = useHistory();

  let image;
  let textTitle = "";
  let body = "";

  let textTitleAnnexeA = "";
  let bodyAnnexeA = "";

  let textTitleAnnexeB = "";
  let bodyAnnexeB = "";

  switch (props.page) {
    case "outstandings":
      image = outstandingsSVG;
      textTitle = (
        <>
          Passez à l’offre <span className="badge-pro">Pro</span>
          <br />
          et identifiez les encours & impayés
        </>
      );
      body = <BodyOutstandings />;
      textTitleAnnexeA = "Prenez la main sur les paramètres";
      bodyAnnexeA = <BodyAnalysisConfig />;
      textTitleAnnexeB = "Détectez les opportunités parmi tous vos dossiers clients";
      bodyAnnexeB = <BodyPortfolio />;
      break;
    case "analysis_config":
      image = analysisConfigSVG;
      textTitle = (
        <>
          Passez à l’offre <span className="badge-pro">Pro</span>
          <br />
          et prenez la main sur les paramètres
        </>
      );
      body = <BodyAnalysisConfig />;
      textTitleAnnexeA = "Détectez les opportunités parmi tous vos dossiers clients";
      bodyAnnexeA = <BodyPortfolio />;
      textTitleAnnexeB = "Identifiez les encours & impayés";
      bodyAnnexeB = <BodyOutstandings />;
      break;
    case "analysis_config_modal":
      image = analysisConfigSVG;
      textTitle = (
        <>
          Passez à l’offre <span className="badge-pro">Pro</span> et prenez la main sur les
          paramètres
        </>
      );
      body = <BodyAnalysisConfig />;
      break;
    case "portfolio":
      image = portfolioSVG;
      textTitle = (
        <>
          <div className="espacement-ligne" style={{ paddingTop: "5px" }}>
            Passez à l’offre <span className="badge-pro">Pro</span>
            <br />
            et détectez les opportunités
            <br />
            parmi tous vos dossiers clients
          </div>
        </>
      );
      body = <BodyPortfolio />;
      textTitleAnnexeA = "Prenez la main sur les paramètres";
      bodyAnnexeA = <BodyAnalysisConfig />;
      textTitleAnnexeB = "Identifiez les encours & impayés";
      bodyAnnexeB = <BodyOutstandings />;
      break;
    default:
      break;
  }

  return (
    <div className="payment-required">
      <Row>
        <Col xs={24} sm={24} md={24} lg={props.page !== "analysis_config_modal" ? 11 : 24}>
          <span style={{ fontSize: "11px" }}>
            <span className="badge-pro">Pro</span>
            <span className="bagde-non-inclus">Non inclus dans votre offre actuelle</span>
          </span>
          <h1>{textTitle}</h1>
          {body}
          <Button
            className="call-action-btn"
            style={{ marginTop: "30px", width: "180px" }}
            onClick={(e) => {
              e.preventDefault();
              history.push("/offers");
            }}
          >
            <b>Je change d'offre</b>
          </Button>
          <Button
            className="call-action-btn-white"
            style={{ marginLeft: "10px", width: "180px" }}
            onClick={(e) => {
              e.preventDefault();
              window.location.href = "https://meetings.hubspot.com/bastien-daubin/clone";
            }}
          >
            <b>Demander une démo</b>
          </Button>
        </Col>
        <Col
          xs={0}
          sm={0}
          md={0}
          lg={props.page !== "analysis_config_modal" ? 13 : 0}
          className="right-col"
        >
          <img src={image} alt="" />
        </Col>
      </Row>
      {props.page !== "analysis_config_modal" ? (
        <>
          <hr
            align="center"
            size="1"
            width="97%"
            style={{ marginTop: "60px", marginBottom: "10px", opacity: "10%" }}
          />
          <Row style={{ marginTop: "40px" }}>
            <Col xs={11} sm={11} md={11} lg={11}>
              <h1>{textTitleAnnexeA}</h1>
              {bodyAnnexeA}
            </Col>
            <Col xs={11} sm={11} md={11} lg={11} offset={1}>
              <h1>{textTitleAnnexeB}</h1>
              {bodyAnnexeB}
            </Col>
          </Row>
        </>
      ) : null}
    </div>
  );
}

export default PaymentRequired;

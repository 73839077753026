import { DeleteOutlined, EditOutlined, SendOutlined } from "@ant-design/icons";
import { Button, Collapse, Form, Input } from "antd";
import { useContext, useEffect, useState } from "react";
import * as API from "../../../../../api/API";
import ClientContext from "../../../../../context/ClientContext";
import * as uploadHelper from "../../../../../helpers/client/clientUploadHelper";
// import FileDisplay from "./FileDisplay.js";
import OperationNature from "../../../OperationNature.js";
import "./desktop.css";
import LostDocument from "./LostDocument.js";

const { TextArea } = Input;

const { Panel } = Collapse;

function OperationActions(props) {
  const [form] = Form.useForm();
  const context = useContext(ClientContext);

  const [editing, setEditing] = useState(false);

  const onSubmit = (value) => {
    const operation = props.operation;
    (async () => {
      const res = await API.UpdateOperation({
        clientId: context.idClient,
        operation,
        comment: value.comment,
        other: props.title === "Autres",
      });
      if (res.status === 200) {
        uploadHelper.updateStatesHelper(
          props.operation.files,
          props.setData,
          props.operation,
          value.comment
        );

        API.postClientTracker(context.idClient, {
          action: "COMMENT",
          operationId: operation._id,
          dateOp: operation.date,
          label: operation.text,
          reference: operation.piece_ref,
          amount: operation.amount,
          nature: operation.nature,
          comment: value.comment,
          category: "Autres",
        });
        if (value.comment) API.notify({ clientId: context.idClient, action: "missingDoc" });
      } else {
        console.log("error OperationActions");
      }
      setEditing(false);
    })();
  };

  useEffect(() => {
    form.resetFields();
  }, [props.operation.comment]);

  return (
    <>
      <Collapse
        className="other-collapse"
        ghost
        activeKey={props.operation.comment || editing ? ["open"] : []}
      >
        <Panel
          showArrow={false}
          header={
            <>
              {!props.operation.comment && (
                <Button type="text" onClick={() => setEditing(!editing)}>
                  Ajouter un commentaire
                </Button>
              )}
              <div style={{ display: "contents", width: "100%" }}>
                {props.category?.accountNumberAux.includes("471") ? (
                  <OperationNature {...props} />
                ) : null}

                {(props.operation.files === null || props.operation.files.length === 0) &&
                  !props.operation.comment && <LostDocument {...props} />}
              </div>
            </>
          }
          key={"open"}
        >
          <div>
            <div>
              <Form
                form={form}
                layout="inline"
                name="comment-form"
                className="submit-btn-flex"
                onFinish={(data) => onSubmit(data)}
                initialValues={{
                  comment: props.operation.comment,
                }}
              >
                <Form.Item
                  name="comment"
                  label={
                    <span className="client-missingDoc-your-comment">
                      <p>Votre commentaire :</p>
                      {props.operation.comment && (
                        <div>
                          <Button
                            type="text"
                            onClick={(e) => {
                              e.preventDefault();
                              setEditing(!editing);
                            }}
                          >
                            <EditOutlined />
                          </Button>
                          <Button
                            type="text"
                            onClick={() => {
                              onSubmit({ comment: null });
                            }}
                          >
                            <DeleteOutlined />
                          </Button>
                        </div>
                      )}

                      {(props.operation.files === null || props.operation.files.length === 0) &&
                        props.operation.comment && (
                          <>
                            {props.title !== "Autres" ? "|" : ""} <LostDocument {...props} />
                          </>
                        )}
                    </span>
                  }
                  className="comment-item"
                >
                  <TextArea
                    disabled={!editing && props.operation.comment}
                    bordered={editing || !props.operation.comment}
                    className={
                      editing || !props.operation.comment ? "edit-txt comment-area" : "comment-show"
                    }
                    onChange={(e) => {
                      e.preventDefault();
                    }}
                    autoSize
                  />
                </Form.Item>

                {(editing || !props.operation.comment) && (
                  <Form.Item className="send-comment">
                    <Button
                      type="primary"
                      className="add-comment hide-btn submit-btn-style"
                      htmlType="submit"
                      style={{ marginTop: "10px" }}
                    >
                      Soumettre <SendOutlined />
                    </Button>
                  </Form.Item>
                )}
              </Form>
            </div>
          </div>
        </Panel>
      </Collapse>
    </>
  );
}

export default OperationActions;

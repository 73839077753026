import { DndContext, MouseSensor, useDroppable, useSensor, useSensors } from "@dnd-kit/core";
import { SortableContext, arrayMove, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { Button } from "antd";
import { useEffect, useState } from "react";
import * as API from "../../../../api/API";
import OrderItem from "./OrderItem";

function Order(props) {
  const [activeId, setActiveId] = useState(null);
  const [reports, setReports] = useState([]);
  const [allReports, setAllReports] = useState([]);
  const { setNodeRef } = useDroppable({
    id: "droppable",
  });

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  );

  const handleDragStart = ({ active }) => {
    setActiveId(active.id);
  };

  const handleDragEnd = ({ active, over }) => {
    if (!over) {
      setActiveId(null);
      return;
    }
    if (active.id !== over.id) {
      const activeIndex = active.data.current.sortable.index;
      const overIndex = over.id in reports ? reports.length + 1 : over.data.current.sortable.index;
      setReports(arrayMove(reports, activeIndex, overIndex));
      setActiveId(null);
    }
  };

  const updateReportsOrder = async () => {
    for (let i = 0; i < reports.length; i++) {
      await API.putReport(reports[i], {
        positionIndex: reports.indexOf(reports[i]),
      });
    }
    props.setReloadAll(!props.reloadAll);

    props.handleOrderModalCancel(true);
  };

  useEffect(() => {
    console.log("reports changed");
  }, [activeId]);

  useEffect(() => {
    (async () => {
      let res = await API.getReportsByUser();
      res = await res.json();
      res = res.sort((a, b) => a.positionIndex - b.positionIndex);
      setReports(res.map(({ _id }) => _id));
      setAllReports(res);
    })();
  }, []);

  return (
    <div className="order-modal-content">
      <p className="order-instruction">Glissez-déposez les tableaux pour les ré-organiser</p>

      <DndContext sensors={sensors} onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
        <SortableContext id={activeId} items={reports} strategy={verticalListSortingStrategy}>
          <div className="droppable" ref={setNodeRef}>
            {reports.map((report) => {
              return (
                <OrderItem
                  {...props}
                  key={report}
                  id={report}
                  report={allReports.find((elt) => elt._id === report)}
                  dragOverlay={report === activeId ? true : false}
                />
              );
            })}
          </div>
        </SortableContext>
      </DndContext>

      <div className="vpf-modal-footer">
        <Button
          className="other-action-btn vpf-btn"
          onClick={(e) => {
            e.preventDefault();
            props.handleOrderModalCancel(true);
          }}
        >
          Annuler
        </Button>
        <Button
          className="call-action-btn vpf-btn"
          onClick={(e) => {
            e.preventDefault();
            updateReportsOrder();
          }}
        >
          Valider
        </Button>
      </div>
    </div>
  );
}

export default Order;

import { EditOutlined, PlusCircleOutlined, SaveOutlined } from "@ant-design/icons";
import { Button } from "antd";
import * as dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as API from "../../../../api/API";
import ArrowLeft from "../../../../assets/images/arrow-left.svg";
import { selectAccountingFirm } from "../../../../slices/accountingFirmSlice";
import { selectUser } from "../../../../slices/userSlice";
import Tableau from "../Table";

const preset = (title, colors) => (
  <Button className="modal-1-button" style={{ background: colors.light }}>
    <span className="modal-1-button-text" style={{ color: colors.dark }}>
      {title}
    </span>
  </Button>
);

function Preview(props) {
  const user = useSelector(selectUser);
  const accountingFirm = useSelector(selectAccountingFirm);

  const [rowNumber, setRowNumber] = useState(0);
  const [, setDeleteReport] = useState();
  const [tableColors] = useState(props.report?.colors || { light: "#FFDCFB", dark: "#6C0092" });

  const [selectedDate] = useState({
    year:
      dayjs().date() < 15
        ? dayjs().subtract(2, "month").year()
        : dayjs().subtract(1, "month").year(),
    month:
      dayjs().date() < 15
        ? dayjs().subtract(2, "month").month() + 1
        : dayjs().subtract(1, "month").month() + 1,
  });

  const onCreateReport = async () => {
    const data = {};
    data.criteria = props.report.criteria;
    data.column = props.report.column;
    data.name = props.report.name;
    data.colors = props.report.colors;
    data.sortInfo = props.report.sortInfo || {
      sortField: "name",
      sortOrder: "ascend",
    };

    props.setIsModalOpen(false);

    if (props.report._id) {
      await API.putReport(props.report._id, data);
    } else {
      for (let i = 0; i < props.reports?.length; i++) {
        await API.putReport(props.reports[i]._id, {
          positionIndex: i + 1,
        });
      }
      await API.postReport({ ...data, positionIndex: 0 });
    }

    if (props.type === "edit") {
      props.setReloadReport(!props.reloadReport);
      props.setCurrentComponent("EditReport");
    } else {
      props.setReloadAll(!props.reloadAll);
      props.setCurrentComponent("SelectPreset");
    }
  };

  useEffect(() => {
    if (rowNumber !== 0) {
      (async () => {
        await API.putReport(props.report._id, {
          rowNumber,
        });
      })();
    }
  }, [rowNumber]);

  return (
    <>
      {props.report.id ? (
        <>
          <div style={{ fontSize: "18px", paddingLeft: "5px", marginBottom: "20px" }}>
            <img
              src={ArrowLeft}
              style={{ height: "17px", marginBottom: "4px", marginRight: "15px" }}
              alt="Flèche vers la gauche"
              onClick={() => props.setCurrentComponent("EditReport")}
            />
            <b>Vous avez sélectionné le tableau pré-configuré: </b>
          </div>
          <div style={{ paddingLeft: "30px", marginBottom: "20px" }}>
            {preset(props.report.name, tableColors)}
          </div>
        </>
      ) : (
        <div style={{ marginBottom: "20px" }}></div>
      )}
      <div style={{ width: "95%", marginLeft: "25px" }}>
        <Tableau
          {...props}
          report={props.report}
          selectedDate={selectedDate}
          currentUser={user}
          connectedRights={accountingFirm?.connectedRights}
          setRowNumber={setRowNumber}
          setDeleteReport={setDeleteReport}
          type="preview"
        />
      </div>
      <div className="vpf-modal-footer">
        <Button
          className="call-action-btn vpf-btn"
          icon={<EditOutlined />}
          onClick={() => props.setCurrentComponent("EditReport")}
        >
          Editer ce tableau
        </Button>
        <Button
          className="call-action-btn"
          icon={props.type === "create" ? <PlusCircleOutlined /> : <SaveOutlined />}
          onClick={onCreateReport}
        >
          {props.type === "create"
            ? "Ajouter à ma vue portefeuille"
            : "Appliquer les modifications"}
        </Button>
      </div>
    </>
  );
}

export default Preview;

import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { selectClient } from "../../slices/clientSlice";
import { selectAccountingFirm } from "../../slices/accountingFirmSlice";

import { Button, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import "./Mail.css";
import "../analysis/Analysis.css";
import TypeSettings from "./TypeSettings.js";

const { confirm } = Modal;

function MailSettings(props) {
  const client = useSelector(selectClient);
  const accountingFirm = useSelector(selectAccountingFirm);

  const [isModifyOn, setIsModifyOn] = useState(false);
  const [componentList, setComponentList] = useState([]);
  const [settings, setSettings] = useState();

  const ref = useRef(null);

  const middleware = (oldObj, obj) => {
    let ret = obj;

    if (!client.email?.[obj.type]?.lastEmailSent) {
      if (!oldObj.visible && obj.visible) ret.refresh = true;
      if (oldObj.refresh && !obj.refresh) ret.visible = false;
    }
    if (oldObj.visible && !obj.visible) ret.refresh = false;
    if (!oldObj.refresh && obj.refresh) ret.visible = true;

    return ret;
  };

  const onChange = (obj) => {
    setSettings([
      ...settings.filter((el) => el.type !== obj.type),
      middleware(
        settings.find((el) => el.type === obj.type),
        obj
      ),
    ]);
  };

  useEffect(() => {
    setSettings([
      {
        type: "missingdoc",
        visible:
          props.type === "missingdoc" ? true : client.clientPage?.missingdoc?.display || false,
        refresh: props.type === "missingdoc",
      },
      {
        type: "outstandingsclients",
        visible:
          props.type === "clients"
            ? true
            : !accountingFirm?.proAbonoFeatures?.includes("outstandings")
            ? false
            : client.clientPage?.outstandingsclients?.display || false,
        refresh: props.type === "clients",
      },
      {
        type: "outstandingsproviders",
        visible:
          props.type === "providers"
            ? true
            : !accountingFirm?.proAbonoFeatures?.includes("outstandings")
            ? false
            : client.clientPage?.outstandingsproviders?.display || false,
        refresh: props.type === "providers",
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.type]);

  const handleClick = () => {
    ref.current.setAttribute("ant-click-animating-without-extra-node", "true");
    setTimeout(() => {
      ref.current.setAttribute("ant-click-animating-without-extra-node", "false");
    }, 300);
  };

  useEffect(() => {
    if (settings) {
      setComponentList([
        {
          type: "missingdoc",
          component: (
            <TypeSettings
              {...props}
              typeSettings="missingdoc"
              key="missingdoc"
              setSettings={(data) => setSettings(data)}
              settings={settings}
              isModifyOn={isModifyOn}
              text="Pièces manquantes"
              onChange={onChange}
              focusModify={handleClick}
            />
          ),
        },
        {
          type: "clients",
          component: (
            <TypeSettings
              {...props}
              typeSettings="outstandingsclients"
              key="outstandingsclients"
              setSettings={(data) => setSettings(data)}
              settings={settings}
              isModifyOn={isModifyOn}
              text="Encours clients"
              onChange={onChange}
              focusModify={handleClick}
            />
          ),
        },
        {
          type: "providers",
          component: (
            <TypeSettings
              {...props}
              typeSettings="outstandingsproviders"
              key="outstandingsproviders"
              setSettings={(data) => setSettings(data)}
              settings={settings}
              isModifyOn={isModifyOn}
              text="Encours fournisseurs"
              onChange={onChange}
              focusModify={handleClick}
            />
          ),
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings, isModifyOn]);

  useEffect(() => {
    if (settings) props.buttonData(settings);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  useEffect(() => {
    props.testSkeleton(isModifyOn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModifyOn]);

  const showHasEditMail = () => {
    confirm({
      title: "",
      icon: <ExclamationCircleOutlined className="prompt-icon" />,
      className: "modal-invalid modal-invalid-email-modif",
      maskClosable: false,
      closable: false,
      content: (
        <div className="prompt-text-style">
          <p>
            Attention ! Votre email est en cours d’édition. Si vous continuez, les modifications
            effectuées sur celui-ci seront perdues.
          </p>
          <p>Êtes-vous sûr de vouloir continuer ?</p>
          <div className="prompt-btns">
            <Button
              className="other-action-btn invalid-cancel"
              onClick={(e) => {
                e.preventDefault();
                console.log("annuler");
                Modal.destroyAll();
              }}
            >
              Annuler
            </Button>
            <Button
              className="call-action-btn"
              onClick={(e) => {
                e.preventDefault();
                console.log("continuer");
                Modal.destroyAll();
                setIsModifyOn(!isModifyOn);
              }}
            >
              Continuer
            </Button>
          </div>
        </div>
      ),
    });
  };

  return (
    <>
      {accountingFirm.proAbonoFeatures?.includes("outstandings") ? (
        <>
          <div
            className={
              "mail-unique-settings " + (isModifyOn ? "" : " mail-unique-settings-not-modify")
            }
          >
            {props.type !== "analysis" ? (
              <div style={{ marginLeft: "5%" }}>
                <p style={{ fontSize: "16px", marginTop: 0 }}>
                  Éléments partagés avec votre client :
                </p>
                <div className="mail-row-selected-line">
                  <span style={{ position: "relative", bottom: "1px" }}>
                    {componentList.find((el) => el.type === props.type)?.component}
                  </span>
                </div>
                <br />
                <p style={{ fontSize: "16px", marginTop: 0 }}>
                  Vous pouvez également inclure à cet envoi :
                </p>
                <span>
                  {componentList.filter((el) => el.type !== props.type).map((el) => el.component)}
                </span>
              </div>
            ) : null}
            <div id="div-modifier"></div>
          </div>

          <div
            style={{
              textAlign: isModifyOn ? "right" : "",
              margin: isModifyOn ? "30px 31px 33px 0px" : "30px 31px 33px 28px",
            }}
          >
            <Button
              ref={!isModifyOn ? ref : null}
              htmlType={isModifyOn ? "submit" : "button"}
              className={
                "submit-mail-btn" +
                (isModifyOn
                  ? " link-button call-action-btn"
                  : " submit-mail-btn-not-edit link-button call-action-btn-white")
              }
              onClick={(e) => {
                e.preventDefault();
                if (props.hasEdit && !isModifyOn) {
                  showHasEditMail();
                } else {
                  setIsModifyOn(!isModifyOn);
                }
              }}
            >
              {isModifyOn ? "Suivant" : "Modifier"}
            </Button>
          </div>
        </>
      ) : null}
    </>
  );
}
export default MailSettings;

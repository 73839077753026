const templateList = [
  {
    id: 1,
    name: "Première vente",
    description: "A définir",
    criteria: {
      keyPointsListInclusive: {
        operator: "or",
        value: [16],
      },
    },
    column: [
      {
        name: "keyPoints",
        show: true,
      },
    ],
  },
  {
    id: 2,
    name: "Variation du chiffre d’affaires",
    description: "A définir",
    criteria: {},
    column: [
      {
        name: "varCAPercent",
        show: true,
      },
      {
        name: "varCAPercentPastMonth",
        show: true,
      },
      {
        name: "varCAPercentCumul",
        show: true,
      },
    ],
  },
  {
    id: 5,
    name: "Première embauche",
    description: "A définir",
    criteria: {
      keyPointsListInclusive: {
        operator: "or",
        value: [17],
      },
    },
    column: [
      {
        name: "keyPoints",
        show: true,
      },
    ],
  },
  {
    id: 6,
    name: "Stop de la masse salariale",
    description: "A définir",
    criteria: {
      keyPointsListInclusive: {
        operator: "or",
        value: [18],
      },
    },
    column: [
      {
        name: "keyPoints",
        show: true,
      },
    ],
  },
  {
    id: 7,
    name: "Variation de la masse salariale",
    description: "A définir",
    criteria: {},
    column: [
      {
        name: "varMasseSalCAPercent",
        show: true,
      },
      {
        name: "varMasseSalCAPercentPastMonth",
        show: true,
      },
      {
        name: "varMasseSalCAPercentCumul",
        show: true,
      },
    ],
  },
  {
    id: 10,
    name: "Variation de la trésorerie",
    description: "A définir",
    criteria: {},
    column: [
      {
        name: "varTresoAmountPastMonth",
        show: true,
      },
      {
        name: "varTresoPercentPastMonth",
        show: true,
      },
    ],
  },
  {
    id: 12,
    name: "Nombre de lignes en compte d’attente (47)",
    description: "A définir",
    criteria: {},
    column: [
      {
        name: "nbAwaitingAccount",
        show: true,
      },
    ],
  },
  {
    id: 13,
    name: "Montant total en compte d’attente (47)",
    description: "A définir",
    criteria: {},
    column: [
      {
        name: "amountAwaitingAccount",
        show: true,
      },
    ],
  },
  {
    id: 14,
    name: "Comptes 421 débiteurs",
    description: "A définir",
    criteria: {
      keyPointsListInclusive: {
        operator: "or",
        value: [14],
      },
    },
    column: [
      {
        name: "keyPoints",
        show: true,
      },
    ],
  },
  {
    id: 15,
    name: "Comptes 455 débiteurs",
    description: "A définir",
    criteria: {
      keyPointsListInclusive: {
        operator: "or",
        value: [7],
      },
    },
    column: [
      {
        name: "keyPoints",
        show: true,
      },
    ],
  },
  {
    id: 16,
    name: "Retard de traitement du dossier comptable",
    description: "A définir",
    criteria: {
      keyPointsListInclusive: {
        operator: "or",
        value: [6],
      },
    },
    column: [
      {
        name: "keyPoints",
        show: true,
      },
    ],
  },
  {
    id: 17,
    name: "Trésorerie en chute",
    description: "A définir",
    criteria: {
      cumulTreso: {
        operator: "lte",
        value: 10000,
      },
      varTresoAmountPastMonth: {
        operator: "lte",
        value: -30,
      },
    },
    column: [
      {
        name: "cumulTreso",
        show: true,
      },
      {
        name: "cumulTresoPastYear",
        show: true,
      },
      {
        name: "varTresoAmountPastMonth",
        show: true,
      },
      {
        name: "varTresoPercentPastMonth",
        show: true,
      },
    ],
  },
  {
    id: 18,
    name: "CA anormal",
    description: "A définir",
    criteria: {
      varCAPercentCumul: {
        operator: "lte",
        value: -5,
      },
    },
    column: [
      {
        name: "varCAPercentCumul",
        show: true,
      },
    ],
  },
];

export default templateList;

import React from "react";
import "../unauthorized.css";
import svg from "../../../assets/images/payment/outstandings_2.svg";

function Outstandings(props) {
  return (
    <>
      <p>
        Grâce aux onglets <b>encours clients & encours fournisseurs,</b> vous assurez un suivi
        complet des impayés et des mouvements en attente.
      </p>
      <img src={svg} alt="Tableau encours" />
    </>
  );
}

export default Outstandings;

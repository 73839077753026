import React from "react";
import { Row, Col } from "antd";

function Loader() {
  return (
    <Row justify="center" className="loader-raw-row">
      <Col xs={15} sm={15} md={7} lg={7} xl={7} className="loader-box">
        <div className="loader">
          <div className="stage">
            <div className="dot-revolution1"></div>
            <div className="dot-revolution2"></div>
            <div className="dot-revolution3"></div>
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default Loader;

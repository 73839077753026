import { ConfigProvider, Input, Modal, Table, Tag } from "antd";
import frFR from "antd/locale/fr_FR";
import { useEffect, useState } from "react";
import * as API from "../../api/API";
import SeshaCloud from "../../assets/images/cloud-192.png";
import { ReactComponent as FilterIcon } from "../../assets/images/filterIcon.svg";
import openNotification from "../../utils/notification";

const { Search } = Input;

function AccessibleClientDocs(props) {
  const [accessibleDocs, setAccessibleDocs] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [filteredTable, setFilteredTable] = useState(null);

  useEffect(() => {
    (async () => {
      if (props.connectedRights) {
        let res = await API.getAllDataProviderCodes();
        if (res.status === 200) {
          const allCodes = await res.json();
          const userCodes = props.allDocs?.map((elem) => ({ docId: elem }));

          const allCodesMap = new Map(allCodes.codes.map((elt) => [elt.code?.toString(), elt]));
          const allIdMap = new Map(allCodes.codes.map((elt) => [elt.id?.toString(), elt]));

          userCodes.forEach((userCode) => {
            const foundCode =
              allCodesMap.get(userCode.docId?.toString()) ||
              allIdMap.get(userCode.docId?.toString());
            userCode.key = userCode.docId;
            if (foundCode) {
              userCode.name = foundCode.name;
            }
          });
          setDataSource(userCodes);
        } else if (res.status === 500) {
          openNotification(
            "error",
            "Une erreur interne est survenue, merci de réessayer dans quelques minutes."
          );
        }
      } else {
        setDataSource(
          props.importedClients
            .filter((el) => props.allDocs.includes(el.remoteId))
            .map((el) => ({ key: el.name, name: el.name, docId: el.remoteId }))
        );
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.allDocs?.length]);

  const columns = [
    {
      title: "ID Dossier",
      dataIndex: "docId",
      key: "docId",
      sorter: (a, b) => a.docId?.localeCompare(b.docId),
    },
    {
      title: "Nom du client",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name?.localeCompare(b.name),
      render: (text, record) => {
        return (
          <>
            {props.importedClients.map(({ remoteId }) => remoteId).includes(record.docId) && (
              <div key={record.docId}>
                <img
                  src={SeshaCloud}
                  alt="cloud"
                  style={{
                    width: "10%",
                    verticalAlign: "middle",
                    marginRight: "10px",
                    float: "right",
                  }}
                />
              </div>
            )}
            <span>{text}</span>
          </>
        );
      },
      filters: [
        {
          text: "Dossier dans Sesha",
          value: "sesha",
        },
      ],
      onFilter: (value, record) =>
        props.importedClients.map(({ remoteId }) => remoteId).includes(record.docId),
      filterIcon: (filtered) => (
        <FilterIcon style={{ color: filtered ? "var(--blue)" : "white" }} />
      ),
    },
  ];

  const onSearch = (value) => {
    if (value !== "") {
      const filterTable = dataSource.filter((elt) =>
        Object.keys(elt).some((k) => String(elt[k]).toLowerCase().includes(value.toLowerCase()))
      );
      setFilteredTable(filterTable);
    } else {
      setFilteredTable(null);
    }
  };

  return (
    <>
      <Tag
        color="blue"
        onClick={(e) => {
          e.preventDefault();
          setAccessibleDocs(!accessibleDocs);
        }}
        style={{ cursor: "pointer" }}
      >
        {props.connectedRights
          ? props.allDocs?.filter((el) =>
              props.importedClients?.map(({ remoteId }) => remoteId)?.includes(el)
            )?.length +
            "/" +
            props.allDocs?.length
          : props.allDocs?.length}
      </Tag>
      <Modal
        title="Dossiers accessibles"
        className="create-user-modal"
        open={accessibleDocs}
        onCancel={(e) => {
          e.preventDefault();
          setAccessibleDocs(false);
          setFilteredTable(null);
        }}
        footer={null}
        forceRender
        destroyOnClose={true}
      >
        <Search
          placeholder="Chercher un dossier client"
          onSearch={onSearch}
          enterButton
          style={{
            width: "100%",
            marginBottom: "20px",
          }}
        />
        <ConfigProvider locale={frFR}>
          {" "}
          <Table
            dataSource={filteredTable == null ? dataSource : filteredTable}
            columns={columns}
            className="documents-id-table"
            pagination={{
              position: ["bottomCenter"],
              showTotal: (total) => `Total ${total} dossiers`,
              size: "small",
            }}
            loading={dataSource.length > 0 ? false : true}
            showSorterTooltip={false}
            locale={{ emptyText: "Pas de dossier client" }}
          />
        </ConfigProvider>
      </Modal>
    </>
  );
}

export default AccessibleClientDocs;

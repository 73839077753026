import React from "react";
import { Column } from "@ant-design/plots";

const OverviewGraphComponentGroup = (props) => {
  console.log(props.data);
  var config = {
    data: props.data,
    xField: "month",
    yField: "count",
    seriesField: "type",
    isGroup: true,
    color: ["#4569f8", "#fcac14"],
  };
  return (
    <div className="overview-chart-component">
      <p>{props.title}</p>
      <Column {...config} />
    </div>
  );
};

export default OverviewGraphComponentGroup;

import { ArrowRightOutlined, PlusCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { EFeatures } from "enums/EFeatures";
import { useState } from "react";
import Autosuggest from "react-autosuggest";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import hasFeature from "utils/dataProviderFeatures";
import logAction from "utils/logActions";
import { hasFeatures } from "utils/security";
import { ReactComponent as LogoSeshaWhite } from "../../assets/images/logo-sesha-white.svg";
import { selectAccountingFirm } from "../../slices/accountingFirmSlice";

function ClientSearch(props) {
  const accountingFirm = useSelector(selectAccountingFirm);

  const [client, setClient] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [search, handleSearchBox] = useState(false);

  const clients =
    !hasFeatures([EFeatures.MANAGE_USERS_AND_RIGHTS]) && !accountingFirm.connectedRights
      ? props.clientList.filter((cl) => cl.active === true)
      : props.clientList;

  const lowerCasedClients = clients.map((c) => {
    return {
      id: c._id,
      remoteId: c.remoteId,
      address: c.address,
      name: c.name,
      code: c.code,
    };
  });

  function getSuggestions(value) {
    return lowerCasedClients.filter(
      (c) =>
        c.name.toLowerCase().includes(value.trim().toLowerCase()) ||
        (accountingFirm.dataProvider !== "SAGE" &&
          !accountingFirm.fec &&
          c.code &&
          c.code.toLowerCase().includes(value.trim().toLowerCase()))
    );
  }

  function renderSuggestion(suggestion) {
    return (
      <Link
        to={{
          pathname: `/analysis/${suggestion.id}/${suggestion.remoteId}`,
          state: {
            name: suggestion.name,
            clientRemoteId: suggestion.remoteId,
            accountingFirm: accountingFirm,
          },
        }}
      >
        <div className="list-suggestions">
          <span className="c-name">
            {accountingFirm.fec || accountingFirm.dataProvider === "SAGE"
              ? suggestion.name
              : props.homepage
              ? suggestion.code + " - " + suggestion.name
              : suggestion.name + " - " + suggestion.code}
          </span>
          <div className="c-address">
            <span className="span-address">{suggestion.address}</span>
            <ArrowRightOutlined />
          </div>
        </div>
      </Link>
    );
  }

  function renderInputComponent(inputProps) {
    return (
      <div
        className={
          !search || suggestions.length === 0
            ? "input-container all-border"
            : "input-container not-all-border"
        }
      >
        <input {...inputProps} className="search-input" />
        <SearchOutlined style={{ fontSize: props.width, color: "black" }} className="search-icon" />
      </div>
    );
  }

  return (
    <>
      {props.homepage === true ? (
        <div className="homepage-logo-white">
          <LogoSeshaWhite />
        </div>
      ) : null}
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsClearRequested={() => {
          setSuggestions([]);
          handleSearchBox(false);
        }}
        onSuggestionsFetchRequested={({ value }) => {
          setClient(value);
          handleSearchBox(true);
          setSuggestions(getSuggestions(value));
        }}
        onSuggestionSelected={(_, { suggestion, suggestionValue }) => {
          logAction(214, 1, suggestion.id);
          props.history.push({
            pathname: `/analysis/${suggestion.id}/${suggestion.remoteId}`,
            state: {
              name: suggestion.name,
              clientRemoteId: suggestion.remoteId,
              accountingFirm: accountingFirm, //TODO: à supprimer après refacto analysis
            },
          });
          props.reload(true);
          window.location.reload();
        }}
        getSuggestionValue={(suggestion) => suggestion.name}
        renderSuggestion={(suggestion) => renderSuggestion(suggestion)}
        inputProps={{
          placeholder: "Rechercher un client...",
          value: client,
          onChange: (_, { newValue, method }) => {
            setClient(newValue);
          },
        }}
        renderInputComponent={renderInputComponent}
        highlightFirstSuggestion={true}
      />

      {props.homepage && (
        <div
          style={{
            visibility:
              !accountingFirm.fec &&
              !hasFeature(accountingFirm.fec, accountingFirm.dataProvider, "CLIENT_IMPORT")
                ? "hidden"
                : undefined,
          }}
        >
          {hasFeatures([EFeatures.ADD_CLIENT]) && (
            <Link
              to={
                accountingFirm.fec
                  ? "/import"
                  : hasFeature(accountingFirm.fec, accountingFirm.dataProvider, "CLIENT_IMPORT")
                  ? "/importdataprovider"
                  : ""
              }
            >
              <Button type="link" className="add-client" icon={<PlusCircleOutlined />}>
                Ajouter un client
              </Button>
            </Link>
          )}
        </div>
      )}
    </>
  );
}

export default ClientSearch;

const columnList = [
  {
    name: "Pièces manquantes (nb)",
    label: "missingDocNumber",
    family: "Indicateur Sesha",
  },
  {
    name: "Pièces manquantes (€)",
    label: "missingDocAmount",
    family: "Indicateur Sesha",
  },
  {
    name: "Pièces reçues / pièces demandées",
    label: "receivedVsRequested",
    family: "Indicateur Sesha",
  },
  {
    name: "Pièces perdues / pièces demandées",
    label: "lostVsRequested",
    family: "Indicateur Sesha",
  },
  {
    name: "Dernière analyse envoyée",
    label: "lastAnalysisEmailSent",
    family: "Indicateur Sesha",
  },
  {
    name: "Dernière relance pièces manquantes",
    label: "lastMissingDocEmailSent",
    family: "Indicateur Sesha",
  },
  {
    name: "Encours fournisseurs (€)",
    label: "outstandingsProviderAmount",
    family: "Indicateur Sesha",
  },
  {
    name: "Encours clients (€)",
    label: "outstandingsClientAmount",
    family: "Indicateur Sesha",
  },
  {
    name: "Factures fournisseur impayées",
    label: "outstandingsProvider",
    family: "Indicateur Sesha",
  },
  {
    name: "Factures client impayées",
    label: "outstandingsClient",
    family: "Indicateur Sesha",
  },
  {
    name: "Secteur(s) d’activité(s)",
    label: "apeCode",
    family: "Indicateur Sesha",
  },
  {
    name: "Points d'attention",
    label: "keyPoints",
    family: "Indicateur Sesha",
  },
  {
    name: "Points d'attention (nb)",
    label: "keyPointsNumber",
    family: "Indicateur Sesha",
  },
  {
    name: "Collaborateurs",
    label: "collaboratorsId",
    family: "Indicateur Sesha",
  },
  {
    name: "Modèle Analyse",
    label: "analysisTemplate",
    family: "Indicateur Sesha",
  },
  {
    name: "Modèle pièces manquantes",
    label: "missingDocTemplate",
    family: "Indicateur Sesha",
  },
  {
    name: "Modèle encours clients",
    label: "outstandingsClientsTemplate",
    family: "Indicateur Sesha",
  },
  {
    name: "Modèle encours fournisseurs",
    label: "outstandingsProvidersTemplate",
    family: "Indicateur Sesha",
  },
  {
    name: "Mois de clôture",
    label: "fiscalEndMonth",
    family: "Indicateur Sesha",
  },
  {
    name: "Dernière mise à jour",
    label: "updateDate",
    family: "Indicateur Sesha",
  },
  {
    name: "CA mensuel",
    label: "monthlyCA",
    family: "Indicateur chiffre d'affaires",
  },
  {
    name: "CA mensuel N-1",
    label: "monthlyCAPastYear",
    family: "Indicateur chiffre d'affaires",
  },
  {
    name: "CA cumulé",
    label: "cumulCA",
    family: "Indicateur chiffre d'affaires",
  },
  {
    name: "CA cumulé N-1",
    label: "cumulCAPastYear",
    family: "Indicateur chiffre d'affaires",
  },
  {
    name: "CA total N-1",
    label: "totalCAPastYear",
    family: "Indicateur chiffre d'affaires",
  },
  {
    name: "CA mensuel (var. en %)",
    label: "varCAPercent",
    family: "Indicateur chiffre d'affaires",
  },
  {
    name: "CA mensuel (var. vs m-1 en %)",
    label: "varCAPercentPastMonth",
    family: "Indicateur chiffre d'affaires",
  },
  {
    name: "CA cumulé (var. en %)",
    label: "varCAPercentCumul",
    family: "Indicateur chiffre d'affaires",
  },
  {
    name: "Masse salariale mensuelle",
    label: "monthlyMasseSal",
    family: "Indicateur masse salariale",
  },
  {
    name: "Masse salariale mensuelle N-1",
    label: "monthlyMasseSalPastYear",
    family: "Indicateur masse salariale",
  },
  {
    name: "Masse salariale cumulée",
    label: "cumulMasseSal",
    family: "Indicateur masse salariale",
  },
  {
    name: "Masse salariale cumulée N-1",
    label: "cumulMasseSalPastYear",
    family: "Indicateur masse salariale",
  },
  {
    name: "Masse salariale en % du CA mensuel (var. en %)",
    label: "varMasseSalCAPercent",
    family: "Indicateur masse salariale",
  },
  {
    name: "Masse salariale en % du CA mensuel (var. vs m-1 en %)",
    label: "varMasseSalCAPercentPastMonth",
    family: "Indicateur masse salariale",
  },
  {
    name: "Masse salariale en % du CA cumulé (var.)",
    label: "varMasseSalCAPercentCumul",
    family: "Indicateur masse salariale",
  },
  {
    name: "Trésorerie cumulée",
    label: "cumulTreso",
    family: "Indicateur trésorerie",
  },
  {
    name: "Trésorerie cumulée n-1",
    label: "cumulTresoPastYear",
    family: "Indicateur trésorerie",
  },
  {
    name: "Trésorerie cumulée (var. vs m-1)",
    label: "varTresoAmountPastMonth",
    family: "Indicateur trésorerie",
  },
  {
    name: "Trésorerie cumulée (var. vs m-1 en %)",
    label: "varTresoPercentPastMonth",
    family: "Indicateur trésorerie",
  },
  {
    name: "Nombre d'opérations non-lettrées en compte d'attente (47)",
    label: "nbAwaitingAccount",
    family: "Surveillance de production",
  },
  {
    name: "Montant total des opérations non-lettrées en compte d'attente (47)",
    label: "amountAwaitingAccount",
    family: "Surveillance de production",
  },
  {
    name: "Marge mensuelle",
    label: "monthlyMarge",
    family: "Indicateur marge",
  },
  {
    name: "Marge mensuelle N-1",
    label: "monthlyMargePastYear",
    family: "Indicateur marge",
  },
  {
    name: "Marge cumulée",
    label: "cumulMarge",
    family: "Indicateur marge",
  },
  {
    name: "Marge cumulée N-1",
    label: "cumulMargePastYear",
    family: "Indicateur marge",
  },
  {
    name: "Taux de marge cumulée N",
    label: "varMargePercentCumul",
    family: "Indicateur marge",
  },
  {
    name: "Taux de marge cumulée N-1",
    label: "varMargePercentCumulPastYear",
    family: "Indicateur marge",
  },
  {
    name: "Variation du taux de marge",
    label: "varTauxMarge",
    family: "Indicateur marge",
  },
  {
    name: "Charges externes mensuelles",
    label: "monthlyChargesExt",
    family: "Indicateur charges externes",
  },
  {
    name: "Charges externes mensuelles N-1",
    label: "monthlyChargesExtPastYear",
    family: "Indicateur charges externes",
  },
  {
    name: "Charges externes cumulées",
    label: "cumulChargesExt",
    family: "Indicateur charges externes",
  },
  {
    name: "Charges externes cumulées N-1",
    label: "cumulChargesExtPastYear",
    family: "Indicateur charges externes",
  },
  {
    name: "Variation des charges externes cumulées en %",
    label: "varChargesExtPercentCumul",
    family: "Indicateur charges externes",
  },
  {
    name: "Charges externes cumulées en % du CA cumulé",
    label: "varChargesExtCAPercentCumul",
    family: "Indicateur charges externes",
  },
  {
    name: "Variation des charges externes cumulées en % du CA cumulé (var. en % vs n-1)",
    label: "varChargesExtCAPercentCumulPastYear",
    family: "Indicateur charges externes",
  },
  {
    name: "Résultat mensuel",
    label: "monthlyResult",
    family: "Indicateur résultat",
  },
  {
    name: "Résultat mensuel N-1",
    label: "monthlyResultPastYear",
    family: "Indicateur résultat",
  },
  {
    name: "Résultat cumulé",
    label: "cumulResult",
    family: "Indicateur résultat",
  },
  {
    name: "Résultat cumulé N-1",
    label: "cumulResultPastYear",
    family: "Indicateur résultat",
  },
  {
    name: "Variation du résultat cumulé en %",
    label: "varResultPercentCumul",
    family: "Indicateur résultat",
  },
  {
    name: "Résultat total N-1",
    label: "totalResultPastYear",
    family: "Indicateur résultat",
  },
];

export default columnList;

const isOperationEmpty = (operation) => {
  if (
    operation.files.length > 0 ||
    operation.comment ||
    operation.isLost ||
    operation.isLostNoAccount
  )
    return false;
  return true;
};

export default isOperationEmpty;

import * as Sentry from "@sentry/react";

const addContext = (context) => {
  Sentry.configureScope((scope) => {
    scope.setContext("Inline context", context);
  });
};

const addTag = (name, value) => {
  Sentry.configureScope((scope) => {
    scope.setTag(name, value);
  });
};

const addAttachment = (filename, data) => {
  Sentry.configureScope((scope) => {
    scope.addAttachment({ filename, data });
  });
};

export { addContext, addTag, addAttachment };

import { useEffect } from "react";
import { renewToken } from "utils/security";

function LandingBeta(props) {
  useEffect(async () => {
    localStorage.setItem("token", props.match.params.token);
    await renewToken();
    window.location.href = process.env.REACT_APP_URL_BETA + "/homepage";
  }, []);

  return <></>;
}

export default LandingBeta;

import { UpCircleOutlined } from "@ant-design/icons";
import { Table } from "antd";
import { useEffect, useState } from "react";
import { ReactComponent as GreenIcon } from "../../../../../assets/images/green-circle.svg";
import { ReactComponent as ImportantIcon } from "../../../../../assets/images/important.svg";
import * as number from "../../../../../utils/number";
import Category from "./Category";
import TableJournals from "./TableJournals";

function Type(props) {
  const [categories, setCategories] = useState([]);
  const [totalAmount, setTotalAmount] = useState();
  const [nbrMissingDoc, setNbrMissingDoc] = useState();
  const [expandedRows, setExpandedRows] = useState([]);

  useEffect(() => {
    console.debug("useEffect init/update data");
    setCategories(
      props.missingDocument.map((cat) => ({
        ...cat,
        key: cat._id || cat.id,
        numberMissingDoc: cat.operations.length,
        amount: cat.operations.reduce((acc, curr) => acc + Math.abs(curr.amount), 0),
      }))
    );
  }, [
    JSON.stringify(props.missingDocument),
    props.startDate,
    props.endDate,
    props.journalListFiltered,
    props.editMode,
  ]);

  useEffect(() => {
    if (categories.length === 0) return;
    console.debug("useEffect update totals");

    let totalAmount = 0;
    let nbrMissingDoc = 0;
    categories.forEach(({ numberMissingDoc, amount }) => {
      totalAmount += Math.abs(amount);
      nbrMissingDoc += numberMissingDoc;
    });

    setTotalAmount(totalAmount);
    setNbrMissingDoc(nbrMissingDoc);
  }, [JSON.stringify(categories)]);

  useEffect(() => {
    if (props.view === 3)
      setExpandedRows(
        props.allExpanded ? categories.map((cat) => `${cat.accountNumberAux}-${cat.title}`) : []
      );
    else setExpandedRows(props.allExpanded ? categories.map((cat) => cat._id) : []);
  }, [props.allExpanded]);

  useEffect(() => {
    if (props.view === 3)
      setExpandedRows(
        props.allExpanded
          ? categories.map((cat) => `${cat.accountNumberAux}-${cat.title}`)
          : expandedRows
      );
    else setExpandedRows(props.allExpanded ? categories.map((cat) => cat._id) : expandedRows);
  }, [categories]);

  const accountColumns = [
    {
      title: "",
      width: "30px",
      render: (text, record) => (
        <span className="operation-title">
          {record.operations.every((op) => op.files.length || op.comment) ? (
            <GreenIcon className="operation-title-circle" />
          ) : record.operations.every(
              (op) =>
                (op.invalidFileComment && (!op.comment || op.files.length === 0)) ||
                op.isLost ||
                op.isLostNoAccount
            ) ? (
            <ImportantIcon className="operation-title-circle" style={{ filter: "saturate(0)" }} />
          ) : (
            <ImportantIcon className="operation-title-circle" />
          )}
        </span>
      ),
    },
    {
      title: "N° de compte aux.",
      dataIndex: "accountNumberAux",
      key: "accountNumberAux",
      width: "170px",
      defaultSortOrder: "ascend",
      sorter:
        props.mode !== 3 ? (a, b) => a.accountNumberAux.localeCompare(b.accountNumberAux) : false,
    },
    {
      title: "Lib. compte aux.",
      dataIndex: "title",
      key: "title",
      width: "150px",
      sorter: (a, b) => a.title.localeCompare(b.title),
    },
    {
      title: "Justificatifs manquants",
      dataIndex: "numberMissingDoc",
      key: "numberMissingDoc",
      align: "center",
      width: "120px",
      sorter: (a, b) => b.numberMissingDoc - a.numberMissingDoc,
    },
    {
      title: "Montant total",
      dataIndex: "amount",
      key: "amount",
      align: "center",
      width: "100px",
      sorter: (a, b) => b.amount - a.amount,
      render: (text) => (
        <div style={{ textAlign: "right" }}>
          {number.withThousandSeparator(number.parseNum(text))}
        </div>
      ),
    },
    Table.EXPAND_COLUMN,
  ];

  return (
    <>
      {props.view === 3 ? (
        <div className="type-missing-doc">
          <div className="type-title">{props.label}</div>
          <div className="type-separation"></div>
          <Table
            className="missing-table journal-missing-table"
            dataSource={[]}
            columns={accountColumns}
            pagination={false}
            locale={{ emptyText: " " }}
            showSorterTooltip={false}
          />
          <TableJournals
            {...props}
            categories={categories}
            accountColumns={accountColumns}
            expandedRowKeys={expandedRows}
            setExpandedRows={setExpandedRows}
            setData={(data) => {
              setCategories(data);
              props.setInitMissingDoc(data);
              props.setMissingDocument(data);
            }}
          />
        </div>
      ) : (
        totalAmount > 0 &&
        categories.length > 0 && (
          <div className="type-missing-doc">
            <div className="type-title">
              {props.label} - {number.withThousandSeparator(number.parseNum(totalAmount))}
            </div>
            <div className="type-separation"></div>
            <Table
              className="missing-table"
              dataSource={categories}
              showSorterTooltip={false}
              columns={accountColumns}
              expandable={{
                expandedRowRender: (record) => (
                  <Category
                    {...props}
                    category={record}
                    key={record._id}
                    setData={(data) => {
                      setCategories(data);
                      props.setInitMissingDoc(data);
                      props.setMissingDocument(data);
                    }}
                  />
                ),
                expandedRowKeys: expandedRows,
                expandRowByClick: !props.editMode,
                onExpandedRowsChange: (rows) => setExpandedRows(rows),
                expandIcon: ({ expanded, onExpand, record }) => (
                  <UpCircleOutlined
                    onClick={(e) => onExpand(record, e)}
                    className={expanded ? "expand-icon-down" : "expand-icon-up"}
                  />
                ),
              }}
            />
          </div>
        )
      )}
    </>
  );
}

export default Type;

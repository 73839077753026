import {
  BoldOutlined,
  FontColorsOutlined,
  SmileOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import Picker from "@emoji-mart/react";
import { mergeAttributes } from "@tiptap/core";
import Bold from "@tiptap/extension-bold";
import BulletList from "@tiptap/extension-bullet-list";
import { Color } from "@tiptap/extension-color";
import Document from "@tiptap/extension-document";
import Highlight from "@tiptap/extension-highlight";
import History from "@tiptap/extension-history";
import Italic from "@tiptap/extension-italic";
import ListItem from "@tiptap/extension-list-item";
import Paragraph from "@tiptap/extension-paragraph";
import Placeholder from "@tiptap/extension-placeholder";
import Text from "@tiptap/extension-text";
import TextStyle from "@tiptap/extension-text-style";
import Underline from "@tiptap/extension-underline";

import { EditorProvider, useCurrentEditor } from "@tiptap/react";
import { Button, Card, ColorPicker, Divider } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectAccountingFirm } from "slices/accountingFirmSlice";
import { selectUser } from "slices/userSlice";

// define your extension array
const extensions = [
  Document,
  Underline,
  BulletList,
  Color,
  Italic,
  ListItem,
  Text,
  Bold,
  TextStyle,
  //Paragraph,
  Placeholder.configure({
    placeholder: "...",
  }),
  Highlight.configure({ multicolor: true }),
  Paragraph.extend({
    parseHTML() {
      return [{ tag: "div" }];
    },
    renderHTML({ HTMLAttributes }) {
      return ["div", mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
    },
  }),
  History,
];

const MenuBar = (props) => {
  const { editor } = useCurrentEditor();
  const [visibleEmoji, setVisibleEmoji] = useState(false);
  const user = useSelector(selectUser);
  const accountingFirm = useSelector(selectAccountingFirm);

  // const dispatch = useDispatch();

  const defaultSignature = `<div>Cordialement</div><div>${user.firstName} ${user.lastName}</div><div><span style="color: rgb(69, 105, 248)">${accountingFirm.name}</span></div>`;

  const handleButtonClick = () => {
    setVisibleEmoji(!visibleEmoji);
  };

  const handleEmojiSelect = (emoji) => {
    setVisibleEmoji(false);
  };

  const saveContent = useCallback(() => {
    if (editor) {
      props.setContent(editor.getHTML());
      console.log("Content saved:", editor.getHTML());
    }
  }, [editor]);

  const resetContent = () => {
    editor.chain().focus().clearNodes().setContent(defaultSignature).blur().run();
    props.setContent(defaultSignature);
    // (async () => {
    //   await API.putUser(user._id, {
    //     signature: defaultSignature,
    //   });
    //   dispatch(
    //     updateUser({
    //       signature: defaultSignature,
    //     })
    //   );
    // })();
  };

  useEffect(() => {
    if (editor) {
      editor.on("focus", () => {
        console.log("Editor is focused");
      });

      editor.on("blur", () => {
        console.log("Editor lost focus");
        saveContent();
      });

      editor.on("update", ({ editor }) => {
        props.setContent(editor.getHTML());
      });
    }
  }, [editor, saveContent]);

  if (!editor) {
    return null;
  }

  return (
    <div>
      <Card className="editor-settings" style={{ display: "inline-block" }}>
        <Button
          style={{ padding: "4px" }}
          type="text"
          onClick={() => editor.chain().focus().toggleBold().run()}
          className={editor.isActive("bold") ? "bold is-active" : ""}
        >
          <BoldOutlined />
        </Button>
        <Divider type="vertical" />
        <ColorPicker
          value={editor.getAttributes("setColor").color}
          onChange={(color, hex) => editor.chain().focus().setColor(hex).run()}
          disabledAlpha
        >
          <Button type="text" style={{ backgroundColor: editor.getAttributes("setColor").color }}>
            <FontColorsOutlined style={{ color: editor.getAttributes("setColor").color }} />
          </Button>
        </ColorPicker>
        <Divider type="vertical" />
        <Button
          style={{ padding: "4px" }}
          type="text"
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={editor.isActive("bulletList") ? "is-active" : ""}
        >
          <UnorderedListOutlined />
        </Button>
        <Divider type="vertical" />
        <Button type="text" style={{ padding: "4px" }} onClick={handleButtonClick}>
          <SmileOutlined />
        </Button>
      </Card>

      {visibleEmoji && (
        <Picker
          onSelect={handleEmojiSelect}
          locale="fr"
          theme="light"
          onEmojiSelect={(emoji) => {
            editor.chain().focus().insertContent(emoji.native).run();
          }}
        />
      )}
      <Button
        className="other-action-btn cancel-btn"
        style={{ float: "right", top: "15px" }}
        onClick={() => resetContent()}
      >
        Réinitialiser
      </Button>
    </div>
  );
};

function TipTapEditorSignature(props) {
  return (
    <div className="tiptap-signature">
      <EditorProvider
        extensions={extensions}
        slotAfter={<MenuBar {...props} />}
        content={props.content || null}
        className="tiptap-signature"
      />
    </div>
  );
}

export default TipTapEditorSignature;

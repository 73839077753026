import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal } from "antd";
import { useSelector } from "react-redux";
import logAction from "utils/logActions";
import * as API from "../../../api/API";
import { selectAccountingFirm } from "../../../slices/accountingFirmSlice";
import { selectClient } from "../../../slices/clientSlice";
import { selectUser } from "../../../slices/userSlice";

const { confirm } = Modal;
const { TextArea } = Input;

function InvalidFile(props) {
  const accountingFirm = useSelector(selectAccountingFirm);
  const user = useSelector(selectUser);
  const client = useSelector(selectClient);

  const fetchInvalid = async (file, values, index) => {
    const id = props.opId.replaceAll("/", "%2F");
    const data = {
      idClient: props.match.params.clientId,
      clientName: client.name,
      opId: props.opId,
      fileId: file._id,
      accountingFirmName: accountingFirm.name,
      operationText: props.operationText,
      invalidFileCommentRaw: values.comment,
      invalidFileComment:
        user.firstName +
        " " +
        user.lastName +
        " (" +
        accountingFirm.name +
        ")" +
        "***" +
        values.comment,
      color: accountingFirm.color || "#eaf4fe",
      mcf: client.mcfState === 0,
      recipientMissingDoc: client.email?.missingdoc?.recipient,
      sendEmail: index === 0, // avoid multiple emails when there is more than one file
    };

    const res = await API.postToInvalidDocument(id, data);
    console.log("Votre fichier a été invalidé et supprimé. (2)");
    return res;
  };

  const getInvalidSync = async (values) => {
    for (let index = 0; index < props.files.length; index++) {
      const file = props.files[index];
      await fetchInvalid(file, values, index);
    }
  };

  const onSubmit = (values) => {
    Modal.destroyAll();
    props.invalidated({ invalidcomment: values.comment, opId: props.opId });
    logAction(235, 1, client._id);

    const id = props.opId.replaceAll("/", "%2F");
    const data = {
      idClient: props.match.params.clientId,
      clientName: client.name,
      opId: props.opId,
      accountingFirmName: accountingFirm.name,
      operationText: props.operationText,
      invalidFileCommentRaw: values.comment,
      invalidFileComment:
        user.firstName +
        " " +
        user.lastName +
        " (" +
        accountingFirm.name +
        ")" +
        "***" +
        values.comment,
      color: accountingFirm.color || "#eaf4fe",
      mcf: client.mcfState === 0,
      iDocus: client.iDocusState === 0,
      recipientMissingDoc: client.email?.missingdoc?.recipient,
      sendEmail: true,
    };
    if (props.files.length > 0) {
      getInvalidSync(values);
    } else {
      (async () => {
        const res = await API.postToInvalidDocument(id, data);
        if (res.status === 200) {
          console.log("Votre fichier a été invalidé et supprimé.");
        }
      })();
    }
    API.postCollabTracker(props.match.params.clientId, {
      author: user.firstName + " " + user.lastName,
      action: "INVALIDATION",
      operation: props.operationText,
      comment: values.comment,
    });
  };

  function showConfirm() {
    confirm({
      title: "Êtes-vous sûr(e) de vouloir invalider ce justificatif ?",
      icon: <ExclamationCircleOutlined />,
      className: "modal-invalid modal-invalid-files",
      maskClosable: true,
      closable: true,
      content: (
        <Form id="invalidform" onFinish={onSubmit}>
          <Form.Item
            name="comment"
            rules={[
              {
                required: true,
                message: "Merci de renseigner un commentaire",
              },
            ]}
          >
            <TextArea
              className="invalid-comment"
              placeholder="Ajouter un commentaire pour le client…"
            />
          </Form.Item>
          <Form.Item className="modal-invali-btns">
            <Button
              className="other-action-btn invalid-cancel"
              onClick={(e) => {
                e.preventDefault();
                Modal.destroyAll();
              }}
            >
              Annuler
            </Button>
            <Button className="call-action-btn invalid-submit" htmlType="submit">
              Invalider
            </Button>
          </Form.Item>
        </Form>
      ),
    });
  }

  return (
    <Button
      className="invalid-file-btn other-action-btn"
      onClick={(e) => {
        e.preventDefault();
        showConfirm();
      }}
    >
      Invalider
    </Button>
  );
}

export default InvalidFile;

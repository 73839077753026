import { InboxOutlined } from "@ant-design/icons";
import { Alert, Collapse, Switch } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as API from "../../api/API";

import logAction from "utils/logActions";
import { selectAccountingFirm, updateAccountingFirm } from "../../slices/accountingFirmSlice";

const { Panel } = Collapse;

function GeneralSettings(props) {
  const dispatch = useDispatch();

  const accountingFirm = useSelector(selectAccountingFirm);

  const [mailToBox, setMailToBox] = useState("");

  useEffect(() => {
    if (accountingFirm.mailToBox) {
      setMailToBox("Activé");
    } else {
      setMailToBox("Désactivé");
    }
  }, []);

  const getHeader = () => {
    if (mailToBox === "Activé") {
      return (
        <>
          <span>Mail to Box - </span>
          <span style={{ color: "#0b8235" }}>{mailToBox}</span>
        </>
      );
    } else {
      return (
        <>
          <span>Mail to Box - </span>
          <span style={{ color: "#99999a" }}>{mailToBox}</span>
        </>
      );
    }
  };

  const onMailToBoxChange = (checked) => {
    if (checked) {
      setMailToBox("Activé");
      logAction(211, 1);
    } else {
      setMailToBox("Désactivé");
      logAction(212, 1);
    }

    (async () => {
      let res = await API.putAccountingFirm(accountingFirm._id, { mailToBox: checked });
      if (res.status === 201) {
        dispatch(updateAccountingFirm({ mailToBox: checked }));
        if (checked === false) {
          (async () => {
            let resClients = await API.getClientsByAccountingFirmId(accountingFirm._id);
            resClients = await resClients.json();
            resClients.forEach((client) => {
              if (client.mailToBox || client.mailToBoxSales || client.mailToBoxOther) {
                (async () => {
                  await API.putClient(client._id, {
                    mailToBox: "",
                    mailToBoxSales: "",
                    mailToBoxOther: "",
                  });
                })();
              }
            });
          })();
        }
      }
    })();
  };

  return (
    <div className="general-settings">
      <Collapse bordered={false} defaultActiveKey={["1"]} className="site-collapse-custom-collapse">
        <Panel
          header={getHeader()}
          key="1"
          className="site-collapse-custom-panel"
          extra={<InboxOutlined style={{ fontSize: "22px" }} />}
          showArrow={false}
        >
          <p style={{ marginTop: "0px" }}>
            Activer la fonctionnalité Mail to Box sur tous vos dossiers clients
          </p>

          <Switch
            checked={mailToBox === "Activé"}
            onChange={onMailToBoxChange}
            label="aze"
            style={{ marginBottom: "30px" }}
          />

          <Alert
            className="modal-lost-alert"
            description={
              <span>
                Les justificatifs transmis par vos clients seront envoyés aux adresses mail
                renseignées dans le dossier client. Vous pourrez vérifier leur bon envoi depuis la
                page "Pièces reçues". En revanche, la réception et le traitement des pièces ne
                dépend pas de Sesha mais du fonctionnement de la box destinataire.
              </span>
            }
            type="warning"
          />
        </Panel>
      </Collapse>
    </div>
  );
}

export default GeneralSettings;

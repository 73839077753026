import { FrownOutlined, InfoCircleTwoTone } from "@ant-design/icons";
import { Button, Popover, Result, Table, Typography } from "antd";
import * as dayjs from "dayjs";
import { useEffect, useState } from "react";
import * as API from "../../../api/API";

const { Text } = Typography;

const ExplainedOp = (props) => {
  const [accountData, setAccountData] = useState([]);
  const [downloading, setDownloading] = useState(false);

  function typeToTitle(type) {
    switch (type) {
      case "clients":
        return "Encours clients";
      case "providers":
        return "Encours fournisseurs";
      default:
        return "";
    }
  }

  useEffect(() => {
    setAccountData(
      props.data.map((cat) => ({
        ...cat,
        key: cat._id,
        operations: cat.operations.map((op) => ({
          ...op,
          dueDays: dayjs(props.client.outstandingsParam[props.type].endDate)
            .startOf("day")
            .diff(dayjs(op.date).startOf("day"), "days"),
          amount: props.type === "clients" ? op.amount * -1 : op.amount,
        })),
      }))
    );
  }, [props.data]);

  const expandedRowRender = (record) => {
    return (
      <Table
        className="outstandings-sub-table"
        showSorterTooltip={false}
        columns={props.operationColumns}
        dataSource={record.operations.map((op) => ({ ...op, key: op.id }))}
        pagination={false}
        defaultExpandAllRows={true}
        showExpandColumn={false}
        expandable={{
          expandedRowRender: (record) => (
            <>
              <p style={{ margin: 0 }}>
                <b>Commentaire client : </b>
                {record.commentClient}
              </p>
              {record.commentCollab ? (
                <Popover
                  placement="right"
                  content={
                    <span className="popover-comment-text">
                      <b>Commentaire cabinet : </b>
                      {record.commentCollab}
                    </span>
                  }
                  className="popover-comment"
                >
                  <InfoCircleTwoTone
                    twoToneColor="#4569f8"
                    style={{ fontSize: "120%", marginTop: "10px" }}
                  />
                </Popover>
              ) : null}
            </>
          ),
        }}
      />
    );
  };

  function pendingDownload() {
    (async () => {
      let res = await API.downloadExplained(props.client._id, accountData);
      res.arrayBuffer().then(function (buffer) {
        const link = document.createElement("a");
        if (link.download !== undefined) {
          var blob = new Blob([buffer], { type: "" });
          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", typeToTitle(props.type) + " - éléments expliqués.xlsx");
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setDownloading(false);
        }
      });
    })();
  }

  return (
    <>
      <Text className="section-title">Élements expliqués</Text>
      <div id={"explained-" + props.type} className="blue-bar"></div>
      {accountData.length ? (
        <>
          <Button
            className="other-action-btn download-explained"
            onClick={(e) => {
              e.preventDefault();
              setDownloading(true);
              pendingDownload();
            }}
            loading={downloading}
          >
            Télécharger un récapitulatif des éléments expliqués
          </Button>
          <Table
            className="explainedop-table outstandings-table"
            columns={props.accountColumns}
            dataSource={accountData}
            expandable={{ expandedRowRender: (record) => expandedRowRender(record) }}
            showSorterTooltip={false}
          />
        </>
      ) : (
        <Result
          icon={<FrownOutlined style={{ color: "#4569f8" }} />}
          title="Votre client n’a encore répondu à aucune de vos demandes"
        />
      )}
    </>
  );
};
export default ExplainedOp;

import { EyeFilled, EyeInvisibleFilled, RedoOutlined } from "@ant-design/icons";
import { Button, Col, Row, Tooltip } from "antd";
import { useSelector } from "react-redux";
import { selectClient } from "../../slices/clientSlice";
import { getMailDate } from "../../utils/getDate";
import "../analysis/Analysis.css";
import "./Mail.css";

function TypeSettings(props) {
  const client = useSelector(selectClient);

  return (
    <>
      <Row style={{ paddingLeft: "20px", marginTop: "12px" }}>
        <Col span={0.5}>
          <Tooltip
            title="Conserver l’onglet dans le formulaire"
            placement="left"
            overlayClassName="info-tooltip-style"
            mouseEnterDelay="1"
          >
            <Button
              className="eye-icon-button"
              style={{ cursor: props.isModifyOn ? "pointer" : "default" }}
              onClick={(e) => {
                e.preventDefault();
                if (props.isModifyOn) {
                  props.onChange({
                    ...props.settings.find((el) => el.type === props.typeSettings),
                    visible: !props.settings.find((el) => el.type === props.typeSettings).visible,
                  });
                } else {
                  props.focusModify(true);
                }
              }}
              icon={
                props.settings.find((el) => el.type === props.typeSettings).visible ? (
                  <EyeFilled style={{ color: "#4b68f3" }} />
                ) : (
                  <EyeInvisibleFilled style={{ color: "#D9D9DA" }} />
                )
              }
              disabled={
                props.type !== "missingdoc"
                  ? "outstandings" + props.type === props.typeSettings
                  : props.type === props.typeSettings
              }
            />
          </Tooltip>
        </Col>
        <Col span={0.5}>
          <Tooltip
            title="Mettre à jour les données du formulaire"
            placement="right"
            overlayClassName="info-tooltip-style"
            mouseEnterDelay="1"
          >
            <Button
              className="update-icon-button"
              style={{ cursor: props.isModifyOn ? "pointer" : "default" }}
              onClick={(e) => {
                e.preventDefault();
                if (props.isModifyOn) {
                  props.onChange({
                    ...props.settings.find((el) => el.type === props.typeSettings),
                    refresh: !props.settings.find((el) => el.type === props.typeSettings).refresh,
                  });
                } else {
                  props.focusModify(true);
                }
              }}
              icon={
                <RedoOutlined
                  style={{
                    color: props.settings.find((el) => el.type === props.typeSettings).refresh
                      ? "#4b68f3"
                      : "#D9D9DA",
                  }}
                />
              }
              disabled={
                props.type !== "missingdoc"
                  ? "outstandings" + props.type === props.typeSettings
                  : props.type === props.typeSettings
              }
            />
          </Tooltip>
        </Col>
        <Col span={8} style={{ marginLeft: "60px", marginTop: "-2px" }}>
          <span>{props.text}</span>
        </Col>
        <Col span={12}>
          {props.typeSettings === "missingdoc" && props.reminderMailSendDate ? (
            <>
              <span className="latest-update-text-maj">
                Dernière mise à jour{" "}
                {getMailDate(client.email?.[props.typeSettings]?.lastEmailSent)}
              </span>
              <span className="latest-update-text-reminder">
                <br></br>
                Relance programmée {getMailDate(props.reminderMailSendDate)}
              </span>
            </>
          ) : props.email?.[props.typeSettings]?.lastEmailSent ? (
            <span className="latest-update-text">
              Dernière mise à jour {getMailDate(client.email?.[props.typeSettings]?.lastEmailSent)}
            </span>
          ) : null}
        </Col>
      </Row>
    </>
  );
}

export default TypeSettings;

import Operation from "./Operation";

function Category(props) {
  return (
    <>
      {props.category.operations.map((operation) => (
        <Operation {...props} operation={operation} key={operation._id} />
      ))}
    </>
  );
}

export default Category;

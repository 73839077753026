import { DownCircleOutlined } from "@ant-design/icons";
import { Collapse, Tag } from "antd";
import "dayjs/locale/fr";
import { useEffect, useState } from "react";
import ClientOther from "./desktop/Other";
import ClientMissingOthersResponsiveCard from "./responsive/OthersResponsiveCard";

const ClientMissingOthers = (props) => {
  const [countOthers, setCountOthers] = useState(0);
  const [allOthers, setAllOthers] = useState([]);

  useEffect(() => {
    if (props.missingDocumentOther && props.missingDocumentOther.length > 0) {
      setAllOthers(props.missingDocumentOther);
      setCountOthers(
        props.missingDocumentOther[0].operations.filter((op) => op.showToClient).length
      );
    } else {
      setCountOthers(0);
      setAllOthers([]);
    }
  }, [JSON.stringify(props.missingDocumentOther)]);

  const others = [
    {
      key: "1",
      label: (
        <>
          {" "}
          <div className="type-title">Autres justificatifs</div>
          <div className="type-separation"></div>
        </>
      ),
      children: (
        <div>
          {props.screenWidth <= 900 ? (
            <ClientMissingOthersResponsiveCard
              setMissingDocumentOther={(data) => {
                setAllOthers(data);
                props.setMissingDocumentOther(data);
                props.setInitMissingDocOther(data);
              }}
              data={allOthers}
              tokenClient={props.tokenClient}
              fileSettings={props.fileSettings}
            />
          ) : (
            allOthers[0]?.operations?.map((operation, k) => {
              if (operation.showToClient) {
                return (
                  <ClientOther
                    key={operation._id}
                    operation={operation}
                    tokenClient={props.tokenClient}
                    setData={(data) => {
                      setAllOthers(data);
                      props.setMissingDocumentOther(data);
                      props.setInitMissingDocOther(data);
                    }}
                    data={props.missingDocumentOther}
                    fileSettings={props.fileSettings}
                  />
                );
              }

              return null;
            })
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      {allOthers[0]?.operations?.filter((op) => op.showToClient).length > 0 ? (
        <Collapse
          className="other-collapse"
          items={others}
          defaultActiveKey={others.map((item) => item.key)}
          expandIconPosition="end"
          bordered={false}
          expandIcon={({ isActive }) => (
            <>
              <DownCircleOutlined
                style={{ fontSize: "26px" }}
                className={isActive ? "expand-icon-down" : "expand-icon-up"}
              />{" "}
              <Tag color="#FF9700" style={{ marginLeft: "10px" }}>
                {countOthers}
              </Tag>
            </>
          )}
        />
      ) : null}
    </>
  );
};

export default ClientMissingOthers;

import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
const modules = {
  toolbar: [
    ["bold"],
    [
      {
        background: ["#FFEED6", "#D9FFED", "#fff"],
      },
    ],
    [
      {
        color: [],
      },
    ],
  ],
};
const formats = ["bold", "background", "color", "list", "bullet", "indent"];
const TextEditorAnalysis = (props) => {
  return React.createElement(
    React.Fragment,
    null,
    React.createElement(ReactQuill, {
      theme: "bubble",
      value: props.value || "",
      modules: modules,
      formats: formats,
      onChange: props.onChange,
      placeholder: props.placeholder,
    })
  );
};
export default TextEditorAnalysis;

import { Input } from "antd";
import { useEffect, useRef, useState } from "react";

function numberWithThousandSeparator(x) {
  return x.toString().replace(/[\u202F\u00A0]/g, " ") + " €";
}

const parseNum = (num) => parseFloat(num).toLocaleString("fr-FR", {});

function RenderCell(props) {
  const [newCellValue, setNewCellValue] = useState(props.text);
  const [editMode, setEditMode] = useState(false);

  const cellRef = useRef(null);
  const inputRef = useRef(null);
  const selectRef = useRef(null);

  useEffect(() => {
    if (editMode) {
      inputRef.current?.focus();
      selectRef.current?.focus();
    }
  }, [editMode]);

  useEffect(() => {
    setNewCellValue(props.text);
  }, [props.text]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (cellRef.current && !cellRef.current?.contains(event.target) && props.editedCell) {
        props.setEditedCell({});
        onFinish(newCellValue);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cellRef, props.editedCell, newCellValue]);

  useEffect(() => {
    setEditMode(
      props.editedCell?.year_type === props.type && props.editedCell?.month === props.month
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.editedCell, props.type]);

  const onFinish = (newCellValue) => {
    props.updateCellValue(props.type, props.month, newCellValue);
    props.setEditedCell({});
  };

  return (
    <>
      {editMode ? (
        <div ref={cellRef}>
          <Input
            decimalSeparator=","
            className="cell-number-input"
            type="number"
            style={{ padding: 0 }}
            ref={inputRef}
            value={newCellValue}
            onChange={(e) => setNewCellValue(parseFloat(e.target.value))}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onFinish(newCellValue);
              }
            }}
          />
        </div>
      ) : (
        <>
          <div>{numberWithThousandSeparator(parseNum(newCellValue || 0))}</div>
        </>
      )}
    </>
  );
}
export default RenderCell;

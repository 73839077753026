import React from "react";
import { Column } from "@ant-design/plots";

function ManagerLineGraph(props) {
  const config = {
    data: props.data,
    height: 200,
    padding: "auto",
    xField: "Date",
    yField: "Total",
    columnStyle: {
      fill: "#4569f8",
    },
    yAxis: {
      grid: { line: { style: { stroke: "transparent" } } },
    },
  };

  return (
    <>
      <p className="manager-chart-title">{props.title}</p>
      <Column {...config} />
    </>
  );
}

export default ManagerLineGraph;

import React from "react";
import { Row, Col, Typography } from "antd";
import Nav from "../nav/Nav.js";
import Footer from "../footer/Footer.js";
import Global from "./global/Global.js";

import "./manager.css";

const { Text } = Typography;

function ManagerView(props) {
  return (
    <div className="manager">
      <Nav {...props} />
      <Row justify="center" className="admin-content">
        <Col xs={22} sm={22} md={22} lg={22} xl={22} className="admin-box">
          <Text className="section-title"> Utilisation de Sesha </Text>{" "}
          <div className="blue-bar"> </div>
          <Global {...props} forceUpdate={true} />
        </Col>
      </Row>
      <Footer />
    </div>
  );
}

export default ManagerView;

import { Button } from "antd";
import { useState } from "react";


// interface ComponentInterface {
//     key: string;
//     label: string;
//     children: JSX.Element;
// }

const ClientResponsiveTabBar = (props) => {
  const [selectedTab, setSelectedTab] = useState(props.activeKey);

  return (
    <>
      <div className="client-tab">
        {props.tabs.map((tab, index) => {
          return (
            <Button
              key={`client-tab ${index}`}
              onClick={() => setSelectedTab(tab.key)}
              className={tab.key === props.activeKey ? "selected" : ""}
              disabled={tab.disabled}
            >
              {tab.label}
            </Button>
          );
        })}
      </div>
      {props.tabs[props.tabs.findIndex((element) => element.key === selectedTab)].children}
    </>
  );
};

export default ClientResponsiveTabBar;

import { UploadOutlined } from "@ant-design/icons";
import { DndContext, PointerSensor, useSensor } from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Button, Skeleton, Upload } from "antd";
import { useEffect, useState } from "react";
import * as API from "../../../api/API";
import openNotification from "../../../utils/notification";

const DraggableUploadListItem = ({ originNode, file }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: file.uid,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: "move",
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      // prevent preview event when drag end
      className={isDragging ? "is-dragging" : ""}
      {...attributes}
      {...listeners}
    >
      {/* hide error tooltip when dragging */}
      {file.status === "error" && isDragging ? originNode.props.children : originNode}
    </div>
  );
};

function CarouselSlider(props) {
  const [fileList, setFileList] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [imgLoading, setImgLoading] = useState(false);

  useEffect(() => {
    setImgLoading(true);
    (async () => {
      let res = await API.getNewsText("news");
      res = await res.json();
      setFileList(res.images);
      setImgLoading(false);
    })();
  }, []);

  // Drag and drop
  const sensor = useSensor(PointerSensor, {
    activationConstraint: {
      distance: 10,
    },
  });

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setFileList((prev) => {
        const activeIndex = prev.findIndex((i) => i.uid === active.id);
        const overIndex = prev.findIndex((i) => i.uid === over?.id);
        return arrayMove(prev, activeIndex, overIndex);
      });
    }
  };

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };
  // Fin Drag and drop

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  async function processImagesAndSubmit() {
    setBtnLoading(true);
    const newBlist = [];
    const promises = fileList.map((file) => {
      return new Promise((resolve) => {
        console.log(file.name + file.dataUrl);
        if (file.dataUrl !== undefined) {
          const imageWithName = {
            name: file.name,
            dataUrl: file.dataUrl,
            uid: file.uid,
          };
          newBlist.push(imageWithName);
          resolve();
        } else {
          getBase64(file.originFileObj, (dataUrl) => {
            const imageWithName = {
              name: file.name,
              dataUrl: dataUrl,
            };
            newBlist.push(imageWithName);
            resolve();
          });
        }
      });
    });
    await Promise.all(promises);
    try {
      const res = await API.postNewsText("news", {
        images: newBlist,
      });
      if (res.status === 200) {
        openNotification("success", "Les images ont été enregistrées");
        setBtnLoading(false);
      } else {
        openNotification("error", "Erreur lors de l'enregistrement des images");
        setBtnLoading(false);
      }
    } catch (error) {
      openNotification("error", "Erreur lors du traitement des images");
      setBtnLoading(false);
    }
  }

  return (
    <>
      {imgLoading ? (
        <>
          <Skeleton active={true} />
        </>
      ) : (
        <DndContext sensors={[sensor]} onDragEnd={onDragEnd}>
          <SortableContext
            items={fileList?.map((i) => i.uid) || []}
            strategy={verticalListSortingStrategy}
          >
            <Upload
              beforeUpload={() => false}
              fileList={fileList}
              onChange={onChange}
              itemRender={(originNode, file) => (
                <DraggableUploadListItem originNode={originNode} file={file} />
              )}
              maxCount={3}
              multiple
            >
              <Button icon={<UploadOutlined />}>Ajouter des images</Button>
            </Upload>
          </SortableContext>
        </DndContext>
      )}
      <br />
      <Button
        // disabled={
        //   fileList.length === 0
        //     ? true
        //     : false || fileList[0]?.originFileObj?.uid === undefined
        //     ? true
        //     : false
        // }
        loading={btnLoading ? true : false}
        className="call-action-btn"
        onClick={() => processImagesAndSubmit()}
      >
        Valider
      </Button>
    </>
  );
}

export default CarouselSlider;

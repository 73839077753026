import { Button } from "antd";

function TemplateBtns(props) {
  if (props.template === "form" || props.template === "formreminder") {
    return (
      <div className="template-btns-subject">
        <p className="template-btns-title">Balises disponibles :</p>
        <p className="template-btns-description">
          Elles seront remplacées par les données correspondantes
        </p>
        <Button
          className="ql-insertClientName call-action-btn template-btn"
          onClick={() => props.insertText("<nom_cabinet>")}
        >
          <p>{"<nom_cabinet>"}</p>
        </Button>
        <Button
          className="ql-insertClientName call-action-btn template-btn"
          onClick={() => props.insertText("<nom_form>")}
        >
          <p>{"<nom_form>"}</p>
        </Button>
        <Button
          className="ql-insertClientName call-action-btn template-btn"
          onClick={() => props.insertText("<date_fin>")}
        >
          <p>{"<date_fin>"}</p>
        </Button>
        {props.template === "formreminder" ? (
          <Button
            className="ql-insertClientName call-action-btn template-btn"
            onClick={() => props.insertText("<taux_completion>")}
          >
            <p>{"<taux_completion>"}</p>
          </Button>
        ) : null}
        <Button
          className="ql-collabSignature call-action-btn template-btn"
          onClick={() => props.insertText("<signature_collab>")}
        >
          <p>{"<signature_collab>"}</p>
        </Button>
      </div>
    );
  } else {
    return (
      <div className="template-btns-subject">
        <p className="template-btns-title">Balises disponibles :</p>
        <p className="template-btns-description">
          Elles seront remplacées par les données correspondantes
        </p>
        <Button
          className="ql-insertClientName call-action-btn template-btn"
          onClick={() => props.insertText("<nom_dossier_client>")}
        >
          <p>{"<nom_dossier_client>"}</p>
        </Button>

        {props.template === "analysis" ? (
          <Button
            className="ql-analysisMonth call-action-btn template-btn"
            onClick={() => props.insertText("<mois_analyse>")}
          >
            <p>{"<mois_analyse>"}</p>
          </Button>
        ) : props.template === "global" ? null : (
          <>
            <Button
              className="ql-NumberMissingDoc call-action-btn template-btn"
              onClick={() =>
                props.template === "missingdoc"
                  ? props.insertText("<nb_justif>")
                  : props.insertText("<nb_factures>")
              }
            >
              <p>{props.template === "missingdoc" ? "<nb_justif>" : "<nb_factures>"}</p>
            </Button>

            <Button
              className="ql-AmountMissingDoc call-action-btn template-btn"
              onClick={() =>
                props.template === "missingdoc"
                  ? props.insertText("<montant_justif>")
                  : props.insertText("<montant_factures>")
              }
            >
              <p>{props.template === "missingdoc" ? "<montant_justif>" : "<montant_factures>"}</p>
            </Button>
          </>
        )}

        <Button
          className="ql-collabFirstName call-action-btn template-btn"
          onClick={() => props.insertText("<prenom_collab>")}
        >
          <p>{"<prenom_collab>"}</p>
        </Button>
        <Button
          className="ql-collabLastName call-action-btn template-btn"
          onClick={() => props.insertText("<nom_collab>")}
        >
          <p>{"<nom_collab>"}</p>
        </Button>
        <Button
          className="ql-collabSignature call-action-btn template-btn"
          onClick={() => props.insertText("<signature_collab>")}
        >
          <p>{"<signature_collab>"}</p>
        </Button>
      </div>
    );
  }
}
export default TemplateBtns;

import { UpCircleOutlined, ZoomInOutlined } from "@ant-design/icons";
import { Checkbox, Modal, Popover, Table } from "antd";
import * as dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as ImportantIcon } from "../../../../assets/images/important.svg";
import { selectClient, updateClient } from "../../../../slices/clientSlice";
import * as number from "../../../../utils/number";
import AccountGlobalTable from "../../AccountGlobalTable";
import Category from "./Category";
import TableJournals from "./TableJournals";

function Type(props) {
  const dispatch = useDispatch();
  const client = useSelector(selectClient);

  const [categories, setCategories] = useState([]);
  const [activeOperations, setActiveOperations] = useState([]);
  const [totalAmount, setTotalAmount] = useState();
  const [nbrMissingDoc, setNbrMissingDoc] = useState();
  const [expandedRows, setExpandedRows] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [modalAccountNumber, setModalAccountNumber] = useState();
  const [modalTitle, setModalTitle] = useState();

  useEffect(() => {
    console.debug("useEffect init/update data");
    setCategories(
      props.data
        .map((cat) => ({
          ...cat,
          operations: cat.operations
            .filter(
              (operation) =>
                (client.showZeroAccounts || !operation.is_in_balanced_account) &&
                dayjs(operation.date) >= props.startDate &&
                dayjs(operation.date) <= props.endDate &&
                props.journalListFiltered.includes(operation.journal?.trim()) &&
                (props.editMode || operation.active !== false) &&
                (operation.ghost === 0 ||
                  (operation.ghost === 1 &&
                    (operation.fileId ||
                      operation.files?.length > 0 ||
                      operation.comment ||
                      operation.isLost ||
                      operation.isLostNoAccount)))
            )
            .map((operation) =>
              operation.active === undefined &&
              client.uncheckedAccounts?.includes(
                cat.accountNumberAux + "-" + (operation.amount > 0 ? 2 : 1)
              )
                ? props.editMode
                  ? { ...operation, active: false }
                  : undefined
                : operation
            )
            .filter((operation) => operation !== undefined),
        }))
        .filter(({ operations }) => operations.length > 0)
        .map((cat) => ({
          ...cat,
          key: cat._id || cat.id,
          numberMissingDoc: cat.operations.filter((op) => op.active !== false).length,
          amount: cat.operations
            .filter((op) => op.active !== false)
            .reduce((acc, curr) => acc + Math.abs(curr.amount), 0),
        }))
        .sort((a, b) => {
          const titleA = a.accountNumberAux
            ? a.accountNumberAux.trim().toUpperCase()
            : a.title.trim().toUpperCase();
          const titleB = b.accountNumberAux
            ? b.accountNumberAux.trim().toUpperCase()
            : b.title.trim().toUpperCase();
          return titleA.localeCompare(titleB, undefined, {
            numeric: true,
            sensitivity: "base",
          });
        }) || []
    );
  }, [
    JSON.stringify(props.data),
    props.startDate,
    props.endDate,
    props.journalListFiltered,
    props.editMode,
  ]);

  useEffect(() => {
    if (categories.length === 0) {
      setActiveOperations([]);
      setTotalAmount(0);
      setNbrMissingDoc(0);
      return;
    }
    console.debug("useEffect update totals");

    let totalAmount = 0;
    let nbrMissingDoc = 0;
    categories.forEach(({ numberMissingDoc, amount }) => {
      totalAmount += Math.abs(amount);
      nbrMissingDoc += numberMissingDoc;
    });

    setActiveOperations(
      categories.map(({ operations }) => operations.filter((op) => op.active !== false)).flat(1)
    );
    setTotalAmount(totalAmount);
    setNbrMissingDoc(nbrMissingDoc);
  }, [JSON.stringify(categories)]);

  // Centralisation du nombre de justificatifs manquants
  useEffect(() => {
    if (
      nbrMissingDoc === props.structuredNbrMissingDoc.find((el) => el.id === props.id).data ||
      nbrMissingDoc === undefined
    )
      return;
    props.setStructuredNbrMissingDoc((prev) =>
      prev.map((el) => (el.id === props.id ? { ...el, data: nbrMissingDoc } : el))
    );
  }, [nbrMissingDoc]);

  // Centralisation du montant
  useEffect(() => {
    if (
      totalAmount === props.structuredAmount.find((el) => el.id === props.id).data ||
      totalAmount === undefined
    )
      return;
    props.setStructuredAmount((prev) =>
      prev.map((el) => (el.id === props.id ? { ...el, data: totalAmount } : el))
    );
  }, [totalAmount]);

  // Centralisation de la liste d'op
  useEffect(() => {
    props.setFilteredOp((prev) =>
      prev.map((el) =>
        el.id === props.id
          ? {
              ...el,
              data: categories.map((cat) => cat.operations).flat(1),
            }
          : el
      )
    );
  }, [categories]);

  useEffect(() => {
    if (props.mode === 3 && !props.allExpanded) {
      setExpandedRows([]);
      return;
    }
    if (props.mode === 3 && props.allExpanded) {
      const categoriesForJournals = categories.map((cat) => {
        const matchingId = props.journalListFiltered.find((_id) => cat._id.includes(_id));
        const type =
          cat.accountNumber === "40" ? "achats" : cat.accountNumber === "41" ? "ventes" : "aux";
        return matchingId ? `${cat._id}${matchingId}${type}` : null;
      });
      setExpandedRows(categoriesForJournals);
    }
  }, [props.allExpanded]);

  useEffect(() => {
    if (props.mode === 3) return;
    setExpandedRows(props.allExpanded ? categories.map((cat) => cat._id) : []);
  }, [props.allExpanded]);

  const uncheckOperations = (categoryId, uncheck) => {
    console.debug("uncheckOperations()");
    props.setAllData([
      ...props.allData.map((el) =>
        el.id !== props.id
          ? el
          : {
              ...el,
              _id: el.accountNumberAux + props.id,
              data: categories.map((cat) =>
                cat._id !== categoryId
                  ? cat
                  : {
                      ...cat,
                      operations: cat.operations.map((op) => ({ ...op, active: !uncheck })),
                    }
              ),
            }
      ),
    ]);
  };

  const changeUncheckedAccounts = (accountNumberAux, uncheck, record) => {
    if (!uncheck) {
      dispatch(
        updateClient({
          uncheckedAccounts: client.uncheckedAccounts.filter(
            (cat) => cat !== accountNumberAux + "-" + (record.operations[0].amount > 0 ? 2 : 1)
          ),
        })
      );
    } else {
      dispatch(
        updateClient({
          uncheckedAccounts: [
            ...client.uncheckedAccounts,
            accountNumberAux + "-" + (record.operations[0].amount > 0 ? 2 : 1),
          ],
        })
      );
    }
  };

  const accountColumns = [
    {
      title: "",
      width: "30px",
      render: () => (
        <span className="operation-title">
          <ImportantIcon className="operation-title-circle" />
        </span>
      ),
    },
    {
      title: "N° de compte aux.",
      dataIndex: "accountNumberAux",
      key: "accountNumberAux",
      width: props.mode === 3 ? "130px" : "170px",
      defaultSortOrder: "ascend",
      sorter:
        props.mode !== 3 ? (a, b) => a.accountNumberAux.localeCompare(b.accountNumberAux) : false,
    },
    {
      title: "Libellé compte aux.",
      dataIndex: "title",
      key: "title",
      width: props.mode === 3 ? "110px" : "150px",
      sorter: (a, b) => a.title.localeCompare(b.title),
    },
    {
      title: "Justificatifs manquants",
      dataIndex: "numberMissingDoc",
      key: "numberMissingDoc",
      align: "center",
      width: "120px",
      sorter: (a, b) => b.numberMissingDoc - a.numberMissingDoc,
    },
    {
      title: "Montant total",
      dataIndex: "amount",
      key: "amount",
      align: "center",
      width: "100px",
      sorter: (a, b) => b.amount - a.amount,
      render: (text) => (
        <div style={{ textAlign: "right" }}>
          {number.withThousandSeparator(number.parseNum(text))}
        </div>
      ),
    },
    {
      title: "",
      width: props.mode === 3 ? "30px" : "20px",
      onCell: () => ({
        onClick: (e) => {
          e.stopPropagation();
        },
      }),
      render: (el) => (
        <div
          onClick={(e) => {
            setShowModal(true);
            setModalAccountNumber(el.accountNumberAux);
            setModalTitle(el.title);
          }}
        >
          <Popover
            placement="top"
            content="Afficher l'ensemble des opérations du compte"
            overlayClassName="navbar-popover-overlay"
          >
            <ZoomInOutlined />
          </Popover>{" "}
        </div>
      ),
    },
    Table.EXPAND_COLUMN,
  ];

  return (
    <>
      {props.mode === 3 ? (
        <div className="type-missing-doc">
          <div className="type-title">
            {props.id} - {props.label}
          </div>
          <div className="type-separation"></div>
          <Table
            className="missing-table journal-missing-table"
            dataSource={[]}
            columns={accountColumns}
            pagination={false}
            showSorterTooltip={false}
            locale={{ emptyText: " " }}
          />
          <TableJournals
            {...props}
            expandedRowKeys={expandedRows}
            setExpandedRows={setExpandedRows}
            categories={categories}
            accountColumns={accountColumns}
          />
        </div>
      ) : (
        (totalAmount > 0 || props.editMode) && (
          <div className="type-missing-doc">
            <div className="type-title">
              {props.label} - {number.withThousandSeparator(number.parseNum(totalAmount))}
            </div>
            <div className="type-separation"></div>
            <Table
              className="missing-table"
              dataSource={categories}
              columns={accountColumns}
              showSorterTooltip={false}
              pagination={{
                defaultPageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ["10", "20", "50", "100"],
              }}
              rowSelection={
                props.editMode && {
                  renderCell: (checked, record) => {
                    const activeOperationsMap = new Map(
                      record.operations.map((op) => [op._id, op])
                    );

                    const totalOperations = record.operations.length;
                    const activeOperationsCount = activeOperations.reduce(
                      (count, op) => (activeOperationsMap.has(op._id) ? count + 1 : count),
                      0
                    );

                    return (
                      <Checkbox
                        indeterminate={
                          activeOperationsCount > 0 && activeOperationsCount < totalOperations
                        }
                        checked={activeOperationsCount === totalOperations}
                        onChange={(e) => {
                          const uncheck = !e.target.checked;
                          uncheckOperations(record._id, uncheck);
                          if (props.mode === 1)
                            changeUncheckedAccounts(record.accountNumberAux, uncheck, record);
                        }}
                      />
                    );
                  },
                  hideSelectAll: true,
                }
              }
              expandable={{
                expandedRowRender: (record) => (
                  <Category {...props} category={record} key={record._id} />
                ),
                expandedRowKeys: expandedRows,
                expandRowByClick: true,
                onExpandedRowsChange: (rows) => setExpandedRows(rows),
                expandIcon: ({ expanded, onExpand, record }) => (
                  <UpCircleOutlined
                    onClick={(e) => onExpand(record, e)}
                    className={expanded ? "expand-icon-down" : "expand-icon-up"}
                  />
                ),
              }}
            />
          </div>
        )
      )}
      <Modal
        title={modalAccountNumber + " - " + modalTitle}
        className="all-op-modal"
        open={showModal}
        onCancel={(e) => {
          e.preventDefault();
          setShowModal(false);
        }}
        footer={null}
      >
        <AccountGlobalTable {...props} client={client} account={modalAccountNumber} />
      </Modal>
    </>
  );
}

export default Type;

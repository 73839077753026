import OutstandingsDesktop from "./desktop/OutstandingsDesktop";
import OutstandingsResponsive from "./responsive/OutstandingsResponsive";

const Outstandings = (props) => {
  // TODO: Investigate why the endDate is undefined

  if (props.screenWidth < 900) {
    return (
      <OutstandingsResponsive
        endDate={props.endDate}
        data={props.data}
        setData={props.setData}
        type={props.type}
        dueDays={props.dueDays}
      />
    );
  }
  return <OutstandingsDesktop {...props} />;
};

export default Outstandings;

import { BankOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Radio, Select, Switch } from "antd";
import { useState } from "react";
import * as API from "../../../api/API";
import openNotification from "../../../utils/notification";

function EditAccountingFirm(props) {
  const [form] = Form.useForm();
  const [firmEdit, setFirmEdit] = useState(false);

  const onFinish = (values) => {
    (async () => {
      // reset id field with field firmId, created to avoid lp autofill in id field
      const res = await API.putAccountingFirm(props.selectedRow._id, {
        ...values,
        id: values.firmId,
      });
      if (res.status === 201) {
        setFirmEdit(false);
        props.updateFirm(true);
        openNotification("success", "Cabinet comptable modifié avec succès");
      } else openNotification("error", "Erreur lors de la modification du cabinet comptable");
    })();
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="edit-user">
      <Button
        className="call-action-btn"
        key="update"
        onClick={(e) => {
          e.preventDefault();
          setFirmEdit(!firmEdit);
        }}
        icon={<EditOutlined />}
      >
        Modifier
      </Button>
      <Modal
        title="Modifier les données du cabinet comptable"
        className="dashboard-modal"
        open={firmEdit}
        onCancel={(e) => {
          e.preventDefault();
          setFirmEdit(false);
        }}
        footer={null}
      >
        <Form
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          labelAlign="left"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={{
            firmId: props.selectedRow._id,
            name: props.selectedRow.name,
            cabinetDomain: props.selectedRow.cabinetDomain,
            fec: props.selectedRow.fec.toString(),
            dataProvider: props.selectedRow.dataProvider,
            acdUrl: props.selectedRow.acdUrl,
            cnx: props.selectedRow.cnx,
            mcf: props.selectedRow.mcf,
            iDocus: props.selectedRow.iDocus,
            active: props.selectedRow.active,
            conciliator: props.selectedRow.conciliator,
          }}
        >
          <Form.Item name="firmId" label="ID Cabinet">
            <Input disabled={true} />
          </Form.Item>
          <Form.Item
            name="name"
            label="Nom"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item className="select-role" name="fec" label="Mode d'import">
            <Radio.Group
              options={[
                { label: "FEC", value: "true" },
                { label: "Automatique", value: "false" },
              ]}
              optionType="button"
              buttonStyle="solid"
            />
          </Form.Item>
          <Form.Item className="select-dataprovider" name="dataProvider" label="Logiciel comptable">
            <Select
              disabled={true}
              placement="bottomLeft"
              dropdownAlign={{ overflow: { adjustY: false } }}
            />
          </Form.Item>
          {props.selectedRow.dataProvider === "ACD" ? (
            <>
              <Form.Item name="acdUrl" label="URL ACD">
                <Input />
              </Form.Item>
              <Form.Item name="cnx" label="CNX">
                <Input prefix={"CNX"} />
              </Form.Item>
            </>
          ) : null}
          {props.selectedRow.dataProvider === "CEGID LOOP" ? (
            <>
              <Form.Item name="cabinetDomain" label="Domaine">
                <Input />
              </Form.Item>
            </>
          ) : null}
          <Form.Item
            className="select-role"
            name="mcf"
            label="Connexion à MCF"
            valuePropName="checked"
          >
            <Switch checkedChildren="Oui" unCheckedChildren="Non" />
          </Form.Item>
          <Form.Item
            className="select-role"
            name="iDocus"
            label="Connexion à iDocus"
            valuePropName="checked"
          >
            <Switch checkedChildren="Oui" unCheckedChildren="Non" />
          </Form.Item>
          <Form.Item
            className="select-role"
            name="conciliator"
            label="Connexion à Conciliator"
            valuePropName="checked"
          >
            <Switch checkedChildren="Oui" unCheckedChildren="Non" />
          </Form.Item>
          <Form.Item
            className="select-role"
            name="active"
            label="Cabinet activé"
            valuePropName="checked"
          >
            <Switch checkedChildren="Oui" unCheckedChildren="Non" />
          </Form.Item>
          <Form.Item wrapperCol={{ span: 24 }}>
            <Button
              block
              className="call-action-btn modal-submit-btn"
              htmlType="submit"
              icon={<BankOutlined />}
            >
              Mettre à jour
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default EditAccountingFirm;

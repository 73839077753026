import { EditOutlined, UserAddOutlined } from "@ant-design/icons";
import { Button, DatePicker, Form, Input, Modal, Select } from "antd";
import locale from "antd/es/date-picker/locale/fr_FR";
import * as dayjs from "dayjs";
import "dayjs/locale/fr";
import { useState } from "react";
import * as API from "../../../api/API";
import openNotification from "../../../utils/notification";

const { Option } = Select;

function EditUser(props) {
  const [form] = Form.useForm();
  const [userEdit, setUserEdit] = useState(false);

  // tri des cabinets par ordre alphabétique
  const firmList = props.firmList.sort(function (a, b) {
    var textA = a.name.toUpperCase();
    var textB = b.name.toUpperCase();
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });

  const onFinish = (values) => {
    (async () => {
      const res = await API.putUser(props.selectedRow._id, values);
      setUserEdit(false);
      props.updateUser(true);
      if (res.status === 201) {
        if (props.selectedRow.scheduledId) {
          (async () => {
            let res = await API.putScheduleUser(props.selectedRow.scheduledId, {
              date: values.schedule,
            });
            if (res.status === 201) openNotification("success", "Utilisateur modifié avec succès");
            else openNotification("error", "Erreur lors de la modification de l'utilisateur");
          })();
        } else openNotification("success", "Utilisateur modifié avec succès");
      } else openNotification("error", "Erreur lors de la modification de l'utilisateur");
    })();
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <>
      <div className="edit-user">
        <Button
          className="call-action-btn"
          key="update"
          onClick={(e) => {
            e.preventDefault();
            setUserEdit(!userEdit);
          }}
          icon={<EditOutlined />}
        >
          Modifier
        </Button>
        <Modal
          title="Modifier les données de l'utilisateur"
          width={620}
          className="dashboard-modal"
          open={userEdit}
          onCancel={(e) => {
            e.preventDefault();
            setUserEdit(false);
          }}
          footer={null}
          forceRender
        >
          <Form
            form={form}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            labelAlign="left"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={{
              firstName: props.selectedRow.firstName,
              lastName: props.selectedRow.lastName,
              accountingFirmId: props.selectedRow.accountingFirmId,
              newRole: props.roleList.find((el) => el._id === props.selectedRow.newRole)._id,
              email: props.selectedRow.email,
              schedule: props.selectedRow.scheduledDate
                ? dayjs(props.selectedRow.scheduledDate)
                : null,
            }}
          >
            <Form.Item
              name="firstName"
              label="Prénom"
              rules={[
                {
                  required: true,
                  message: "Merci de renseigner le prénom",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="lastName"
              label="Nom"
              rules={[
                {
                  required: true,
                  message: "Merci de renseigner le nom",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="accountingFirmId"
              label="Cabinet Comptable"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                showSearch={true}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                placement="bottomLeft"
                dropdownAlign={{ overflow: { adjustY: false } }}
              >
                {firmList.map((item) => (
                  <Option key={item._id}>{item.name}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="newRole"
              label="Droits associés"
              rules={[
                {
                  required: true,
                  message: "Merci de renseigner le rôle",
                },
              ]}
            >
              <Select>
                {props.roleList
                  .filter(
                    (el) =>
                      el.accountingFirmId === props.selectedRow.accountingFirmId ||
                      el.accountingFirmId === "SESHA_ADMIN"
                  )
                  .map((role) => (
                    <Option key={role._id} value={role._id}>
                      {role.label}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="email"
              label="Adresse email"
              rules={[
                {
                  type: "email",
                  required: true,
                  message: "Merci de renseigner l'adresse email",
                },
              ]}
            >
              <Input />
            </Form.Item>
            {props.selectedRow.scheduledDate ? (
              <Form.Item
                name="schedule"
                label="Programmer l'envoi du mail"
                rules={[
                  {
                    required: true,
                    message: "Merci de renseigner la date",
                  },
                ]}
              >
                <DatePicker locale={locale} />
              </Form.Item>
            ) : null}
            <Form.Item wrapperCol={{ span: 24 }}>
              <Button
                block
                className="call-action-btn modal-submit-btn"
                htmlType="submit"
                icon={<UserAddOutlined />}
              >
                Mettre à jour
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </>
  );
}

export default EditUser;

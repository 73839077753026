import {
  ArrowRightOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
  ExclamationCircleOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from "@ant-design/icons";
import { Button, Col, Form, Input, Modal, Select, Switch } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TooltipConnect from "utils/tooltipConnect";
import * as API from "../../../api/API";
import { selectAccountingFirm, updateAccountingFirm } from "../../../slices/accountingFirmSlice";
import openNotification from "../../../utils/notification";

const option = [
  { label: ".png", value: "image/png" },
  { label: ".jpeg", value: "image/jpeg" },
  { label: ".jpg", value: "image/jpg" },
  { label: ".tiff", value: "image/tiff" },
  { label: ".pdf", value: "application/pdf" },
];

const { confirm } = Modal;

function AdminIDocus() {
  const dispatch = useDispatch();

  const accountingFirm = useSelector(selectAccountingFirm);
  const [isIDocusConnected, setIsIDocusConnected] = useState();
  const [checkIDocusConnexion, setCheckIDocusConnexion] = useState();
  const [loading, setLoading] = useState(false);

  const updateAccountingFirmData = async (data) => {
    data.fileTypes = option.map(({ value }) => value);
    try {
      await API.putAccountingFirm(accountingFirm._id, data);
      openNotification("success", "Modifications enregistrées");
      dispatch(updateAccountingFirm(data));
    } catch (error) {
      console.log(error);
      openNotification("error", "Erreur lors de l'enregistrement");
    }
  };

  useEffect(() => {
    const testIDocusConnection = async () => {
      const res = await API.testIDocusConnection({
        token: accountingFirm.iDocusToken,
      });
      if (res.statusCode === 0) {
        setCheckIDocusConnexion(true);
      } else {
        setCheckIDocusConnexion(false);
      }
      setLoading(false);
    };

    setLoading(true);
    if (accountingFirm.iDocusToken) {
      setIsIDocusConnected(true);
      testIDocusConnection();
    } else {
      setLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isIDocusConnected === false) {
      updateAccountingFirmData({ iDocusToken: null });
      setCheckIDocusConnexion();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIDocusConnected]);

  const onFinish = (values) => {
    (async () => {
      const res = await API.testIDocusConnection({
        token: values.iDocusToken,
      });
      if (res.statusCode === 0) {
        setCheckIDocusConnexion(true);
        setIsIDocusConnected(true);
        const data = {
          iDocusToken: values.iDocusToken,
          iDocusEnc: values.iDocusEnc,
          iDocusDec: values.iDocusDec,
          iDocusAutres: values.iDocusAutres,
        };
        updateAccountingFirmData(data);
      } else {
        setCheckIDocusConnexion(false);
      }
    })();
  };

  const showIDocusAlert = () => {
    confirm({
      title: "",
      icon: <ExclamationCircleOutlined className="prompt-icon" style={{ top: "-30px" }} />,
      className: "modal-invalid modal-invalid-mail",
      maskClosable: false,
      closable: false,
      content: (
        <div>
          <div className="prompt-text-style">
            <p>
              Voulez-vous vraiment désactiver la connexion à iDocus ? Si vous continuez, le token
              sera supprimé.
            </p>
          </div>
          <div className="prompt-btns">
            <Button
              className="other-action-btn invalid-cancel"
              onClick={(e) => {
                e.preventDefault();
                Modal.destroyAll();
              }}
            >
              Annuler
            </Button>
            <Button
              className="call-action-btn"
              onClick={(e) => {
                e.preventDefault();
                setIsIDocusConnected(false);
                updateAccountingFirm({ iDocusToken: null });
                Modal.destroyAll();
              }}
            >
              Continuer
            </Button>
          </div>
        </div>
      ),
    });
  };

  return (
    <>
      {loading ? (
        ""
      ) : (
        <Col className="idocus-co">
          <p className="settings-category-p mcf-co">Connexion à iDocus : </p>
          <div className="how-to-connection-float">
            <TooltipConnect
              url={"https://knowledge.sesha.expert/knowledge/comment-connecter-sesha-%C3%A0-idocus"}
              text={"Comment connecter Sesha à iDocus ?"}
            />
          </div>
          <br />
          <div className="mcf-switch">
            <Switch
              checked={isIDocusConnected}
              onChange={() => {
                if (isIDocusConnected) showIDocusAlert();
                else setIsIDocusConnected(!isIDocusConnected);
              }}
            />{" "}
            <span>
              {isIDocusConnected ? (
                checkIDocusConnexion ? (
                  "Activée"
                ) : (
                  <b>Veuillez renseigner votre token iDocus et cliquer sur "Valider"</b>
                )
              ) : (
                "Désactivée"
              )}
            </span>
          </div>

          {isIDocusConnected ? (
            <>
              {checkIDocusConnexion ? (
                <div className="mcf-valid-block">
                  <CheckCircleFilled className="mcf-valid-icon" />
                  <p className="mcf-valid-text">
                    <b>Félicitations, Sesha est connecté à iDocus.</b>
                  </p>
                  <p>
                    Les pièces reçues de vos clients sont automatiquement chargées dans la zone de
                    dépôt correspondante.
                  </p>
                </div>
              ) : checkIDocusConnexion === false ? (
                <div className="mcf-invalid-block">
                  <CloseCircleFilled className="mcf-invalid-icon" />
                  <p className="mcf-invalid-text">
                    <b>Token non reconnu.</b>
                  </p>
                  <p>Veuillez essayer à nouveau.</p>
                </div>
              ) : null}
              {isIDocusConnected ? (
                <Form
                  onFinish={onFinish}
                  layout="vertical"
                  initialValues={{
                    iDocusToken: accountingFirm.iDocusToken,
                    iDocusDec: accountingFirm.iDocusDec,
                    iDocusEnc: accountingFirm.iDocusEnc,
                    iDocusAutres: accountingFirm.iDocusAutres,
                  }}
                  className="user-profile-form"
                >
                  <Form.Item name="iDocusToken" label="Token iDocus">
                    <Input.Password
                      bordered={true}
                      className={"form-input"}
                      iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                      }
                    />
                  </Form.Item>
                  <Form.Item name="iDocusDec" label="Achats">
                    <Select
                      mode="tags"
                      className="form-input form-input-tags"
                      dropdownClassName={"config-select-dropdown"}
                    />
                  </Form.Item>
                  <Form.Item name="iDocusEnc" label="Ventes">
                    <Select
                      mode="tags"
                      className="form-input form-input-tags"
                      popupClassName={"config-select-dropdown"}
                    />
                  </Form.Item>
                  <Form.Item name="iDocusAutres" label="Autres">
                    <Select
                      mode="tags"
                      className="form-input form-input-tags"
                      dropdownClassName={"config-select-dropdown"}
                    />
                  </Form.Item>
                  <Form.Item className="submit-changes-btn" style={{ marginLeft: "0" }}>
                    <Button className="call-action-btn modal-submit-btn" htmlType="submit">
                      Valider <ArrowRightOutlined />
                    </Button>
                  </Form.Item>
                </Form>
              ) : null}
            </>
          ) : (
            <div>
              <p>La connexion à iDocus est disponible pour votre cabinet.</p>
              <p>
                Une fois activée, les pièces reçues de vos clients seront automatiquement chargées
                dans la zone de dépôt correspondante.
              </p>
            </div>
          )}
        </Col>
      )}
    </>
  );
}
export default AdminIDocus;

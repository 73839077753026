import React from "react";
import { Row, Col } from "antd";

function Loader(props) {
  const Text = (
    <div>
      <p>Merci de bien vouloir patienter.</p>
      {props.text ? <p>{props.text}</p> : null}
    </div>
  );

  return (
    <Row justify="center" className="loader-row">
      <Col xs={15} sm={15} md={7} lg={7} xl={7} className="loader-box">
        <div className="loader-div">
          <div className="loader">
            <div className="stage">
              <div className="dot-revolution1"></div>
              <div className="dot-revolution2"></div>
              <div className="dot-revolution3"></div>
            </div>
          </div>
          {Text}
        </div>
      </Col>
    </Row>
  );
}

export default Loader;

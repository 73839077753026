import { Select } from "antd";
import { useContext } from "react";
import * as API from "../../api/API";
import ClientContext from "../../context/ClientContext";

const { Option } = Select;

function OperationNature(props) {
  const operation = props.operation;
  console.log(operation);
  const context = useContext(ClientContext);

  const onSubmit = (value) => {
    (async () => {
      const res = await API.UpdateOperation({
        clientId: context.idClient,
        operation,
        nature: value,
        other: props.title === "Autres",
      });
      if (res.status === 200) {
        props.setData((previous) => {
          return previous.map((el) => {
            if (props.title === "Autres") {
              if (el._id === operation._id) {
                return { ...el, nature: value };
              }
              return el;
            } else {
              return {
                ...el,
                operations: el.operations.map((o) => {
                  if (o._id === operation._id) {
                    return { ...o, nature: value };
                  }
                  return o;
                }),
              };
            }
          });
        });
      } else {
        console.log("error OperationNature");
      }
    })();
  };

  return (
    <>
      {operation.amount < 0 ? (
        <Select
          defaultValue={operation.nature}
          popupClassName="ant-select-dropdown-client"
          className="nature-select"
          placeholder="Nature du mouvement"
          style={{ width: 200 }}
          onChange={(value) => onSubmit(value)}
          placement="bottomLeft"
          dropdownAlign={{ overflow: { adjustY: false } }}
        >
          <Option value="Paiement fournisseur">Paiement fournisseur</Option>
          <Option value="Paiement d'un salarié">Paiement d'un salarié</Option>
          <Option value="Retrait d'un dirigeant">Retrait d'un dirigeant</Option>
          <Option value="Impôt ou charge sociale">Impôt ou charge sociale</Option>
          <Option value="Autre">Autre</Option>
        </Select>
      ) : operation.amount > 0 ? (
        <Select
          defaultValue={operation.nature}
          className="nature-select"
          popupClassName="ant-select-dropdown-client"
          placeholder="Nature du mouvement"
          style={{ width: 200 }}
          onChange={(value) => onSubmit(value)}
          placement="bottomLeft"
          dropdownAlign={{ overflow: { adjustY: false } }}
        >
          <Option value="Paiement client">Paiement client</Option>
          <Option value="Apport d'un dirigeant">Apport d'un dirigeant</Option>
          <Option value="Autre">Autre</Option>
        </Select>
      ) : null}
    </>
  );
}

export default OperationNature;

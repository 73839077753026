function TextAnalysis(props) {
  return (
    <>
      {props.analysisData.text[props.type] !== undefined ? (
        <div dangerouslySetInnerHTML={{ __html: props.analysisData.text[props.type]["text"] }} />
      ) : (
        <div>
          <p>{props.analysisData.text}</p>
        </div>
      )}
    </>
  );
}

export default TextAnalysis;

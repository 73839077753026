import {
  BackwardOutlined,
  BankOutlined,
  CopyOutlined,
  ForwardOutlined,
  LoadingOutlined,
  PlusOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { Alert, Button, Divider, Form, Input, Modal, Radio, Select, Switch } from "antd";
import { EFeatures } from "enums/EFeatures";
import { useEffect, useState } from "react";
import { hasFeatures } from "utils/security";
import * as API from "../../../api/API";
import logAction from "../../../utils/logActions";
import openNotification from "../../../utils/notification";

const { Option } = Select;

const genRandomHex = (size) =>
  [...Array(size)].map(() => Math.floor(Math.random() * 16).toString(16)).join("");

function CreateAccountingFirm(props) {
  const [form] = Form.useForm();
  const [createFirm, setCreateFirm] = useState(false);
  const [alert, setAlert] = useState(false);
  const [name, setName] = useState("");
  const [step, setStep] = useState(1);
  const [hubspotConnection, setHubspotConnection] = useState(false);

  const [hubspotLoading, setHubspotLoading] = useState(false);

  const [selectedDataProvider, setSelectedDataProvider] = useState("");
  const [accFirmIdForm, setAccFirmIdForm] = useState(genRandomHex(32));
  const [dataProviders, setDataProviders] = useState([
    "ACD",
    "AGIRIS",
    "AGIRIS old",
    "CEGID EXPERT v1",
    "CEGID EXPERT",
    "CEGID LOOP",
    "CEGID QUADRA",
    "EBP",
    "IBIZA",
    "MYUNISOFT",
    "QUICKBOOKS",
    "SAGE",
    "FULLL",
    "HORUS",
    "INQOM",
  ]);
  const offers = [
    {
      value: process.env.REACT_APP_PROABONO_ESSAI_14_JOURS,
      label: "Essai 14 jours",
    },
    {
      value: process.env.REACT_APP_PROABONO_ESSAI_1_MOIS,
      label: "Essai 1 mois",
    },
    {
      value: process.env.REACT_APP_PROABONO_ESSAI_2_MOIS,
      label: "Essai 2 mois",
    },
    {
      value: "b2361e3e-f191-44f1-9b16-e3bead3b82ea",
      label: "Bêta-test Innest",
    },
  ];

  const onNameChange = (event) => {
    setName(event.target.value);
  };

  const handleChangeDataProvider = (value) => {
    setSelectedDataProvider(value);
  };

  const addItem = () => {
    setDataProviders([...dataProviders, name]);
    setName("");
  };

  useEffect(() => {
    form.setFieldsValue({
      id: accFirmIdForm,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accFirmIdForm]);

  const onFinish = (values) => {
    (async () => {
      const resA = await API.postAccountingFirm(values);
      const resB = await API.postAccountingFirmBackend({
        firm_id: values.id,
        firm_name: values.name,
        data_provider: values.dataProvider,
      });
      if (resA.status === 404 || resB.status === 404) {
        setAlert(true);
      } else {
        setCreateFirm(false);
        props.updateFirm(true);
        openNotification("success", "Cabinet comptable créé avec succès");
        logAction(60, 1, null, values.id);
        reset();
      }
    })();
  };

  const reset = () => {
    setStep(1);
    setHubspotConnection(false);
    setHubspotLoading(false);
    form.resetFields();
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const validateMessages = {
    // eslint-disable-next-line no-template-curly-in-string
    required: "${label} doit être renseigné",
  };

  const checkDomainName = () => {
    setHubspotLoading(true);
    API.checkCompanyById({ id: accFirmIdForm }).then(async (company) => {
      const companyRes = await company.json();
      if (!companyRes.exist) setHubspotConnection(false);
      else setHubspotConnection(true);
      setHubspotLoading(false);
    });
  };

  return (
    <div className="create-user">
      {hasFeatures([EFeatures.SUPERADMIN_WRITE, EFeatures.SUPERADMIN_TECH]) && (
        <Button
          className="call-action-btn"
          onClick={(e) => {
            e.preventDefault();
            setCreateFirm(!createFirm);
            setAccFirmIdForm(genRandomHex(32));
          }}
          icon={<BankOutlined />}
        >
          <span style={{ marginBottom: "1px" }}>Ajouter un cabinet</span>
        </Button>
      )}
      <Modal
        title="Création d'un nouveau cabinet comptable"
        className="dashboard-modal"
        open={createFirm}
        onCancel={(e) => {
          e.preventDefault();
          setCreateFirm(false);
          reset();
        }}
        footer={null}
        forceRender
      >
        {alert === true ? (
          <Alert
            className="alert-duplicate"
            message="Erreur lors de l'ajout du cabinet comptable"
            type="error"
            closable
            banner
            onClose={() => setAlert(false)}
          />
        ) : null}

        <Form
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          labelAlign="left"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          validateMessages={validateMessages}
          initialValues={{
            fec: "true",
          }}
        >
          <Form.Item
            name="id"
            label="Identifiant"
            hidden={step === 2}
            rules={[{ required: true }, { max: 50, message: "50 caractères maximum" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="name"
            label="Nom"
            hidden={step === 2}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item className="select-role" name="fec" label="Mode d'import" hidden={step === 2}>
            <Radio.Group
              options={[
                { label: "FEC", value: "true" },
                { label: "Automatique", value: "false" },
              ]}
              optionType="button"
              buttonStyle="solid"
            />
          </Form.Item>
          <Form.Item
            className="select-dataprovider"
            name="dataProvider"
            label="Logiciel comptable"
            hidden={step === 2}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              onChange={handleChangeDataProvider}
              dropdownRender={(menu) => (
                <div>
                  {menu}
                  <Divider style={{ margin: "4px 0" }} />
                  <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                    <Input style={{ flex: "auto" }} value={name} onChange={onNameChange} />
                    <Button
                      type="link"
                      style={{
                        flex: "none",
                        padding: "8px",
                        display: "block",
                        cursor: "pointer",
                      }}
                      onClick={addItem}
                    >
                      <PlusOutlined /> Ajouter
                    </Button>
                  </div>
                </div>
              )}
              placement="bottomLeft"
              dropdownAlign={{ overflow: { adjustY: false } }}
            >
              {dataProviders.map((item) => (
                <Option key={item}>{item}</Option>
              ))}
            </Select>
          </Form.Item>
          {selectedDataProvider === "ACD" ? (
            <>
              <Form.Item name="acdUrl" label="URL ACD" hidden={step === 2}>
                <Input />
              </Form.Item>
              <Form.Item name="cnx" label="CNX" hidden={step === 2}>
                <Input prefix={"CNX"} />
              </Form.Item>
            </>
          ) : null}
          {selectedDataProvider === "CEGID LOOP" ? (
            <>
              <Form.Item name="cabinetDomain" label="Domaine" hidden={step === 2}>
                <Input />
              </Form.Item>
            </>
          ) : null}
          <Form.Item name="offer" label="Offre" hidden={step === 2}>
            <Select
              options={offers}
              placement="bottomLeft"
              dropdownAlign={{ overflow: { adjustY: false } }}
            />
          </Form.Item>
          <Form.Item
            className="select-role"
            name="mcf"
            label="Connexion à MCF"
            hidden={step === 2}
            valuePropName="unchecked"
          >
            <Switch checkedChildren="Oui" unCheckedChildren="Non" />
          </Form.Item>
          <Form.Item
            className="select-role"
            name="iDocus"
            label="Connexion à iDocus"
            hidden={step === 2}
            valuePropName="unchecked"
          >
            <Switch checkedChildren="Oui" unCheckedChildren="Non" />
          </Form.Item>
          <Form.Item
            className="select-role"
            name="conciliator"
            label="Connexion à Conciliator"
            hidden={step === 2}
            valuePropName="unchecked"
          >
            <Switch checkedChildren="Oui" unCheckedChildren="Non" />
          </Form.Item>
          <Form.Item wrapperCol={{ span: 24 }}>
            <Button
              block
              className="call-action-btn modal-submit-btn"
              htmlType="button"
              onClick={() => setStep(2)}
              style={{ display: step === 2 ? "none" : "" }}
              icon={<ForwardOutlined />}
            >
              Suivant
            </Button>
          </Form.Item>

          {step === 2 ? (
            <div style={{ marginTop: "-50px" }}>
              <p>
                Afin de synchroniser Sesha avec Hubspot, copier cet identifiant dans la propriété{" "}
                <b>Identifiant Sesha</b> de la transaction <b>Abonnement</b> associée:
              </p>
              <Alert
                style={{ textAlign: "center", height: "80px" }}
                message={
                  <>
                    <h4 style={{ marginTop: "18px", fontWeight: "500" }}>
                      {accFirmIdForm}{" "}
                      <CopyOutlined
                        onClick={() => {
                          navigator.clipboard.writeText(accFirmIdForm);
                        }}
                      />
                    </h4>
                  </>
                }
                type={hubspotConnection ? "success" : "error"}
                showIcon
              />
              <br />
              <Button
                block
                className="call-action-btn-white modal-submit-btn"
                shape="round"
                onClick={checkDomainName}
              >
                {hubspotLoading ? <LoadingOutlined /> : <ReloadOutlined />} Vérifier la connexion
              </Button>
              <br />
              <br />
              <Form.Item wrapperCol={{ span: 24 }}>
                <Button
                  block
                  className="call-action-btn-white modal-submit-btn"
                  icon={<BackwardOutlined />}
                  onClick={() => setStep(1)}
                  style={{ width: "49%", marginRight: "2%" }}
                >
                  Précédent
                </Button>
                <Button
                  block
                  className="call-action-btn modal-submit-btn"
                  htmlType="submit"
                  style={{ width: "49%" }}
                  icon={<BankOutlined />}
                >
                  Créer
                </Button>
              </Form.Item>
            </div>
          ) : null}
        </Form>
      </Modal>
    </div>
  );
}

export default CreateAccountingFirm;

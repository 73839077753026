import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Input } from "antd";
import { useContext, useEffect, useState } from "react";
import ClientContext from "../../../../../context/ClientContext";
import * as OutstandingHelper from "../../../../../helpers/client/clientOutstandingsHelper";

const Comment = (props) => {
  const [editing, setEditing] = useState(false);
  const [comment, setComment] = useState(props.record.commentClient);

  const context = useContext(ClientContext);
  const idClient = context.idClient;

  useEffect(() => {
    setComment(props.record.commentClient);
  }, [props.record]);

  const handleOnPressEnter = async (comment) => {
    const res = await OutstandingHelper.addCommentHelper(
      comment,
      idClient,
      props.type,
      props.record,
      props.accountData,
      props.data
    );
    if (!res) return;
    setComment(comment);
    props.setAccountData(res);
  };

  const handleDelete = async () => {
    const res = await OutstandingHelper.deleteCommentHelper(
      idClient,
      props.type,
      props.record,
      props.accountData,
      props.data
    );
    if (!res) return;
    setComment("");
    props.setAccountData(res);
  };

  return (
    <>
      {comment ? (
        <>
          {!editing ? (
            <>
              <p className="other-span">{comment}</p>
            </>
          ) : (
            <p className="other-edit-span">
              <Input
                className="other-edit"
                type="text"
                defaultValue={comment}
                onPressEnter={(e) => {
                  handleOnPressEnter(e.target.value);
                  setEditing(false);
                }}
              />
            </p>
          )}
          <Button
            className="other-action-btn"
            shape="circle"
            icon={<EditOutlined />}
            onClick={(e) => setEditing(!editing)}
          />
          <Button
            className="other-action-btn other-delete-btn"
            shape="circle"
            icon={<DeleteOutlined />}
            onClick={(e) => handleDelete()}
          />
        </>
      ) : (
        <>
          <Input
            className="other-input"
            type="text"
            onPressEnter={(e) => handleOnPressEnter(e.target.value)}
            placeholder="Ajouter un commentaire"
          />
          <span className="help-txt">Appuyez sur « Entrée » pour valider</span>
        </>
      )}
    </>
  );
};

export default Comment;

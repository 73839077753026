import { CloudDownloadOutlined, LinkOutlined } from "@ant-design/icons";
import { Button, Table, notification } from "antd";
import { useEffect, useRef, useState } from "react";
import ColoredCapsule from "utils/ColoredCapsule";

import * as API from "../../../../api/API";
import openNotification from "../../../../utils/notification";
import { addSearchAndSorterToColumns } from "../../../../utils/table";

import { CopyOutlined } from "@ant-design/icons";

function MonitoringTable(props) {
  const [clientsData, setClientsData] = useState();

  const [clientsDataLoading, setClientsDataLoading] = useState(true);

  const [searchTexts, setSearchTexts] = useState({});
  const [searchedColumn, setSearchedColumn] = useState([]);
  const searchInput = useRef(null);

  let searchVariables = {
    searchTexts,
    setSearchTexts,
    searchedColumn,
    setSearchedColumn,
    searchInput: searchInput,
  };

  useEffect(() => {
    (async () => {
      setClientsDataLoading(true);

      const clientList = props.displayedClients.map((e) => e._id);
      const userList = props.displayedUsers.map((e) => e._id);
      let res = await API.createAndGetMonitoringHistory(props.currentAccFirmId, {
        clientList: clientList,
        userList: userList,
      });
      res = await res.json();

      res.forEach((event) => {
        let user = props.displayedUsers.find((user) => user._id === event.userId);
        let client = props.displayedClients.find((client) => client._id === event.clientId);

        event.key = event.eventId;
        event.userName =
          event.userId === "unknownUser" ? event.userId : user?.lastName + " " + user?.firstName;
        event.clientName = client?.name;
      });

      setClientsData(res);

      setClientsDataLoading(false);
    })();
  }, [props.displayedClients, props.displayedUsers]);

  const downloadS3 = (jsonId) => {
    (async () => {
      let res = await API.getFileFromS3(jsonId);
      if (res.status === 500)
        openNotification("error", "Erreur lors de la récupération du fichier dans le S3");
      else {
        res = await res.blob();
        const url = URL.createObjectURL(res);
        const link = document.createElement("a");

        link.setAttribute("href", url);
        link.setAttribute("download", jsonId);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    })();
  };

  function typeDownloadJson(keyOnS3) {
    return (
      <>
        {keyOnS3 ? (
          <Button
            onClick={(e) => {
              e.preventDefault();
              downloadS3(keyOnS3);
            }}
            icon={<CloudDownloadOutlined style={{ color: "blue" }} />}
          ></Button>
        ) : (
          ""
        )}
      </>
    );
  }

  function statusCodeRender(status) {
    if (status) return <ColoredCapsule status={status === "200" ? "ok" : "ko"} content={status} />;
    else return <></>;
  }

  const columns = [
    {
      title: "Date",
      dataIndex: "callDate",
      key: "date",
      align: "center",
      width: "40px",
    },
    {
      title: "Heure",
      dataIndex: "callHours",
      key: "hours",
      align: "center",
      width: "40px",
    },
    {
      title: "Event ID",
      dataIndex: "eventId",
      key: "eventId",
      align: "center",
      width: "80px",
      render: (eventId) => {
        const openNotification = () => {
          notification.info({
            message: "eventID copié",
            description: eventId,
          });
        };
        return (
          <>
            <Button
              type="text"
              onClick={() => {
                openNotification();
                navigator.clipboard.writeText(eventId);
              }}
            >
              <CopyOutlined style={{ fontSize: "20px", color: "#4569F8" }} />
            </Button>
          </>
        );
      },
    },
    {
      title: "Nom",
      dataIndex: "clientName",
      key: "date",
      align: "center",
      width: "40px",
    },
    {
      title: "Utilisateur",
      dataIndex: "userName",
      key: "userName",
      align: "center",
      width: "50px",
    },
    {
      title: "Lien Sentry",
      dataIndex: "eventId",
      key: "eventId",
      align: "center",
      width: "80px",
      render: (eventId) =>
        eventId ? (
          <Button
            type="text"
            href={"https://addventa.sentry.io/issues/?query=eventId:" + eventId}
            target="_blank"
          >
            <LinkOutlined style={{ color: "blue" }} />
          </Button>
        ) : (
          ""
        ),
    },
    {
      title: "Analyse",
      children: [
        {
          title: "Status",
          dataIndex: ["analyse", "status"],
          key: "analyse.status",
          align: "center",
          width: "40px",
          render: statusCodeRender,
        },
        {
          title: "JSON",
          dataIndex: ["analyse", "keyOnS3"],
          key: "analyse.keyOnS3",
          align: "center",
          width: "40px",
          render: (keyOnS3) => typeDownloadJson(keyOnS3),
          defaultSearch: false,
        },
      ],
    },
    {
      title: "Points d'attention",
      children: [
        {
          title: "Status",
          dataIndex: ["key_points", "status"],
          key: "key_points.status",
          align: "center",
          width: "40px",
          render: statusCodeRender,
        },
        {
          title: "JSON",
          dataIndex: ["key_points", "keyOnS3"],
          key: "key_points.keyOnS3",
          align: "center",
          width: "40px",
          render: (keyOnS3) => typeDownloadJson(keyOnS3),
          defaultSearch: false,
        },
      ],
    },
    {
      title: "Pièces manquantes",
      children: [
        {
          title: "Status",
          dataIndex: ["missing_pieces", "status"],
          key: "missing_pieces.status",
          align: "center",
          width: "40px",
          render: statusCodeRender,
        },
        {
          title: "JSON",
          dataIndex: ["missing_pieces", "keyOnS3"],
          key: "missing_pieces.keyOnS3",
          align: "center",
          width: "40px",
          render: (keyOnS3) => typeDownloadJson(keyOnS3),
          defaultSearch: false,
        },
      ],
    },
    {
      title: "Pièces manquantes 2",
      children: [
        {
          title: "Status",
          dataIndex: ["missing_pieces_2", "status"],
          key: "missing_pieces_2.status",
          align: "center",
          width: "40px",
          render: statusCodeRender,
        },
        {
          title: "JSON",
          dataIndex: ["missing_pieces_2", "keyOnS3"],
          key: "missing_pieces_2.keyOnS3",
          align: "center",
          width: "40px",
          render: (keyOnS3) => typeDownloadJson(keyOnS3),
          defaultSearch: false,
        },
      ],
    },
    {
      title: "Encours clients",
      children: [
        {
          title: "Status",
          dataIndex: ["outstandings_clients", "status"],
          key: "outstandings_clients.status",
          align: "center",
          width: "40px",
          render: statusCodeRender,
        },
        {
          title: "JSON",
          dataIndex: ["outstandings_clients", "keyOnS3"],
          key: "outstandings_clients.keyOnS3",
          align: "center",
          width: "40px",
          render: (keyOnS3) => typeDownloadJson(keyOnS3),
          defaultSearch: false,
        },
      ],
    },
    {
      title: "Encours fournisseurs",
      children: [
        {
          title: "Status",
          dataIndex: ["outstandings_providers", "status"],
          key: "outstandings_providers.status",
          align: "center",
          width: "40px",
          render: statusCodeRender,
          defaultFilters: true,
        },
        {
          title: "JSON",
          dataIndex: ["outstandings_providers", "keyOnS3"],
          key: "outstandings_providers.keyOnS3",
          align: "center",
          width: "40px",
          render: (keyOnS3) => typeDownloadJson(keyOnS3),
          defaultSearch: false,
        },
      ],
    },
  ];

  addSearchAndSorterToColumns(columns, searchVariables, clientsData);

  return (
    <Table
      columns={columns}
      loading={clientsDataLoading}
      dataSource={clientsData}
      size="small"
      bordered
      pagination={true}
      rowKey={(record) => record.key}
      scroll={{
        x: "max-content",
      }}
    />
  );
}

export default MonitoringTable;
